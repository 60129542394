<div class="container mb-2 mb-lg-4">
  <h2 *ngIf="isRecommendedProducts" class="section-title text-center mb-0">
    Cele mai recomandate produse
  </h2>
  <p *ngIf="isRecommendedProducts" class="section-description text-center mb-3">
    Cele mai recomandate produse din catalogul nostru
  </p>

  <div class="row pb-2">
    <ng-container *ngIf="isRecommendedProducts">
      <div class="col-sm-4" *ngFor="let product of products">
        <div class="product-default inner-quickview inner-icon">
          <figure>
            <a
              *ngIf="product?.selectedImage"
              routerLink="/produs/{{ product?.alias }}/{{ product?.id }}"
            >
              <ng-container *ngFor="let image of product?.selectedImage.url">
                <img src="{{ uploadedImgUrl }}{{ image.url }}" alt="image" />
              </ng-container>
            </a>
            <div class="label-group">
              <div
                class="product-label label-sale"
                *ngIf="product?.selectedSize.old_price > 0"
              >
                {{
                  100 -
                    (product?.selectedSize.current_price /
                      product?.selectedSize.old_price) *
                      100 | number : "1.0-0"
                }}
                %
              </div>

              <div class="product-label label-hot">Recomandat</div>
            </div>
            <!-- <div>
              <span class="stoc-alert product-label label-sale" *ngIf="product?.selectedSize.stoc == 3">Ultimele trei produse</span>
              <span class="stoc-alert product-label label-sale" *ngIf="product?.selectedSize.stoc == 2">Ultimele doua produse</span>
              <span class="stoc-alert product-label label-sale" *ngIf="product?.selectedSize.stoc == 1">Ultimul produs</span>
            </div> -->
            <!-- <div class="btn-icon-group">
              <button class="btn-icon btn-add-cart" (click)="addToCart(product); addedToCart.triggerModal(product)"><i
                  class="icon-shopping-cart"></i></button>
            </div> -->
            <a
              (click)="quickView.triggerModal(product)"
              class="btn-quickview"
              title="Vezi Produs"
              >Vezi Produs</a
            >
          </figure>
          <div class="product-details">
            <div class="category-wrap">
              <!-- <div class="category-list">
                <ng-container *ngFor="let category of product?.categories; let isLast = last">
                  <a *ngFor="let subcategory of category.subcategories"
                    routerLink="/produse/{{category.category_name.toLowerCase()}}/{{subcategory.subcategory_name.toLowerCase()}}-{{category.category_name.toLowerCase()}}"
                    class="product-category"> {{subcategory.subcategory_name}} {{category.category_name}}
                    {{isLast ? ' ' : ' , '}} </a>
                </ng-container>
  
              </div> -->
              <a
                *ngIf="!product?.selectedColor.addedToFavorites"
                (click)="toggleFavorites(product)"
                class="btn-icon-wish"
                ><i class="icon-heart"></i
              ></a>
              <a
                *ngIf="product?.selectedColor.addedToFavorites"
                (click)="toggleFavorites(product)"
                class="btn-icon-wish"
                ><i class="fas fa-heart"></i
              ></a>
            </div>
            <div class="product-select-group">
              <!-- <ng-container *ngFor="let color of product?.colors; first as isFirstColor; let i = index">
                <a *ngIf="color.color_list.length === 1" (click)="selectColor(product, color)"
                  class="product-select type-image"
                  [ngClass]="{'checked': product.selectedColor.ColorID === color.ColorID}"
                  [style.background-color]="color.color_list[0]"></a>
                <a *ngIf="color.color_list.length === 0" (click)="selectColor(product, color)"
                  class="product-select type-image"
                  [ngClass]="{'checked': product.selectedColor.ColorID === color.ColorID}"
                  style="background-color: #fff;"></a>
                <a (click)="selectColor(product, color)" class="product-select type-image two-colors"
                  [ngClass]="{'checked': product.selectedColor.ColorID === color.ColorID}"
                  *ngIf="color.color_list.length > 1">
                  <span [style.background-color]="color.color_list[0]"></span>
                  <span [style.background-color]="color.color_list[1]"></span>
                </a>
              </ng-container> -->
              <ng-container *ngFor="let color of product?.colors">
                <ul class="color-container">
                  <li>
                    <a
                      (click)="
                        selectColor(product, color); changeImage(image.url)
                      "
                      class="d-flex align-items-center justify-content-center p-0"
                      [ngClass]="{
                        checked: product.selectedColor.ColorID === color.ColorID
                      }"
                    >
                      <img
                        [src]="uploadedImgUrl + color.images[0].url"
                        width="30"
                        height="30"
                        alt="filter-img"
                      />
                    </a>
                  </li>
                </ul>
              </ng-container>
              <!-- <ul class="sizes">
                <li *ngFor="let size of product?.sizes">
                  <a (click)="selectSize(product, size);" class="product-select type-image"
                    [ngClass]="{'checked': product.selectedSize.SizeID === size.SizeID}">{{size.size_name}}</a>
                </li>
              </ul> -->
            </div>
            <h2 class="product-title">
              <a routerLink="/produs/{{ product?.alias }}/{{ product?.id }}">{{
                product?.name
              }}</a>
            </h2>
            <div class="ratings-container">
              <div class="product-ratings">
                <!-- <ng-template #t let-fill="fill">
                    <span class="star" [class.full]="fill === 100">
                      <span class="half" [style.width.%]="fill">&#9733;</span>&#9734;
                    </span>
                  </ng-template>
                  <ngb-rating [(rate)]="product.rating" [starTemplate]="t" [readonly]="true" max="5"></ngb-rating>
                  <span class="tooltiptext tooltip-top">{{product?.rating}}</span> -->
              </div>
              <!-- End .product-ratings -->
            </div>
            <!-- End .product-container -->
            <div class="price-box">
              <span
                class="product-price"
                [class.reduced]="product?.selectedSize.old_price > 0"
                >{{ product?.selectedSize.current_price }} Lei</span
              >
              <span
                *ngIf="product?.selectedSize.old_price > 0"
                class="old-price"
                >{{ product?.selectedSize.old_price }} Lei</span
              >
            </div>
            <!-- End .price-box -->
          </div>
          <!-- End .product-details -->
        </div>
      </div>
      <!-- End .col-sm-4 -->
    </ng-container>
  </div>
  <!-- End .row -->

  <hr class="mt-1" />

  <div class="row">
    <div class="col-md-4 pb-5 pb-md-0">
      <h4 class="section-sub-title mb-2">Recent adaugate</h4>

      <div
        class="product-default small-view-product left-details product-widget"
        *ngFor="let product of recentAddedProducts"
      >
        <figure>
          <a
            *ngIf="product?.selectedImage"
            routerLink="/produs/{{ product?.alias }}/{{ product?.id }}"
          >
            <ng-container *ngFor="let image of product?.selectedImage.url">
              <img src="{{ uploadedImgUrl }}{{ image.url }}" alt="image" />
            </ng-container>
          </a>
          <div class="label-group">
            <div
              class="product-label label-sale"
              *ngIf="product?.selectedSize.old_price > 0"
            >
              {{
                100 -
                  (product?.selectedSize.current_price /
                    product?.selectedSize.old_price) *
                    100 | number : "1.0-0"
              }}
              %
            </div>
          </div>
        </figure>
        <div class="product-details">
          <div class="category-wrap">
            <div class="category-list">
              <ng-container
                *ngFor="let category of product?.categories; let isLast = last"
              >
                <a
                  *ngFor="let subcategory of category.subcategories"
                  routerLink="/produse/{{
                    category.category_name.toLowerCase()
                  }}/{{ subcategory.subcategory_name.toLowerCase() }}-{{
                    category.category_name.toLowerCase()
                  }}"
                  class="product-category"
                  >{{ subcategory.subcategory_name }} {{ category.name
                  }}{{ isLast ? " " : ", " }}
                </a>
              </ng-container>
            </div>
            <!-- <a href="#" class="btn-icon-wish"><i class="icon-heart"></i></a> -->
          </div>

          <h2 class="product-title">
            <a routerLink="/produs/{{ product?.alias }}/{{ product?.id }}">{{
              product?.name
            }}</a>
          </h2>
          <div class="ratings-container">
            <div class="product-ratings">
              <!-- <ng-template #t let-fill="fill">
                <span class="star" [class.full]="fill === 100">
                  <span class="half" [style.width.%]="fill">&#9733;</span>&#9734;
                </span>
              </ng-template>
              <ngb-rating [(rate)]="product.rating" [starTemplate]="t" [readonly]="true" max="5"></ngb-rating>
              <span class="tooltiptext tooltip-top">{{product?.rating}}</span> -->
            </div>
            <!-- End .product-ratings -->
          </div>
          <!-- End .product-container -->
          <div class="price-box">
            <span
              class="product-price"
              [class.reduced]="product?.selectedSize.old_price > 0"
              >{{ product?.selectedSize.current_price }} Lei</span
            >
            <span *ngIf="product?.selectedSize.old_price > 0" class="old-price"
              >{{ product?.selectedSize.old_price }} Lei</span
            >
          </div>
          <!-- End .price-box -->
        </div>
        <!-- End .product-details -->
      </div>
    </div>
    <div class="col-md-4 pb-5 pb-md-0">
      <h4 class="section-sub-title mb-2">Produse la reducere</h4>
      <div
        class="product-default small-view-product left-details product-widget"
        *ngFor="let product of mostSelledProducts"
      >
        <figure>
          <a
            *ngIf="product?.selectedImage"
            routerLink="/produs/{{ product?.alias }}/{{ product?.id }}"
          >
            <img
              src="{{ uploadedImgUrl }}{{ product.selectedImageObj }}"
              alt="image"
            />
          </a>
          <div class="label-group">
            <div
              class="product-label label-sale"
              *ngIf="product?.selectedSize.old_price > 0"
            >
              {{
                100 -
                  (product?.selectedSize.current_price /
                    product?.selectedSize.old_price) *
                    100 | number : "1.0-0"
              }}
              %
            </div>
          </div>
        </figure>
        <div class="product-details">
          <h2 class="product-title">
            <a routerLink="/produs/{{ product?.alias }}/{{ product?.id }}">{{
              product?.name
            }}</a>
          </h2>
          <div class="ratings-container">
            <!-- <div class="product-ratings">
                              <span class="ratings" style="width:100%"></span>
                              <span class="tooltiptext tooltip-top"></span>
                          </div> -->
          </div>
          <!-- End .product-container -->
          <div class="price-box">
            <span
              class="product-price"
              [class.reduced]="product?.selectedSize.old_price > 0"
              >{{ product?.selectedSize.current_price }} Lei</span
            >
            <span *ngIf="product?.selectedSize.old_price > 0" class="old-price"
              >{{ product?.selectedSize.old_price }} Lei</span
            >
          </div>
          <!-- End .price-box -->
        </div>
        <!-- End .product-details -->
      </div>
    </div>
    <div class="col-md-4 pb-5 pb-md-0">
      <h4 class="section-sub-title mb-2">Cele mai vizualizate</h4>
      <div
        class="product-default small-view-product left-details product-widget"
        *ngFor="let product of mostViewedProducts"
      >
        <figure>
          <a
            *ngIf="product?.selectedImage"
            routerLink="/produs/{{ product?.alias }}/{{ product?.id }}"
          >
            <ng-container *ngFor="let image of product?.selectedImage.url">
              <img src="{{ uploadedImgUrl }}{{ image.url }}" alt="image" />
            </ng-container>
          </a>
          <div class="label-group">
            <div
              class="product-label label-sale"
              *ngIf="product?.selectedSize.old_price > 0"
            >
              {{
                100 -
                  (product?.selectedSize.current_price /
                    product?.selectedSize.old_price) *
                    100 | number : "1.0-0"
              }}
              %
            </div>
          </div>
        </figure>
        <div class="product-details">
          <div class="category-wrap">
            <div class="category-list">
              <ng-container
                *ngFor="let category of product?.categories; let isLast = last"
              >
                <a
                  *ngFor="let subcategory of category.subcategories"
                  routerLink="/produse/{{
                    category.category_name.toLowerCase()
                  }}/{{ subcategory.subcategory_name.toLowerCase() }}-{{
                    category.category_name.toLowerCase()
                  }}"
                  class="product-category"
                  >{{ subcategory.subcategory_name }} {{ category.name
                  }}{{ isLast ? " " : ", " }}
                </a>
              </ng-container>
            </div>
            <!-- <a href="#" class="btn-icon-wish"><i class="icon-heart"></i></a> -->
          </div>

          <h2 class="product-title">
            <a routerLink="/produs/{{ product?.alias }}/{{ product?.id }}">{{
              product?.name
            }}</a>
          </h2>
          <div class="ratings-container">
            <div class="product-ratings">
              <!-- <ng-template #t let-fill="fill">
                <span class="star" [class.full]="fill === 100">
                  <span class="half" [style.width.%]="fill">&#9733;</span>&#9734;
                </span>
              </ng-template>
              <ngb-rating [(rate)]="product.rating" [starTemplate]="t" [readonly]="true" max="5"></ngb-rating>
              <span class="tooltiptext tooltip-top">{{product?.rating}}</span> -->
            </div>
            <!-- End .product-ratings -->
          </div>
          <!-- End .product-container -->
          <div class="price-box">
            <span
              class="product-price"
              [class.reduced]="product?.selectedSize.old_price > 0"
              >{{ product?.selectedSize.current_price }} Lei</span
            >
            <span *ngIf="product?.selectedSize.old_price > 0" class="old-price"
              >{{ product?.selectedSize.old_price }} Lei</span
            >
          </div>
          <!-- End .price-box -->
        </div>
        <!-- End .product-details -->
      </div>
    </div>
  </div>
  <!-- End .row -->
</div>
<!-- End .container -->
<app-product-quickview #quickView></app-product-quickview>
<app-added-to-cart #addedToCart></app-added-to-cart>
