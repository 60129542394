import { HttpClient } from '@angular/common/http';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Product, ProductInterface } from '../models';
import { Color } from '../models/color.model';
import { Category } from '../models/category.model';
import { Images } from '../models/image.model';
@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private _apiUrl: string;
  productCatalog: string;
  theMarketer: string;
  favoriteProducts: any[];

  constructor(
    private _httpClient: HttpClient,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this._apiUrl = environment.apiUrl;
    this.productCatalog = environment.productCatalog;
    this.theMarketer = environment.theMarketer;
    if (isPlatformBrowser(this.platformId)) {
      this.favoriteProducts = JSON.parse(
        localStorage.getItem('shardorayFavorites') || '[]'
      );
    }
  }

  private productsSubject = new BehaviorSubject<Product[]>([]);

  products$ = this.productsSubject.asObservable();
  productsSearch$ = new BehaviorSubject<Product[]>([]);
  categories$ = new BehaviorSubject<any>([]);
  productsOnSeason$ = new BehaviorSubject<Product[]>([]);
  searchProductValue$ = new BehaviorSubject<string>('');
  recommendedProducts$ = new BehaviorSubject<Product[]>([]);
  productsAll$ = new BehaviorSubject<Product[]>([]);
  selectedColorChecked$ = new BehaviorSubject<any>([]);
  selectedSizeChecked$ = new BehaviorSubject<any>([]);
  selectedSortChecked$ = new BehaviorSubject<any>([]);
  selectedPriceChecked$ = new BehaviorSubject<any>([]);
  /**
   * Get products client
   *
   * @returns {Promise<any>}
   */
  getProductsAll(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + `products`)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get products client
   *
   * @returns {Promise<any>}
   */
  getProductsComplementary(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + `products/complementary`)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get products client
   *
   * @returns {Promise<any>}
   */
  getProductsAllPaginate(params, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + `products/paginate/${params.page}/${params.size}`, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get products admin
   *
   * @returns {Promise<any>}
   */
  getProductsAdminAll(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + 'products/adminNew')
        .subscribe((response: any) => {
          this.productsSubject.next(response);
          resolve(response);
        }, reject);
    });
  }

  /**
   * search products
   *
   * @returns {Promise<any>}
   */
  searchProducts(options, search): Observable<any> {
    return this._httpClient.get(
      this._apiUrl + `products/search/${search}`,
      options
    );
  }
  /**
   * Fetch search products
   *
   * @returns {Promise<any>}
   */
  fetchSearchProducts(search): Observable<any> {
    return this._httpClient.get(this._apiUrl + `products/search/${search}`);
  }
  /**
   * Fetch search products
   *
   * @returns {Promise<any>}
   */
  fetchMaxPriceByCategory(categoryAlias, subcagoryAlias): Observable<any> {
    return this._httpClient.get(this._apiUrl + `products/maxPriceByCategory/${categoryAlias}/${subcagoryAlias}`);
  }

  /**
   * search products images
   *
   * @returns {Promise<any>}
   */
  searchProductsImages(options, search): Observable<any> {
    return this._httpClient.get(
      this._apiUrl + `products/searchImages/${search}`,
      options
    );
  }
  /**
   * search products
   *
   * @returns {Promise<any>}
   */
  searchProductsClient(params): Observable<any> {
    return this._httpClient.get(
      this._apiUrl +
      `products/searchClient/${params.page}/${params.size}/${params.title}`
    );
  }
  /**
   * product images
   *
   * @returns {Promise<any>}
   */
  getProductsImagesPaginate(params): Observable<any> {
    return this._httpClient.get(
      this._apiUrl +
      `products/admin/images/paginate/${params.page}/${params.size}`
    );
  }

  /**
   * Get products admin
   *
   * @returns {Promise<any>}
   */
  getProductsAdminAllPaginate(options, params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(
          this._apiUrl +
          `products/admin/paginate/${params.page}/${params.size}`,
          options
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get products admin without stoc
   *
   * @returns {Promise<any>}
   */
  getProductsWithoutStoc(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + 'products/admin/productsWithoutStoc')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get discount products
   *
   * @returns {Promise<any>}
   */

  public getDiscountProducts(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Product[]>(this._apiUrl + 'products/discountProducts')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get recommended products
   *
   * @returns {Promise<any>}
   */

  public getRecommendedProducts(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Product[]>(this._apiUrl + 'products/recommendedProducts')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get special products
   *
   * @returns {Promise<any>}
   */

  public getProductsSpecial(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Product[]>(this._apiUrl + 'products/special')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get recommended products
   *
   * @returns {Promise<any>}
   */

  public getRecentAddedProducts(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Product[]>(this._apiUrl + 'products/recentAddedProducts')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get recommended products
   *
   * @returns {Promise<any>}
   */

  public getMostSelledProducts(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Product[]>(this._apiUrl + 'products/mostSelledProducts')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get recommended products
   *
   * @returns {Promise<any>}
   */

  public getMostViewedProducts(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Product[]>(this._apiUrl + 'products/mostViewedProducts')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  public getProductsByCategory(categoryAlias) {
    return this._httpClient.get<Product[]>(
      this._apiUrl + 'products/filterTest/' + categoryAlias
    );
  }

  public getProductsByCategoryPaginate(categoryAlias, subcagoryAlias, params, options) {
    
    return this._httpClient.get<Product[]>(
      this._apiUrl + `products/filterByCategoryTest/${categoryAlias}/${subcagoryAlias}/${params.page}/${params.size}`, options
    );
  }



  public getProductsFilterByCategory(categoryAlias, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Product[]>(
          this._apiUrl + 'products/filter/' + categoryAlias,
          options
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  public getProductsFilterByCategoryAndSubcategory(
    categoryAlias,
    subcategoryAlias,
    options
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Product[]>(
          this._apiUrl +
          'products/filter/' +
          categoryAlias +
          '/' +
          subcategoryAlias,
          options
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  public getProductsAdminFilterByCategoryAndSubcategory(
    categoryAlias,
    subcategoryAlias,
    options
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Product[]>(
          this._apiUrl +
          'products/admin/filter/' +
          categoryAlias +
          '/' +
          subcategoryAlias,
          options
        )
        .subscribe((response: any) => {
          this.productsSubject.next(response);
          resolve(response);
        }, reject);
    });
  }
  public getProductsByPriceAndCategoryAndSubcategory(
    categoryAlias,
    subcategoryAlias,
    options
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Product[]>(
          this._apiUrl +
          'products/filter/' +
          categoryAlias +
          '/' +
          subcategoryAlias,
          options
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  public getProductsBySizeAndCategory(categoryAlias, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Product[]>(
          this._apiUrl + 'products/filter/' + categoryAlias,
          options
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  public getProductsRecommended(category: number, subcategory: number, productID: number) {
    
    return this._httpClient.get<Product[]>(this._apiUrl + 'products/recommendedProducts/' + category + '/' + subcategory + '/' + productID).pipe(
      tap((products: any) => {
        
        this.recommendedProducts$.next(this.cleanObjProductCatalog(products));
      }),
    )
  }
  public getProductsBySizeAndCategoryAndSubcategory(
    categoryAlias,
    subcategoryAlias,
    options
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Product[]>(
          this._apiUrl +
          'products/filter/' +
          categoryAlias +
          '/' +
          subcategoryAlias,
          options
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  public getProductsByColorAndCategory(categoryAlias, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Product[]>(
          this._apiUrl + 'products/filter/' + categoryAlias,
          options
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  public getProductsByColorAndCategoryAndSubcategory(
    categoryAlias,
    subcategoryAlias,
    options
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Product[]>(
          this._apiUrl +
          'products/filter/' +
          categoryAlias +
          '/' +
          subcategoryAlias,
          options
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  public getMaxValue(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Product[]>(this._apiUrl + 'products/price/max')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  public getBestCategoryDiscount(categoryID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Product[]>(this._apiUrl + 'products/maxDiscount/' + categoryID)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  public getBestSubcategoryDiscount(
    categorySlug,
    subcategorySlug
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      // this._httpClient
      //   .get<Product[]>(
      //     this._apiUrl +
      //       'products/minDiscount/' +
      //       categorySlug +
      //       '/' +
      //       subcategorySlug
      //   )
      //   .subscribe((response: any) => {
      //     resolve(response);
      //   }, reject);
    });
  }
  public getSmallerCategoryPrice(categoryID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Product[]>(this._apiUrl + 'products/minDiscount/' + categoryID)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  public deleteProduct(ProductID, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<Product[]>(
          this._apiUrl + 'products/delete',
          { ProductID: ProductID },
          options
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  public generateCatalogFeed(products): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<Product[]>(this.productCatalog, { products: products }).subscribe((response: any) => {
          resolve(response);
        }, reject);;
    });
  }
  public generateTheMarketerFeed(products): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<Product[]>(this.theMarketer, { products: products }).subscribe((response: any) => {
          resolve(response);
        }, reject);;
    });
  }

  public cleanObjCart(products: any, existingProducts: any) {

    // Properties to retrieve from matching objects
    const propertiesToRetrieve = ['fullPrice', 'selectedColor', 'selectedImage', 'selectedImageObj', 'selectedPrice', 'selectedSize'];

    // Iterate through each object in products
    products.forEach(obj1 => {
      // Find matching object in existingProducts
      const matchingObj = existingProducts.find(obj2 =>
        obj1.name === obj2.name && obj1.age === obj2.age
      );

      // Copy desired properties from matching object
      if (matchingObj) {
        propertiesToRetrieve.forEach(prop => {
          obj1[prop] = matchingObj[prop];
        });
      }
    });

    // Output the modified products
    return products;
  }
  public cleanObj(products: any) {
    let productsRaw = products.slice();
    let productsClean = [];

    // get colors without sizes
    productsRaw.map((product) => {
      product.colors.map((color) => {
        if (color.sizes.length === 0) {
          product.colors = product.colors.filter(
            (color) => color.sizes.length > 0
          );
        }
      });
    });

    productsRaw.map((product, i) => {
      if (product.colors.length > 0) {
        productsClean.push(product);
      }
    });

    // remove colors without sizes
    // productsRaw.slice().map(product => product.colors.filter(color => !colorsWithoutSizes.find(colorWithoutSizes => colorWithoutSizes.ColorID === color.ColorID)))

    // // get products without colors
    // let productsWithoutColor = productsRaw.slice().filter(product => !product.colors);
    // // remove products without colors
    // productsRaw.slice().filter(product => !productsWithoutColor.find(productWithoutSizes => productWithoutSizes.id === product.id))

    productsClean.map((product) => {
      // replace colors without sizes again
      product.colors = product.colors.filter((color) => color.sizes.length > 0);
      product.selectedColor = product.colors[0];
      product.colors.filter((color) => {
        if (color.images.length > 0) {
          product.selectedImage.url = product.selectedColor.images;
          product.selectedImageObj = product.selectedColor.images[0].url;
        }
      });

      product.sizes = product.colors[0].sizes;
      product.selectedSize = product.sizes[0];
      product.selectedPrice = product.sizes[0].current_price;
    });

    if (this.favoriteProducts.length > 0) {
      this.favoriteProducts.forEach((favoriteProduct: any) => {
        const matchingRawProduct = productsClean.find((rawProduct: any) =>
          rawProduct.id === favoriteProduct.id &&
          rawProduct.selectedColor.ColorID === favoriteProduct.selectedColor.ColorID
        );


        
        if (matchingRawProduct) {
          matchingRawProduct.addedToFavorites = true;
          const favoriteColor = matchingRawProduct.colors.find(color => color.ColorID === favoriteProduct.selectedColor.ColorID);
          favoriteColor.addedToFavorites = true;
        }
      });
    }
    return productsClean;
  }

  public cleanObjProductCatalog(products: ProductInterface[]) {
    let productsRaw = products.slice();
    let productsClean: any[] = [];

    // remove products sizes without price
    productsRaw.map((product) =>
      product.colors.map(
        (color) =>
          (color.sizes = color.sizes.filter((size) => size.current_price > 0))
      )
    );

    // remove products sizes without stoc
    productsRaw.map((product) =>
      product.colors.map(
        (color) => (color.sizes = color.sizes.filter((size) => size.stoc > 0))
      )
    );

    // remove products colors without sizes
    productsRaw.map(
      (product) =>
      (product.colors = product.colors.filter(
        (color) => color.sizes.length > 0
      ))
    );

    // remove products colors without images
    productsRaw.map(
      (product) =>
      (product.colors = product.colors.filter(
        (color) => color.images.length > 0
      ))
    );

    // remove products without colors
    productsRaw = productsRaw.filter((product) => product.colors.length > 0);

    // remove products categories without subcategories
    productsRaw.map(
      (product) =>
      (product.categories = product.categories.filter(
        (category) => category.subcategories.length > 0
      ))
    );

    // remove products without categories
    productsRaw = productsRaw.filter(
      (product) => product.categories.length > 0
    );



    let allProductsBasedOnColors: any[] = [];
    let colors: any[] = [];

    // filter for products complemetary
    productsRaw.map((product) =>
      product.isMultiple
        ? (product.colors = product.colors.filter(
          (color) => color.ColorID === 158
        ))
        : ''
    );

    // filter for products with discount
    productsRaw.map((product) =>
      product.hasDiscount
        ? (product.hasDiscount = true)
        : (product.hasDiscount = false)
    );

    productsRaw.map((product: any) => {
      product.colors.map((color: any, j: number) => {
        allProductsBasedOnColors.push(product);
        colors.push(color);
      });
    });

    

    productsClean = allProductsBasedOnColors.map((item, i) => {
      let selectedColor = colors[i];
      let selectedPrice = selectedColor.sizes[0].current_price;
      let selectedSize = selectedColor.sizes[0];

      let selectedImage;
      let selectedImageObj;
      let images;
      if (selectedColor.images.length > 0) {
        selectedImage = selectedColor.images[0].url;
        selectedImageObj = selectedColor.images[0].url;
        images = selectedColor.images;
      }

      let sizes = selectedColor.sizes;

      //let hasDiscount = false;

      let selectedCategory: Category = item.categories[0];
      let selectedSubcategory = selectedCategory.subcategories;
      return (item = {
        ...item,
        selectedColor,
        selectedPrice,
        selectedSize,
        selectedImage,
        selectedImageObj,
        sizes,
        selectedCategory,
        selectedSubcategory,
      });
    });

    if (this.favoriteProducts.length > 0) {
      this.favoriteProducts.forEach((favoriteProduct: any) => {
        const matchingRawProduct = productsClean.find((rawProduct: any) =>
          rawProduct.id === favoriteProduct.id &&
          rawProduct.selectedColor.ColorID === favoriteProduct.selectedColor.ColorID
        );


        
        if (matchingRawProduct) {
          matchingRawProduct.addedToFavorites = true;
          const favoriteColor = matchingRawProduct.colors.find(color => color.ColorID === favoriteProduct.selectedColor.ColorID);
          favoriteColor.addedToFavorites = true;
        }
      });
    }

    return productsClean;
  }
  public cleanObjProductSearch(products: ProductInterface[]) {
    let productsRaw = products.slice();

    // remove products without colors
    productsRaw = productsRaw.filter((product) => product.selectedImageObj != '');
    // remove products without colors
    productsRaw = productsRaw.filter((product) => product.colors.length > 0);


    return productsRaw;
  }

  public cleanObjProductAdmin(products: ProductInterface[]) {
    let productsRaw = products.slice();
    let productsClean: any[] = [];

    // remove products sizes without price
    productsRaw.map((product) =>
      product.colors.map(
        (color) =>
          (color.sizes = color.sizes.filter((size) => size.current_price > 0))
      )
    );

    // remove products sizes without stoc
    // productsRaw.map(product => product.colors.map(color => color.sizes = color.sizes.filter(size => size.stoc > 0)))

    // remove products colors without sizes
    productsRaw.map(
      (product) =>
      (product.colors = product.colors.filter(
        (color) => color.sizes.length > 0
      ))
    );

    // remove products colors without images
    productsRaw.map(
      (product) =>
      (product.colors = product.colors.filter(
        (color) => color.images.length > 0
      ))
    );

    // remove products without colors
    productsRaw = productsRaw.filter((product) => product.colors.length > 0);

    // remove products categories without subcategories
    productsRaw.map(
      (product) =>
      (product.categories = product.categories.filter(
        (category) => category.subcategories.length > 0
      ))
    );

    // remove products without categories
    productsRaw = productsRaw.filter(
      (product) => product.categories.length > 0
    );

    // if (this.favoriteProducts.length > 0) {
    //   let existingFavoriteProducts = this.favoriteProducts.filter((ar: any) =>
    //     productsRaw.some(
    //       (rm: any) =>
    //         rm.id == ar.id &&
    //         rm.selectedColor.ColorID == ar.selectedColor.ColorID
    //     )
    //   );
    //   existingFavoriteProducts.map((product) => {
    //     let objIndex = productsRaw.findIndex(
    //       (existingProduct) =>
    //         existingProduct.id == product.id &&
    //         existingProduct.selectedColor.ColorID ==
    //         product.selectedColor.ColorID
    //     );
    //     productsRaw[objIndex].addedToFavorites = true;
    //   });
    // }



    if (this.favoriteProducts.length > 0) {
      const existingFavoriteProducts = this.favoriteProducts.filter((favoriteProduct: any) =>
        productsRaw.some((rawProduct: any) =>
          rawProduct.id === favoriteProduct.id &&
          rawProduct.selectedColor.ColorID === favoriteProduct.selectedColor.ColorID
        )
      );

      

      existingFavoriteProducts.forEach((existingFavoriteProduct) => {
        const objIndex = productsRaw.findIndex((rawProduct) =>
          rawProduct.id === existingFavoriteProduct.id &&
          rawProduct.selectedColor.ColorID === existingFavoriteProduct.selectedColor.ColorID
        );

        if (objIndex !== -1) {
          productsRaw[objIndex].addedToFavorites = true;
        }
      });
    }

    let allProductsBasedOnColors: any[] = [];
    let colors: any[] = [];

    // filter for products complemetary
    productsRaw.map((product) =>
      product.isMultiple
        ? (product.colors = product.colors.filter(
          (color) => color.ColorID === 158
        ))
        : ''
    );

    // filter for products with discount
    productsRaw.map((product) =>
      product.hasDiscount
        ? (product.hasDiscount = true)
        : (product.hasDiscount = false)
    );

    productsRaw.map((product: any) => {
      product.colors.map((color: any, j: number) => {
        allProductsBasedOnColors.push(product);
        colors.push(color);
      });
    });

    productsClean = allProductsBasedOnColors.map((item, i) => {
      let selectedColor = colors[i];
      let selectedPrice = selectedColor.sizes[0].current_price;
      let selectedSize = selectedColor.sizes[0];

      let selectedImage;
      let selectedImageObj;
      let images;
      if (selectedColor.images.length > 0) {
        selectedImage = selectedColor.images[0].url;
        selectedImageObj = selectedColor.images[0].url;
        images = selectedColor.images;
      }

      let sizes = selectedColor.sizes;

      //let hasDiscount = false;

      let selectedCategory: Category = item.categories[0];
      let selectedSubcategory = selectedCategory.subcategories;
      return (item = {
        ...item,
        selectedColor,
        selectedPrice,
        selectedSize,
        selectedImage,
        selectedImageObj,
        sizes,
        selectedCategory,
        selectedSubcategory,
      });
    });
    return productsClean;
  }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }
}
