import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';

import { take, map, tap, switchMap, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Product } from '../models';
import { ToastrService } from 'ngx-toastr';
import { v4 as uuidv4 } from 'uuid';

import { PixelService } from 'ngx-pixel';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { ProductService } from './product.service';
import { ProductsService } from './products.service';

import { DeliveryAddressService } from '../pages/checkout/delivery-address/delivery-address.service';
import { CouponsService } from '../pages/cart/coupons/coupons.service';
import { NewsletterService } from './newsletter.service';
import { DataLayerService } from './dataLayer.service';
declare let dataLayer: any[];
@Injectable({
  providedIn: 'root',
})
export class CartService {
  public numTotal = new BehaviorSubject<any>(0);
  public totalPrice = new BehaviorSubject<any>(0);
  public fullPrice = new BehaviorSubject<any>(0);
  public cartUuid$ = new BehaviorSubject<any>(0);
  public appliedDiscount = new BehaviorSubject<boolean>(false);
  public newsletterDiscount$ = new BehaviorSubject<boolean>(false);
  public thirdProductInCart$ = new BehaviorSubject<boolean>(false);
  private _apiUrl = environment.apiUrl;
  private initialCartItems: any;
  uuid: string;
  discountHasBeenApplied: boolean;
  private existingCartItems: any;
  cartInfo: any = {};
  message$: Observable<string>;
  thirdProductInCartHasDiscount$ = new BehaviorSubject<boolean>(false);
  discountThirdProductInCart: string = '-20% la al treilea produs din cos';
  discountFirstOrder: string = '-10% la prima comanda';
  courierPrice$ = new BehaviorSubject<number>(0);
  totalPriceWithCourier$ = new BehaviorSubject<number>(0);
  items$ = new BehaviorSubject<Product[]>([]);

  constructor(
    public router: Router,
    private toaster: ToastrService,
    private pixel: PixelService,
    private _httpClient: HttpClient,
    private _ProductService: ProductService,
    private _ProductsService: ProductsService,
    private _CouponService: CouponsService,
    private _NewsletterService: NewsletterService,
    private _DataLayeService: DataLayerService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.initialCartItems = JSON.parse(
        localStorage.getItem('extendedShardorayProducts')
      );
      this.existingCartItems = JSON.parse(
        localStorage.getItem('shardorayProducts')
      );
    }

    if (!this.initialCartItems || this.initialCartItems.length === 0) {
      return;
    } else if (!this.existingCartItems && !this.initialCartItems) {
      return;
    } else if (!this.initialCartItems && this.existingCartItems) {
      this.initialCartItems = this.existingCartItems;
    }

    if (this.initialCartItems) {
      this.items$.next(this.initialCartItems.products);

      this.numTotal.next(
        this.initialCartItems.products.reduce((acc, pr) => (acc += pr.num), 0)
      );

      this.cartUuid$.next(this.initialCartItems.uuid);

      this.courierPrice$.next(environment.courierPrice);

      // PROMOTION
      this.applyDiscount(
        this.multiplyDuplicates(this.initialCartItems.products)
      );
      this.calcTotal();
    }
  }

  applyDiscount(extendedCart) {
    // add fullPrice to keep original price
    extendedCart.map((product) =>
      !product.fullPrice ? (product.fullPrice = product.selectedPrice) : ''
    );

    // if there are atleast 3 products in cart...
    if (extendedCart.length > 2) {
      // check if discount is already applied on any product
      const isDiscountActive = !!extendedCart.filter((item) => item.hasDiscount)
        .length;
      // get min value from all products which doesn't have discount
      const min = Math.min(...extendedCart.map((item) => item.fullPrice));
      // let min = Math.min(...extendedCart
      //     .filter(item => !item.hasDiscount)
      //     .map(item => item.selectedPrice))

      // if its not applied already...
      if (!isDiscountActive) {
        // find the product which has the minimum value
        const minValueProduct = extendedCart.find(
          (item) => item.fullPrice === min
        );
        // set 30% discount
        minValueProduct.hasDiscount = true;
        minValueProduct.selectedPrice = Math.floor(
          0.8 * minValueProduct.fullPrice
        );
        // new total and old total
        // get new total
        const newTotal = extendedCart.reduce(
          (acc, curr) => acc + curr.num * curr.selectedPrice,
          0
        );
        //  get old total
        const oldTotal = extendedCart.reduce(
          (acc, curr) => acc + curr.num * curr.fullPrice,
          0
        );
        // update cart
        //this.totalPrice.next(newTotal)
        this.items$
          .pipe(
            take(1),
            map((products) => {
              localStorage.setItem(
                'shardorayProducts',
                JSON.stringify({
                  uuid: this.cartUuid$.value,
                  products: products,
                })
              );
              localStorage.setItem(
                'extendedShardorayProducts',
                JSON.stringify({
                  uuid: this.cartUuid$.value,
                  products: extendedCart,
                })
              );
            })
          )
          .subscribe();
        this.items$.next(extendedCart);
      }
      // if discount has been applied...
      else {
        // get discounted product
        const discountedProduct = extendedCart.find((item) => item.hasDiscount);
        // extract its original price
        const discountedInitialPrice = discountedProduct.fullPrice;

        // find the product which has the minimum value
        const minValueProduct = extendedCart.find(
          (item) => item.fullPrice === min
        );
        if (
          minValueProduct.id != discountedProduct.id &&
          minValueProduct.fullPrice < discountedProduct.fullPrice
        ) {
          discountedProduct.hasDiscount = false;
          discountedProduct.selectedPrice = discountedProduct.fullPrice;

          minValueProduct.hasDiscount = true;
          minValueProduct.selectedPrice = Math.floor(
            0.8 * minValueProduct.fullPrice
          );
        }

        if (extendedCart.length < 3) {
          extendedCart.map((product) => {
            product.hasDiscount = false;
            product.selectedPrice = product.fullPrice;
          });
        }

        this.items$
          .pipe(
            take(1),
            map((products) => {
              localStorage.setItem(
                'shardorayProducts',
                JSON.stringify({
                  uuid: this.cartUuid$.value,
                  products: products,
                })
              );
              localStorage.setItem(
                'extendedShardorayProducts',
                JSON.stringify({
                  uuid: this.cartUuid$.value,
                  products: extendedCart,
                })
              );
            })
          )
          .subscribe();
        this.items$.next(extendedCart);
      }
    } else {
      extendedCart.map((product) => {
        product.hasDiscount = false;
        product.selectedPrice = product.fullPrice;
      });
      this.items$
        .pipe(
          take(1),
          map((products) => {
            localStorage.setItem(
              'shardorayProducts',
              JSON.stringify({ uuid: this.cartUuid$.value, products: products })
            );
            localStorage.setItem(
              'extendedShardorayProducts',
              JSON.stringify({
                uuid: this.cartUuid$.value,
                products: extendedCart,
              })
            );
          })
        )
        .subscribe();
    }
    return extendedCart;
  }

  multiplyDuplicates(cartObjects) {
    // copy current array of products in cart
    const cartCopy = JSON.parse(JSON.stringify(cartObjects));
    cartCopy.map((cartProduct) => {
      // for each product that has num >= 2
      if (cartProduct.num >= 2) {
        // multiply each object num - 1 times
        for (let i = cartProduct.num; i > 1; i--) {
          const copy = Object.assign({}, cartProduct);
          // set current copy object num to 1 (will always be 1 on multiply)
          copy.num = 1;
          copy.hasDiscount = false;
          copy.selectedPrice = cartProduct.fullPrice ?? copy.selectedPrice;
          copy.cart_uuid = uuidv4();
          cartCopy.push(copy);
        }
        // set current product num property to 1 (from 3, 4, 5 etc... will always be 1 after multiply)
        cartProduct.num = 1;
      }
    });

    return cartCopy;
  }

  addToCart(product, value) {
    this._DataLayeService.reinitializeScript();
    (window as any).dataLayer.push({
      event: '__sm__add_to_cart',
      product_id: product.id,
      quantity: 1,
      variation: {
        id: `${product.id}-${product.selectedColor.ColorID}-${product.selectedSize.SizeID}`,
        sku: `${product.id}-${product.selectedColor.color_name}-${product.selectedSize.size_name}`,
      },
    });
    this.pixel.track('AddToCart');
    const existingCartItems = JSON.parse(
      localStorage.getItem('extendedShardorayProducts')
    );

    if (existingCartItems && existingCartItems.uuid) {
      this.cartUuid$.next(existingCartItems.uuid);
    } else {
      this.cartUuid$.next(uuidv4());
    }

    this.items$
      .pipe(
        take(1),
        map((products) => {
          if (value <= 0) {
            this.removeFromCart(product);
            return;
          }

          const existing = products.filter((existingProduct) => {
            return (
              existingProduct['id'] == product.id &&
              existingProduct['selectedColor'].ColorID ==
                product.selectedColor.ColorID &&
              existingProduct['selectedSize'].SizeID ==
                product.selectedSize.SizeID
            );
          });

          if (existing.length > 0) {
            if (value + 1 > product.selectedSize.stoc) {
              existing[0].num = parseInt(product.selectedSize.stoc);
              this.toaster.warning(
                `Stoc: ${product.selectedSize.stoc}`,
                'Ati ales o cantitate mai mare decat stocul produsului!',
                {
                  timeOut: 3000,
                  positionClass: 'toast-bottom-right',
                }
              );
              return;
            } else {
              existing[0].num += parseInt(value);
            }
          } else {
            if (value > product.selectedSize.stoc) {
              products.push({
                ...product,
                num: parseInt(product.selectedSize.stoc),
                cart_uuid: uuidv4(),
              });
              this.toaster.warning(
                `Stoc: ${product.selectedSize.stoc}`,
                'Ati ales o cantitate mai mare decat stocul produsului!',
                {
                  timeOut: 3000,
                  positionClass: 'toast-bottom-right',
                }
              );
            } else {
              products.push({
                ...product,
                num: parseInt(value),
                cart_uuid: uuidv4(),
              });
            }
          }

          // localStorage.setItem(
          //   'shardorayProducts',
          //   JSON.stringify({ uuid: this.cartUuid$.value, products })
          // );
          // !this._CouponService.individualDiscountHasApplied$.value &&
          // !this._NewsletterService.firstOrderDiscountHasApplied$.value
          //   ? this.applyDiscount(this.multiplyDuplicates(products))
          //   : '';
          // localStorage.setItem(
          //   'extendedShardorayProducts',
          //   JSON.stringify({
          //     uuid: this.cartUuid$.value,
          //     products:
          //       !this._CouponService.individualDiscountHasApplied$.value &&
          //       !this._NewsletterService.firstOrderDiscountHasApplied$.value
          //         ? this.applyDiscount(this.multiplyDuplicates(products))
          //         : products,
          //   })
          // );

          this.calcTotal();
          // this.saveLog(this.cartUuid$.value, 'addToCart');
          this.saveCart(this.cartUuid$.value, products);
          (window as any).dataLayer = (window as any).dataLayer || [];
          (window as any).dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
              currencyCode: 'RON', // Currency code
              add: {
                products: product,
              },
            },
          });
        })
      )
      .subscribe();
  }

  removeFromCart(product: Product) {
    this._DataLayeService.reinitializeScript();
    (window as any).dataLayer.push({
      event: '__sm__remove_from_cart',
      product_id: product.id,
      quantity: 1,
      variation: {
        id: `${product.id}-${product.selectedColor.ColorID}-${product.selectedSize.SizeID}`,
        sku: `${product.id}-${product.selectedColor.color_name}-${product.selectedSize.size_name}`,
      },
    });
    this.items$
      .pipe(
        take(1),
        switchMap((products) => {
          const updatedProducts = products.filter(
            (p) => p.cart_uuid !== product.cart_uuid
          );
          if (updatedProducts.length === products.length) {
            // Product not found in the cart, do nothing
            return of(products);
          }

          // Use tap for side effects
          (window as any).dataLayer = (window as any).dataLayer || [];
          (window as any).dataLayer.push({
            event: 'remove_from_cart',
            ecommerce: {
              currencyCode: 'RON', // Currency code
              remove: {
                products: [product],
              },
            },
          });

          // Emit updated products using tap
          return of(updatedProducts);
        }),
        tap((updatedProducts: Product[]) => {
          // Update the items$ observable and local storage
          this.items$.next(updatedProducts);
          localStorage.setItem(
            'shardorayProducts',
            JSON.stringify({
              uuid: this.cartUuid$.value,
              products: updatedProducts,
            })
          );
          localStorage.setItem(
            'extendedShardorayProducts',
            JSON.stringify({
              uuid: this.cartUuid$.value,
              products: updatedProducts,
            })
          );

          // Calculate the total after updating the cart
          this.calcTotal();

          // Display a toaster message
          this.toaster.success(``, 'Produsul a fost scos din cos!', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });

          // Handle empty cart here if needed
          if (updatedProducts.length === 0) {
            this.emptyCart();
          }
        })
      )
      .subscribe((products) => console.log(products));
  }
  emptyCart() {
    localStorage.removeItem('shardorayProducts');
    localStorage.removeItem('extendedShardorayProducts');
    this.items$.next([]);
    this.numTotal.next(0);
    this.totalPrice.next(0);
    this.fullPrice.next(0);
    this.deleteCart(this.cartUuid$.value);
  }

  calcTotal() {
    this.items$
      .pipe(
        take(1),
        switchMap((items) => {
          console.log(items);
          const cartValue =
            items.reduce((acc, pr) => (acc += pr.selectedPrice * pr.num), 0) <=
            89;

          if (
            this._CouponService.individualDiscountHasApplied$.value ||
            this._NewsletterService.firstOrderDiscountHasApplied$.value
          ) {
            if (cartValue) {
              this.toaster.warning(
                ``,
                `Valoarea comenzii trebuie sa fie mai mare de 89 Lei pentru a beneficia de reducere!`,
                {
                  timeOut: 3000,
                  positionClass: 'toast-bottom-right',
                }
              );
              this._CouponService.individualDiscountHasApplied$.next(false);
              this._NewsletterService.firstOrderDiscountHasApplied$.next(false);
              this.totalPrice.next(
                items.reduce((acc, pr) => (acc += pr.selectedPrice * pr.num), 0)
              );

              this.totalPriceWithCourier$.next(
                this.totalPrice.value < 249
                  ? this.totalPrice.value + this.courierPrice$.value
                  : this.totalPrice.value
              );
              if (isPlatformBrowser(this.platformId)) {
                localStorage.setItem(
                  'shardorayProducts',
                  JSON.stringify({
                    uuid: this.cartUuid$.value,
                    products: items,
                  })
                );
                localStorage.setItem(
                  'extendedShardorayProducts',
                  JSON.stringify({
                    uuid: this.cartUuid$.value,
                    products: items,
                  })
                );
              }
            } else {
              if (this.thirdProductInCart$.value) {
                let removeDiscountThirdProduct = items.find(
                  (item) => item.hasDiscount
                );

                if (
                  removeDiscountThirdProduct &&
                  removeDiscountThirdProduct.hasDiscount
                ) {
                  removeDiscountThirdProduct.hasDiscount = false;
                  removeDiscountThirdProduct.selectedPrice =
                    removeDiscountThirdProduct.fullPrice;

                  this.thirdProductInCartHasDiscount$.next(false);
                }
              }
              if (isPlatformBrowser(this.platformId)) {
                localStorage.setItem(
                  'shardorayProducts',
                  JSON.stringify({
                    uuid: this.cartUuid$.value,
                    products: items,
                  })
                );
                localStorage.setItem(
                  'extendedShardorayProducts',
                  JSON.stringify({
                    uuid: this.cartUuid$.value,
                    products: items,
                  })
                );
              }
            }
          } else {
            items = this.applyDiscount(this.multiplyDuplicates(items));

            if (isPlatformBrowser(this.platformId)) {
              localStorage.setItem(
                'shardorayProducts',
                JSON.stringify({ uuid: this.cartUuid$.value, items })
              );

              localStorage.setItem(
                'extendedShardorayProducts',
                JSON.stringify({
                  uuid: this.cartUuid$.value,
                  products: items,
                })
              );
            }

            if (
              !items.find(
                (existingProduct) => existingProduct['hasDiscount'] === true
              )
            ) {
              this.appliedDiscount.next(false);
            }

            this.items$.next(items);
          }
          return of(items);
        })
      )
      .subscribe((items) => {
        this.numTotal.next(items.reduce((acc, pr) => (acc += pr.num), 0));

        console.log(items);

        this.totalPrice.next(
          this.applyDiscountForIndividualAndNewsletter(items)
        );

        this.totalPriceWithCourier$.next(
          this.totalPrice.value < 249
            ? this.totalPrice.value + this.courierPrice$.value
            : this.totalPrice.value
        );

        if (items.reduce((acc, pr) => (acc += pr.num), 0) >= 3) {
          this.thirdProductInCartHasDiscount$.next(
            items.find((product) => product.hasDiscount) ? true : false
          );

          this.fullPrice.next(
            items.reduce(
              (acc, pr) =>
                (acc +=
                  (this.thirdProductInCartHasDiscount$.value
                    ? pr.fullPrice
                    : pr.selectedPrice) * pr.num),
              0
            )
          );
          this.thirdProductInCart$.next(true);
        } else {
          this.fullPrice.next(
            items.reduce((acc, pr) => (acc += pr.selectedPrice * pr.num), 0)
          );
          this.thirdProductInCart$.next(false);
          this.thirdProductInCartHasDiscount$.next(false);
        }

        this.saveCart(this.cartUuid$.value, items);
      });
  }

  applyDiscountForIndividualAndNewsletter(items) {
    // let dataPrice = this._CouponService.individualDiscountHasApplied$.value
    //   ? Math.floor(
    //       this._CouponService.individualDiscount$.value.coupon.is_fixed_discount
    //         ? items.reduce((acc, pr) => (acc += pr.selectedPrice * pr.num), 0) -
    //             this._CouponService.individualDiscount$.value.coupon.value
    //         : items.reduce((acc, pr) => (acc += pr.selectedPrice * pr.num), 0) -
    //             (items.reduce(
    //               (acc, pr) => (acc += pr.selectedPrice * pr.num),
    //               0
    //             ) *
    //               this._CouponService.individualDiscount$.value.coupon.value) /
    //               100
    //     )
    //   : this._NewsletterService.firstOrderDiscountHasApplied$.value
    //   ? Math.floor(
    //       items.reduce((acc, pr) => (acc += pr.selectedPrice * pr.num), 0) -
    //         (items.reduce((acc, pr) => (acc += pr.selectedPrice * pr.num), 0) *
    //           10) /
    //           100
    //     )
    //   : items.reduce((acc, pr) => (acc += pr.selectedPrice * pr.num), 0);

    // console.log(dataPrice);
    // return dataPrice;
    let dataPrice = 0;

    if (this._CouponService.individualDiscountHasApplied$.value) {
      dataPrice = Math.floor(
        this._CouponService.individualDiscount$.value.coupon.is_fixed_discount
          ? items.reduce((acc, pr) => (acc += pr.selectedPrice * pr.num), 0) -
              this._CouponService.individualDiscount$.value.coupon.value
          : items.reduce((acc, pr) => (acc += pr.selectedPrice * pr.num), 0) -
              (items.reduce(
                (acc, pr) => (acc += pr.selectedPrice * pr.num),
                0
              ) *
                this._CouponService.individualDiscount$.value.coupon.value) /
                100
      );
    } else if (this._NewsletterService.firstOrderDiscountHasApplied$.value) {
      dataPrice = Math.floor(
        items.reduce((acc, pr) => (acc += pr.selectedPrice * pr.num), 0) -
          (items.reduce((acc, pr) => (acc += pr.selectedPrice * pr.num), 0) *
            10) /
            100
      );
    } else {
      dataPrice = items.reduce(
        (acc, pr) => (acc += pr.selectedPrice * pr.num),
        0
      );
    }

    console.log(dataPrice);
    return dataPrice;
  }

  saveLog(uuid, action) {
    this._apiUrl = environment.apiUrl;
    const existingCartItems = JSON.parse(
      localStorage.getItem('shardorayProducts')
    );
    this._httpClient
      .post(this._apiUrl + 'orders/cart-log', [
        {
          action: action,
          cart_uuid: existingCartItems.uuid,
          products: existingCartItems.products,
        },
      ])
      .subscribe();
  }

  saveCart(uuid, products) {
    this._apiUrl = environment.apiUrl;

    const loggedInUser = JSON.parse(localStorage.getItem('ShardorayUserData'));
    const userEmail =
      !loggedInUser || loggedInUser.length === 0 ? 0 : loggedInUser.email;

    this._httpClient
      .post(this._apiUrl + 'orders/cart-save', [
        { order_uuid: uuid, products: products, email: userEmail },
      ])
      .subscribe();
  }

  deleteCart(uuid) {
    this._httpClient
      .post(this._apiUrl + 'orders/cart-delete', [{ order_uuid: uuid }])
      .subscribe();
  }

  getCart(): Observable<string> {
    return this._httpClient
      .post(this._apiUrl + 'orders/getCart', [
        { order_uuid: this.cartUuid$.value },
      ])
      .pipe(
        switchMap((data: any) => {
          if (data.success) {
            return this._ProductService
              .getProductForCart(JSON.parse(data.cart.products))
              .pipe(
                map((products: any) => {
                  let newProducts = this._ProductsService.cleanObjCart(
                    products,
                    JSON.parse(data.cart.products)
                  );

                  if (
                    JSON.parse(data.cart.products).length === newProducts.length
                  ) {
                    const nonMatchingProducts = JSON.parse(
                      data.cart.products
                    ).filter((cartProduct) => {
                      return !newProducts.some((newProduct) => {
                        return (
                          newProduct.colors &&
                          newProduct.colors.some(
                            (newProductColor) =>
                              newProductColor.ColorID ===
                              cartProduct.selectedColor.ColorID
                          ) &&
                          newProduct.sizes &&
                          newProduct.sizes.some(
                            (newProductSize) =>
                              newProductSize.SizeID ===
                              cartProduct.selectedSize.SizeID
                          ) &&
                          newProduct.id === cartProduct.id
                        );
                      });
                    });
                    if (nonMatchingProducts.length > 0) {
                      this.toaster.warning(
                        ``,
                        'Un produs pe care l-ai adaugat in cos nu se mai afla in stoc!',
                        {
                          timeOut: 3000,
                          positionClass: 'toast-bottom-right',
                        }
                      );
                      this.emptyCart();
                      newProducts.map((product) => this.addToCart(product, 1));
                      return 'Un produs pe care l-ai adaugat in cos nu se mai afla in stoc!';
                    }
                  } else {
                    this.toaster.warning(
                      ``,
                      'Un produs pe care l-ai adaugat in cos nu se mai afla in stoc!',
                      {
                        timeOut: 3000,
                        positionClass: 'toast-bottom-right',
                      }
                    );
                    this.emptyCart();
                    newProducts.map((product) => this.addToCart(product, 1));
                    return 'Un produs pe care l-ai adaugat in cos nu se mai afla in stoc!';
                  }

                  return '';
                })
              );
          } else {
            return 'empty cart';
          }
        })
      );
  }

  abandonedCarts(email: string) {
    this._httpClient
      .put(this._apiUrl + 'orders/updateCart', [
        { order_uuid: this.cartUuid$.value, email: email },
      ])
      .subscribe();
  }
}
