<div class="newsletter-container">
    <h3>Doar acum, abonează-te la newsletter şi vei primi</h3>
    <p>o reducere de 10% pentru cumpărăturile online
        profită de reducere şi bucură-te de cumpărături pe Shardoray.com.</p>
    <form #f="ngForm" id="ngForm" (ngSubmit)="addToNewsletter()" [formGroup]="form" novalidate>
        <div class="form-control">

            <input type="email" class="form-input" name="email"
                [ngClass]="{ 'is-invalid': f.form.controls['email']?.touched && f.form.controls['email'].invalid, 'is-not-empty': typing !== '' }"
                formControlName="email" required (input)="onBlurMethod()"  />
            <label class="form-label">Email
                <abbr class="required" title="required">*</abbr></label>
            
            <button [disabled]="f.form.controls['email'].invalid" type="submit" class="btn form-button">ABONARE</button>
        </div>

        <div *ngIf="f.form.controls['email']?.touched && f.form.controls['email'].invalid" class="alert alert-danger">
            <div *ngIf="f.form.controls['email'].errors?.required">Te rugam sa introduci email-ul!</div>
    
        </div>
    </form>
    
</div>