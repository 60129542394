import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-verification-email',
  templateUrl: './verification-email.component.html',
  styleUrls: ['./verification-email.component.scss']
})
export class VerificationEmailComponent implements OnInit {

  token: string = '';
  text = new BehaviorSubject<string>('');
  _apiUrl = environment.apiUrl;

  constructor(
    private _httpClient: HttpClient,
    private _ActivatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this._ActivatedRoute.paramMap.subscribe(params => {
      this.token = params.get('token');
    });
  }

  ngOnInit(): void {
    this._httpClient.post(this._apiUrl + 'users/getUser', { 'token': this.token })
      .subscribe((response: any) => {
        if (response.success === false) {
          this.text.next('Acest link nu mai este valabil');
        } else {
          this._httpClient.put(this._apiUrl + 'users/updateActive', { 'token': this.token }).subscribe((response: any) => {
            this.text.next(response.message);
          });
        }
      });
  }

}
