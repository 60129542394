import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  loadScript(src: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');
      script.async = true;
      script.src = src;

      script.onload = () => resolve();
      script.onerror = (error) => reject(error);

      document.head.appendChild(script);
    });
  }
  initializeGTM(containerId) {
    // Create a script element
    var script = document.createElement('script');

    // Set the script's attributes
    script.id = 'tagManager';
    script.innerHTML = `
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "${containerId}");
    `;

    // Append the script to the document's head
    document.head.appendChild(script);
  }

  initializeGTMNoScript(containerId) {
    // Create a noscript element
    var noscript = document.createElement('noscript');

    // Set the noscript's attributes
    noscript.id = 'noScript';
    noscript.innerHTML = `
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=${containerId}"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe>
    `;

    // Append the noscript to the document's body
    document.body.appendChild(noscript);
  }
}
