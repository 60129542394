import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

const DEFAULT_TITLE = 'These Is default';

@Injectable({ providedIn: 'root' })
export class TitleService {
  title$ = new BehaviorSubject<string>(DEFAULT_TITLE);
  meta$ = new BehaviorSubject<string>('');

  private titleRoute$: Observable<string | undefined> = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(() => this.getPageTitle(this.activatedRoute.firstChild))
  );

  private titleState$ = merge(this.title$, this.titleRoute$).pipe(
    filter((title) => title !== undefined),
    tap((title) => {
      // this.titleService.setTitle(`${title}`);
    })
  );

  private metaState$ = this.meta$.pipe(
    filter((meta) => meta !== undefined),
    tap((meta) => {
      // eslint-disable-next-line no-debugger
      // this.meta.updateTag({
      //   name: 'description',
      //   content: meta,
      // });
    })
  );

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private meta: Meta
  ) {
    this.titleState$.subscribe();
    this.metaState$.subscribe();
  }

  private getPageTitle(
    activatedRoute: ActivatedRoute | null
  ): string | undefined {
    while (activatedRoute) {
      if (activatedRoute.firstChild) {
        activatedRoute = activatedRoute.firstChild;
      } else if (
        activatedRoute.snapshot.data &&
        activatedRoute.snapshot.data['pageTitle']
      ) {
        return activatedRoute.snapshot.data['pageTitle'] as string;
      } else {
        return undefined;
      }
    }
    return undefined;
  }
}
