import { Component, OnInit } from '@angular/core';
import { DataLayerService } from 'src/app/services/dataLayer.service';
declare let dataLayer: any[];
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  constructor(
    private _DataLayeService: DataLayerService
  ) {}

  ngOnInit(): void {
    this._DataLayeService.reinitializeScript();
    (window as any).dataLayer.push({
      event: '__sm__view_homepage',
    });
  }
}
