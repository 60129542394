import { Injectable } from '@angular/core';
import { Coupon } from './coupons.type';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { BehaviorSubject, take, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NewsletterService } from 'src/app/services';

@Injectable({
  providedIn: 'root',
})
export class CouponsService {
  _apiUrl: string;
  individualDiscount$ = new BehaviorSubject<Coupon>(null);
  individualDiscountHasApplied$ = new BehaviorSubject<boolean>(false);
  rememberIndividualDiscountApplied$ = new BehaviorSubject<boolean>(false);
  constructor(private _httpClient: HttpClient, private toaster: ToastrService, private _NewsletterService: NewsletterService) {
    this._apiUrl = environment.apiUrl;
  }

  /**
   * check individual discount
   *
   * @returns {Promise<any>}
   */
  checkForIndividualDiscount(code, email) {
    return this._httpClient
      .post(this._apiUrl + 'coupons/check', { email: email, code: code })
      .pipe(
        take(1),
        tap((coupon: Coupon) => {
          if (coupon.success) {
            this.toaster.success(``, coupon.message, {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            this.individualDiscountHasApplied$.next(true);
            this.individualDiscount$.next(coupon);
            this.rememberIndividualDiscountApplied$.next(true);
            this._NewsletterService.firstOrderDiscountHasApplied$.next(false);
          } else {
            this.toaster.success(``, coupon.message, {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
          }
        })
      );
  }
  /**
   * Use Newsletter
   *
   * @returns {Promise<any>}
   */
  useDiscountFirtOrder(email): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this._apiUrl + 'newsletter/use', { email: email })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Use Newsletter
   *
   * @returns {Promise<any>}
   */
  useIdividualDiscount(email): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this._apiUrl + 'coupons/useCoupon', { email: email, coupon: this.individualDiscount$.value.coupon.code})
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
}
