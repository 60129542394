<!-- <div class="category-banner " [ngClass]="{'halloween': halloween, 'valentine': valentine}">
  <div class="promo-content d-sm-flex align-items-center justify-content-center">
    <div>
      <h2 class="m-b-1">{{subcategorySlugRaw ? activeCategory + ' ' + categorySlug : activeCategory}}</h2>
      <h3 *ngIf="subcategoryDiscount" class="mb-0">Reduceri de pana la {{categorySlug.toLowerCase() == 'femei' ? 60 :
        60}}%</h3>
    </div>
  </div>
</div> -->
<div class="container-fluid" [ngClass]="{ 'black-friday': isBlackFriday }" #scrollTop>
  <img *ngIf="halloween" class="halloween-image" src="/assets/images/banners/products-spider-web.svg" alt="" />
  <nav aria-label="breadcrumb" class="breadcrumb-nav mb-4">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/">Acasa</a></li>
        <li class="breadcrumb-item active" *ngIf="!categorySlug && !subcategorySlug" aria-current="page">
          Produse
        </li>
        <li class="breadcrumb-item">
          <a routerLink="/produse/{{ categorySlug | lowercase }}">{{
            categorySlug | replace : "-" : " "
            }}</a>
        </li>
        <li class="breadcrumb-item active" *ngIf="subcategorySlug" aria-current="page">
          {{ activeCategory }}
        </li>
      </ol>
    </div>
  </nav>
  <div class="row mb-8" *ngIf="categorySlug === 'cautare'">
    <div class="col-12">
      <input type="search" (input)="searchProduct()" class="form-control" [(ngModel)]="title" name="q" id="q"
        placeholder="{{ title }}" required="" />
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <nav class="toolbox" *ngIf="(products$ | async).length > 0 && categorySlug !== 'cautare'">
        <div class="toolbox-left">
          <div class="toolbox-item toolbox-sort">
            <div class="multiple-selection" [class.show]="sortDropdown">
              <label (click)="toggleSortDropdown()">Sortare dupa <span *ngIf="selectedSortChecked.length > 0">
                  1</span></label>
              <ul class="checkbox-dropdown has-filter">
                <li *ngFor="let sort of (sortSelection$ | async)">
                  <input type="radio" id="{{sort.value}}" [(ngModel)]="selectedSortChecked" [value]="sort.value"
                    (change)="sortProducts(sort.value)" name="sort">
                  <label for="{{sort.value}}">{{sort.label}}</label>
                </li>
                <li>
                  <button (click)="filterSelected()" class="btn btn-filter">Sortare</button>
                </li>
              </ul>
            </div>
          </div>
          <div class="toolbox-item toolbox-sort">
            <div class="multiple-selection" [class.show]="colorDropdown">
              <label (click)="toggleColorDropdown()">Culori <span *ngIf="selectedColorChecked.length > 0">
                  {{selectedColorChecked.length}}</span></label>
              <ul class="checkbox-dropdown has-filter">
                <li *ngFor="let color of (colors$ | async)">
                  <input type="checkbox" id="{{color.id}}" [(ngModel)]="color.isChecked" value="{{color.color_name}}"
                    (change)="selectedColor(color)" name="{{color.color_name}}">
                </li>
                <li>
                  <button (click)="filterSelected()" class="btn btn-filter">Filtru</button>
                </li>
              </ul>
            </div>
          </div>
          <div class="toolbox-item toolbox-sort">
            <div class="multiple-selection" [class.show]="sizeDropdown">
              <label (click)="toggleSizeDropdown()">Marimi <span *ngIf="selectedSizeChecked.length > 0">
                  {{selectedSizeChecked.length}}</span></label>
              <ul class="checkbox-dropdown has-filter">
                <li *ngFor="let size of (sizes$ | async)">
                  <input type="checkbox" id="{{size.id}}" [(ngModel)]="size.isChecked" value="{{size.size_name}}"
                  (change)="selectedSize(size)" name="{{size.size_name}}">
                </li>
                <li>
                  <button (click)="filterSelected()" class="btn btn-filter">Filtru</button>
                </li>
              </ul>
            </div>
          </div>
          <div class="toolbox-item toolbox-sort">
            <div class="multiple-selection" [class.show]="priceDropdown">
              <label (click)="togglePriceDropdown()">Pret <span *ngIf="selectedPriceChecked.length > 0">
                  1</span></label>
              <ul class="checkbox-dropdown price">
                <li>
                  <div class="form-control">
                    <input type="number" class="form-input" [(ngModel)]="minValue" [ngClass]="{ 'is-not-empty': minValue >= 0 }">
                    <label class="form-label">Pret de la:</label>
                  </div>
                </li>
                <li>
                  <div class="form-control">
                    <input type="number" class="form-input" [(ngModel)]="maxValue" [ngClass]="{ 'is-not-empty': maxValue >= 0 }">
                    <label class="form-label">Pret pana la:</label>
                  </div>
                </li>
                <li>
                  <button (click)="selectedPrice()" class="btn btn-filter">Filtru</button>
                </li>
              </ul>

            </div>
          </div>
        </div>

        <div class="reset-filters" *ngIf="selectedColorChecked.length > 0 || selectedSizeChecked.length > 0 || selectedSortChecked.length > 0 || selectedPriceChecked.length > 0">
          <button (click)="deleteFilters()" class="btn btn-reset-filter">Sterge filtre</button>
        </div>
        <!-- <div class="toolbox-right">
          <div class="toolbox-item toolbox-show">
            <label>Afiseaza pe pagina:</label>

            <div class="select-custom">
              <select
                name="count"
                [(ngModel)]="ipp"
                (change)="changeShowOnPage($event.target.value)"
                class="form-control"
              >
                <option value="12">12</option>
                <option value="24">24</option>
                <option value="36">36</option>
              </select>
            </div>
          </div>

          <div class="toolbox-item layout-modes">
            <a
              (click)="displayProductsAs('grid')"
              [ngClass]="{ active: displayType === 'grid' }"
              class="layout-btn btn-grid active"
              title="Grid"
            >
              <i class="icon-mode-grid"></i>
            </a>
            <a
              (click)="displayProductsAs('list')"
              [ngClass]="{ active: displayType === 'list' }"
              class="layout-btn btn-list"
              title="List"
            >
              <i class="icon-mode-list"></i>
            </a>
          </div> 
        </div> -->
      </nav>

      <ng-container *ngIf="!loaded">
        <div class="row">
          <div class="col-6 col-sm-4" *ngFor="let i of [].constructor(20)">
            <div class="o-media__figure">
              <span class="skeleton-box"></span>
            </div>
            <span class="skeleton-box line" style="width: 100%"></span>
            <span class="skeleton-box line" style="width: 70%"></span>
            <span class="skeleton-box line" style="width: 50%"></span>
            <span class="skeleton-box line" style="width: 30%"></span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="loaded">
        <div class="row" *ngIf="displayType === 'grid'">
          <p class="col-12" *ngIf="(products$ | async).length === 0">
            Niciun rezultat
          </p>
          <div class="col-6 col-sm-2" *ngFor="
              let product of products$
                | async
                | paginate
                  : {
                      itemsPerPage: ipp,
                      currentPage: page,
                      totalItems: (count$ | async)
                    }
            ">
            <div class="product-default inner-quickview inner-icon" [attr.data-id]="product?.id">
              <figure>
                <img class="bf-badge" *ngIf="product.isBlackFriday" src="/assets/images/banners/bf-badge.png" alt="" />
                <a (click)="
                    navigateToProduct(
                      product?.alias,
                      product?.id,
                      product?.selectedColor.ColorID,
                      product?.meta
                    )
                  ">
                  <!-- <a [routerLink]="'/produs/'product?.alias/product?.id"> -->
                  <img src="{{ uploadedImgUrl }}{{ product.selectedImageObj }}" alt="image" />
                </a>
                <!-- <a *ngIf="product?.selectedImage" (click)="navigateToProduct(product?.alias, product?.id, product?.selectedColor.ColorID)">
                  <ng-container *ngFor="let image of product?.selectedImage.url">
                    <img src="{{uploadedImgUrl}}{{image.url}}" alt="image">
                  </ng-container>
                </a> -->
                <div class="label-group">
                  <div class="product-label label-sale" *ngIf="
                      product?.selectedSize &&
                      product?.selectedSize.old_price > 0
                    ">
                    {{
                    100 -
                    (product?.selectedSize.current_price /
                    product?.selectedSize.old_price) *
                    100 | number : "1.0-0"
                    }}
                    %
                  </div>

                  <div *ngIf="product?.isRecommended === 1" class="product-label label-hot">
                    Recomandat
                  </div>
                </div>
                <!-- <div>
                  <span class="stoc-alert product-label label-sale" *ngIf="product?.selectedSize.stoc == 3">Ultimele trei produse</span>
                  <span class="stoc-alert product-label label-sale" *ngIf="product?.selectedSize.stoc == 2">Ultimele doua produse</span>
                  <span class="stoc-alert product-label label-sale" *ngIf="product?.selectedSize.stoc == 1">Ultimul produs</span>
                </div> -->
                <!-- <div class="btn-icon-group">
                  <button class="btn-icon btn-add-cart"
                    (click)="addToCart(product); addedToCart.triggerModal(product)"><i
                      class="icon-shopping-cart"></i></button>
                </div> -->
                <a (click)="quickView.triggerModal(product)" class="btn-quickview" title="Vezi Produs">Vezi Produs</a>
              </figure>
              <div class="product-details">
                <div class="category-wrap">
                  <!-- <div class="category-list">
                    <a routerLink="/produse/{{product?.categories.category_name.toLowerCase()}}/{{product?.categories.subcategories.subcategory_name.toLowerCase()}}-{{product?.categories.category_name.toLowerCase()}}"
                      class="product-category"> {{product?.categories.subcategories.subcategory_name}}
                      {{product?.categories.category_name}}</a>
                    <ng-container *ngFor="let category of product?.categories; let isLast = last">
                      <a *ngFor="let subcategory of category.subcategories"
                        routerLink="/produse/{{category.category_name.toLowerCase()}}/{{subcategory.subcategory_name.toLowerCase()}}-{{category.category_name.toLowerCase()}}"
                        class="product-category"> {{subcategory.subcategory_name}} {{category.category_name}}
                        {{isLast ? ' ' : ' , '}} </a>
                    </ng-container>

                  </div> -->
                  <a *ngIf="!product?.selectedColor.addedToFavorites" (click)="toggleFavorites(product)" class="btn-icon-wish"><i
                      class="icon-heart"></i></a>
                  <a *ngIf="product?.selectedColor.addedToFavorites" (click)="toggleFavorites(product)" class="btn-icon-wish"><i
                      class="fas fa-heart"></i></a>
                </div>
                <div class="product-select-group" *ngIf="product?.selectedColor">
                  <ng-container *ngFor="let color of product?.colors">
                    <ul class="color-container">
                      <li>
                        <a (click)="
                            selectColor(product, color); changeImage(image.url)
                          " class="d-flex align-items-center justify-content-center p-0" [ngClass]="{
                            checked:
                              product.selectedColor.ColorID === color.ColorID
                          }">
                          <img [src]="uploadedImgUrl + color.images[0].url" width="30" height="30" alt="filter-img" />
                        </a>
                      </li>
                    </ul>
                  </ng-container>
                </div>

                <h2 class="product-title">
                  <a
                    (click)="navigateToProduct(product?.alias, product?.id, product?.selectedColor.ColorID, product?.meta)">{{
                    product?.name
                    }}</a>
                </h2>
                <div class="ratings-container">
                  <div class="product-ratings">
                    <!-- <ng-template #t let-fill="fill">
                      <span class="star" [class.full]="fill === 100">
                        <span class="half" [style.width.%]="fill">&#9733;</span>&#9734;
                      </span>
                    </ng-template>
                    <ngb-rating [(rate)]="product.rating" [starTemplate]="t" [readonly]="true" max="5"></ngb-rating>
                    <span class="tooltiptext tooltip-top">{{product?.rating}}</span> -->
                  </div>
                </div>
                <div class="price-box">
                  <span *ngIf="
                      product?.selectedSize &&
                      product?.selectedSize.current_price > 0
                    " class="product-price" [class.reduced]="product?.selectedSize.old_price > 0">{{
                    product?.selectedSize.current_price }} Lei</span>
                  <span *ngIf="
                      product?.selectedSize &&
                      product?.selectedSize.old_price > 0
                    " class="old-price">{{ product?.selectedSize.old_price }} Lei</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <nav class="toolbox toolbox-pagination">
        <!-- <div class="toolbox-item toolbox-show" *ngIf="(products$ | async).length > 0 && (count$ | async) > ipp">
          <label>Afiseaza pe pagina:</label>

          <div class="select-custom">
            <select name="count" [(ngModel)]="ipp" (change)="changeShowOnPage($event.target.value)"
              class="form-control">
              <option value="12">12</option>
              <option value="24">24</option>
              <option value="36">36</option>
            </select>
          </div>
        </div> -->

        <pagination-controls (pageChange)="handlePageChange($event)" class="float-right pagination"
          directionLinks="true" autoHide="true" responsive="true" maxSize="6" previousLabel="" nextLabel=""
          screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
          screenReaderCurrentLabel="Esti pe pagina"></pagination-controls>
      </nav>
    </div>
    <div class="sidebar-overlay"></div>

    <!-- <div *ngIf="!fullSize" class="sidebar-toggle" (click)="openMobileMenu()"><i class="fas fa-sliders-h"></i></div>
    <aside *ngIf="!fullSize" class="sidebar-shop col-lg-3 order-lg-first mobile-sidebar">
      <div class="sidebar-wrapper">
        <div class="widget">
          <h3 class="widget-title">
            <a data-toggle="collapse" (click)="toggleWidgetCategories()" [ngClass]="{'collapsed': widgetCategories}"
              role="button" aria-expanded="true" aria-controls="widget-body-2">Categorii</a>
          </h3>

          <div class="collapse show" [ngClass]="{'show': widgetCategories}" id="widget-body-2">
            <div class="widget-body">
              <ul class="cat-list">
                <ng-container *ngFor="let category of categories$ | async">
                  <li>
                    <a routerLink="/produse/{{category.categoryAlias}}"><strong>{{category.category_name}}</strong></a>
                    <ul *ngIf="category.subcategories && category.subcategories.length > 0">
                      <ng-container *ngFor="let subcategory of category.subcategories;">
                        <li [class.active]="subcategorySlugRaw == subcategory.alias">
                          <a routerLink="/produse/{{category.categoryAlias}}/{{subcategory.alias}}">{{subcategory.subcategory_name}} </a>
                        </li>
                      </ng-container>

                    </ul>
                  </li>
                </ng-container>

              </ul>
            </div>
          </div>
        </div>

        <div class="widget" *ngIf="maxValue > minValue">
          <h3 class="widget-title">
            <a data-toggle="collapse" (click)="toggleWidgetPrices()" [ngClass]="{'collapsed': widgetPrices}"
              role="button" aria-expanded="true" aria-controls="widget-body-3">Pret</a>
          </h3>
          <div class="collapse show" [ngClass]="{'show': widgetPrices}" id="widget-body-3">
            <div class="widget-body">
              <div class="price-slider-wrapper">
                <div id="price-slider"></div>
              </div>
              <div class="filter-price-action d-flex align-items-center justify-content-between flex-wrap">
                <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" (click)="filterProducts('filterPrice')"
                  [options]="options"></ngx-slider>

              </div>
            </div>
          </div>
        </div>

        <div class="widget">
          <h3 class="widget-title">
            <a data-toggle="collapse" (click)="toggleWidgetSizes()" [ngClass]="{'collapsed': widgetSizes}" role="button"
              aria-expanded="true" aria-controls="widget-body-4">Marime</a>
          </h3>

          <div class="collapse show" [ngClass]="{'show': widgetSizes}" id="widget-body-4">
            <div class="widget-body">
              <ul class="cat-list">
                <li *ngFor="let size of sizes" [ngClass]="{'active': menuSelectedSize === size.id}"><a
                    (click)="menuSelectSizes(size.id); getProductsBySize(size.id)">{{size.size_name}}</a></li>
              </ul>
            </div>
          </div>
        </div>


        <div class="widget">
          <h3 class="widget-title">
            <a data-toggle="collapse" (click)="toggleWidgetColors()" [ngClass]="{'collapsed': widgetColors}"
              role="button" aria-expanded="true" aria-controls="widget-body-6">Culori</a>
          </h3>

          <div class="collapse show" [ngClass]="{'show': widgetColors}" id="widget-body-6">
            <div class="widget-body">
              <ul class="config-swatch-list colors-list">
                <li *ngFor="let color of colors; let i = index"
                  (click)="getProductsByColor(color.id); menuSelectColor(color.id)"
                  [ngClass]="{'active': menuSelectedColor === color.id}">

                  <a *ngIf="color.color_list && color.color_list != null && color.color_list.length == 1"
                    [style.background-color]="color.color_list[0]"></a>
                  <a *ngIf="color.color_list && color.color_list != null && color.color_list.length == 0"
                    style="background-color: #fff;"></a>
                  <a class="two-colors"
                    *ngIf="color.color_list && color.color_list != null && color.color_list.length > 1">
                    <span [style.background-color]="color.color_list[0]"></span>
                    <span [style.background-color]="color.color_list[1]"></span>
                  </a>


                  <span>{{color.color_name}}</span>
                </li>

              </ul>
            </div>
          </div>
        </div>




      </div>
    </aside> -->
  </div>
</div>
<app-product-quickview #quickView></app-product-quickview>
<app-added-to-cart #addedToCart></app-added-to-cart>