import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeliveryMethodService {
  samedayDelivery$ = new BehaviorSubject<string>('curier');
  lockerAddress$ = new BehaviorSubject<any>({});
  constructor() { }
}
