import { Component, OnInit } from '@angular/core';
import { CartService, FavoritesService, ProductService, ProductsService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { map, take } from 'rxjs/operators';
import { PixelService } from 'ngx-pixel';

@Component({
  selector: 'app-recommended-products',
  templateUrl: './recommended-products.component.html',
  styleUrls: ['./recommended-products.component.scss']
})
export class RecommendedProductsComponent implements OnInit {
  products: any;
  recentAddedProducts: any;
  mostSelledProducts: any;
  mostViewedProducts: any;
  uploadedImgUrl: string;
  isRecommendedProducts: boolean;
  public itemsFavorites$ = this._FavoritesService.itemsFavorites$;

  constructor(
    private _ProductsService: ProductsService,
    private _ProductService: ProductService,
    private _CartService: CartService,
    private _FavoritesService: FavoritesService,
    private _DomSanitizationService: DomSanitizer,
    private pixel: PixelService
  ) { 
    this.uploadedImgUrl = environment.uploadImagesUrl;
  }

  ngOnInit(): void {
    this._ProductsService.getRecommendedProducts().then(data => {
    
      if(data.length > 0) {
        data.map((product, i) => {

          // data[i]['sizes'] = product['colors'][0].sizes;
          // data[i]['selectedPrice'] = product['colors'][0].sizes[0].current_price;
          this.itemsFavorites$.pipe(
            take(1),
            map((products) => {
              products.map(existingProduct => {
                
                if (existingProduct["name"] == data[i].product_name) {
                  data[i].addedToFavorites = true;
                }
              });
            }),
          ).subscribe();
        })
        this.products = data;   
        this.isRecommendedProducts = true;
      } else {
        this.isRecommendedProducts = false;
        this.products = [];   
      }
     
    })
    this._ProductsService.getRecentAddedProducts().then(data => {
      this.recentAddedProducts = this._ProductsService.cleanObj(data);
    })

    this._ProductsService.getMostSelledProducts().then(data => {
      
      this.mostSelledProducts = this._ProductsService.cleanObj(data).slice(0, 3);
    })

    this._ProductsService.getMostViewedProducts().then(data => {
      this.mostViewedProducts = this._ProductsService.cleanObj(data).slice(0, 3);
    })
  }

  selectColor(product, color) {
    this._ProductService.selectColor(product, color);
  }


  selectSize(product, size) {
    this._ProductService.selectSize(product, size);
  }

  addToCart(product) {
    
    this._CartService.addToCart(product, 1);
  }

  toggleFavorites(product) {
    this._FavoritesService.toggleFavorites(product);
  }

}
