// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  courierPrice: 19,
  apiUrl: 'https://shardoray.ro/shardoray-api/v2/',
  uploadImages: 'https://shardoray.ro/shardoray-api/data/upload-images.php',
  uploadCategoriesImages: 'https://shardoray.ro/shardoray-api/data/upload-categories.php',
  uploadSlider: 'https://shardoray.ro/shardoray-api/data/upload-slider.php',
  uploadImagesUrl: 'https://shardoray.ro/shardoray-api/data/',
  deleteImage: 'https://shardoray.ro/shardoray-api/data/delete-image.php',
  productCatalog: 'https://shardoray.ro/shardoray-api/data/google-catalog.php',
  theMarketer: 'https://shardoray.ro/shardoray-api/data/themarketer-feed.php',
  theMarketerCategories: 'https://shardoray.ro/shardoray-api/data/categories-feed.php',
  theMarketerApiKey: '9ZPUZUAR',
  theMarketerCustomerID: '6523f7bbe48f3b1dc601cff7',
  theMarketerSaveOrder: 'https://t.themarketer.com/api/v1/save_order',
  send_order_email: 'https://shardoray.ro/shardoray-api/data/send-order-email.php',
  send_confirmation_email: 'https://shardoray.ro/shardoray-api/data/send-confirmation-email.php',
  //generateAWBLink: 'https://shardoray.ro/shardoray-api/data/delivery/fc_hash.php',
  generateAWBLink: 'https://shardoray.ro/shardoray-api/data/delivery/sameday/generateAwb.php',
  generateAWBLinkStreetFit: 'https://shardoray.ro/shardoray-api/data/delivery/sameday/generateAWBLinkStreetFit.php',
  downloadAWBLink: 'https://shardoray.ro/shardoray-api/data/delivery/sameday/downloadAWB.php',
  downloadAWBLinkStreetFit: 'https://shardoray.ro/shardoray-api/data/delivery/sameday/downloadAWBLinkStreetFit.php',
  //generateAWBLink: 'api.sameday.ro',
  getCourierToken: 'https://shardoray.ro/shardoray-api/data/delivery/sameday/getToken.php',
  getCourierTokenStreetFit: 'https://shardoray.ro/shardoray-api/data/delivery/sameday/getTokenStreetFit.php',
  generateAWBPrint: 'https://shardoray.ro/shardoray-api/data/delivery/sameday/print.php',
  checkAWBStatus: 'https://shardoray.ro/shardoray-api/data/delivery/sameday/checkStatus.php',
  courierUsername: 'crownfashioninternationalAPI',
  courierPass: 'kKywkwWFQQ==',
  courierUsernameStreetFit: 'streetoutfitAPI',
  courierPassStreetFit: '*pEKA9nD',
  geocodingKey: '39f4b81d8da34f8d931057be718b6b14',
  smartbillToken: '002|8de59942305dc0cd34403d5b24d489d4',
  smartbillCif: 'RO42872870',
  smartbillUser: 'comenzi.shardoray@gmail.com',
  smartbillEmitere: 'https://shardoray.ro/shardoray-api/data/smartbill/emitere.php',
  smartbillIncasare: 'https://shardoray.ro/shardoray-api/data/smartbill/incasare.php',
  smarbillEncodedToken: 'Y29tZW56aS5zaGFyZG9yYXlAZ21haWwuY29tOjAwMnw4ZGU1OTk0MjMwNWRjMGNkMzQ0MDNkNWIyNGQ0ODlkNA==',
  smartbillStreetFitToken: '002|de4f618a983a018d15f6127222948922',
  smartbillStreetFitCif: '47925666',
  smartbillStreetFitUser: 'streetfitro@gmail.com',
  smartbillStreetFitEmitere: 'https://shardoray.ro/shardoray-api/data/smartbillStreetFit/emitere.php',
  smartbillStreetFitIncasare: 'https://shardoray.ro/shardoray-api/data/smartbillStreetFit/incasare.php',
  smarbillStreetFitEncodedToken: 'c3RyZWV0Zml0cm9AZ21haWwuY29tOjAwMnxkZTRmNjE4YTk4M2EwMThkMTVmNjEyNzIyMjk0ODkyMg==',
  geocoding: 'https://api.geoapify.com/v1/geocode/search?text=',
  reverseGeocoding: 'https://api.geoapify.com/v1/geocode/reverse?',
  cookieDomain: 'localhost',
  halloween: false,
  valentine: false,
  blackFriday: true,
  euplatesctSend: 'https://shardoray.ro/shardoray-api/data/euplatesc/ep_send_test.php',
  samedayEasyboxClinetID: 'ed658ef1-908c-4e52-8c55-e405555281be',
  products: [
    {
      id: 1,
      name: 'Tricou Sport La Casa De Papel',
      alias: 'tricou-sport-la-casa-de-papel',
      category: 'Femei',
      subcategory: 'Tricouri',
      rating: 3.8,
      allImages: [
        {
          id: 1,
          src: 'https://shardoray.ro/wp-content/uploads/2020/07/tricou-sport-angro-8960-scaled.jpg'
        },
        {
          id: 2,
          src: 'https://shardoray.ro/wp-content/uploads/2020/07/tricou-sport-angro-8968-scaled.jpg'
        },
        {
          id: 3,
          src: 'assets/images/products/product-3.jpg'
        },
        {
          id: 4,
          src: 'assets/images/products/product-2.jpg'
        },
      ],
      colors: [
        {
          id: 1,
          name: '#f0d27a',
          images: [
            {
              id: 1,
              src: 'https://shardoray.ro/wp-content/uploads/2020/07/tricou-sport-angro-8960-scaled.jpg'
            },
            {
              id: 2,
              src: 'https://shardoray.ro/wp-content/uploads/2020/07/tricou-sport-angro-8968-scaled.jpg'
            },
          ],
          sizes: [
            {
              id: 1,
              name: 'XS',
              old_price: 101,
              new_price: 19,
              stoc: 109
            },
            {
              id: 2,
              name: 'S',
              old_price: 201,
              new_price: 20,
              stoc: 99
            },
            {
              id: 3,
              name: 'M',
              old_price: 301,
              new_price: 30,
              stoc: 499
            },
            {
              id: 4,
              name: 'L',
              old_price: 401,
              new_price: 40,
              stoc: 2099
            }
          ]
        },
        {
          id: 2,
          name: '#95cae9',
          images: [
            {
              id: 3,
              src: 'assets/images/products/product-3.jpg'
            },
            {
              id: 4,
              src: 'assets/images/products/product-2.jpg'
            },
          ],
          sizes: [
            {
              id: 1,
              name: 'XS',
              old_price: 101,
              new_price: 19,
              stoc: 109
            },
            {
              id: 2,
              name: 'S',
              old_price: 201,
              new_price: 20,
              stoc: 99
            },
            {
              id: 3,
              name: 'M',
              old_price: 301,
              new_price: 30,
              stoc: 499
            },
            {
              id: 4,
              name: 'L',
              old_price: 401,
              new_price: 40,
              stoc: 2099
            }
          ]
        },
      ],

    },
    {
      id: 2,
      name: 'Trening Lace',
      alias: 'trening-lace',
      category: 'Femei',
      subcategory: 'Trening',
      rating: 4.8,
      allImages: [
        {
          id: 1,
          src: 'https://shardoray.ro/wp-content/uploads/2020/08/Screenshot-2020-08-19-at-12.12.39.png'
        },
        {
          id: 2,
          src: 'https://shardoray.ro/wp-content/uploads/2020/08/Trening-dama-lung-albastru-cu-alb-cu-pantaloni-lungi-si-bluza-cu-maneca-lunga-fashion-1017x1000-1-scaled.jpg'
        },
        {
          id: 3,
          src: 'assets/images/products/product-3.jpg'
        },
        {
          id: 4,
          src: 'assets/images/products/product-2.jpg'
        },
      ],
      colors: [
        {
          id: 1,
          name: '#f0d27a',
          images: [
            {
              id: 1,
              src: 'https://shardoray.ro/wp-content/uploads/2020/08/Screenshot-2020-08-19-at-12.12.39.png'
            },
            {
              id: 2,
              src: 'https://shardoray.ro/wp-content/uploads/2020/08/Trening-dama-lung-albastru-cu-alb-cu-pantaloni-lungi-si-bluza-cu-maneca-lunga-fashion-1017x1000-1-scaled.jpg'
            },
          ],
          sizes: [
            {
              id: 1,
              name: 'XS',
              old_price: 101,
              new_price: 19,
              stoc: 109
            },
            {
              id: 2,
              name: 'S',
              old_price: 201,
              new_price: 20,
              stoc: 99
            },
            {
              id: 3,
              name: 'M',
              old_price: 301,
              new_price: 30,
              stoc: 499
            },
            {
              id: 4,
              name: 'L',
              old_price: 401,
              new_price: 40,
              stoc: 2099
            }
          ]
        },
        {
          id: 2,
          name: '#95cae9',
          images: [
            {
              id: 3,
              src: 'assets/images/products/product-3.jpg'
            },
            {
              id: 4,
              src: 'assets/images/products/product-2.jpg'
            },
          ],
          sizes: [
            {
              id: 1,
              name: 'XS',
              old_price: 101,
              new_price: 19,
              stoc: 109
            },
            {
              id: 2,
              name: 'S',
              old_price: 201,
              new_price: 20,
              stoc: 99
            },
            {
              id: 3,
              name: 'M',
              old_price: 301,
              new_price: 30,
              stoc: 499
            },
            {
              id: 4,
              name: 'L',
              old_price: 401,
              new_price: 40,
              stoc: 2099
            }
          ]
        },
      ],

    },
    {
      id: 3,
      name: 'Hanorac Apartado',
      alias: 'hanorac-apartado',
      category: 'Femei',
      subcategory: 'Hanorace',
      rating: 4.7,
      allImages: [
        {
          id: 1,
          src: 'https://shardoray.ro/wp-content/uploads/2020/08/Untitled-15fhh.jpg'
        },
        {
          id: 2,
          src: 'https://shardoray.ro/wp-content/uploads/2020/08/WhatsApp-Image-2020-08-31-at-08.22.26-2-scaled.jpeg'
        },
        {
          id: 3,
          src: 'https://shardoray.ro/wp-content/uploads/2020/08/WhatsApp-Image-2020-08-31-at-08.22.26-1-scaled.jpeg'
        },
        {
          id: 4,
          src: 'https://shardoray.ro/wp-content/uploads/2020/08/WhatsApp-Image-2020-08-31-at-08.22.27-1-scaled.jpeg'
        },
      ],
      colors: [
        {
          id: 1,
          name: '#f0d27a',
          images: [
            {
              id: 1,
              src: 'https://shardoray.ro/wp-content/uploads/2020/08/Untitled-15fhh.jpg'
            },
            {
              id: 2,
              src: 'https://shardoray.ro/wp-content/uploads/2020/08/WhatsApp-Image-2020-08-31-at-08.22.26-2-scaled.jpeg'
            },
          ],
          sizes: [
            {
              id: 1,
              name: 'XS',
              old_price: 101,
              new_price: 19,
              stoc: 109
            },
            {
              id: 2,
              name: 'S',
              old_price: 201,
              new_price: 20,
              stoc: 99
            },
            {
              id: 3,
              name: 'M',
              old_price: 301,
              new_price: 30,
              stoc: 499
            },
            {
              id: 4,
              name: 'L',
              old_price: 401,
              new_price: 40,
              stoc: 2099
            }
          ]
        },
        {
          id: 2,
          name: '#95cae9',
          images: [
            {
              id: 3,
              src: 'https://shardoray.ro/wp-content/uploads/2020/08/WhatsApp-Image-2020-08-31-at-08.22.26-1-scaled.jpeg'
            },
            {
              id: 4,
              src: 'https://shardoray.ro/wp-content/uploads/2020/08/WhatsApp-Image-2020-08-31-at-08.22.27-1-scaled.jpeg'
            },
          ],
          sizes: [
            {
              id: 1,
              name: 'XS',
              old_price: 101,
              new_price: 19,
              stoc: 109
            },
            {
              id: 2,
              name: 'S',
              old_price: 201,
              new_price: 20,
              stoc: 99
            },
            {
              id: 3,
              name: 'M',
              old_price: 301,
              new_price: 30,
              stoc: 499
            },
            {
              id: 4,
              name: 'L',
              old_price: 401,
              new_price: 40,
              stoc: 2099
            }
          ]
        },
      ],

    },
    {
      id: 4,
      name: 'Bluza "Social"',
      alias: 'bluza-social',
      category: 'Femei',
      subcategory: 'Bluze',
      rating: 4.4,
      allImages: [
        {
          id: 1,
          src: 'https://shardoray.ro/wp-content/uploads/2020/09/WhatsApp-Image-2020-09-24-at-16.03.38-1-scaled.jpeg'
        },
        {
          id: 2,
          src: 'https://shardoray.ro/wp-content/uploads/2020/09/WhatsApp-Image-2020-09-24-at-16.03.32-scaled.jpeg'
        },
        {
          id: 3,
          src: 'https://shardoray.ro/wp-content/uploads/2020/09/WhatsApp-Image-2020-09-24-at-16.03.39-1-scaled.jpeg'
        },
        {
          id: 4,
          src: 'https://shardoray.ro/wp-content/uploads/2020/09/WhatsApp-Image-2020-09-24-at-16.03.38-scaled.jpeg'
        },
      ],
      colors: [
        {
          id: 1,
          name: '#f0d27a',
          images: [
            {
              id: 1,
              src: 'https://shardoray.ro/wp-content/uploads/2020/09/WhatsApp-Image-2020-09-24-at-16.03.38-1-scaled.jpeg'
            },
            {
              id: 2,
              src: 'https://shardoray.ro/wp-content/uploads/2020/09/WhatsApp-Image-2020-09-24-at-16.03.32-scaled.jpeg'
            },
          ],
          sizes: [
            {
              id: 1,
              name: 'XS',
              old_price: 101,
              new_price: 19,
              stoc: 109
            },
            {
              id: 2,
              name: 'S',
              old_price: 201,
              new_price: 20,
              stoc: 99
            },
            {
              id: 3,
              name: 'M',
              old_price: 301,
              new_price: 30,
              stoc: 499
            },
            {
              id: 4,
              name: 'L',
              old_price: 401,
              new_price: 40,
              stoc: 2099
            }
          ]
        },
        {
          id: 2,
          name: '#95cae9',
          images: [
            {
              id: 3,
              src: 'https://shardoray.ro/wp-content/uploads/2020/09/WhatsApp-Image-2020-09-24-at-16.03.39-1-scaled.jpeg'
            },
            {
              id: 4,
              src: 'https://shardoray.ro/wp-content/uploads/2020/09/WhatsApp-Image-2020-09-24-at-16.03.38-scaled.jpeg'
            },
          ],
          sizes: [
            {
              id: 1,
              name: 'S',
              old_price: 190,
              new_price: 69,
              stoc: 9
            },

            {
              id: 2,
              name: 'L',
              old_price: 280,
              new_price: 89,
              stoc: 20
            }
          ]
        },
      ],

    },
  ],
  categories: [
    {
      
        name: 'Femei',
        subcategories: [
        {
          'slug': 'tricouri-femei',
          'name': 'TRICOURI',
          'img': 'tricouri-femei.png',
        },
        {
          'slug': 'compleuri-femei',
          'name': 'COMPLEURI',
          'img': 'compleuri-femei.png',
        },
        {
          'slug': 'pulovere-femei',
          'name': 'PULOVERE',
          'img': 'pulovere-femei.png',
        },
        {
          'slug': 'bluze-femei',
          'name': 'BLUZE',
          'img': 'bluze-femei.png',
        },
        {
          'slug': 'hanorace-femei',
          'name': 'HANORACE',
          'img': 'hanorace-femei.png',
        },
        {
          'slug': 'rochii-femei',
          'name': 'ROCHII',
          'img': 'rochii-femei.png',
        },
        {
          'slug': 'topuri-femei',
          'name': 'TOPURI',
          'img': 'topuri-femei.png',
        },
        {
          'slug': 'pijamale-femei',
          'name': 'PIJAMALE',
          'img': 'pijamale-femei.png',
        },
        {
          'slug': 'colanti-femei',
          'name': 'COLANTI',
          'img': 'colanti-femei.png',
        },
        {
          'slug': 'pantaloni-sport-femei',
          'name': 'PANTALONI SPORT',
          'img': 'pantaloni-sport-femei.png',
        },
        {
          'slug': 'jeans-femei',
          'name': 'JEANS',
          'img': 'blugi-femei.png',
        },
        {
          'slug': 'trening-femei',
          'name': 'TRENING',
          'img': 'trening-femei.png',
        },
        {
          'slug': 'geci-femei',
          'name': 'GECI',
          'img': 'geci-femei.png',
        },
        {
          'slug': 'body-femei',
          'name': 'BODY',
          'img': 'body-femei.png',
        },
      ],
    },
    {
      name: "Barbati",
      subcategories: [
        {
          'slug': 'pantaloni-barbati',
          'name': 'PANTALONI',
          'img': 'pantaloni-barbati.png',
        },
        {
          'slug': 'trening-barbati',
          'name': 'TRENING',
          'img': 'trening-barbati.png',
        },
        {
          'slug': 'bluze-barbati',
          'name': 'BLUZE',
          'img': 'bluze-barbati.png',
        },
        {
          'slug': 'pulovere-barbati',
          'name': 'PULOVERE',
          'img': 'pulovere-barbati.png',
        },
        {
          'slug': 'tricouri-barbati',
          'name': 'TRICOURI',
          'img': 'tricouri-barbati.png',
        },
        {
          'slug': 'hanorace-barbati',
          'name': 'HANORACE',
          'img': 'hanorace-barbati.png',
        },
        {
          'slug': 'blugi-barbati',
          'name': 'BLUGI',
          'img': 'blugi-barbati.png',
        },
        {
          'slug': 'camasi-barbati',
          'name': 'CAMASI',
          'img': 'camasi-barbati.png',
        },
        {
          'slug': 'maiouri-barbati',
          'name': 'MAIOURI',
          'img': 'maiouri-barbati.png',
        },
        {
          'slug': 'cardigane-barbati',
          'name': 'CARDIGANE',
          'img': 'cardigane-barbati.png',
        },
      ],
    },
    {
      name: 'Accesorii',
      subcategories: [
        {
          'slug': 'accesorii',
        }
      ]
    },
    {
      name: 'Home&Deco',
      subcategories: [
        {
          'slug': 'home-and-deco',
        }
      ]
    }
  ],
  colors: [
    {
      id: 1,
      name: 'Negru',
      color: '#000'
    },
    {
      id: 2,
      name: 'Albastru',
      color: '#0188cc'
    },
    {
      id: 3,
      name: 'Verde',
      color: '#81d742'
    },
    {
      id: 4,
      name: 'Rosu',
      color: '#ab6e6e'
    },
    {
      id: 5,
      name: 'Galben',
      color: '#eded68'
    },
    
  ],
  sizes: [
    {
      id:1,
      name: 'XS'
    },
    {
      id:2,
      name: 'S'
    },
    {
      id:3,
      name: 'M'
    },
    {
      id:4,
      name: 'L'
    },
    {
      id:5,
      name: 'XL'
    },
  ]
  
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
