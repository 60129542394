
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, UrlTree } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { concatMap, delay, map, take, tap } from 'rxjs/operators';
import { AuthAPIService } from '../services';
import { isPlatformBrowser } from '@angular/common';
import { User } from '../models';

@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthAPIService,
        @Inject(PLATFORM_ID) private platformId
    ) { }




    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //     if (localStorage.getItem('ShardorayUserData')) {

    //         // logged in so return true
    //         return true;
    //         //return this.router.navigate(['/contul-meu']);
    //     }

    //     // not logged in so redirect to login page with the return url and return false
    //     this.router.navigate(['autentificare'], { queryParams: { returnUrl: state.url }});
    //     return false;
    // }

    canActivate(route: ActivatedRouteSnapshot, router: RouterStateSnapshot): | boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
        if (isPlatformBrowser(this.platformId)) {
            if (localStorage.getItem('ShardorayUserData')) {
                this.authService.user = new BehaviorSubject<User>(
                    JSON.parse(localStorage.getItem('ShardorayUserData'))
                );

                return this.authService.user.pipe(
                    take(1),
                    map(user => {
                        const isAuth = !!user && user.active > 0;
                        if (isAuth) {
                            return true;
                        }
                        return this.router.createUrlTree(['/autentificare']);
                    })
                )
            }
        }
        
    }
}
