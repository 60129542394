import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Product } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReturnFormService {

  setProducts$ = new BehaviorSubject<any[]>([]);
  _apiUrl = environment.apiUrl;

  constructor(
    private _httpClient: HttpClient,
    private toaster: ToastrService
  ) { }

  /**
   * Get return
   *
   * @returns {Observable<Return>}
   */
   getOrderDetails(orderID: string) {
    return this._httpClient.get(this._apiUrl + 'returns/retur/' + orderID).pipe(
      tap((data: any) => {
        return data;
        
      })
    )
  }
  /**
   * Add return
   *
   * @returns {Promise<any>}
   */
   sendReturn(obj: any) {
    return this._httpClient.post(this._apiUrl + 'returns/retur/add', obj).pipe(
      tap((data: any) => {
        
        if (!data.success) {
          this.toaster.warning('', `${data.returnal_messages}`, {
            timeOut: 3000,
            positionClass: 'toast-bottom-right'
          });
          return;
        }
        return data;
      })
    )
  }

   /**
    * Get return
    *
    * @returns {Promise<any>}
    */
    countReturns(returnID, options): Promise<any> {
      
      return new Promise((resolve, reject) => {
        this._httpClient.get(this._apiUrl + 'returns/admin/countRetur/' + returnID, options)
          .subscribe((response: any) => {
            resolve(response);
          }, reject);
      });
  
    }
}
