<div class="checkout-info-box">
  <h3 class="step-title">Metoda de plata:</h3>
  <div class="info-box" *ngIf="samedayDelivery$.value === 'easybox'">
    <img src="/assets/images/icons/info.svg" alt="info" />
    <p>Nu este posibila plata numerar la livrarea prin Easybox!</p>
  </div>
  <ul class="payment-method">
    <li
      (click)="handlePaymentChange('numerar')"
      *ngIf="samedayDelivery$.value !== 'easybox'"
      [class.selected]="payment$.value === 'numerar'"
    >
      <input
        type="radio"
        name="select-payment"
        id="numerar"
        [checked]="payment$.value === 'numerar'"
        value="numerar"
      />
      <label for="numerar"><strong>Numerar</strong></label>
    </li>
    <li *ngIf="samedayDelivery$.value === 'easybox'">
      <input disabled type="radio" name="select-payment" />
      <label><strong>Numerar</strong></label>
    </li>

    <li
      (click)="handlePaymentChange('card online')"
      [class.selected]="payment$.value === 'card online'"
    >
      <input
        type="radio"
        name="select-payment"
        id="cardOnline"
        [checked]="payment$.value === 'card online'"
        value="cardOnline"
      />
      <label for="cardOnline"><strong>Plata online</strong></label>
    </li>
  </ul>
</div>
