<nav aria-label="breadcrumb" class="breadcrumb-nav">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/">Acasa</a></li>
        <li class="breadcrumb-item active" aria-current="page">Comanda finalizata</li>
      </ol>
    </div><!-- End .container -->
  </nav>
<div class="mt-6"></div><!-- margin -->
<div class="container">
  <div class="row clearfix">
    <div class="col-lg-12 col-md-12 col-sm-12 cart-shiping-update-wrapper">
      <div class="cart-shiping-update">
        <h1>Am primit comanda ta!</h1>
        <h2>In cel mai scurt timp vei fi contactat(a) de catre unul din operatorii nostri.</h2>
        <a class="btn btn-primary" routerLink="/"><i class="fas fa-long-arrow-alt-left"></i>Inapoi la cumparaturi</a>
      </div>
    </div>
  </div>
</div>
<div class="mb-6"></div><!-- margin -->
