<div class="container">
  <nav aria-label="breadcrumb" class="breadcrumb-nav mb-4">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/">Acasa</a></li>
        <li *ngIf="productCategory" class="breadcrumb-item">
          <a routerLink="/produse/{{ productCategory.alias }}">{{
            productCategory.category_name
          }}</a>
        </li>
        <li
          *ngIf="productCategory && productSubcategory"
          class="breadcrumb-item"
        >
          <a
            routerLink="/produse/{{ productCategory.alias }}/{{
              productSubcategory.subcategory_alias
            }}"
            >{{ productSubcategory.subcategory_name }}</a
          >
        </li>
      </ol>
    </div>
  </nav>
  <div
    class="product-single-container product-single-default product-quick-view"
  >
    <div class="row">
      <div class="col-md-5 product-single-gallery">
        <div class="product-slider-container">
          <owl-carousel-o
            [options]="customOptionsQuick"
            class="product-single-carousel show-nav-hover"
            (change)="slideChange($event)"
            #owlCar
          >
            <ng-container *ngFor="let image of product?.images; let i = index">
              <ng-template carouselSlide [id]="image.url">
                <div class="product-item">
                  <!-- <img class="product-single-image" [src]="uploadedImgUrl + image.url" width="915" height="915"
                    alt="product" /> -->
                  <lib-ngx-image-zoom
                    *ngIf="colorChanged$ | async"
                    [thumbImage]="uploadedImgUrl + image.url"
                    [fullImage]="none"
                    [magnification]="1"
                    [enableScrollZoom]="true"
                    [enableLens]="true"
                    [lensWidth]="100"
                    [lensHeight]="100"
                  ></lib-ngx-image-zoom>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
        <div class="prod-thumbnail" id="carousel-custom-dots">
          <!-- <div class="prod-thumbnail owl-dots transparent-dots flex-column" id='carousel-custom-dots'>
            <ng-container *ngFor="let image of product?.images; let j = index">
              <div class="owl-dot" (click)="owlCar.to(image.url); changeImage(image.url)"
                [class.active]="selectedImage === image.url">
                <img [src]="uploadedImgUrl + image.url" width="98" height="98" alt="product" />
              </div>
            </ng-container> 
          </div>-->
        </div>
      </div>

      <div class="col-md-7 product-single-details">
        <h1 class="product-title">{{ product.name }}</h1>

        <div class="ratings-container">
          <div class="product-ratings">
            <span class="ratings" style="width: 60%"></span>
          </div>

          <!-- <a href="#" class="rating-link">( 6 Recenzii )</a> -->
          <!-- <p>Stoc: {{product?.stoc}}</p> -->
        </div>

        <div class="price-box" *ngIf="product?.selectedSize">
          <span
            *ngIf="product?.selectedSize && product?.selectedSize.old_price > 0"
            class="old-price"
            >{{ product?.selectedSize.old_price }} Lei</span
          >
          <span
            class="product-price"
            [class.reduced]="product?.selectedSize.old_price > 0"
            >{{ product?.selectedSize.current_price }} Lei</span
          >
        </div>
        <div class="product-desc">
          <p>
            Compozitie:
            {{ product.composition }}
          </p>
          <p>Descriere:</p>
          <p [innerHTML]="product.description"></p>
        </div>
        <div class="product-filters-container">
          <div class="product-single-filter mb-2 colors-list">
            <label>Culori:</label>
            <!-- <ng-container *ngFor="let color of product?.colors; first as isFirstColor; let i = index">
              <a *ngIf="color.color_list.length === 1" (click)="selectColor(product, color, i); owlCar.to('slide-' + i)"
                class="product-select type-image"
                [ngClass]="{'checked': product.selectedColor.ColorID === color.ColorID}"
                [style.background-color]="color.color_list[0]"></a>
              <a *ngIf="color.color_list.length === 0" (click)="selectColor(product, color, i); owlCar.to('slide-' + i)"
                class="product-select type-image"
                [ngClass]="{'checked': product.selectedColor.ColorID === color.ColorID}"
                style="background-color: #fff;"></a>
              <a (click)="selectColor(product, color, i); owlCar.to('slide-' + i)"
                class="product-select type-image two-colors"
                [ngClass]="{'checked': product.selectedColor.ColorID === color.ColorID}"
                *ngIf="color.color_list.length > 1">
                <span [style.background-color]="color.color_list[0]"></span>
                <span [style.background-color]="color.color_list[1]"></span>
              </a>
            </ng-container> -->
            <ng-container *ngFor="let color of product?.colors">
              <ul class="color-container">
                <li>
                  <a
                    (click)="
                      selectColor(product, color);
                      owlCar.to(color.images[0].url);
                      changeImage(color.images[0].url)
                    "
                    class="d-flex align-items-center justify-content-center p-0"
                    [ngClass]="{
                      checked: product.selectedColor?.ColorID === color.ColorID
                    }"
                  >
                    <img
                      [src]="uploadedImgUrl + color.images[0].url"
                      width="30"
                      height="30"
                      alt="filter-img"
                    />
                  </a>
                </li>
              </ul>
            </ng-container>
          </div>
        </div>

        <div class="product-filters-container">
          <div class="product-single-filter mb-2">
            <label>Marimi:</label>
            <ul class="sizes" *ngIf="product.isMultiple === 0">
              <li *ngFor="let size of product?.sizes">
                <a
                  (click)="selectSize(product, size)"
                  class="product-select type-image"
                  [ngClass]="{
                    checked: product.selectedSize.SizeID === size.SizeID
                  }"
                  >{{ size.size_name }}</a
                >
              </li>
            </ul>
            <div
              class="complementary_products"
              *ngIf="product.isMultiple === 1"
            >
              <ng-container
                *ngFor="
                  let complementary_product of product?.complementary_products
                "
              >
                <div class="complementary_product__container">
                  <a
                    [routerLink]="
                      '/produs/' +
                      complementary_product.alias +
                      '/' +
                      complementary_product.id
                    "
                    class="complementary_product__image"
                  >
                    <img
                      [src]="
                        uploadedImgUrl + complementary_product.selectedImageObj
                      "
                      alt=""
                    />
                  </a>
                  <div class="complementary_product__details">
                    <strong>{{ complementary_product.name }}</strong>
                    <ul class="complementary_product__list">
                      <li
                        class="complementary_product__sizes"
                        *ngFor="
                          let size of complementary_product.colors[0].sizes
                        "
                      >
                        <a
                          (click)="
                            selectSizeMultiple(
                              product,
                              complementary_product,
                              size
                            )
                          "
                          class="product-select type-image"
                          [ngClass]="{
                            checked:
                              complementary_product.selectedSize.SizeID ===
                              size.SizeID
                          }"
                          >{{ size.size_name }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="stoc-alert__container" *ngIf="product?.selectedSize">
            <span
              class="stoc-alert product-label label-sale"
              *ngIf="product?.selectedSize.stoc == 3"
              >Ultimele trei produse</span
            >
            <span
              class="stoc-alert product-label label-sale"
              *ngIf="product?.selectedSize.stoc == 2"
              >Ultimele doua produse</span
            >
            <span
              class="stoc-alert product-label label-sale"
              *ngIf="product?.selectedSize.stoc == 1"
              >Ultimul produs</span
            >
          </div>
        </div>

        <div class="product-action">
          <div class="product-single-qty">
            <div
              class="input-group bootstrap-touchspin bootstrap-touchspin-injected"
            >
              <span class="input-group-btn input-group-prepend">
                <button
                  (click)="removeOneFromCart()"
                  class="btn btn-outline btn-down-icon bootstrap-touchspin-down"
                ></button>
              </span>

              <input
                class="horizontal-quantity form-control"
                min="1"
                [(ngModel)]="cartQuantity"
                value="1"
                type="number"
                name="quantity"
              />
              <span class="input-group-btn input-group-append">
                <button
                  (click)="addOneToCart()"
                  class="btn btn-outline btn-up-icon bootstrap-touchspin-up"
                ></button>
              </span>
            </div>
          </div>

          <a
            (click)="addToCart(product); addedToCart.triggerModal(product)"
            class="btn btn-dark add-cart icon-shopping-cart"
            title="Add to Cart"
            >Adauga in cos</a
          >
        </div>

        <div class="product-single-share mt-2">
          <div class="row">
            <div class="col-12">
              <label class="sr-only">Distribuie:</label>
              <share-buttons
                [theme]="'circles-dark'"
                [include]="[
                  'facebook',
                  'messenger',
                  'whatsapp',
                  'pinterest',
                  'email',
                  'copy'
                ]"
                [show]="3"
                [title]="product?.product_name"
                [description]="product?.description"
                [url]="shareUrl"
                [image]="uploadedImgUrl + selectedImage"
                [autoSetMeta]="false"
              >
              </share-buttons>
            </div>
          </div>
          <a
            class="add-wishlist"
            title="Add to Wishlist"
            *ngIf="!product?.selectedColor?.addedToFavorites" 
            (click)="toggleFavorites(product)"
            >Adauga la favorite</a
          >
          <a
            class="add-wishlist"
            [class.favorite]="product?.selectedColor?.addedToFavorites"
            *ngIf="product?.selectedColor?.addedToFavorites
            "
            (click)="toggleFavorites(product)"
            >Sterge de la favorite</a
          >
        </div>
      </div>
    </div>
  </div>

  <div class="product-single-tabs">
    <ul class="nav nav-tabs" role="tablist">
      <!-- <li class="nav-item">
        <a class="nav-link active" id="product-tab-desc" data-toggle="tab"
          [ngClass]="{ 'active':activeTab ==='product-desc-content'}" (click)="toggleTab('product-desc-content')"
          role="tab" aria-controls="product-desc-content" aria-selected="true">Descriere</a>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" id="product-tab-delivery-info" data-toggle="tab"
          [ngClass]="{ 'active':activeTab ==='product-delivery-info-content'}"
          (click)="toggleTab('product-delivery-info-content')" role="tab" aria-controls="product-delivery-info-content"
          aria-selected="false">Livrare si plata</a>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" id="product-tab-warranty" data-toggle="tab"
          [ngClass]="{ 'active':activeTab ==='product-warranty-content'}"
          (click)="toggleTab('product-warranty-content')" role="tab" aria-controls="product-warranty-content"
          aria-selected="false">Retur si garantie</a>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" id="product-tab-reviews" data-toggle="tab"
          [ngClass]="{ 'active':activeTab==='product-reviews-content'}" (click)="toggleTab('product-reviews-content')"
          role="tab" aria-controls="product-reviews-content" aria-selected="false">Recenzii (3)</a>
      </li> -->
    </ul>
    <div class="tab-content">
      <!-- <div class="tab-pane fade show active" [ngClass]="{ 'active':activeTab ==='product-desc-content'}"
        id="product-desc-content" role="tabpanel" aria-labelledby="product-tab-desc">
        <div class="product-desc-content">
          <ul>
            <li><i class="fa fa-check-circle"></i>Trening damă sport de calitate, la un preț avantajos ⭐</li>
            <li><i class="fa fa-check-circle"></i>Comandă acum și beneficiezi de Livrare Rapidă în
              toată țara în 24/48h ✅</li>
          </ul>
          <p>70% bumbac, 25% poliester şi 5% lycra </p>
        </div>
      </div> -->

      <!-- <div class="tab-pane fade show fade" [ngClass]="{ 'active':activeTab ==='product-delivery-info-content'}"
        id="product-more-info-content" role="tabpanel" aria-labelledby="product-tab-more-info">
        <div class="product-desc-content">

          <p><strong>LIVRARE</strong></p>

          • <strong>GRATUITA</strong> pentru livrarea prin curier a comenzilor peste valoarea minima de 199 lei.<br>
          • <strong>6 lei </strong>pentru procesarea comenzilor cu plata la livrare/ramburs.<br>
          • <strong>20 lei</strong> pentru livrarea prin curier a comenzilor sub valoarea minima de 199 lei.<br>

          <p><strong>PLATI</strong><br>
            • Plata prin <strong>card de credit/debit</strong> - Mastercard, Visa sau Maestro.<br>
            • Plata prin <strong>card de credit, in rate</strong> - prin: UniCreditCard | ING Credit
            Card |Star BT | CardAvantaj | Raiffeisen Bank | BRD Finance | Alpha Bank | GarantiBank<br>
            • Plata <strong>la livrare/ramburs</strong> (doar pentru membrii Shardoray).<br>
          </p>
        </div>
      </div> -->

      <!-- <div class="tab-pane show fade" id="product-warranty-content"
        [ngClass]="{ 'active':activeTab ==='product-warranty-content'}" role="tabpanel"
        aria-labelledby="product-tab-tags">
        <div class="product-tags-content">
          <p><strong>30 DE ZILE RETUR GRATUIT</strong></p>

          <p>
            • <strong>ridicare de catre Curier</strong> - completeaza formularul de retur in sectiunea Retururi din
            contul tau Shardoray
          </p>

          <p>Afla mai multe informatii despre conditiile de retur <a
              routerLink="/politica-retur"><strong>AICI</strong></a>!</p>

          <p>In cazul in care produsul primit este deteriorat sau se afla in garantie, te rugam sa contactezi
            departamentul de <strong>Relatii cu Clientii</strong> la numerele de telefon: <strong>(0723) 333 333 /
              (0723) 333 333</strong>.</p>

        </div>
      </div> -->

      <!-- <div class="tab-pane show fade" id="product-reviews-content"
        [ngClass]="{ 'active':activeTab==='product-reviews-content'}" role="tabpanel"
        aria-labelledby="product-tab-reviews">
        <div class="product-reviews-content">
          <div class="row">
            <div class="col-xl-7">
              <h2 class="reviews-title">3 reviews for Product Long Name</h2>

              <ol class="comment-list">
                <li class="comment-container">
                  <div class="comment-avatar">
                    <img src="assets/images/avatar/avatar1.jpg" width="65" height="65" alt="avatar" />
                  </div>

                  <div class="comment-box">
                    <div class="ratings-container">
                      <div class="product-ratings">
                        <span class="ratings" style="width:80%"></span>
                      </div>
                    </div>

                    <div class="comment-info mb-1">
                      <h4 class="avatar-name">Cristian Hurduc</h4> - <span class="comment-date">Mai 15, 2021</span>
                    </div>

                    <div class="comment-text">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip.</p>
                    </div>
                  </div>
                </li>

                <li class="comment-container">
                  <div class="comment-avatar">
                    <img src="assets/images/avatar/avatar2.jpg" width="65" height="65" alt="avatar" />
                  </div>

                  <div class="comment-box">
                    <div class="ratings-container">
                      <div class="product-ratings">
                        <span class="ratings" style="width:80%"></span>
                      </div>
                    </div>

                    <div class="comment-info mb-1">
                      <h4 class="avatar-name">Ionut Mircioaga</h4> - <span class="comment-date">Mai 15, 2021</span>
                    </div>

                    <div class="comment-text">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip.</p>
                    </div>
                  </div>
                </li>

                <li class="comment-container">
                  <div class="comment-avatar">
                    <img src="assets/images/avatar/avatar3.jpg" width="65" height="65" alt="avatar" />
                  </div>

                  <div class="comment-box">
                    <div class="ratings-container">
                      <div class="product-ratings">
                        <span class="ratings" style="width:80%"></span>
                      </div>
                    </div>

                    <div class="comment-info mb-1">
                      <h4 class="avatar-name">Catalin Milea</h4> - <span class="comment-date">Mai 15, 2021</span>
                    </div>

                    <div class="comment-text">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip.</p>
                    </div>
                  </div>
                </li>
              </ol>
            </div>

            <div class="col-xl-5">
              <div class="add-product-review">
                <form action="#" class="comment-form m-0">
                  <h3 class="review-title">Adauga o recenzie</h3>

                  <div class="rating-form">
                    <label for="rating">Adauga calificativul tau</label>
                    <span class="rating-stars">
                      <a class="star-1" href="#">1</a>
                      <a class="star-2" href="#">2</a>
                      <a class="star-3" href="#">3</a>
                      <a class="star-4" href="#">4</a>
                      <a class="star-5" href="#">5</a>
                    </span>

                  </div>

                  <div class="form-group">
                    <label>Recenzia ta</label>
                    <textarea cols="5" rows="6" class="form-control form-control-sm"></textarea>
                  </div>


                  <div class="row">
                    <div class="col-md-6 col-xl-12">
                      <div class="form-group">
                        <label>Numele tau</label>
                        <input type="text" class="form-control form-control-sm" required>
                      </div>
                    </div>

                    <div class="col-md-6 col-xl-12">
                      <div class="form-group">
                        <label>E-mail-ul tau</label>
                        <input type="text" class="form-control form-control-sm" required>
                      </div>
                    </div>
                  </div>

                  <input type="submit" class="btn btn-dark ls-n-15" value="Trimite">
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>

  <div class="products-section pt-0">
    <h2 class="section-title border-0">Completeaza-ti tinuta</h2>

    <owl-carousel-o
      class="products-slider dots-top"
      [options]="categoryProducts"
    >
      <ng-template
        carouselSlide
        *ngFor="let product of recommendedProducts$ | async"
      >
        <div class="product-default inner-quickview inner-icon">
          <figure>
            <img
              class="bf-badge"
              *ngIf="product.isBlackFriday"
              src="/assets/images/banners/bf-badge.png"
              alt=""
            />
            <a
              (click)="
                navigateToProduct(
                  product?.alias,
                  product?.id,
                  product?.selectedColor?.ColorID,
                  product?.meta
                )
              "
            >
              <img
                src="{{ uploadedImgUrl }}{{ product.selectedImageObj }}"
                alt="image"
              />
            </a>
            <div class="label-group">
              <div
                class="product-label label-sale"
                *ngIf="
                  product?.selectedSize && product?.selectedSize.old_price > 0
                "
              >
                {{
                  100 -
                    (product?.selectedSize.current_price /
                      product?.selectedSize.old_price) *
                      100 | number : "1.0-0"
                }}
                %
              </div>
              <div
                *ngIf="product?.isRecommended === 1"
                class="product-label label-hot"
              >
                Recomandat
              </div>
            </div>

            <a
              (click)="quickView.triggerModal(product)"
              class="btn-quickview"
              title="Vezi Produs"
              >Vezi Produs</a
            >
          </figure>
          <div class="product-details">
            <div class="category-wrap">
              <a
                class="add-wishlist"
                title="Add to Wishlist"
                *ngIf="!product?.addedToFavorites"
                (click)="toggleFavorites(product)"
                >Adauga la favorite</a
              >
              <a
                class="add-wishlist"
                [class.favorite]="product?.addedToFavorites"
                *ngIf="
                  product?.addedToFavorites &&
                  product?.selectedColor?.addedToFavorites
                "
                (click)="toggleFavorites(product)"
                >Sterge de la favorite</a
              >
            </div>
            <div class="product-select-group" *ngIf="product?.selectedColor">
              <ng-container *ngFor="let color of product?.colors">
                <ul class="color-container">
                  <li>
                    <a
                      (click)="
                        selectColor(product, color);
                        changeImage(color.images[0].url)
                      "
                      class="d-flex align-items-center justify-content-center p-0"
                      [ngClass]="{
                        checked: product.selectedColor?.ColorID === color.ColorID
                      }"
                    >
                      <img
                        [src]="uploadedImgUrl + color.images[0].url"
                        width="30"
                        height="30"
                        alt="filter-img"
                      />
                    </a>
                  </li>
                </ul>
              </ng-container>
            </div>
            <h2 class="product-title">
              <a (click)="navigateToProduct(product?.alias, product?.id)">{{
                product?.name
              }}</a>
            </h2>
            <div class="ratings-container">
              <div class="product-ratings">
                <!-- <ng-template #t let-fill="fill">
                  <span class="star" [class.full]="fill === 100">
                    <span class="half" [style.width.%]="fill">&#9733;</span>&#9734;
                  </span>
                </ng-template>
                <ngb-rating [(rate)]="product.rating" [starTemplate]="t" [readonly]="true" max="5"></ngb-rating>
                <span class="tooltiptext tooltip-top">{{product?.rating}}</span> -->
              </div>
            </div>
            <div class="price-box">
              <span
                *ngIf="
                  product?.selectedSize &&
                  product?.selectedSize.current_price > 0
                "
                class="product-price"
                [class.reduced]="product?.selectedSize.old_price > 0"
                >{{ product?.selectedSize.current_price }} Lei</span
              >
              <span
                *ngIf="
                  product?.selectedSize && product?.selectedSize.old_price > 0
                "
                class="old-price"
                >{{ product?.selectedSize.old_price }} Lei</span
              >
            </div>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</div>
<app-product-quickview #quickView></app-product-quickview>
<app-added-to-cart #addedToCart></app-added-to-cart>
