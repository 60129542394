import { NgModule } from '@angular/core';
import { CouponsComponent } from './coupons.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    CouponsComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [CouponsComponent]
})
export class CouponsModule { }
