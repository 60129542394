import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  private _apiUrl: string;
  private categoryFeed: string;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */

  constructor(private _httpClient: HttpClient) {
    this._apiUrl = environment.apiUrl;
    this.categoryFeed = environment.theMarketerCategories;
  }

  /**
   * Get categories
   *
   * @returns {Promise<any>}
   */

  public getCategories(): Promise<any> {

    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'categories/allCategories')
        .subscribe((response: any) => {
          
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get categories
   *
   * @returns {Promise<any>}
   */

  public getCategoriesForFilter(): Promise<any> {

    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'categories/allCategoriesForFilter')
        .subscribe((response: any) => {
          
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get all categories
   *
   * @returns {Promise<any>}
   */

  public getAdminCategories(): Promise<any> {

    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'categories/admin/categories')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Add category
   *
   * @returns {Promise<any>}
   */

   public addAdminCategory(category_name, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'categories/admin/addCategory', {'category_name': category_name}, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Update category name
   *
   * @returns {Promise<any>}
   */

  public updateCategoryName(categoryID, category_name, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'categories/admin/updateCategoryName', {'categoryID': categoryID, 'category_name': category_name}, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get all categories
   *
   * @returns {Promise<any>}
   */

   public getAdminSubcategories(): Promise<any> {

    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'categories/admin/subcategories')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Add category
   *
   * @returns {Promise<any>}
   */

   public addAdminSubcategory(subcategory_name, categoryID, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'categories/admin/addSubcategory', {'subcategory_name': subcategory_name, 'categoryID': categoryID}, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Update category name
   *
   * @returns {Promise<any>}
   */

  public updateSubcategoryName(subcategoryID, subcategory_name, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'categories/admin/updateSubcategoryName', {'subcategoryID': subcategoryID, 'subcategory_name': subcategory_name}, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Update category name
   *
   * @returns {Promise<any>}
   */

  public updateCategoryOnSubcategory(categoryID, subcategoryID, options): Promise<any> {
    
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'categories/admin/updateCategoryOnSubcategory', {'subcategoryID': subcategoryID, 'categoryID': categoryID}, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get categories
   *
   * @returns {Promise<any>}
   */

   public getClientCategories(): Promise<any> {

    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'categories/allCategories')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get categories with products
   *
   * @returns {Promise<any>}
   */

  public getCategoriesWithProducts(): Promise<any> {

    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'categories')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get category
   *
   * @returns {Promise<any>}
   */

  public getCategory(categoryID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'category/' + categoryID)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  
  /**
   * Get product categories
   *
   * @returns {Promise<any>}
   */

  public getProductCategories(productID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'categories/productCategories/' + productID)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Add product categories
   *
   * @returns {Promise<any>}
   */

  public addNewProductCategory(productID, addNewProductCategory, categoryID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'addProductCategory', { 'productID': productID, 'subcategoryID': addNewProductCategory, 'categoryID': categoryID})
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Remove product categories
   *
   * @returns {Promise<any>}
   */

  public removeProductCategory(productID, removeProductCategory, categoryID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'removeProductCategory', { 'productID': productID, 'subcategoryID': removeProductCategory, 'categoryID': categoryID})
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }


  /**
   * Get random categories
   *
   * @returns {Promise<any>}
   */

  public getRandomCategories(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'categories/random')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  

  public generateCategoriesFeed(categories){
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(this.categoryFeed, { categories: categories }).subscribe((response: any) => {
          resolve(response);
        }, reject);;
    });
  }


  public getMockCategories() {
    return environment.categories;
  }
}
