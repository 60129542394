import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthAPIService, MyAccountService, ProductsService } from 'src/app/services';
import { environment } from 'src/environments/environment.prod';


@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  order: any;
  products: any;
  uploadedImgUrl = environment.uploadImagesUrl;

  constructor(
    private myAccountService: MyAccountService, 
    private _route: ActivatedRoute, 
    public router: Router,  
    public authAPIService: AuthAPIService,
    public _ProductsService: ProductsService,
    ) { }

  ngOnInit(): void {
    this._route.paramMap.subscribe(params => {
      this.authAPIService.user.subscribe(user => {
        this.myAccountService.getUserOrder(params.get('orderUUID'), user._token).then(data => {
          this.order = data[0];
          this.products = JSON.parse(data[0].products_ordered);
        })
      })
    });
  }

  logout() {
    this.authAPIService.logout();
  }
}
