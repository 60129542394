<app-slider></app-slider>
<!-- <app-scheduled-categories></app-scheduled-categories> -->
<app-selected-products></app-selected-products>
<app-newsletter></app-newsletter>
<!-- <app-discount-products></app-discount-products>

<div class="container">
  <hr>
  <app-categories></app-categories>
</div> -->
<!-- <app-season-products></app-season-products> -->


<!-- <app-recommended-products></app-recommended-products> -->
