<div class="dropdown h-100" class="statusDropdownContainer">
  <a class="dropdown-toggle" role="button" id="dropdownMenuLink" (click)="toggle()">
    <span class="text-truncate" *ngIf="!selectedValue">Alege produs</span>
    <img *ngIf="selectedValue" [src]="uploadedImgUrl + selectedValue.selectedImageObj">
    <span class="text-truncate" *ngIf="selectedValue">{{selectedValue.name}}</span>
    <span class="arrow"></span>
  </a>

  <div class="dropdown-menu pt-0" [class.show]="toggleState$ | async" aria-labelledby="dropdownMenuButton">
    <input type="text" class="p-2 searchInput" placeholder="Cauta..." [ngModel]="searchValue"
      (ngModelChange)="filterDropdown($event)">
    <a *ngFor="let product of filteredList; let i=index" class="dropdown-item pointer text-contain"
      [ngClass]="{'alternateBackground': i%2 == 0 }" (click)="selectValue(product)">
      <img [src]="uploadedImgUrl + product.selectedImageObj">
      {{product.name}}
    </a>
    <div *ngIf="filteredList.length <= 0" class="text-center text-muted mt-1">{{status$ | async}}</div>

  </div>
</div>
