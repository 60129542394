<nav aria-label="breadcrumb" class="breadcrumb-nav">
  <div class="container">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Acasa</a></li>
      <li class="breadcrumb-item active" aria-current="page">Termeni si conditii</li>
    </ol>
  </div><!-- End .container -->
</nav>
<div class="mt-6"></div><!-- margin -->
<div class="container">
  <h1>Gdpr si cookies</h1>
  <div class="jas-container">
    <div class="jas-row jas-page">
      <div class="jas-col-md-12 jas-col-xs-12 mt__60 mb__60" role="main" itemscope="itemscope"
        itemtype="http://schema.org/CreativeWork">
        <div class="intro grid x12 y12 z4">
          <div class="welcome gridModule x6 y12 z4">
            <h4>Informare despre detaliile legale privind datele personale GDPR</h4>
            <p>In toate tarile membre Uniunii Europene (UE), incepand cu data de 25.05.2018, devine aplicabil
              Regulamentul 2016/679 privind protectia persoanelor fizice in ceea ce priveste prelucrarea datelor cu
              caracter personal si privind libera circulatie a acestor date, cunoscut si sub acronimul GDPR (General
              Data Protection Regulation).</p>
          </div>
        </div>
        <div class="indexContent">
          <div class="list grid x12 y12 z4">
            <div class="text gridModule x6 y12 z4">
              <p>GDPR intareste vechea legislatie si o uniformizeaza la nivelul tuturor tarilor din UE. In Romania,
                exista in prezent Legea 677/2001 pentru protectia persoanelor cu privire la prelucrarea datelor cu
                caracter personal si libera circulatie a acestora, ale carei prevederi au fost aplicate deja de
                Shardoray. In documentele contractuale, trimiterile la Legea 677/2001 vor fi inlocuite cu mentiuni
                privind GDPR.</p>
              <p>Din acest motiv, Conditiile generale de derulare a operatiunilor bancare pentru persoane fizice
                (CGB-PF) vor fi actualizate in sectiunea Prelucrarea datelor cu caracter personal. Va descriem mai jos
                principalele modificari:</p>
              <p>Care sunt datele cu caracter personal<br>
                Orice informatie prin care o persoana fizica devine identificabila, cum ar fi: un nume, un numar de
                identificare, date de localizare, un identificator online, unul sau mai multe elemente specifice
                identitatii sale fizice, fiziologice, genetice, psihice, economice, culturale sau sociale etc.</p>
              <p>Ce inseamna prelucrarea de date cu caracter personal<br>
                Orice operatiune sau set de operatiuni efectuate asupra datelor cu caracter personal sau asupra
                seturilor de date cu caracter personal, cu sau fara utilizarea de mijloace automatizate, cum ar fi:
                colectarea, inregistrarea, organizarea, structurarea, stocarea, adaptarea, modificarea, extragerea,
                consultarea, utilizarea, dezvaluirea prin transmitere (inclusiv catre terti), diseminare sau punere la
                dispozitie in orice alt mod, alaturarea/alinierea ori combinarea, blocarea/restrictionarea, stergerea
                sau distrugerea.</p>
              <p>Care sunt categoriile de persoane fizice ale caror date pot fi prelucrate<br>
                Datele cu caracter personal care sunt prelucrate de catre Shardoray apartin urmatoarelor categorii de
                persoane fizice: client (chiar si dupa incetarea relatiei contractuale), reprezentantii legali sau
                conventionali ai clientului, utilizatorii de card suplimentar, beneficiarii reali, precum si membrii
                familiilor acestora.</p>
              <p>De ce prelucreaza Shardoray date cu caracter personal<br>
                Shardoray prelucreaza date cu caracter personal in scopul indeplinirii obligatiilor legale, pentru a
                respecta prevederile contractelor incheiate cu clientii sai si pentru a optimiza fluxurile de lucru si
                reglementarile interne, astfel incat fiecare client sa aiba parte, zi de zi, de servicii si produse
                mereu imbunatatite. De asemenea, un client Shardoray sau oricine va folosi serviciile si produsele
                bancii, in baza consimtamantului deja exprimat, va putea primi comunicari prin care ne facem cunoscute
                ofertele.</p>
              <p>Catre cine poate dezvalui Shardoray datele cu caracter personal si in ce tari poate transfera aceste
                date<br>
                Banca poate dezvalui datele cu caracter personal catre urmatoarele categorii de destinatari: clientilor
                si imputernicitilor acestora, reprezentantilor Shardoray, altor persoane fizice sau juridice care
                prelucreaza datele personale in numele Shardoray, partenerilor contractuali ai Shardoray,
                imputernicitilor Shardoray in ceea ce priveste prelucrarea datelor cu caracter personal, autoritatilor
                judecatoreasti, autoritatilor publice centrale, autoritatilor publice locale, organizatiilor
                internationale, furnizorilor de servicii si de bunuri, birourilor de credit, societatilor de asigurare
                si reasigurare, organizatiilor profesionale, organizatiilor de cercetare a pietei, agentiilor de
                colectare a debitelor/recuperare a creantelor.</p>
              <p>De asemenea, poate sa transfere anumite categorii de date cu caracter personal in afara Romaniei, in
                state din cadrul UE/ SEE, cat si in afara UE/SEE. Puteti obtine o lista actualizata cu statele unde se
                transfera datele cu caracter personal accesand Politica privind protectia datelor cu caracter personal
                si confidentialitatea datelor, disponibila la link-ul <a
                  href="https://Shardoray.ro/politica-de-confidentialitate/">https://Shardoray.ro/politica-de-confidentialitate/</a>.
              </p>
              <p>Care sunt drepturile persoanelor vizate:<br>
                Dreptul la informare – dreptul de a fi informat cu privire la prelucrarea si protejarea datelor cu
                caracter personal de catre Shardoray;<br>
                Dreptul de acces la date – dreptul de a obtine de la operatorul de date (precum Shardoray) confirmarea
                faptului ca datele cu caracter personal sunt sau nu prelucrate de catre acesta;<br>
                Dreptul la rectificare – dreptul de a obtine rectificarea datelor inexacte, precum si completarea
                datelor incomplete;<br>
                Dreptul la stergerea datelor („dreptul de a fi uitat”) – dreptul de a obtine, in masura in care sunt
                indeplinite conditiile legale, stergerea datelor cu caracter personal;<br>
                Dreptul la restrictionarea prelucrarii – dreptul de a obtine, in masura in care sunt indeplinite
                conditiile legale, marcarea datelor cu caracter personal stocate, cu scopul de a limita prelucrarea
                ulterioara a acestora;<br>
                Dreptul la portabilitatea datelor – dreptul de a primi datele cu caracter personal intr-o modalitate
                structurata, folosita in mod obisnuit si intr-un format usor de citit, precum și dreptul ca aceste date
                să fie transmise de către Shardoray. către alt operator de date, in măsura in care sunt indeplinite
                condițiile legale;<br>
                Dreptul la opozitie – dreptul de a se opune in orice moment, din motive intemeiate si legitime legate de
                situatia particulara, ca datele cu caracter personal sa faca obiectul unei prelucrari, in măsura in care
                sunt indeplinite condițiile legale;<br>
                Dreptul de a nu fi supus unei decizii individuale – dreptul de a cere si de a obtine retragerea,
                anularea sau reevaluarea oricarei decizii bazate exclusiv pe prelucrari efectuate prin mijloace automate
                (incluzand crearea de profiluri) care produce efecte juridice sau afectează in mod similar, intr-o
                măsura semnificativa, persoanele vizate;<br>
                Dreptul de a se adresa justitiei sau Autoritatii Nationale de Supraveghere a Prelucrarii Datelor cu
                Caracter Personal – dreptul de a se adresa cu plangere Autoritatii Nationale de Supraveghere a
                Prelucrarii Datelor cu Caracter Personal, respectiv de a se adresa justitiei pentru apararea oricaror
                drepturi garantate de legislația aplicabilă in domeniul protectiei datelor cu caracter personal, care au
                fost incalcate.</p>
              <p>In cazul in care un client nu este de acord cu modificarile prezentate mai sus, pana la 1 august 2018,
                are dreptul de a denunta in mod unilateral contractul cu Shardoray fara a suporta penalitati si fara a
                fi necesara invocarea unui motiv. Ulterior acestei date, modificarile vor fi considerate acceptate.</p>
              <p>Increderea clientilor nostri este cea mai importanta pentru noi, iar siguranta si confidentialitatea
                datelor lor reprezinta prioritatea noastra pentru ca ne dorim sa oferim permanent servicii&nbsp; la cel
                mai inalt nivel de securitate!</p>
              <p>Pentru informatii complete privind toate modificarile mentionate, ne poate contacta pe e-mailul
                contact@mnineliboutique.ro.</p>
            </div>
          </div>
          <div class="list grid x12 y12 z4">
            <div class="heading gridModule x3 y12 z4">
              <h2>Intrebari frecvente</h2>
            </div>
            <div class="text gridModule x6 y12 z4">
              <h4 class="dark primaryHeading">1. Ce este GDPR si ce scop are?</h4>
              <p>GDPR este&nbsp; noul regulament european in materia prelucrarii datelor personale ce a intrat in
                vigoare in 2016 si devine direct aplicabil in Romania incepand cu 25 mai 2018. Regulamentul abroga
                Directiva 95/46/CE, transpusa in legea nationala prin Legea nr. 677/2001 pentru protectia persoanelor cu
                privire la prelucrarea datelor cu caracter personal si libera circulaţie a acestor date.</p>
              <p>Reglementarea are ca scop principal asigurarea, la nivelul Uniunii Europene, a unui cadru legislativ
                uniform pentru protejarea drepturilor si intarirea protectiei datelor personale ale persoanelor fizice
                aflate in Uniunea Europeana, precum si reglarea transferului de date intre statele Uniunii Europene cat
                si in afara acesteia, in masura in care prelucrarea datelor vizeaza persoane fizice din cadrul Uniunii,
                prin:<br>
                – prelucrarea legala, echitabila si transparenta<br>
                – micsorarea volumului de date personale colectate de la persoana vizata<br>
                – stergerea datelor nenecesare<br>
                – asigurarea exactitatii datelor cu caracter personal prelucrate de catre Raiffeisen Bank<br>
                – securizarea datelor personale ale persoanei vizate</p>
              <p>Este esential sa stim ca GDPR se refera la datele cu caracter personal ale persoanelor fizice.</p>
            </div>
          </div>
          <div class="list grid x12 y12 z4">
            <div class="heading gridModule x3 y12 z4">
              <h2></h2>
            </div>
            <div class="text gridModule x6 y12 z4">
              <h4 class="dark primaryHeading">2. Ce sunt datele cu caracter personal?</h4>
              <p>Orice informatie care direct&nbsp; sau prin asociere cu alte date poate avea ca efect identificarea
                unei persoane fizice.<br>
                Exemple de date cu caracter personal: prenume, telefon, e-mail, data nasterii, loc de munca, CNP, serie
                si numar CI/BI, semnatura, imagine, voce, adresa IP, incadrare in clasa de risc, detalii tranzactionale,
                comportament, obisnuinte, etc.</p>
            </div>
          </div>
          <div class="list grid x12 y12 z4">
            <div class="heading gridModule x3 y12 z4">
              <h2></h2>
            </div>
            <div class="text gridModule x6 y12 z4">
              <h4 class="dark primaryHeading">3. Ce inseamna prelucrarea de date?</h4>
              <p>Orice operatiune care implica date cu caracter personal, cum ar fi colectarea, inregistrarea,
                organizarea, structurarea, stocarea, adaptarea sau modificarea, extragerea, consultarea, utilizarea,
                divulgarea prin transmitere, diseminarea&nbsp; sau&nbsp; punerea&nbsp; la&nbsp; dispoziţie&nbsp;
                in&nbsp; orice&nbsp; alt&nbsp; mod,&nbsp; alinierea&nbsp; sau&nbsp; combinarea, restricţionarea,
                stergerea sau distrugerea, inclusiv&nbsp; simpla vizualizare a datelor.</p>
            </div>
          </div>
          <div class="list grid x12 y12 z4">
            <div class="heading gridModule x3 y12 z4">
              <h2></h2>
            </div>
            <div class="text gridModule x6 y12 z4">
              <h4 class="dark primaryHeading">4. Cine este operator de date?</h4>
              <p>Orice persoana fizica sau juridica, autoritate publica sau alta entitate care stabileste scopul si
                mijloacele de prelucrare a datelor cu caracter personal. Operatorul poate actiona, din perspectiva
                prelucrarii de date cu caracter personal,singur,&nbsp; impreuna cu un alt operator/alti operatori
                (operatori asociati) sau prin intermediul unei terte parti (persoana imputernicita de operator) care va
                prelucra date in numele operatorului, conform limitelor si conditiilor stabilite de acesta.</p>
            </div>
          </div>
          <div class="list grid x12 y12 z4">
            <div class="heading gridModule x3 y12 z4">
              <h2></h2>
            </div>
            <div class="text gridModule x6 y12 z4">
              <h4 class="dark primaryHeading">5. Cine este persoana vizata?</h4>
              <p>Acea persoana care poate fi identificata prin intermediul activitatii de prelucrare date cu caracter
                personal, in mod direct sau indirect, in special prin referire la un element de identificare (de ex.
                nume, numar&nbsp; de identificare, date de localizare, identificator online, etc.) ori la unul sau la
                mai mulţi factori specifici identitaţii sale fizice, fiziologice, genetice, psihice, economice,
                culturale sau sociale.</p>
            </div>
          </div>
          <div class="list grid x12 y12 z4">
            <div class="heading gridModule x3 y12 z4">
              <h2></h2>
            </div>
            <div class="text gridModule x6 y12 z4">
              <h4 class="dark primaryHeading">6. Care sunt principalele noutati ale GDPR?</h4>
              <p>Introducerea&nbsp; a doua noi drepturi ale persoanelor vizate:<br>
                – Dreptul de a fi uitat: persoana poate cere stergerea datelor sale daca acestea sunt prelucrate ilegal,
                fara consimtamant sau daca datele nu mai sunt necesare scopului pentru care au fost furnizate;<br>
                – Dreptul la portabilitatea datelor: utilizatorul poate alege sa transmita datele sale personale catre
                alt operator;</p>
              <p>Introducerea a doua noi concepte de protectie a datelor:<br>
                – Privacy by Design : aspectele de protectia datelor care trebuie sa fie luate in considerare la
                momentul initierii unei noi activitati de prelucrare, a unei noi aplicatii prin intermediul careia se
                prelucreaza date etc., precum si la momentul prelucrarii efective, având in vedere natura, domeniul de
                aplicare, contextul si scopurile prelucrarii, precum si riscurile cu grade diferite de probabilitate si
                gravitate pentru drepturile si libertatile persoanelor fizice pe care le prezinta prelucrarea;<br>
                – Privacy by Default : punerea in aplicare a unor masuri tehnice si organizatorice adecvate pentru a
                asigura ca, in mod implicit, sunt prelucrate numai date cu caracter personal care sunt necesare pentru
                fiecare scop specific al prelucrarii, tinând cont de volumul de date colectate, gradul de prelucrare a
                acestora, perioada lor de stocare si caracterul accesibil al acestora. In special, astfel de masuri
                asigura ca, in mod implicit, datele cu caracter personal nu pot fi accesate fara interventia persoanei,
                de un numar nelimitat de persoane.</p>
              <p>Bresele in securitatea datelor cu caracter personal, inclusiv scurgerile de date – trebuie raportate in
                72 de ore catre autoritatile responsabile cu protectia datelor</p>
              <p>Intarirea obligatiei de informare a persoanei vizate prin transparentizarea activitatii de prelucrare
                date; persoana vizata este informata de catre operator referitor la scopurile si temeiurile procesarii,
                drepturile de care dispune, perioada de stocare a datelor, potentialii destinatari ai datelor colectate,
                sursele din care operatorul a obtinut datele (daca acestea nu au fost obtinute total sau partial de la
                persoana vizata), efectele refuzului de a furniza date etc.</p>
              <p>Cresterea rigorilor aplicabile consimtamantului dat de catre persoana vizata asupra prelucrarii datelor
                sale, prin stabilirea unor cerinte precise de informare detaliata, intr-un limbaj clar si simplu,&nbsp;
                a persoanei asupra aspectelor pentru care isi da acordul in mod liber si neconditionat. Conform GDPR,
                consimtamantul poate fi retras oricand de catre persoana vizata.</p>
            </div>
          </div>
          <div class="list grid x12 y12 z4">
            <div class="heading gridModule x3 y12 z4">
              <h2></h2>
            </div>
            <div class="text gridModule x6 y12 z4">
              <h4 class="dark primaryHeading">7. Care sunt categoriile de persoane fizice?</h4>
              <p>– clienti Shardoray si reprezentanti ai acestora – persoane fizice<br>
                – reprezentanti/persoane de contact/asociati ai (i) clientilor – persoane juridice ; (ii) partenerilor
                Shardoray<br>
                –&nbsp;beneficiari reali<br>
                –&nbsp;non-clienti<br>
                –&nbsp;angajati<br>
                –&nbsp;vizitatori<br>
                –&nbsp;codebitori<br>
                –&nbsp;membrii familiilor clientilor</p>
            </div>
          </div>
          <div class="list grid x12 y12 z4">
            <div class="text gridModule x6 y12 z4">
              <h4 class="dark primaryHeading">8. Cum asigura Shardoray respectarea principiilor GDPR?</h4>
              <p>Datele sunt prelucrate in scopuri determinate, explicite si legitime si nu sunt&nbsp; prelucrate
                ulterior intr-un mod incompatibil cu aceste scopuri.</p>
              <p>Scopurile sunt indicate in documentele de informare asupra activitatii de prelucrare date cu caracter
                personal efectuata de catre Shardoray. Datele sunt prelucrate intr-o forma care permite identificarea
                persoanelor vizate strict pe perioada necesara indeplinirii scopurilor de prelucrare, luand in calcul
                durata contractuala,&nbsp; obligatiile legale&nbsp; de arhivare</p>
              <p>Datele sunt prelucrate intr-o modalitate care asigura securitatea datelor. Datele personale ale
                persoanelor vizate sunt accesate si folosite numai pentru indeplinirea obligatiilor legale, contractuale
                si a intereselor legitime ale Shardoray.</p>
              <p>Raiffeisen Bank utilizeaza doar programe software verificate in prealabil de catre departamentele
                relevante din cadrul organizatiei, nu si cele care provin din surse externe, neverificate.</p>
            </div>
          </div>
          <div class="list grid x12 y12 z4">
            <div class="text gridModule x6 y12 z4">
              <h4 class="dark primaryHeading">9. Care sunt Drepturile persoanelor vizate privind protectia datelor lor?
              </h4>
              <p>Dreptul de informare = dreptul de a primi continutul minim de informatii prevazut de lege privind
                prelucrarea datelor cu caracter personal<br>
                Dreptul de acces = dreptul de a obtine confirmarea din partea Shardoray cu privire la prelucrarea
                datelor cu caracter personal, precum si detalii privind activitatile de prelucrare<br>
                Dreptul la portabilitatea datelor = dreptul de a primi datele cu caracter personal furnizate in mod
                direct intr-o modalitate structurata, folosita in mod obisnuit si intr-un format usor de citit, precum
                si dreptul ca aceste date sa fie transmise de catre Shardoray catre alt operator de date, in masura in
                care sunt indeplinite conditiile prevazute de lege<br>
                Dreptul de a nu fi supus unei decizii individuale automate = dreptul de a nu fi subiectul unei decizii
                luate numai pe baza unor activitati de prelucrare automate<br>
                Dreptul de rectificare = dreptul de a obtine rectificarea de catre Shardoray a datelor inexacte, precum
                si completarea datelor incomplete<br>
                Dreptul de opozitie = dreptul de a se opune activitatilor de prelucrare, in conditiile prevazute de
                lege; pentru marketing direct, opozitia poate fi exercitata in orice moment, prin transmiterea unei
                solicitari catre Shardoray<br>
                Dreptul la restrictionarea folosirii datelor = dreptul de a se obtine restrictionarea activitatilor de
                prelucrare, in conditiile prevazute de lege<br>
                Dreptul de stergere a datelor = dreptul de a obtine stergerea datelor daca sunt indeplinite anumite
                conditii prevazute de lege</p>
            </div>
          </div>
          <div class="list grid x12 y12 z4">
            <div class="heading gridModule x3 y12 z4">
              <h2></h2>
            </div>
            <div class="text gridModule x6 y12 z4">
              <h4 class="dark primaryHeading">10. Cum pot solicita exercitarea drepturilor?</h4>
              <p>Toate aceste drepturi pot fi exercitate prin depunerea unei cereri scrise, semnata si datata in orice
                agentie Shardoray, prin e-mail transmis la adresa office@Shardoray.ro.<br>
                Sesizarile sau intrebarile legate de prelucrarea datelor personale pot fi adresate Responsabilului
                pentru protectia datelor la adresa office@Shardoray.ro.</p>
            </div>
          </div>
          <div class="list grid x12 y12 z4">
            <div class="heading gridModule x3 y12 z4">
              <h2></h2>
            </div>
            <div class="text gridModule x6 y12 z4">
              <h4 class="dark primaryHeading">11. Care sunt autoritatile responsabile cu protectia datelor?</h4>
              <p>Plangerile referitoare la prelucrarea datelor personale pot fi adresate Autoritatii Nationale de
                Supraveghere a Prelucrarii Datelor cu Caracter Personal&nbsp;<a
                  href="http://dataprotection.ro/">http://dataprotection.ro/</a></p>
            </div>
          </div>
        </div>
      </div><!-- $classes -->

    </div><!-- .jas-row -->
  </div>
</div>
<div class="mb-6"></div>