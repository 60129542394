import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SelectedProductsService {

  _apiUrl = environment.apiUrl;

  constructor(
    private _httpClient: HttpClient,
  ) { }

  /**
   * Get selected products
   *
   * @returns {Promise<any>}
   */

  public getSelectedProducts(): Promise<any> {

    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'selectedProducts')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
}
