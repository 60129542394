import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryMethodComponent } from './delivery-method.component';



@NgModule({
  declarations: [
    DeliveryMethodComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DeliveryMethodComponent
  ]
})
export class DeliveryMethodModule { }
