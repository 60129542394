import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataLayerService } from '../services/dataLayer.service';
declare let dataLayer: any[];
@Injectable()
export class DataLayerInterceptor implements HttpInterceptor {
  constructor(@Inject(DataLayerService) private dataService: DataLayerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Log the request to the data layer
    const requestData = {
      event: '__custom_request',
      method: req.method,
      url: req.url,
      timestamp: new Date().toISOString(),
    };

    console.log('Interceptor is working!', req.method, req.url);

    //this.dataService.logToDataLayer(requestData);

    // Continue with the original request
    return next.handle(req);
  }
}