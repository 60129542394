<div class="promo-section mb-5">
    <div class="parallax-background" style="background-image: url('/assets/images/banners/category-autum-bg.png')">
        <!-- <img src="/assets/images/banners/category-bg.png" alt="" ngxParallaxScroll
        [parallaxSpeed]='1'
        [parallaxSmoothness]='1'
        [parallaxDirection]='"straight"'
        [parallaxTimingFunction]='"linear"'
        [parallaxThrottleTime]='0'> -->
    </div>

    <!-- <ngx-parallax-scroll class="parallax-background" [config]="ngParallaxConf">
        <img src="/assets/images/banners/category-bg.png" class="parallax-img" alt="parallax-img">
      </ngx-parallax-scroll> -->
  
  <div class="promo-content">
    <h2 class="m-b-1">Produse de sezon</h2>
    <h3>Lichidare de stoc</h3>
    <hr class="divider-short-thick">
    <a routerLink="/produse/produse-de-sezon" class="btn btn-light ls-n-25">Cumpara acum <i class="fas fa-long-arrow-alt-right ml-2"></i></a>
  </div>
</div><!-- End .promo-section -->
