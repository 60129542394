import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  _apiUrl = environment.apiUrl;

  constructor(private _httpClient: HttpClient) { }

  addAlert(first_row: string, second_row: string, color: string, options): Observable<any> {
    return this._httpClient.post(this._apiUrl + `alerts/addAlert`, { 'first_row': first_row, 'second_row': second_row, 'color': color }, options);
  }

  getAlerts(): Observable<any> {
    return this._httpClient.get(this._apiUrl + `alerts/getAlerts`);
  }
}
