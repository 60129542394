<header class="header">

  <div class="header-wrapper" [ngClass]="{'header-transparent': _Router.url == '/'}">
    <div class="halloween" *ngIf="halloween">
      <div class="halloween__inner">
        <div class="net-top-left">
          <img src="/assets/images/banners/top-left.svg" alt="">
        </div>
        <div class="net-top-right">
          <img src="/assets/images/banners/top-right.svg" alt="">
        </div>
      </div>
    </div>
    <div class="header-middle sticky-header"
      [ngClass]="{'fixed': sticky === 'fixed', 'scroll-down': scroll === 'scrollDown', 'scroll-up': scroll === 'scrollUp'}">
      <div class="sizes-alert" [ngStyle]="{ 'background-color': alert.backgroundColor }">
        <p>{{alert.row_one}}</p>
        <p> {{alert.row_two}}</p>
      </div>
      <div class="container-header">
        <div class="container-header__inner">
          <div class="header-left">
            <a (click)="toggleMenu()" class="popup-menu-toggler" [class.open]="open">
              <div class="icon"></div>
              <small>Meniu</small>
            </a>

            <div class="popup-menu" [class.open]="open">
              <div class="popup-menu__header">
                <button title="Close (Esc)" type="button" (click)="toggleMenu()"
                  class="mfp-close popup-menu-close"></button>
              </div>

              <nav>
                <ul class="popup-menu-ul" *ngIf="!isAdmin">
                  <ng-container *ngFor="let category of categories">
                    <li (click)="category.subcategories.length === 0 ? navigateToCategory(category.categoryAlias) : ''"
                      [ngClass]="{ 'open': activeCategory === category.category_name, 'black-friday': category.categoryAlias === 'reduceri'}">
                      <a (click)="toggleCategory(category.category_name)"
                        class="sf-with-ul"><span>{{category.category_name}}</span></a>
                      <ul [ngClass]="{ 'open': activeCategory === category.category_name}"
                        *ngIf="category.subcategories && category.subcategories.length">
                        <li *ngFor="let subcategory of category.subcategories">
                          <a
                            routerLink="/produse/{{category.categoryAlias}}/{{subcategory.alias}}">{{subcategory.subcategory_name}}</a>
                        </li>
                      </ul>
                    </li>
                  </ng-container>

                  <!-- <li>
                    <a routerLink="/cos-cumparaturi" class="sf-with-ul">Cos cumparaturi</a>
                  </li> -->
                  <!-- <li *ngIf="isAuthentificated">
                    <a routerLink="/contul-meu" class="sf-with-ul">Contul meu</a>
                  </li>
                  <li *ngIf="!isAuthentificated">
                    <a routerLink="/autentificare" class="sf-with-ul">Autentificare</a>
                  </li> -->
                </ul>
                <div class="info-text" *ngIf="!isAdmin">
                  <p>Reducere -20% la al treilea produs adaugat in cos!</p>
                  <p>Livrare gratuita pentru comenzi mai mari de 249 Lei!</p>
                </div>
                <div class="login-signup">
                  <a routerLink="/autentificare" class="sf-with-ul">Autentificare</a>
                  <span>sau</span>
                  <a routerLink="/autentificare" class="sf-with-ul">Creeaza cont</a>
                </div>
                <ul class="popup-menu-ul" *ngIf="isAdmin">
                  <li><a routerLink="/admin/dashboard">Dashboard</a></li>
                  <li><a routerLink="/admin/orders">Comenzi</a></li>
                  <li><a routerLink="/admin/returns">Retururi</a></li>
                  <li>
                    <a routerLink="/admin/products">Produse</a>
                  </li>
                  <li>
                    <a routerLink="/admin/colors">Culori</a>
                  </li>
                  <li>
                    <a routerLink="/admin/categories">Categorii</a>
                  </li>
                  <li>
                    <a routerLink="/admin/subcategories">Subcategorii</a>
                  </li>
                  <li>
                    <a routerLink="/admin/sizes">Marimi</a>
                  </li>
                  <li>
                    <a (click)="logout()">Delogare</a>
                  </li>

                </ul>
              </nav>
            </div><!-- End .popup-menu -->
            <a routerLink="/" class="logo">
              <img height="50" src="/assets/images/logo.png" alt="Shardoray Logo">
            </a>
          </div><!-- End .header-left -->
          <div class="mega-menu">
            <ul>
              <ng-container *ngFor="let category of categories">
                <li>
                  <a (click)="navigateToCategory(category.categoryAlias)" class="sf-with-ul">{{category.category_name}}</a>
                  <ul [ngClass]="{ 'open': activeCategory === category.category_name}"
                    *ngIf="category.subcategories && category.subcategories.length">
                    <li *ngFor="let subcategory of category.subcategories">
                      <a
                        routerLink="/produse/{{category.categoryAlias}}/{{subcategory.alias}}">{{subcategory.subcategory_name}}</a>
                    </li>
                  </ul>
                </li>
              </ng-container>
            </ul>
          </div>


          <div class="header-right">


            <div [class.show]="search" class="header-search header-search-popup header-search-category  d-sm-block">
              <a (click)="toggleSearch()" class="search-toggle" role="button"><i class="icon-magnifier"></i></a>

              <div class="header-search-wrapper">
                <input type="search" class="form-control" [(ngModel)]="productSearch" name="q" id="q"
                  placeholder="Cauta..." required="">
                <!-- <div class="select-custom">
                    <select id="cat" name="cat">
                      <option value="">Toate</option>
                      <option value="4">Imbracaminte</option>
                      <option value="12">- Imbracaminte femei</option>
                      <option value="13">- Imbracaminte barbati</option>
                      <option value="66">- Imbracaminte copii</option>
  
                    </select>
                  </div> -->
                <button class="btn bg-dark icon-search-3" (click)="onSearchChange()"></button>
              </div>
              <!-- <ul class="results" *ngIf="results">
                  <li *ngFor="let product of products | filter:productSearch">
                    <a routerLink="/produs/{{product?.alias}}/{{product?.id}}">
                      <figure>
                        <a  routerLink="/produs/{{product?.alias}}/{{product?.id}}">
                          
                            <img src="{{uploadedImgUrl}}{{product.selectedImageObj}}" alt="image">
                          
                        </a>
                      </figure>
  
                      <strong>{{product.name}}</strong>
                    </a>
                  </li>
                </ul> -->

            </div>

            <a *ngIf="isAuthentificated" routerLink="/contul-meu" class="header-icon login-link">
              <i class="icon-user-2"></i>
              <small>Cont</small>
            </a>
            <a *ngIf="!isAuthentificated" routerLink="/autentificare" class="header-icon login-link">
              <i class="icon-user-2"></i>
              <small>Cont</small>
            </a>
            <div class="dropdown cart-dropdown favorites" *ngIf="favorites.length > 0">
              <a class="header-icon heart-container dropdown-toggle" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" data-display="static">
                <i class="icon-wishlist-2"></i>
                <span class="cart-count badge-circle">{{favorites.length}}</span>
                <small>Favorite</small>
              </a>
              <div class="dropdown-menu">
                <div class="dropdownmenu-wrapper">
                  <div class="dropdown-cart-products">
                    <div class="product" *ngFor="let product of favorites">
                      <div class="product-details">
                        <h4 class="product-title">
                          <a routerLink="/produs/{{product?.alias}}/{{product?.id}}">{{product?.name}}</a>
                        </h4>
                        <span *ngIf="product?.selectedSize.old_price > 0"
                          class="old-price">{{product?.selectedSize.old_price}}
                          Lei</span>
                        <span class="cart-product-info">
                          {{product?.selectedSize.current_price}} Lei
                        </span>
                        <p>
                          <a (click)="addToCart(product); toggleFavorites(product)">Muta in cos</a>
                        </p>

                      </div><!-- End .product-details -->

                      <figure class="product-image-container">
                        <a routerLink="/produs/{{product?.alias}}/{{product?.id}}">
                          <img src="{{uploadedImgUrl}}{{product?.selectedImageObj}}" alt="image">
                        </a>
                        <a (click)="toggleFavorites(product)" class="btn-remove icon-cancel" title="Remove Product"></a>
                      </figure>
                    </div><!-- End .product -->
                  </div><!-- End .cart-product -->
                </div><!-- End .dropdownmenu-wrapper -->
              </div>
            </div>

            <div class="dropdown cart-dropdown">
              <!-- On Desktop: Show on hover -->
              <!-- <div class="dropdown-toggle" *ngIf="!isMobile" (mouseenter)="showCart = true; $event.preventDefault()" (mouseleave)="showCart = false">
                <i class="icon-shopping-cart"></i>
                <span class="cart-count badge-circle">{{cartTotal$.value}}</span>
                <small>Cos</small>
              </div> -->

              <!-- On Mobile: Show on click -->
              <!-- <div class="dropdown-toggle" *ngIf="isMobile" (click)="showCart = !showCart">
                <i class="icon-shopping-cart"></i>
                <span class="cart-count badge-circle">{{cartTotal$.value}}</span>
                <small>Cos</small>
              </div> -->
              <a routerLink="/cos-cumparaturi" class="dropdown-toggle" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" data-display="static">
                <i class="icon-shopping-cart"></i>
                <span class="cart-count badge-circle">{{cartTotal$.value}}</span>
                <small>Cos</small>
              </a>

              <div class="dropdown-menu">
                <div class="dropdownmenu-wrapper" *ngIf="cartTotal$.value > 0">
                  <div class="dropdown-cart-header">
                    <span>{{cartTotal$.value}} {{cartTotal$.value === 1 ? 'produs' : 'produse'}}</span>

                    <a routerLink="/cos-cumparaturi" class="float-right">Vezi cos</a>
                  </div><!-- End .dropdown-cart-header -->

                  <div class="dropdown-cart-products">

                    <div class="product" *ngFor="let product of items$ | async">
                      <div class="product-details">
                        <h4 class="product-title">
                          <a routerLink="/produs/{{product?.alias}}/{{product?.id}}">{{product?.name}}</a>
                          <span class="type-image" *ngIf="product?.selectedColor.color_list.length == 1"
                            [style.background-color]="product?.selectedColor.color_list[0]"></span>
                          <span class="type-image" *ngIf="product?.selectedColor.color_list.length == 0"
                            style="background-color: #fff;">{{product.selectedSize.size_name}}</span>
                          <div class="two-colors" *ngIf="product?.selectedColor.color_list.length > 1">
                            <span [style.background-color]="product?.selectedColor.color_list[0]"></span>
                            <span [style.background-color]="product?.selectedColor.color_list[1]"></span>
                          </div>
                          <span class="size-text">
                            {{product.selectedSize.size_name}}
                          </span>

                        </h4>

                        <span class="cart-product-info">
                          <span class="cart-product-qty">{{product?.num}}</span>
                          x {{product?.selectedPrice}} <span
                            *ngIf="product?.fullPrice != product?.selectedPrice">(<del>{{product?.fullPrice}}
                              Lei</del>)</span> Lei
                        </span>
                      </div><!-- End .product-details -->

                      <figure class="product-image-container">
                        <a routerLink="/produs/{{product?.alias}}/{{product?.id}}" class="product-image">

                          <img src="{{uploadedImgUrl}}{{product?.selectedImageObj}}" alt="image">
                        </a>
                        <a (click)="removeCart(product)" class="btn-remove icon-cancel" title="Remove Product"></a>
                        <span class="discount-applied" *ngIf="product?.hasDiscount"> <small>-20%
                          </small></span>
                      </figure>
                    </div><!-- End .product -->



                  </div><!-- End .cart-product -->
                  <div class="info">
                    <span class="info-danger" *ngIf="(!discountCart$ | async) && (!firstOrderDiscountHasApplied$ | async) || (!individualDiscountHasApplied$ | async)">Reducere -20% la al treilea produs adaugat in
                      cos!</span>
                    <span class="info-danger" *ngIf="totalPrice$.value < 249">Livrare gratuita daca valoarea cosului este mai mare de 249 Lei!</span>

                      <span class="info-success" *ngIf="(discountCart$ | async) && (!firstOrderDiscountHasApplied$ | async) || (!individualDiscountHasApplied$ | async)">
                       Ai primit o reducere de -20% la al treilea produs din cos
                      </span>
  
                      <span class="info-success" *ngIf="firstOrderDiscountHasApplied$ | async">Ai primit o reducere de -10% la prima comanda</span>
                      <span class="info-success" *ngIf="individualDiscountHasApplied$ | async">
                        Ai primit o reducere de {{ individualDiscountHasApplied$.value
                          ? "-" +
                            individualDiscountValue$.value.coupon.value +
                            " " +
                            (individualDiscountValue$.value.coupon
                              .is_fixed_discount === 1
                              ? "Lei"
                              : "%")
                          : "" }}
                      </span>
                  </div>

                  <div class="dropdown-cart-total">
                    
                    <span>Total:</span>


                    <span class="cart-total-price float-right">{{totalPrice$ | async}} Lei</span>
                    <span class="cart-total-price float-right"
                      *ngIf="fullPrice$ && fullPrice$ > totalPrice$.value"><del>{{fullPrice$}}
                        Lei</del></span>


                  </div><!-- End .dropdown-cart-total -->

                  <div class="dropdown-cart-action">
                    <a routerLink="/finalizare-comanda" class="btn btn-primary btn-block">Finalizeaza comanda</a>
                  </div><!-- End .dropdown-cart-total -->
                </div><!-- End .dropdownmenu-wrapper -->
                <div class="dropdownmenu-wrapper" *ngIf="cartTotal$.value === 0">
                  <p class="empty">Niciun produs in cos</p>
                </div>

              </div><!-- End .dropdown-menu -->
            </div><!-- End .dropdown -->
          </div><!-- End .header-right -->
        </div>
        <form (ngSubmit)="onSearchChange()">
          <input type="search" class="form-control" [(ngModel)]="productSearch" name="q" id="q"
            placeholder="Cauta produs" required="">
          <i class="icon-magnifier"></i>
        </form>

      </div><!-- End .container -->
      <!-- <img class="flags" src="/assets/images/icons/flags.svg" alt=""> -->
      <div class="black-friday-counter" [class.isActive]="isBlackFriday">

        <div class="times">
          <h1>REDUCERILE BLACK FRIDAY SE VOR SFARSI:</h1>
          <div class="item">
            <p #days></p>
            <p>Zile</p>
          </div>
          <div class="item">
            <p #hours></p>
            <p>Ore</p>
          </div>
          <div class="item">
            <p #minutes></p>
            <p>Min.</p>
          </div>
          <div class="item">
            <p #seconds></p>
            <p>Sec.</p>
          </div>
        </div>
      </div>
    </div><!-- End .header-middle -->
  </div><!-- End .header-transparent -->
</header><!-- End .header -->