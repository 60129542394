import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { debounceTime, take, takeUntil, tap } from 'rxjs/operators';
import { Product } from 'src/app/models';
import { ReturnFormService } from 'src/app/pages/return-form/return-form.service';
import { ProductsService } from 'src/app/services';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-dropdown-products',
  templateUrl: './dropdown-products.component.html',
  styleUrls: ['./dropdown-products.component.scss']
})
export class DropdownProductsComponent implements OnInit {

  @Output() selectProduct = new EventEmitter<string>();

  public selectedValue: any;
  public searchValue: any;
  public filteredList: any = [];
  products: any;
  toggleState$ = new BehaviorSubject<boolean>(false);
  uploadedImgUrl: string = environment.uploadImagesUrl;
  status$ = new BehaviorSubject<string>('Incarca...');

  products$ = this._ReturnFormService.setProducts$

  constructor(
    private _ProductsService: ProductsService,
    private _ReturnFormService: ReturnFormService
  ) { }

  ngOnInit(): void {
    this._ReturnFormService.setProducts$.pipe(
      take(1),
      tap(products => {
        this.products = products;
        this.filteredList = products;
        this.status$.next('Niciun rezultat!')
      })
    ).subscribe()
    // this._ProductsService.getProductsAll().then(products => {

    // })
  }

  filterDropdown(e) {
    console.log(e)
    if (e.length > 3) {
      timer(400).subscribe(() =>
        this._ProductsService.fetchSearchProducts(e)
          .pipe(
            debounceTime(400),
            take(1))
          .subscribe(products => {
            this.products = this._ProductsService.cleanObjProductAdmin(products);
            if (this.products) {
              window.scrollTo(window.scrollX, window.scrollY + 1);
              this.filteredList = this.products
              // let searchString = e.toLowerCase();
              // if (!searchString) {
              //   this.filteredList = this.products.slice();
              //   return;
              // } else {
              //   this.filteredList = this.products.filter(
              //     product => product.name.toLowerCase().indexOf(searchString) > -1
              //   );
              // }
              // window.scrollTo(window.scrollX, window.scrollY - 1);
              // console.log("this.filteredList indropdown -------> ", this.filteredList);
            }
          })
      )
    }
  }

  selectValue(product) {
    this.selectedValue = product;
    this.selectProduct.next(product);
    this.toggleState$.next(false);
  }



  callParent(): void {

  }

  toggle() {
    this.toggleState$.next(true);
  }

}
