import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, take, takeUntil } from 'rxjs';
import { AuthAPIService } from 'src/app/services';

@Component({
  selector: 'app-checkout-login-form',
  templateUrl: './checkout-login-form.component.html',
  styleUrls: ['./checkout-login-form.component.scss']
})
export class CheckoutLoginFormComponent implements OnInit, OnDestroy {
  isAuthentificated: boolean;
  toggleAuth: boolean;
  login: any = {};

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    private _AuthAPIService: AuthAPIService,
    private toaster: ToastrService,
  ) {

  }

  ngOnInit(): void {
    this._AuthAPIService.user.pipe(takeUntil(this._unsubscribeAll)).subscribe((user) => {
      this.isAuthentificated = !!user;
    });
  }

  toggleAuthFn() {
    this.toggleAuth = !this.toggleAuth;
    console.log(this.toggleAuth)
  }

  auth(form: NgForm) {

    this._AuthAPIService.login(this.login.email, this.login.password).pipe(take(1)).subscribe(
      (data) => {
        if (data['success']) {
          this.toaster.success('', `${data['message']}`, {
            timeOut: 8000,
            positionClass: 'toast-bottom-right',
          });
        } else {
          this.toaster.warning('', `${data['message']}`, {
            timeOut: 8000,
            positionClass: 'toast-bottom-right',
          });
          form.reset();
        }

        this.isAuthentificated = !!data;
        this.toggleAuth = false;
      },
      (error) => {}
    );
  }

  signInWithFB(): void {
    // this.SocialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(userData => {
    //   this.apiConnection(userData);
    // });;
  }

  signOut(): void {
    //this.SocialAuthService.signOut();
  }

  apiConnection(data) {
    // this.userPostData.email = data.email;
    // this.userPostData.name = data.name;
    // this.userPostData.provider = data.provider;
    // this.userPostData.provider_id = data.id;
    // this.userPostData.provider_pic = data.photoUrl;
    // this.userPostData.token = data.authToken;

    // this.authAPIService.postData(this.userPostData, 'signup').then(
    //   (result) => {
    //     this.responseData = result;
    //     if (this.responseData.userData) {
    //       this.userService.storeData(this.responseData.userData);
    //     }
    //   },
    //   (err) => {}
    // );
  }

  ngOnDestroy() {
    this._unsubscribeAll.next('');
    this._unsubscribeAll.complete();
  }
}
