import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Product } from 'src/app/models';
import { CartService, ProductService, UtilityService } from 'src/app/services';
import { environment } from 'src/environments/environment.prod';
import { TestProductsService } from './test-products.service';

@Component({
  selector: 'app-test-products',
  templateUrl: './test-products.component.html',
  styleUrls: ['./test-products.component.scss']
})
export class TestProductsComponent implements OnInit {

  categorySlug: string;
  uploadedImgUrl = environment.uploadImagesUrl;

  colors: any[] = [];
  products$ = new BehaviorSubject<Product[]>([]);
  categories$ = new BehaviorSubject<any[]>([]);
  colors$ = new BehaviorSubject<any[]>([]);
  sizes$ = new BehaviorSubject<any[]>([]);

  widgetColors: boolean = true;
  widgetCategories: boolean = true;
  widgetPrices: boolean = true;
  widgetSizes: boolean = true;
  minValue: number;
  maxValue: number;
  selectedValue: number = 0;

  filter: any = {};
  filters = {
    filterCategoryAndSubcategory: (item: any) => { },
    filterColor: (item: any) => { },
    filterSize: (item: any) => { },
    filterPrice: (item: any) => { }
  }
  appliedFilter: any[] = [];
  selectedFilters: any[] = [];
  isFilterActive$ = new BehaviorSubject<any>({});
  selectedCategory: number = 0;

  checked: any;

  page: number = 1;
  ipp: number = 12;
  count = 0;

  statusMobile: boolean;

  constructor(
    private _TestProductsService: TestProductsService,
    private _ProductService: ProductService,
    private _CartService: CartService,
    public _Router: Router,
    public _Util: UtilityService,
    private _ActivatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit(): void {
    console.log('asdas')
    this._ActivatedRoute.paramMap.subscribe(params => {
      this.categorySlug = params.get('categorySlug')
    })

    combineLatest([
      this._TestProductsService.getProducts$(),
      this._TestProductsService.getCategories$(),
      this._TestProductsService.getColors$(),
      this._TestProductsService.getSizes$(),
    ]
    ).pipe(
      take(1),
      map(([
        products$,
        categories$,
        colors$,
        sizes$,
      ]) => ({
        products: products$,
        categories: categories$,
        colors: colors$,
        sizes: sizes$,
      })),
      tap((data: any) => {
        console.log(data)
        this.products$.next(data.products);
        this.categories$.next(data.categories);
        this.colors$.next(data.colors);
        this.sizes$.next(data.sizes);

        this.checked = new Array(data.categories.length);
        this.checked.fill(false);
      })
    ).subscribe(console.log);

  }

  toggleWidgetSizes() {
    this.widgetSizes = !this.widgetSizes;
  }
  toggleWidgetPrices() {
    this.widgetPrices = !this.widgetPrices;
  }
  toggleWidgetCategories() {
    this.widgetCategories = !this.widgetCategories;
  }
  toggleWidgetColors() {
    this.widgetColors = !this.widgetColors;
  }

  handlePageChange(newPage: number): void {

    this.page = newPage;

    let queryParams: Params = { page: newPage };
    queryParams = { ...this._ActivatedRoute.snapshot.queryParams };

    delete queryParams.visited;
    queryParams.page = newPage;
    this.navigateByParams(queryParams)
    if (this.appliedFilter.length === 0) {
      this._TestProductsService.fetchProductsTest().subscribe();
    }

    const scrollToBody = document.querySelector('.category-banner');
    scrollToBody.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  navigateByParams(queryParams) {
    this._Router.navigate(
      [],
      {
        //relativeTo: this._ActivatedRoute,
        queryParams: queryParams,
        // queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }


  setAnchor(id) {
    const queryParams: Params = { visited: id };

    this._Router.navigate(
      [],
      {
        relativeTo: this._ActivatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });


  }
  setIpp(ipp) {
    const queryParams: Params = { produse: ipp };

    this._Router.navigate(
      [],
      {
        relativeTo: this._ActivatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });


  }

  navigateToProduct(alias, id, colorID, meta) {
    this._ProductService.productID$.next(id);
    this._ProductService.productID$.pipe(take(1)).subscribe(() => {
      this._Router.navigate([`/produs/${alias}/${id}/${colorID}`], {
        queryParams: { title: alias, metadata: meta },
      });
    })
  }

  openMobileMenu() {
    this.statusMobile = !this.statusMobile;
    this._Util.toggleMobileMenu(this.statusMobile)
  }


  // toggleFilter(check: boolean, filter: string, categoryID, subcategoryID) {
  //   if (!check) {
  //     this.removeFilter(filter)
  //   } else {
  //     this.checked.fill(false);
  //     this.checked[subcategoryID] = true;
  //     this.filter.category = categoryID;
  //     this.filter.subcategory = subcategoryID;
  //     this.filterProducts('filterCategoryAndSubcategory');
  //   }
  // }

  // removeFilter(filterSelect: string) {

  //   let idx = this.appliedFilter.indexOf(filterSelect, 0);
  //   this.selectedFilters.splice(idx, 1);
  //   this.appliedFilter = this.appliedFilter.filter(item => item !== filterSelect);

  //   let result = this._ProductsService.productsSearch$.getValue().slice().filter(item => this.selectedFilters.every(f => f(item)));


  //   if (result) {
  //     this.getColors(result)
  //     this.getSizes(result)
  //     this.getPrice(result)
  //   }

  //   this.count = result.length;
  //   this.products$.next(result);
  //   const scrollToBody = document.querySelector('.category-banner');
  //   scrollToBody.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  // }



  filterProducts(filterSelect: string) {

    this.filters.filterCategoryAndSubcategory = (item: any) => item.categories.some((category: any) => category.subcategories.some(subcategory => subcategory.id === this.filter.subcategory && subcategory.CategoryID === this.filter.category))
    this.filters.filterColor = (item: any) => item.colors.some((color: any) => color.ColorID == this.filter.ColorID);
    this.filters.filterSize = (item: any) => item.colors.some((color: any) => color.sizes.some(size => size.SizeID == this.filter.SizeID))
    this.filters.filterPrice = (item: any) => item.colors.some((color: any) => color.sizes.some(size => size.current_price > this.minValue && size.current_price < this.maxValue))

    const found = this.appliedFilter.some(el => el == filterSelect);

  
    this.filter.maxValue =  this.maxValue;
    this.filter.minValue =  this.minValue;
    this.selectedValue = this.maxValue


    if (!found) {

      this.appliedFilter.push(filterSelect)
      this.selectedFilters.push(this.filters[filterSelect]);
    } else {

      if (filterSelect != 'filterPrice') {
        let idx = this.appliedFilter.indexOf(filterSelect, 0);
        this.selectedFilters.splice(idx, 1);
        this.appliedFilter = this.appliedFilter.filter(item => item !== filterSelect)
      }
    }

    let result = this.products$.getValue().slice().filter(item => this.selectedFilters.every(f => f(item)));

    this.count = result.length;
    this.page = 1;
    this.products$.next(result);

    this.isFilterActive$.next(this.filter)
  

    const scrollToBody = document.querySelector('.category-banner');
    scrollToBody.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    this.selectedCategory = this.filter.subcategory;
  }

  addToCart(product) {
    this._CartService.addToCart(product, 1);
  }

}
