import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ScriptLoaderService } from '../helpers/script-loader.service';
declare let dataLayer: any[];

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  mktrEvents = [
    '__sm__view_category',
    '__sm__view_product',
    '__sm__view_homepage',
    '__sm__add_to_cart',
    '__sm__remove_from_cart',
    '__sm__add_to_wishlist',
    '__sm__remove_from_wishlist',
    '__sm__set_email',
    '__sm__set_phone',
    '__sm__search',
    '__sm__initiate_checkout',
    '__sm__order',
    'purchase',
    'add_to_cart',
    'remove_from_cart',
    'view_item',
  ];

  scriptLoaded: boolean = false;

  constructor(
    private router: Router,
    private scriptLoader: ScriptLoaderService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Reset the Google Tag Manager data layer on route change
        // this.resetGoogleTagManagerDataLayer();
        this.removeEventsOnNavigate(this.mktrEvents);
      }
    });
  }

  removeEventsOnNavigate(eventNames: string[]) {
    dataLayer = dataLayer.filter((event) => !eventNames.includes(event.event));
    console.log(dataLayer);
  }

  async reinitializeScript() {
    const script = document.querySelector(
      'script[src*="https://t.themarketer.com/t/j/"]'
    );
    const script2 = document.querySelectorAll('#mkt-init-popup-trigger-rules');

    if (script) {
      script.parentNode.removeChild(script);
    }
    if (script2) {
      script2.forEach((script) => {
        script.parentNode.removeChild(script);
      });
    }

    const scriptUrl = 'https://t.themarketer.com/t/j/KILY212D';

    try {
      this.removeEventsOnNavigate(this.mktrEvents);
      // Load the script
      await this.scriptLoader.loadScript(scriptUrl);
    } catch (error) {
      console.error('Error loading script:', error);
    }
  }

  reinitializeGTMScript() {
    const script3 = document.querySelector('#tagManager');
    const script4 = document.querySelector('#noScript');

    try {
      this.scriptLoader.initializeGTM('GTM-W8ZVGN2');
      this.scriptLoader.initializeGTMNoScript('GTM-W8ZVGN2');
    } catch (error) {
      console.error('Error loading script:', error);
    }
  }

  resetGoogleTagManagerDataLayer() {
    dataLayer = [];

    dataLayer.push(() => {
      (window as any).google_tag_manager['GTM-W8ZVGN2'].dataLayer.reset();
    });
  }

  isEventInDataLayer(eventName: string, eventValue: string) {
    return dataLayer.some(
      (event) => event.event === eventName && event.product_id === eventValue
    );
  }

  pushEventIfNotExists(eventName: string, eventData: any) {
    if (!this.isEventInDataLayer(eventName, eventData.product_id)) {
      dataLayer.push({
        event: eventName,
        ...eventData,
      });
    }
  }

  logToDataLayer(data: any): void {
    dataLayer = dataLayer || [];
    dataLayer.push(data);
  }
}
