<nav aria-label="breadcrumb" class="breadcrumb-nav">
  <div class="container">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Acasa</a></li>
      <li class="breadcrumb-item active" aria-current="page">
        Cos cumparaturi
      </li>
    </ol>
  </div>
  <!-- End .container -->
</nav>
<div class="mt-6"></div>
<!-- margin -->
<div class="container" *ngIf="(items$ | async).length === 0">
  <div class="row clearfix">
    <div class="col-lg-12 col-md-12 col-sm-12 cart-shiping-update-wrapper">
      <div class="cart-shiping-update">
        <p>Nu ai niciun produs in cos!</p>
        <a routerLink="/produse">Inapoi la cumparaturi</a>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="(items$ | async).length > 0">
  <div class="row">
    <div class="col-lg-8">
      <div class="cart-table-container">
        <table class="table table-cart">
          <thead>
            <tr>
              <th class="product-col">Produs</th>
              <th class="price-col">Pret</th>
              <th class="qty-col">Cantitate</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let product of items$ | async">
              <tr class="product-row">
                <td class="product-col">
                  <figure class="product-image-container">
                    <a
                      routerLink="/produs/{{ product?.alias }}/{{
                        product?.id
                      }}"
                      class="product-image"
                    >
                      <img
                        src="{{ uploadedImgUrl }}{{
                          product?.selectedImageObj
                        }}"
                        alt="image"
                      />
                    </a>
                    <a
                      (click)="removeCart(product)"
                      class="btn-remove icon-cancel"
                      title="Remove Product"
                    ></a>
                    <span class="discount-applied" *ngIf="product?.hasDiscount"
                      >-20%</span
                    >
                  </figure>
                  <h4 class="product-title">
                    <div class="selected-container">
                      <span
                        class="type-image"
                        *ngIf="product?.selectedColor.color_list.length == 1"
                        [style.background-color]="
                          product?.selectedColor.color_list[0]
                        "
                      ></span>
                      <span
                        class="type-image"
                        *ngIf="product?.selectedColor.color_list.length == 0"
                        style="background-color: #fff"
                        >{{ product.selectedSize.size_name }}</span
                      >
                      <div
                        class="two-colors"
                        *ngIf="product?.selectedColor.color_list.length > 1"
                      >
                        <span
                          [style.background-color]="
                            product?.selectedColor.color_list[0]
                          "
                        ></span>
                        <span
                          [style.background-color]="
                            product?.selectedColor.color_list[1]
                          "
                        ></span>
                      </div>
                      <span class="size-text">
                        {{ product.selectedSize.size_name }}
                      </span>
                    </div>

                    <!-- <span class="type-image"
                      [style.background-color]="product.selectedColor.color_list[0]"></span> -->
                  </h4>
                </td>
                <td>
                  <del *ngIf="product.hasDiscount"
                    >{{ product.fullPrice }} Lei</del
                  >
                  {{ product.selectedPrice }} Lei
                </td>
                <td>
                  {{ product?.num }}
                  <!-- <div class="cart-plus-minus" *ngIf="!product?.hasDiscount">
                    <input
                      class="cart-plus-minus-box"
                      [value]="product?.num"
                      type="text"
                      name="quantity"
                      (blur)="addToCart(product, $event)"
                    />
                    <div class="cart-plus-minus__actions">
                      <button
                        (click)="addOneToCart(product)"
                        class="inc qtybutton"
                      ></button>
                      <button
                        (click)="removeOneFromCart(product)"
                        class="desc qtybutton"
                      ></button>
                    </div>
                  </div> -->
                </td>
                <td>
                  {{ product.num * product.selectedPrice | number : "1.2-2" }}
                  Lei
                </td>
              </tr>
              <tr class="product-action-row">
                <td colspan="4" class="clearfix">
                  <div class="float-left">
                    <a
                    class="add-wishlist"
                    title="Add to Wishlist"
                    *ngIf="!product?.selectedColor?.addedToFavorites" 
                    (click)="toggleFavorites(product)"
                    >Adauga la favorite</a
                  >
                  <a
                    class="add-wishlist"
                    [class.favorite]="product?.selectedColor?.addedToFavorites"
                    *ngIf="product?.selectedColor?.addedToFavorites
                    "
                    (click)="toggleFavorites(product)"
                    >Sterge de la favorite</a
                  >
                  </div>
                  <!-- End .float-left -->

                  <div class="float-right">
                    <a
                      (click)="removeFromCart(product)"
                      title="Remove product"
                      class="btn-remove"
                      ><span class="sr-only">Remove</span
                      ><i class="fa fa-trash-alt"></i
                    ></a>
                  </div>
                  <!-- End .float-right -->
                </td>
              </tr>
            </ng-container>
          </tbody>

          <tfoot>
            <tr>
              <td colspan="4" class="clearfix">
                <div class="float-left">
                  <a routerLink="/produse" class="btn btn-outline-secondary"
                    >Continua Cumparaturile</a
                  >
                </div>
                <!-- End .float-left -->

                <div class="float-right">
                  <a
                    (click)="emptyCart()"
                    class="btn btn-outline-secondary btn-clear-cart"
                    >Sterge cosul de cumparaturi</a
                  >
                </div>
                <!-- End .float-right -->
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <!-- End .cart-table-container -->

      <!-- <div class="cart-discount">
        <h4>Ai un cupon de reducere?</h4>
        <form action="#">
          <div class="input-group">
            <input type="text" class="form-control form-control-sm" placeholder="Adauga cuponul de reducere" required>
            <div class="input-group-append">
              <button class="btn btn-sm btn-primary" type="submit">Aplica reducere</button>
            </div>
          </div>
        </form>
      </div> -->
    </div>

    <div class="col-lg-4">
      <div class="cart-summary">
        <h3>Detalii comanda</h3>
        <app-coupons-discount></app-coupons-discount>

        <table class="table table-totals">
          <tbody>
            <tr>
              <td>Total produse</td>
              <td>
                <span
                  class="cart-total-price"
                  *ngIf="
                    (fullPrice$ | async) &&
                    (fullPrice$ | async) > 0 &&
                    (fullPrice$ | async) != totalPrice$
                  "
                  ><del *ngIf="(fullPrice$ | async) > (totalPrice$ | async)"
                    >{{ fullPrice$ | async }} Lei</del
                  ></span
                >{{ totalPrice$ | async }} lei
              </td>
            </tr>

            <tr
              *ngIf="
                (thirdProductInCartHasDiscount$ | async) ||
                (individualDiscountHasApplied$ | async) ||
                (firstOrderDiscountHasApplied$ | async)
              "
            >
              <td>Reducere</td>

              <td>
                {{
                  thirdProductInCartHasDiscount$.value
                    ? discountThirdProductInCart
                    : firstOrderDiscountHasApplied$.value
                    ? discountFirstOrder
                    : individualDiscountHasApplied$.value
                    ? "-" +
                      individualDiscountValue$.value.coupon.value +
                      " " +
                      (individualDiscountValue$.value.coupon
                        .is_fixed_discount === 1
                        ? "Lei"
                        : "%")
                    : ""
                }}
              </td>
            </tr>

            <tr *ngIf="(totalPrice$ | async) < 249">
              <td>Livrare</td>
              <td>{{ courierPrice$.value }} lei</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Total</td>
              <td>{{ totalPriceWithCourier$.value }} lei</td>
            </tr>
          </tfoot>
        </table>
        <p
          class="info info-danger"
          *ngIf="
            !(thirdProductInCartHasDiscount$ | async) &&
            !(individualDiscountHasApplied$ | async) &&
            !(firstOrderDiscountHasApplied$ | async)
          "
        >
          Primesti o reducere de -20% la al treilea produs adaugat in cos!
        </p>
        <p class="info info-danger" *ngIf="(totalPrice$ | async) < 249">
          Livrare gratuita daca valoarea cosului este mai mare de 249 Lei!
        </p>
        <div class="checkout-methods">
          <a
            routerLink="/finalizare-comanda"
            class="btn btn-block btn-sm btn-primary"
            >Finalizare comanda</a
          >
        </div>
        <!-- End .checkout-methods -->
      </div>
      <!-- End .cart-summary -->
    </div>
    <!-- End .col-lg-4 -->
  </div>
  <!-- End .row -->
</div>
<!-- End .container -->

<div class="mb-6"></div>
<!-- margin -->
