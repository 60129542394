import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import {
  map,
  takeUntil,
} from 'rxjs/operators';
import {
  CartService,
  FavoritesService,
  NewsletterService,
} from 'src/app/services';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { CouponsService } from './coupons/coupons.service';
import { Coupon } from './coupons/coupons.type';
import { Product } from 'src/app/models';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  public items$ = new BehaviorSubject<Product[]>([]);

  totalPrice$ = new BehaviorSubject<any>(0);
  fullPrice$ = new BehaviorSubject<any>(0);
  
  
  uploadedImgUrl: string;
  discountThirdProductInCart: string;
  discountFirstOrder: string;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  thirdProductInCartHasDiscount$ = new BehaviorSubject<boolean>(false);
  individualDiscountHasApplied$ = new BehaviorSubject<boolean>(false);
  firstOrderDiscountHasApplied$ = new BehaviorSubject<boolean>(false);
  individualDiscountValue$ = new BehaviorSubject<Coupon>({} as Coupon);
  totalPriceWithCourier$ = new BehaviorSubject<number>(0);
  courierPrice$ = new BehaviorSubject<number>(0);
  constructor(
    public _Router: Router,
    private _CartService: CartService,
    private _FavoritesService: FavoritesService,
    private _NewsletterService: NewsletterService,
    private _CouponsService: CouponsService,
  ) {
    this.items$ = this._CartService.items$;
    this.uploadedImgUrl = environment.uploadImagesUrl;
    this.totalPrice$ = this._CartService.totalPrice;
    this.fullPrice$ = this._CartService.fullPrice;
    this.thirdProductInCartHasDiscount$ =
      this._CartService.thirdProductInCartHasDiscount$;
    this.individualDiscountHasApplied$ =
      this._CouponsService.individualDiscountHasApplied$;
    this.firstOrderDiscountHasApplied$ =
      this._NewsletterService.firstOrderDiscountHasApplied$;
    this.discountThirdProductInCart =
      this._CartService.discountThirdProductInCart;
    this.discountFirstOrder = this._CartService.discountFirstOrder;
    this.totalPriceWithCourier$ = this._CartService.totalPriceWithCourier$;
    this.courierPrice$.next(environment.courierPrice)
    this.individualDiscountValue$ = this._CouponsService.individualDiscount$;
  }

  ngOnInit(): void {
    this.getProducts();
  }

  getProducts() {
    this.items$
      .pipe(
        takeUntil(this._unsubscribeAll),
        map((products) => {
          products.map((product) => {
            this._FavoritesService.setFavorites(product);
            if (product['selectedColor'].color_list > 1) {
              product['selectedColor'].color_list =
                product['selectedColor'].color_list.split(',');
            }
          });

        })
      )
      .subscribe();
  }

  emptyCart() {
    this._CartService.emptyCart();
  }

  removeFromCart(product) {
    this._CartService.removeFromCart(product);
  }

  toggleFavorites(product) {
    this._FavoritesService.toggleFavorites(product);
  }


  ngOnDestroy() {
    this._unsubscribeAll.next('');
    this._unsubscribeAll.complete();
  }
}
