import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PixelService } from 'ngx-pixel';
import { CartService, ProductService } from 'src/app/services';
import { DataLayerService } from 'src/app/services/dataLayer.service';
import { environment } from 'src/environments/environment';
declare let dataLayer: any[];
@Component({
  selector: 'app-product-quickview',
  templateUrl: './product-quickview.component.html',
  styleUrls: ['./product-quickview.component.scss']
})
export class ProductQuickviewComponent implements OnInit {
  @ViewChild('modalData') modalContent;

  closeModal: string;
  product: any;
  dotsOptions: OwlOptions;
  additionalImages: any;
  name = 'ngx sharebuttons';

  customOptionsQuick: OwlOptions = {
    items: 1, dots: true, margin: 0, stagePadding: 0,
    loop: true,
    navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
    autoplay: true,
    nav: true,

    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  }
  selectedImage: string;
  cartQuantity: number = 1;
  uploadedImgUrl: string;

  /**
   * Constructor
   *
   * @param {ProductService} _ProductService
   * @param {CartService} _CartService
   * @param {FavoriteService} _FavoriteService
   * @param {NgbModal} _ModalService
   * 
   */


  constructor(
    private _ProductService: ProductService,
    private _ModalService: NgbModal,
    private _CartService: CartService,
    private _DataLayeService: DataLayerService,
    private pixel: PixelService
  ) { 
    this.uploadedImgUrl = environment.uploadImagesUrl;
  }


  ngOnInit(): void {
  }

  triggerModal(product) {
    this.pixel.track('ViewContent');
    this._DataLayeService.reinitializeScript();
    (window as any).dataLayer.push({
      event: '__sm__view_product',
      product_id: product.id,
    });

    let imgArr = []
    product.colors.map((color, i) => {
      color.images.map(image => {
        imgArr.push(image)
      })      
    })

    
    this._ProductService.incrementProductView(product.id);
    product.images = imgArr;
    this.product = product;
    this.additionalImages = imgArr.length;
    this.dotsOptions = {
      items: imgArr.length, dots: false, autoWidth: true,  navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
    }
    this._ModalService.open(this.modalContent, { ariaLabelledBy: 'modal-basic-title' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  changeImage(image: string) {
    
   // this.product.selectedImage = image;
    this.selectedImage = image;
  }



  selectColor(product, color) {
    this._ProductService.selectColor(product, color);
  }


  selectSize(product, size) {
    this._ProductService.selectSize(product, size);
  }

  founded(image: { id: number }, images) {
    return !!images.find(x => x.id === image.id)
  }

  addToCart(product) {
    
    this._CartService.addToCart(product, this.cartQuantity);
  }

  addOneToCart() {
    this.cartQuantity += 1;
  }

  removeOneFromCart() {
    if (this.cartQuantity === 1) {
      return;
    }
    this.cartQuantity -= 1;
  }

}
