<div class="info-box">
  <img src="/assets/images/icons/info.svg" alt="info" />
  <p>
    Ai deja cont? Te rugam sa te autentifici
    <a class="auth-toggle" (click)="toggleAuthFn()">aici</a> sau
    <a class="auth-toggle" routerLink="/autentificare">creeaza cont</a>
  </p>
</div>

<div class="login-container" *ngIf="toggleAuth">
  <form (ngSubmit)="auth(f)" #f="ngForm">
    <button
      title="Close (Esc)"
      type="button"
      (click)="toggleAuthFn()"
      class="mfp-close popup-menu-close"
    ></button>
    <div class="form-group">
      <input
        type="text"
        name="email"
        placeholder="Emailul tau"
        class="form-control"
        [(ngModel)]="login.email"
        #email="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
        required
        email
      />
      <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
        <div *ngIf="email.errors.required">Te rugam sa introduci email-ul</div>
      </div>
    </div>
    <div class="form-group">
      <input
        type="password"
        placeholder="Parola ta"
        name="password"
        class="form-control"
        [(ngModel)]="login.password"
        #password="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && password.invalid }"
        required
      />
      <div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
        <div *ngIf="password.errors.required">Te rugam sa introduci parola</div>
      </div>
    </div>

    <div class="form-footer">
      <button type="submit" class="btn btn-primary">Autentificare</button>
      <a routerLink="/am-uitat-parola" class="forget-pass"> Ai uitat parola?</a>
    </div>

    <!-- <div class="social-login">
          <button (click)="signInWithFB()" class="btn btn-primary btn-facebook">
            <span class="fa fa-facebook"></span> Autentificare cu Facebook
          </button>
        </div> -->
  </form>
</div>
