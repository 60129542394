<div class="container mb-4 mb-lg-6">
  <!-- <h2 class="section-title text-center mb-0">Produse la reducere</h2>
  <p class="section-description text-center mb-3">Cele mai mari reduceri la produsele din catalogul nostru -->
  <h2 class="section-title text-center mb-0">Cele mai vandute produse</h2>
  <p class="section-description text-center mb-3">
    Cele mai vandute produse din catalogul nostru
  </p>

  <div class="row product-ajax-grid">
    <div class="col-sm-3 col-6" *ngFor="let product of products">
      <div class="product-default inner-quickview inner-icon">
        <figure>
          <a
            *ngIf="product?.selectedImage"
            routerLink="/produs/{{ product?.alias }}/{{ product?.id }}"
          >
            <ng-container *ngFor="let image of product?.selectedImage.url">
              <img src="{{ uploadedImgUrl }}{{ image.url }}" alt="image" />
            </ng-container>
          </a>
          <div class="label-group">
            <div
              class="product-label label-sale"
              *ngIf="product?.selectedSize.old_price > 0"
            >
              {{
                100 -
                  (product?.selectedSize.current_price /
                    product?.selectedSize.old_price) *
                    100 | number : "1.0-0"
              }}
              %
            </div>

            <div
              *ngIf="product?.isRecommended > 0"
              class="product-label label-hot"
            >
              Recomandat
            </div>
          </div>
          <!-- <div>
            <span class="stoc-alert product-label label-sale" *ngIf="product?.selectedSize.stoc == 3">Ultimele trei produse</span>
            <span class="stoc-alert product-label label-sale" *ngIf="product?.selectedSize.stoc == 2">Ultimele doua produse</span>
            <span class="stoc-alert product-label label-sale" *ngIf="product?.selectedSize.stoc == 1">Ultimul produs</span>
          </div> -->
          <!-- <div class="btn-icon-group">
            <button class="btn-icon btn-add-cart" (click)="addToCart(product); addedToCart.triggerModal(product)"><i
                class="icon-shopping-cart"></i></button>
          </div> -->
          <a
            (click)="quickView.triggerModal(product)"
            class="btn-quickview"
            title="Vezi Produs"
            >Vezi Produs</a
          >
        </figure>
        <div class="product-details">
          <div class="category-wrap">
            <a
              *ngIf="!product?.selectedColor.addedToFavorites"
              (click)="toggleFavorites(product)"
              class="btn-icon-wish"
              ><i class="icon-heart"></i
            ></a>
            <a
              *ngIf="product?.selectedColor.addedToFavorites"
              (click)="toggleFavorites(product)"
              class="btn-icon-wish"
              ><i class="fas fa-heart"></i
            ></a>
          </div>
          <div class="product-select-group">
            <ng-container *ngFor="let color of product?.colors">
              <ul class="color-container">
                <li>
                  <a
                    (click)="
                      selectColor(product, color); changeImage(image.url)
                    "
                    class="d-flex align-items-center justify-content-center p-0"
                    [ngClass]="{
                      checked: product.selectedColor.ColorID === color.ColorID
                    }"
                  >
                    <img
                      [src]="uploadedImgUrl + color.images[0].url"
                      width="30"
                      height="30"
                      alt="filter-img"
                    />
                  </a>
                </li>
              </ul>
            </ng-container>
            
          </div>
          <h2 class="product-title">
            <a routerLink="/produs/{{ product?.alias }}/{{ product?.id }}">{{
              product?.name
            }}</a>
          </h2>
          <div class="ratings-container">
            <div class="product-ratings">
              <!-- <ng-template #t let-fill="fill">
                  <span class="star" [class.full]="fill === 100">
                    <span class="half" [style.width.%]="fill">&#9733;</span>&#9734;
                  </span>
                </ng-template>
                <ngb-rating [(rate)]="product.rating" [starTemplate]="t" [readonly]="true" max="5"></ngb-rating>
                <span class="tooltiptext tooltip-top">{{product?.rating}}</span> -->
            </div>
            <!-- End .product-ratings -->
          </div>
          <!-- End .product-container -->
          <div class="price-box">
            <span
              class="product-price"
              [class.reduced]="product?.selectedSize.old_price > 0"
              >{{ product?.selectedSize.current_price }} Lei</span
            >
            <span *ngIf="product?.selectedSize.old_price > 0" class="old-price"
              >{{ product?.selectedSize.old_price }} Lei</span
            >
          </div>
          <!-- End .price-box -->
        </div>
        <!-- End .product-details -->
      </div>
    </div>
    <!-- End .col-sm-3 -->
  </div>
  <!-- End .row -->
</div>
<!-- End .container -->
<app-product-quickview #quickView></app-product-quickview>
<app-added-to-cart #addedToCart></app-added-to-cart>
