<h2 class="section-title text-center mb-0">Categorii</h2>
<p class="section-description text-center mb-3">Categorii de top pentru produse de fashion</p>
<owl-carousel-o [options]="customOptions"
  class="owl-carousel owl-theme categories-slider content-center-bottom nav-outer">
  <ng-template carouselSlide *ngFor="let category of categories">
    <div class="product-category">
      <a routerLink="/produse/{{category.category_name.toLowerCase()}}/{{category.categoryAlias}}">
        <figure>
          <img src="{{uploadedImgUrl}}{{category.subcategory_image}}">
        </figure>
        <div class="category-content">
          <h3>{{category.subcategory_name}}</h3>
        </div>
      </a>
    </div>
  </ng-template>

</owl-carousel-o>
<div class="mb-8"></div>
