import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { Product, ReturnProduct } from 'src/app/models';
import { AuthAPIService, ProductService, ProductsService } from 'src/app/services';
import { OrdersService } from 'src/app/services/orders.service';
import { environment } from 'src/environments/environment.prod';
import { ReturnFormService } from './return-form.service';


@Component({
  selector: 'app-return-form',
  templateUrl: './return-form.component.html',
  styleUrls: ['./return-form.component.scss']
})
export class ReturnFormComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  uploadedImgUrl: string = environment.uploadImagesUrl;
  showStepTwo$ = new BehaviorSubject<boolean>(false);
  stepOneComplete$ = new BehaviorSubject<boolean>(false);
  retunObj$ = new BehaviorSubject<ReturnProduct>({} as ReturnProduct);
  method$ = new BehaviorSubject<string>('');
  products$ = new BehaviorSubject<any>('');
  dataProducts$ = new BehaviorSubject<any>('');

  order_uuid: any;
  products: any;

  productsChange: any;

  selectProductColorChange: any = [];
  selectProductSizeChange: any = [];


  isAuthentificated: boolean;
  userAccess: number = 0;

  model: any = {};

  returnMethod: any = [];
  motive: any = [];
  replaceProduct: any = [];

  options: any = [];



  constructor(
    private _OrdersService: OrdersService,
    private _ProductsService: ProductsService,
    private _ProductService: ProductService,
    private toaster: ToastrService,
    private router: Router,
    public authAPIService: AuthAPIService,
    private _ReturnFormService: ReturnFormService,
    private changeDetectorRef: ChangeDetectorRef,
    private _Router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

  }

  ngOnInit(): void {
    this.authAPIService.user.pipe(
      take(1),
      tap(user => {
        this.isAuthentificated = !!user;
        if (this.isAuthentificated) {
          this.userAccess = user.access;
        }
      })
    )
      .subscribe();
    this.changeDetectorRef.detectChanges();
  }

  // Step 1

  findOrder() {
    this.selectProductColorChange = [];
    this.selectProductSizeChange = [];


    

    this._ProductsService.getProductsAll().then((products: Product[]) => {
      this.productsChange = products;
      this._ReturnFormService.setProducts$.next(this._ProductsService.cleanObj(products));
    })

    // Order id provided
    if (this.order_uuid) {
      this._ReturnFormService.getOrderDetails(this.order_uuid)
        .pipe(
          tap(data => {

            // check if order id exist

            if (!data.success) {
              this.toaster.warning('', `${data.message}`, {
                timeOut: 3000,
                positionClass: 'toast-bottom-right'
              });
              return;
            }




            // check if there are previous returns

            let getConfirmedSwap = data.return.returns.filter(returnedProducts => returnedProducts.return_status === 'confirmata' && returnedProducts.return_product_swap);

            if (getConfirmedSwap.length > 0) {

              //get products that are already returned


              // transform products json
              let getConfirmedSwapProducts = [];
              getConfirmedSwap.map(products => {
                getConfirmedSwapProducts.push(JSON.parse(products.return_product_swap));
              })

              // filter the products

              let getProductsWithoutReturn = data.return.returns.filter(
                productInReturn => getConfirmedSwapProducts.find(confirmedSwapProducts =>
                  productInReturn.id != confirmedSwapProducts.return_id
                ));


              // prepare to fill with full obj
              data.return.returns = data.return.returns.filter(
                productInReturn => getProductsWithoutReturn.find(confirmedSwapProducts =>
                  productInReturn.id === confirmedSwapProducts.id &&
                  productInReturn.ProductID === confirmedSwapProducts.id &&
                  productInReturn.selectedColor.ColorID === confirmedSwapProducts.ColorID &&
                  productInReturn.selectedSize.SizeID === confirmedSwapProducts.SizeID
                ));


              getConfirmedSwapProducts.map(products => {
                data.return.returns.push(products);
              })

              // get full product object o products without return
              getProductsWithoutReturn.map(product => {
                return this._ProductService.getProduct(product.ProductID).pipe(takeUntil(this._unsubscribeAll))
                  .subscribe((fullProductObj: any) => {
                    if (fullProductObj.product.id === product.ProductID) {

                      fullProductObj.product.selectedPrice = product.price
                      fullProductObj.product.selectedColor = {};
                      let selectedColor = fullProductObj.product.colors.filter(color => color.ColorID === product.ColorID)
                      fullProductObj.product.selectedColor = selectedColor[0];
                      fullProductObj.product.selectedSize = fullProductObj.product.selectedColor.sizes.filter(color => color.SizeID === product.SizeID);
                    }
                    data.return.returns.push(fullProductObj.product);
                    this.dataProducts$.next(data.return.returns);
                  })


              })


              this.dataProducts$.pipe(
                distinctUntilChanged(),
                tap(data => {
                  if (data) {


                    this.products = data;
                    this.products$.next(this.products);

                    for (let i = 0; i <= this.products.length; i++) {
                      this.options.push({ returnMethod: false, motive: false, replaceProduct: false, color: false, size: false })
                    }
                  }
                })
              ).subscribe()



            } else {

              this.products = JSON.parse(data.return.products_ordered);

              this.products$.next(this.products);

              for (let i = 0; i <= this.products.length; i++) {
                this.options.push({ returnMethod: false, motive: false, replaceProduct: false, color: false, size: false })
              }

            }
            //


            this.retunObj$.next({
              order_uuid: data.return.order_uuid,
              products: [],
              userAccess: this.userAccess,
              return_iban_name: '',
              return_iban_request: ''
            });


            this.stepOneComplete$.next(true);

          })
        ).subscribe();
    } else {
      this.toaster.warning('', 'Te rugam sa adaugi un numar de comanda!', {
        timeOut: 3000,
        positionClass: 'toast-bottom-right'
      });
    }

  }

  // Step 2

  selectForReturn(product, i) {

    this.retunObj$.pipe(
      take(1),
      tap((obj: any) => {
        let existing = obj.products.filter(existingProduct =>
          existingProduct.id == product.id &&
          existingProduct.ColorID == product["selectedColor"].ColorID &&
          existingProduct.SizeID == product["selectedSize"].SizeID &&
          existingProduct.index == i)

        let selectedProduct = obj.products.findIndex(item => item.index === i)
        if (existing.length > 0) {
          this.options[existing[0].index] = { returnMethod: false, motive: false, replaceProduct: false, color: false, size: false }
          obj.products = obj.products.filter(product => product.index != i);
          this.checkMetod(i);
        } else {
          this.options[i].returnMethod = true;
          obj.products.push(
            {
              id: product.id,
              SizeID: product.selectedSize.SizeID,
              ColorID: product.selectedColor.ColorID,
              num: (product.num === undefined ? 1 : product.num),
              price: product.selectedPrice,
              index: i
            });


        }

      })
    )
      .subscribe()
  }


  // Step 3

  addReturnMethod(method, i) {
    this.retunObj$.pipe(
      take(1),
      tap((obj: any) => {
        let existing = obj.products.filter(product => product.index == i);
        existing[0].returnMethod = method;


        this.options[i].motive = true;

        this.checkMetod(i);
      })
    )
      .subscribe();
  }

  // Step 4

  addMotive(reason, i) {
    this.retunObj$.pipe(
      take(1),
      tap((obj: any) => {
        let existing = obj.products.filter(product => product.index == i);
        existing[0].reason = reason;

        if (existing[0].returnMethod === 'schimb') {
          this.options[i].replaceProduct = true;
        } else {
          this.options[i].replaceProduct = false;
        }

      })
    )
      .subscribe();
  }


  // Step 5 if swap product
  selectProduct(product, i) {
    this.retunObj$.pipe(
      // take(1),
      tap((obj: any) => {


        let selectedProduct = obj.products.findIndex(item => item.index === i)
        // let existing = obj.products.filter(prod => prod.index == i);



        obj.products[selectedProduct].return_product_swap = product;
        obj.products[selectedProduct].return_product_swap.selectedColor = undefined;
        obj.products[selectedProduct].return_product_swap.ColorID = undefined;
        obj.products[selectedProduct].return_product_swap.selectedSize = undefined;
        obj.products[selectedProduct].return_product_swap.SizeID = undefined;












        this.options[i].color = true;


      })
    )
      .subscribe();
  }

  // Step 6 if swap product

  addColor(color, i) {
    this.retunObj$.pipe(
      take(1),
      tap((obj: any) => {
        let existing = obj.products.filter(prod => prod.index == i);
        existing[0].return_product_swap.selectedColor = color;
        existing[0].return_product_swap.ColorID = color.ColorID;
        existing[0].return_product_swap.sizes = color.sizes;

        this.options[i].size = true;

      })
    )
      .subscribe();
  }

  // Step 7 if swap product
  addSize(size, i) {

    this.retunObj$.pipe(
      take(1),
      tap((obj: any) => {
        let existing = obj.products.filter(prod => prod.index == i);
        existing[0].return_product_swap.selectedSize = size;
        existing[0].return_product_swap.SizeID = size.SizeID;
        existing[0].return_product_swap.selectedPrice = size.current_price;
        existing[0].return_product_swap.price = size.current_price;
      })
    )
      .subscribe();
  }


  checkMetod(i) {
    this.retunObj$.pipe(
      take(1),
      tap((obj: any) => {
        let iban = obj.products.filter(existingProduct =>
          existingProduct.returnMethod === 'iban')

        let existing = obj.products.filter(prod => prod.index == i);

        if (iban.length > 0) {
          this.method$.next('iban');
          this.options[i].replaceProduct = false;
        } else {
          this.method$.next('schimb');
          if (existing[0] && existing[0].motive) {
            this.options[i].replaceProduct = true;
          }

        }
      })
    )
      .subscribe()
  }


  checkIfErrors() {
    this.retunObj$.pipe(
      take(1),
      map(obj => {
        let errors = false;

        if (obj.products.length === 0) {
          this.toaster.warning('', 'Te rugam sa alegi un produs!', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right'
          });
          return of(null);
        } else {

          obj.products.map(product => {
            if (!product.returnMethod) {
              this.toaster.warning('', 'Te rugam sa alegi metoda de retur!', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right'
              });
              errors = true;
              return;
            }
            if (!product.reason) {
              this.toaster.warning('', 'Te rugam sa alegi motivul!', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right'
              });
              errors = true;
              return;
            }
            if (product.returnMethod === 'iban' && !this.model.iban) {
              this.toaster.warning('', 'Te rugam sa adaugi ibanul!', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right'
              });
              errors = true;
              return;
            } else if (product.returnMethod === 'iban' && this.model.iban) {
              obj.return_iban_request = this.model.iban;
            }
            if (product.returnMethod === 'iban' && !this.model.name) {
              this.toaster.warning('', 'Te rugam sa adaugi titularul!', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right'
              });
              errors = true;
              return;
            } else if (product.returnMethod === 'iban' && this.model.name) {
              obj.return_iban_name = this.model.name;
            }
            if (product.returnMethod === 'iban' && this.model.iban.length < 24) {
              this.toaster.warning('', 'Ibanul trebuie sa contina 24 de caractere!', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right'
              });
              errors = true;
              return;
            }

            if (!product.return_product_swap && product.returnMethod === 'schimb') {
              this.toaster.warning('', 'Te rugam sa alegi un produs inlocuitor!', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right'
              });
              errors = true;
              return;
            }
            if (product.return_product_swap && !product.return_product_swap.selectedColor) {
              this.toaster.warning('', 'Te rugam sa alegi o culoare!', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right'
              });
              errors = true;
              return;
            }
            if (product.return_product_swap && product.return_product_swap.selectedColor && !product.return_product_swap.selectedSize) {
              this.toaster.warning('', 'Te rugam sa alegi o marime!', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right'
              });
              errors = true;
              return;
            }
          })
          if (!errors) {
            return obj;
          }
        }
      })
    )
      .subscribe(obj => {
        if (obj) {

          this._ReturnFormService.sendReturn(obj)
            .pipe(
              tap(data => {
                if (data.success) {
                  data.returnal_messages.map(message => {
                    if (!message.success) {
                      this.toaster.warning('', `${message.message}`, {
                        timeOut: 3000,
                        positionClass: 'toast-bottom-right'
                      });
                    } else {
                      this.toaster.success('', `${message.message}`, {
                        timeOut: 3000,
                        positionClass: 'toast-bottom-right'
                      });
                      this.router.navigate(['/']);
                    }
                  })
                }
              })
            ).subscribe()
        }
      })
  }

  removeWhiteSpaces() {
    this.model.iban = this.model.iban.replace(/\s/g, '');
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this._unsubscribeAll.next('');
      this._unsubscribeAll.complete();
    }
  }
}
