import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';

import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Role, User } from '../models';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { NewsletterService } from './newsletter.service';
import { ToastrService } from 'ngx-toastr';

export interface AuthResponseData {
  user: User;
}

@Injectable({ providedIn: 'root' })
export class AuthAPIService {
  private _apiUrl: string;

  user = new BehaviorSubject<User>({} as User);

  private tokenExpirationTimer: any;

  constructor(
    private _NewsletterService: NewsletterService,
    private _httpClient: HttpClient,
    public _router: Router,
    private _toaster: ToastrService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this._apiUrl = environment.apiUrl + 'users/';
    if (isPlatformBrowser(this.platformId)) {
      this.user = new BehaviorSubject<User>(
        JSON.parse(localStorage.getItem('ShardorayUserData'))
      );
    }
  }

  public get currentUserValue(): User {
    return this.user.value;
  }

  public postData(credentials, type): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders();
      this._httpClient
        .post(this._apiUrl + type, JSON.stringify(credentials))
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  signup(user) {
    return this._httpClient
      .post<AuthResponseData>(this._apiUrl + 'register', {
        name: user.name,
        email: user.email,
        password: user.password,
      })
      .pipe(
        tap((data: any) => {
          if (data.success) {
            this.handleAuthentication(
              data.user.id,
              data.user.name,
              data.user.email,
              data.user.provider,
              data.user.provider_id,
              data.user.provider_pic,
              data.user.date_last_visit,
              data.user.access,
              data.user.token,
              data.user.active
            );
          }
        })
      );
  }

  login(email: string, password: string): Observable<AuthResponseData> {
    return this._httpClient
      .post<AuthResponseData>(this._apiUrl + 'login', {
        email: email,
        password: password,
      })
      .pipe(
        tap((data) => {

          if (!data['success']) {
            this._toaster.warning('', `${data['message']}`, {
              timeOut: 8000,
              positionClass: 'toast-bottom-right'
            });
            throw new Error('Login failed');
          } else {
            this._toaster.success('', `${data['message']}`, {
              timeOut: 8000,
              positionClass: 'toast-bottom-right'
            });
          }

          this.handleAuthentication(
            data.user.id,
            data.user.name,
            data.user.email,
            data.user.provider,
            data.user.provider_id,
            data.user.provider_pic,
            data.user.date_last_visit,
            data.user.access,
            data.user.token,
            data.user.active
          );

        }),
        // switchMap(data => {
        //   if(data.user.active > 0) {
        //     return this._NewsletterService.checkDiscountFirstOrder(data.user.email);
        //   }
        // }),
        catchError(error => {
          // Handle the error here or return an observable with a default value
          console.error(error);
          return of(/* Your default value or error response */);
        })
      );
  }

  logout() {
    this.user.next(null);
    this._router.navigate(['/autentificare']);
    localStorage.removeItem('ShardorayUserData');
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

  autoLogin() {
    let data = localStorage.getItem('ShardorayUserData');
    if (data) {
      const ShardorayUserData: {
        id: number;
        name: string;
        email: string;
        provider: string;
        provider_id: string;
        provider_pic: string;
        date_last_visit: Date;
        access: number;
        active: number;
        _token: string;
        _tokenExpirationDate: string;
      } = JSON.parse(data);

      const loadedUser = new User(
        ShardorayUserData.id,
        ShardorayUserData.name,
        ShardorayUserData.email,
        ShardorayUserData.provider,
        ShardorayUserData.provider_id,
        ShardorayUserData.provider_pic,
        ShardorayUserData.date_last_visit,
        ShardorayUserData.access,
        ShardorayUserData.active,
        ShardorayUserData._token,
        new Date(ShardorayUserData._tokenExpirationDate)
      );

      if (loadedUser.token) {
        this.user.next(loadedUser);
        const expirationDuration =
          new Date(ShardorayUserData._tokenExpirationDate).getTime() -
          new Date().getTime();
        //this.autoLogout(expirationDuration);
      }
    }
  }

  recover(email) {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this._apiUrl + 'login/recover', { email: email })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  changePassword(password, token) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', token);
      this._httpClient
        .post(
          this._apiUrl + 'login/changePassword',
          { password: password },
          { headers: headers }
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  private handleAuthentication(
    id: number,
    name: string,
    email: string,
    provider: string,
    provider_id: string,
    provider_pic: string,
    date_last_visit: Date,
    access: number,
    token: string,
    active: number
  ) {
    const expirationDate = new Date(date_last_visit);
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    const user = new User(
      id,
      name,
      email,
      provider,
      provider_id,
      provider_pic,
      date_last_visit,
      access,
      active,
      token,
      expirationDate
    );

    if (user.active == 0 ) {
      this._toaster.warning('Apasa <strong style="text-decoration: underline">AICI</strong> pentru a retrimite emailul!', 'Nu ai primit email-ul pentru confirmarea contului?', {
        enableHtml: true,
        timeOut: 10000,
        positionClass: 'toast-bottom-right'
      }).onTap
      .pipe(take(1))
      .subscribe(() => this.resendConfirmationEmail());
      return;
    } else {
      this._NewsletterService.checkDiscountFirstOrder(user.email).pipe(take(1)).subscribe();
      this.user.next(user);
      localStorage.setItem('ShardorayUserData', JSON.stringify(user));
      //this._router.navigate(['/contul-meu'])
    }

    //this.autoLogout(expirationDate.getTime());
  }

  resendConfirmationEmail() {
    console.log('resend')
  }

  private handleError(errorRes: HttpErrorResponse) {
    let errorMessage = 'An unknown error occured!';
    if (!errorRes.error || !errorRes.error.error) {
      return throwError(errorMessage);
    }
    switch (errorRes.error.error.message) {
      case 'EMAIL_EXISTS':
        errorMessage = 'This email exists already!';
    }
    return throwError(errorMessage);
  }
}
