import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  UrlSegment,
} from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
// import { TransferStateService } from '@scullyio/ng-lib';
import {
  OwlOptions,
  SlidesOutputData,
  CarouselModule,
} from 'ngx-owl-carousel-o';
import { PixelService } from 'ngx-pixel';
import {
  BehaviorSubject,
  from,
  Observable,
  Subject,
  Subscription,
  timer,
} from 'rxjs';
import { map, pluck, switchMap, take, tap } from 'rxjs/operators';
import { ScriptLoaderService } from 'src/app/helpers/script-loader.service';
import { Product } from 'src/app/models';
import {
  CartService,
  FavoritesService,
  ProductService,
  ProductsService,
} from 'src/app/services';
import { DataLayerService } from 'src/app/services/dataLayer.service';
import { TitleService } from 'src/app/services/title.service';
import { environment } from 'src/environments/environment';

declare let dataLayer: any[];

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit, OnDestroy {
  products: any;
  productSlug: string;
  productColor: string;
  productSize: string;
  colorID: string;
  additionalImages: any;
  product: any = null;
  dotsOptions: OwlOptions;

  @Input() newsId: string;
  @ViewChild('owlCar', { static: false }) owlCar: any;

  favoriteProducts: any[] = [];

  customOptionsQuick: OwlOptions = {
    items: 1,
    dots: true,
    margin: 0,
    stagePadding: 0,
    loop: true,
    navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
    autoplay: true,
    nav: true,

    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
  };

  categoryProducts: OwlOptions = {
    loop: true,
    margin: 20,
    autoplay: true,
    dots: true,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      740: {
        items: 2,
      },
      940: {
        items: 4,
      },
    },
  };
  selectedImage: string;
  activeTab: string = 'product-delivery-info-content';
  categoryProductsArr: any;
  selectedColor: any;
  selectedSize: any;
  shareUrl: string;
  cartQuantity: number = 1;
  productID: any;
  public itemsFavorites$ = this._FavoritesService.itemsFavorites$;
  uploadedImgUrl: string;
  productCategory: any;
  productSubcategory: any;
  activeSlides: SlidesOutputData;
  _apiUrl = environment.apiUrl;

  protected subscription = new Subscription();

  productId$ = this._ActivatedRoute.params.pipe(map((params) => params['id']));

  readonly product$: Observable<Product> = this._ActivatedRoute.url.pipe(
    switchMap((url: UrlSegment[]) => {
      return this._ProductService.fetchProductByID(parseInt(url[0].path));
    })
  );

  recommendedProducts$ = this._ProductsService.recommendedProducts$;
  colorChanged$ = new BehaviorSubject<boolean>(true);

  /**
   * Constructor
   *
   * @param {ProductService} _ProductService
   * @param {CartService} _CartService
   * @param {FavoriteService} _FavoriteService
   * @param {Router} _Router
   * @param {ActivatedRoute} _ActivatedRoute
   *
   */

  constructor(
    private _ProductService: ProductService,
    public _Router: Router,
    private _ActivatedRoute: ActivatedRoute,
    private _CartService: CartService,
    private _DomSanitizationService: DomSanitizer,
    private _FavoritesService: FavoritesService,
    private _ProductsService: ProductsService,
    private pixel: PixelService,
    private title: Title,
    private meta: Meta,
    private titleService: TitleService,
    private titleService2: Title,
    // private transferState: TransferStateService,
    private _httpClient: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _DataLayeService: DataLayerService,
    private _scriptLoader: ScriptLoaderService
  ) {
    this.uploadedImgUrl = environment.uploadImagesUrl;
    // this._Router.routeReuseStrategy.shouldReuseRoute = function () {
    //   return false;
    // };
    this._ActivatedRoute.queryParams.subscribe((params) => {
      this.titleService2.setTitle(params.title);
      this.meta.updateTag({
        name: 'description',
        content: params.metadata ? params.metadata : 'Shardoray',
      });
    });

    this._Router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        this._DataLayeService.reinitializeGTMScript();
      }
    });
  }

  ngOnInit(): void {
    this._ActivatedRoute.paramMap.subscribe((params) => {
      this.productSlug = params.get('productSlug');
      this.productColor = params.get('productColor');
      this.productSize = params.get('productSize');
      this.productID = params.get('productID');
      this.colorID = params.get('colorID');
    });

    this.pixel.track('ViewContent');

    this.product = this._ProductService
      .getProductInfo(this.productID, this.colorID)
      .pipe(take(1))
      .subscribe((product: any) => {
        const productIdSubscription = this.productId$
          .pipe(tap((id) => this.titleService.title$.next(product.seo)))
          .subscribe();

        this.subscription.add(productIdSubscription);
      });

    this._ProductService.incrementProductView(this.productID);

    this.getProduct();
  }

  // getProduct() {
  //   this._ProductService
  //     .getProductInfo(this.productID, this.colorID)
  //     .pipe(take(1))
  //     .subscribe((product: any) => {
  //       this.product = product.product;
  //       this._ProductsService
  //         .getProductsRecommended(
  //           this.product.categories[0].id,
  //           this.product.categories[0].subcategories[0].id,
  //           this.product.id
  //         )
  //         .pipe(take(1))
  //         .subscribe();

  //       this.titleService.title$.next(this.product.seo);
  //       this.titleService.meta$.next(this.product.meta);

  //       if (this.product.isMultiple === 1) {
  //         const complementaryProductColorArr = this.product.colors.filter(
  //           (color) => color.ColorID === 158
  //         );
  //         const complementaryProductColorOnj = this.product.colors.find(
  //           (color) => color.ColorID === 158
  //         );

  //         this.product.colors = complementaryProductColorArr;
  //         this.product.selectedColor = complementaryProductColorOnj;
  //         this.product.selectedImageObj =
  //           complementaryProductColorOnj.images[0].url;
  //         this.product.complementary_products = JSON.parse(
  //           this.product.complementary_products
  //         );
  //       }

  //       if(this.colorID) {
  //         this.product.selectedColor = this.product.color.find(color => color.ColorID === this.colorID)
  //       } else {
  //         this.product.selectedColor = this.product.colors[0];
  //       }

  //       this.product.selectedImage.url = product.product.colors[0].images;
  //       let imgArr = [];
  //       product.product.colors.map((color, i) => {
  //         color.images.map((image) => {
  //           imgArr.push(image);
  //         });
  //       });
  //       this.product.sizes = this.product.colors[0].sizes;
  //       this.product.selectedPrice = this.product.colors[0].sizes[0].current_price;
  //       this.product.selectedSize = this.product.colors[0].sizes[0];
  //       this.product.images = imgArr;

  //       this.additionalImages = imgArr.length;
  //       this.selectedImage = this.product.selectedImage.url;
  //       this.dotsOptions = {
  //         items: imgArr.length,
  //         nav: true,
  //         dots: true,
  //         autoWidth: true,
  //         navText: [
  //           '<i class="icon-angle-left">',
  //           '<i class="icon-angle-right">',
  //         ],
  //       };

  //       this.itemsFavorites$
  //         .pipe(
  //           take(1),
  //           map((products) => {
  //             console.log(products)
  //             products.map((existingProduct) => {
  //               if (existingProduct.id === product.product.id) {
  //                 product.product.addedToFavorites = true;
  //               }

  //               if(existingProduct.id === product.product.id && existingProduct.selectedColor.ColorID === product.product.selectedColor.ColorID) {
  //                 product.product.selectedColor.addedToFavorites = true;
  //               } else {
  //                 product.product.selectedColor.addedToFavorites = false;
  //               }

  //             });
  //           })
  //         )
  //         .subscribe();

  //       this.productCategory = this.product.categories[0];
  //       this.productSubategory = this.product.categories[0].subcategories[0];
  //       this.shareUrl =
  //         'https://shardoray.ro/produs/' +
  //         this.product.alias +
  //         '/' +
  //         this.product.id;

  //       dataLayer.push({ ecommerce: null });
  //       dataLayer.push({
  //         event: 'view_item',
  //         ecommerce: {
  //           detail: {
  //             items: [
  //               {
  //                 id: product.product.id,
  //                 name: product.product.name,
  //                 variant: '',
  //                 brand: '',
  //                 category:
  //                   product.product.categories[0].category_name +
  //                   ' ' +
  //                   product.product.categories[0].subcategories[0]
  //                     .subcategory_name,
  //                 sku: '',
  //                 base_price: '',
  //                 price: product.product['selectedPrice'],
  //                 currency: 'RON',
  //                 quantity: 1,
  //               },
  //             ],
  //           },
  //         },
  //       });
  //     });
  // }

  getProduct() {
    this._ProductService
      .getProductInfo(this.productID, this.colorID)
      .pipe(take(1))
      .subscribe((productResponse: any) => {
        console.log(productResponse);
        const product = productResponse.product;

        // Set product details
        this.product = product;
        this.setComplementaryProductDetails(product);
        // this.setProductSelectedColor(product);
        this.setProductImagesAndDetails(product);

        // Set page title and meta tags
        this.titleService.title$.next(product.seo);
        this.titleService.meta$.next(product.meta);

        // Get recommended products
        this._ProductsService
          .getProductsRecommended(
            product.categories[0].id,
            product.categories[0].subcategories[0].id,
            product.id
          )
          .pipe(take(1))
          .subscribe();

        // Set favorites and analytics
        this._FavoritesService.setFavorites(product);

        // Set product category and share URL
        this.productCategory = product.categories[0];
        this.productSubcategory = product.categories[0].subcategories[0];
        this.shareUrl = `https://shardoray.ro/produs/${product.alias}/${product.id}`;

        // Push data to dataLayer for analytics
        this.pushAnalyticsData(product);
      });
  }

  // Helper functions

  private setComplementaryProductDetails(product: any): void {
    if (product.isMultiple === 1) {
      const complementaryProductColorArr = product.colors.filter(
        (color: any) => color.ColorID === 158
      );
      const complementaryProductColorObj = product.colors.find(
        (color: any) => color.ColorID === 158
      );

      product.colors = complementaryProductColorArr;
      product.selectedColor = complementaryProductColorObj;
      product.selectedImageObj = complementaryProductColorObj.images[0].url;
      product.complementary_products = JSON.parse(
        product.complementary_products
      );
    }
  }

  private setProductSelectedColor(product: any): void {
    if (this.colorID) {
      product.selectedColor = product.colors.find(
        (color: any) => color.ColorID === this.colorID
      );
    }
  }

  private setProductImagesAndDetails(product: any): void {
    this.product.selectedImage.url = product.colors[0].images;
    const imgArr = product.colors.reduce((acc: any[], color: any) => {
      color.images.forEach((image: any) => {
        acc.push(image);
      });
      return acc;
    }, []);
    this.product.sizes = product.colors[0].sizes;
    this.product.selectedPrice = product.colors[0].sizes[0].current_price;
    this.product.selectedSize = product.colors[0].sizes[0];
    this.product.images = imgArr;

    this.additionalImages = imgArr.length;
    this.selectedImage = this.product.selectedImage.url;
    this.dotsOptions = {
      items: imgArr.length,
      nav: true,
      dots: true,
      autoWidth: true,
      navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
    };
  }

  // private setFavorites(product: any): void {
  //   this.itemsFavorites$
  //     .pipe(
  //       take(1),
  //       map((products: any[]) => {
  //         products.forEach((existingProduct) => {
  //           if (existingProduct.id === product.id) {
  //             product.addedToFavorites = true;
  //           }

  //           if (existingProduct.id === product.id && existingProduct.selectedColor.ColorID === product.selectedColor.ColorID) {
  //             product.selectedColor.addedToFavorites = true;
  //           }
  //         });
  //       })
  //     )
  //     .subscribe();
  // }

  private pushAnalyticsData(product: any): void {
    this._DataLayeService.reinitializeScript();
    (window as any).dataLayer.push({
      event: '__sm__view_product',
      product_id: this.productID,
    });
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: 'view_item',
      ecommerce: {
        detail: {
          items: [
            {
              id: product.id,
              name: product.name,
              variant: '',
              brand: '',
              category: `${product.categories[0].category_name} ${product.categories[0].subcategories[0].subcategory_name}`,
              sku: '',
              base_price: '',
              price: product.selectedPrice,
              currency: 'RON',
              quantity: 1,
            },
          ],
        },
      },
    });
  }

  changeImage(image: string) {
    this.selectedImage = image;
  }

  slideChange(data: SlidesOutputData) {
    this.activeSlides = data;
  }

  founded(image: { id: number }, images) {
    return !!images.find((x) => x.id === image.id);
  }

  toggleTab(activeTab) {
    this.activeTab = activeTab;
  }

  addToCart(product) {
    this._CartService.addToCart(product, this.cartQuantity);
  }

  addOneToCart() {
    this.cartQuantity += 1;
  }

  removeOneFromCart() {
    if (this.cartQuantity === 1) {
      return;
    }
    this.cartQuantity -= 1;
  }

  toggleFavorites(product: Product) {
    this._FavoritesService.toggleFavorites(product);
  }

  selectColor(product, color, i) {
    // this.activeSlides.startPosition = i
    // this.owlCar.to(i);
    this.colorChanged$.next(false);
    this._ProductService.selectColor(product, color);
    timer(200).subscribe(() => {
      this.colorChanged$.next(true);
    });
    const scrollToBody = document.querySelector('.main');
    scrollToBody.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  // selectColor(product: Product, color: any) {
  //   this._ProductService.selectColor(product, color);
  // }

  selectSize(product, size) {
    this._ProductService.selectSize(product, size);
  }

  selectSizeMultiple(product, complementary_product, size) {
    this._ProductService.selectSizeMultiple(
      product,
      complementary_product,
      size
    );
  }

  navigateToProduct(alias, id, colorID, meta) {
    this._ProductService.productID$.next(id);
    this._ProductService.productID$.pipe(take(1)).subscribe(() => {
      this._Router.navigate([`/produs/${alias}/${id}/${colorID}`], {
        queryParams: { title: alias, metadata: meta },
      });
    });
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
}
