<div class="checkout-info-box">
    <h3 class="step-title">Metoda de livrare:</h3>
    <ul class="delivery-method">
      <li (click)="toggleDelivery('curier')" [class.selected]="samedayDelivery$.value === 'curier'">
        <input type="radio" name="select-delivery" id="curier" [checked]="samedayDelivery$.value === 'curier'" value="curier">
        <label for="curier"><strong>Livrare curier</strong></label>
      </li>
      <li (click)="toggleDelivery('easybox')" [class.selected]="samedayDelivery$.value === 'easybox'">
        <input type="radio" name="select-delivery" id="easybox" [checked]="samedayDelivery$.value === 'easybox'" value="easybox">
        <label for="easybox"><strong>Livrare easybox</strong></label>
      </li>
    </ul>
  </div>
  <div class="easybox-info" *ngIf="(samedayDelivery$ | async) === 'easybox' && (lockerAddress$ | async)?.address">
    <img src="/assets/images/icons/easybox.svg" alt="">
    <strong>{{(lockerAddress$ | async)?.name + ' ' + (lockerAddress$ | async)?.address + ' ' + (lockerAddress$ | async)?.city + ' ' + (lockerAddress$ | async)?.county + ' ' + (lockerAddress$ | async)?.postalCode}}</strong>
  </div>