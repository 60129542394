<div class="checkout-steps" #scrollTop>

    <h2 class="step-title">{{samedayDelivery$.value === 'easybox' ? 'Persoana de contact' : 'Adresa livrare'}}</h2>
    <div class="mb-2"></div>
    <form #f="ngForm" [formGroup]="form" class="row">
      <div class="form-group required-field col-md-3 col-xs-12">
        <label>Email </label>
        <ng-container
          *ngIf="
            (individualDiscountHasApplied$ | async) ||
              (firstOrderDiscountHasApplied$ | async);
            else enableInput
          "
        >
          <input
            disabled
            readonly
            type="text"
            class="form-control"
            placeholder="Ex: adrian.popescu@yahoo.com"
            name="email"
            class="form-control"
            formControlName="email"
          />
        </ng-container>
        <ng-template #enableInput>
          <input
            type="text"
            class="form-control"
            placeholder="Ex: adrian.popescu@yahoo.com"
            name="email"
            class="form-control"
            formControlName="email"
            (blur)="cleanForm(form); checkEmail()"
            [ngClass]="{
              'is-invalid':
                (!formValid || f.form.controls['email'].touched) &&
                f.form.controls['phone'].invalid
            }"
          />
        </ng-template>

        <div
          *ngIf="
            (!formValid || f.form.controls['email'].touched) &&
            f.form.controls['email'].invalid
          "
          class="alert alert-danger"
        >
          <div *ngIf="f.form.controls['email']?.errors?.required">
            Te rugam sa introduci adresa de email!
          </div>
          <div
            *ngIf="
              f.form.controls['email'].errors &&
              !f.form.controls['email']?.errors?.required
            "
          >
            Email-ul nu are formatul corect!
          </div>
        </div>
      </div>
      <div class="form-group required-field col-md-3 col-xs-12">
        <label>Telefon </label>
        <input
          type="text"
          class="form-control"
          [ngClass]="{
            'is-invalid':
              (!formValid || f.form.controls['phone'].touched) &&
              f.form.controls['phone'].invalid
          }"
          placeholder="Ex: 0721231231"
          formControlName="phone"
          required
        />

        <div
          *ngIf="
            (!formValid || f.form.controls['phone'].touched) &&
            f.form.controls['phone'].invalid
          "
          class="alert alert-danger"
        >
          <div *ngIf="f.form.controls['phone']?.errors?.required">
            Te rugam sa introduci numarul de telefon!
          </div>
          <div *ngIf="f.form.controls['phone'].errors">
            Telefonul nu are formatul corect!
          </div>
        </div>
      </div>
      <div class="form-group required-field col-xs-12 col-md-3">
        <label>Nume </label>
        <input
          type="text"
          class="form-control"
          name="surname"
          class="form-control"
          [ngClass]="{
            'is-invalid':
              (!formValid || f.form.controls['surname'].touched) &&
              f.form.controls['surname'].invalid
          }"
          formControlName="surname"
          required
          minlength="3"
        />
        <div
          *ngIf="
            (!formValid || f.form.controls['surname'].touched) &&
            f.form.controls['surname'].invalid
          "
          class="alert alert-danger"
        >
          <div *ngIf="f.form.controls['name']?.errors?.required">
            Te rugam sa introduci numele!
          </div>
          <div *ngIf="f.form.controls['name']?.errors?.minlength">
            Numele trebuie sa contina cel putin 3 litere!
          </div>
        </div>
      </div>
      <!-- End .form-group -->
      <div class="form-group required-field col-xs-12 col-md-3">
        <label>Prenume </label>
        <input
          type="text"
          class="form-control"
          name="name"
          class="form-control"
          [ngClass]="{
            'is-invalid':
              (!formValid || f.form.controls['name'].touched) &&
              f.form.controls['name'].invalid
          }"
          formControlName="name"
          required
          minlength="3"
        />
        <div
          *ngIf="
            (!formValid || f.form.controls['name'].touched) &&
            f.form.controls['name'].invalid
          "
          class="alert alert-danger"
        >
          <div *ngIf="f.form.controls['name']?.errors?.required">
            Te rugam sa introduci prenumele!
          </div>
          <div *ngIf="f.form.controls['name']?.errors?.minlength">
            Prenumele trebuie sa contina cel putin 3 litere!
          </div>
        </div>
      </div>
      <!-- End .form-group -->

      <div class="form-group required-field col-xs-12 col-md-6" [class.d-none]="samedayDelivery$.value === 'easybox'">
        <label>Adresa completa </label>
        <input
          class="form-control"
          placeholder="Ex: Strada Principala, nr 2, bloc/casa"
          [ngClass]="{
            'is-invalid':
              (!formValid || f.form.controls['address'].touched) &&
              f.form.controls['address'].invalid
          }"
          formControlName="address"
          (onAddressChange)="handleAddressChange($event)"
        />

        <div
          *ngIf="
            (!formValid || f.form.controls['address'].touched) &&
            f.form.controls['address'].invalid
          "
          class="alert alert-danger"
        >
          <div *ngIf="f.form.controls['address'].errors">
            Te rugam sa introduci adresa!
          </div>
        </div>
      </div>

      <div class="form-group required-field col-xs-12 col-md-3" [class.d-none]="samedayDelivery$.value === 'easybox'">
        <label for="">Oras/comuna/sat</label>
        <input
          type="text"
          class="form-control"
          [ngClass]="{
            'is-invalid':
              (!formValid || f.form.controls['city'].touched) &&
              f.form.controls['city'].invalid
          }"
          placeholder="Orasul sau comuna sau satul"
          formControlName="city"
          required
        />
        <div
          *ngIf="
            (!formValid || f.form.controls['city'].touched) &&
            f.form.controls['city'].invalid
          "
          class="alert alert-danger"
        >
          <div *ngIf="f.form.controls['city']?.errors">
            Te rugam sa introduci orasul/comuna/satul!
          </div>
        </div>
      </div>

      <div class="form-group required-field col-xs-12 col-md-3" [class.d-none]="samedayDelivery$.value === 'easybox'">
        <label for="">Judet</label>
        <input
          type="text"
          class="form-control"
          placeholder="Adauga judet"
          name="name"
          class="form-control"
          [ngClass]="{
            'is-invalid':
              (!formValid || f.form.controls['county'].touched) &&
              f.form.controls['county'].invalid
          }"
          formControlName="county"
          required
          minlength="3"
        />
        <div
          *ngIf="
            (!formValid || f.form.controls['county'].touched) &&
            f.form.controls['county'].invalid
          "
          class="alert alert-danger"
        >
          <div *ngIf="f.form.controls['county']?.errors?.required">
            Te rugam sa introduci numele judetului!
          </div>
          <div *ngIf="f.form.controls['county']?.errors?.minlength">
            Numele judetului trebuie sa contina cel putin 3 litere!
          </div>
        </div>
      </div>

      <div class="form-group gdpr required-field col-xs-12 col-md-6">
        <input
          type="checkbox"
          class="form-control"
          name="gdpr"
          id="gdpr"
          class="form-control"
          formControlName="gdpr"
          [ngClass]="{
            'is-invalid':
              (!formValid || f.form.controls['gdpr'].touched) &&
              f.form.controls['gdpr'].invalid
          }"
        />

        <label for="gdpr"
          >Sunt de acord cu
          <a class="auth-toggle" routerLink="/termeni-si-conditii"
            >termenii si conditiile</a
          >
          Shardoray.ro</label
        >
        <div
          *ngIf="
          (!formValid || f.form.controls['gdpr'].touched) &&
          f.form.controls['gdpr'].invalid
          "
          class="alert alert-danger"
        >
          <div *ngIf="f.form.controls['gdpr']?.errors?.required">
            Trebuie sa fii de acord cu termenii si conditiile site-ului pentru a
            plasa o comanda!
          </div>
        </div>
      </div>
    </form>
  </div>
