import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { TestProductsService } from './test-products.service';

@Injectable({
  providedIn: 'root'
})
export class TestProductsResolver implements Resolve<boolean> {
  constructor(
    private _TestProductsService: TestProductsService,

  ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any | Observable<any> | Promise<any> {
    return forkJoin([
      this._TestProductsService.fetchProductsTest()
    ])
  }
}
