import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Newsletter } from '../types/newsletter.type';
import { ToastrService } from 'ngx-toastr';
import { CartService } from './cart.service';
import { CheckoutService } from '../pages/checkout/checkout.service';

@Injectable({
  providedIn: 'root',
})
export class NewsletterService {
  private _apiUrl: string;
  private _uploadImages: string;
  private _deleteImage: any;

  badCustomer$ = new BehaviorSubject<any>(null);
  firstOrderDiscountHasApplied$ = new BehaviorSubject<boolean>(false);
  remembeAboutTheFirstOrder$ = new BehaviorSubject<boolean>(false);

  constructor(
    private _httpClient: HttpClient, 
    private toaster: ToastrService,
    ) {
    this._apiUrl = environment.apiUrl;
  }

  /**
   * check Newsletter
   *
   * @returns {Promise<any>}
   */
  checkDiscountFirstOrder(email) {
    return this._httpClient
      .post(this._apiUrl + 'newsletter/check', { email: email })
      .pipe(
        tap((data: any) => {
          if (data && data.success) {
            this.toaster.success(``, `${data.message}`, {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            this.firstOrderDiscountHasApplied$.next(true);
            this.remembeAboutTheFirstOrder$.next(true);
          } else {
            
            // this.toaster.warning(``, `${data.message}`, {
            //   timeOut: 3000,
            //   positionClass: 'toast-bottom-right',
            // });
          }
        })
      )
      
  }

  /**
   * check Newsletter
   *
   * @returns {Promise<any>}
   */
  checkBadCustomer(email) {
    return this._httpClient
      .post(this._apiUrl + 'newsletter/badCustomer', { email: email })
      .pipe(
        tap((data: any) => {
          this.badCustomer$.next(data);
        })
      )
      .subscribe();
  }
  /**
   * check Newsletter
   *
   * @returns {Promise<any>}
   */
  // checkForIndividualDiscount(email) {
  //   return this._httpClient
  //     .post(this._apiUrl + 'newsletter/coupons/check', { email: email })
  //     .pipe(tap((data: Newsletter) => this.individualDiscount$.next(data)))
  //     .subscribe();
  // }
  /**
   * Use Newsletter
   *
   * @returns {Promise<any>}
   */
  useDiscountFirtOrder(email): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this._apiUrl + 'newsletter/use', { email: email })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Use Newsletter
   *
   * @returns {Promise<any>}
   */
  useIdividualDiscount(email): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this._apiUrl + 'newsletter/useCoupon', { email: email })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Add to newsletter
   *
   * @returns {Promise<any>}
   */

  public addToNewsletter(email): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this._apiUrl + 'newsletter/add', { email: email })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
}
