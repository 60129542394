import { Component, OnInit } from '@angular/core';
// import { IParallaxScrollConfig } from 'ngx-parallax-scroll';

@Component({
  selector: 'app-season-products',
  templateUrl: './season-products.component.html',
  styleUrls: ['./season-products.component.scss']
})
export class SeasonProductsComponent implements OnInit {

  // ngParallaxConf: IParallaxScrollConfig = {
  //   parallaxSpeed: 1,
  //   parallaxSmoothness: 1,
  //   parallaxDirection: 'reverse',
  //   parallaxTimingFunction: 'ease-in',
  //   parallaxThrottleTime: 80
  // };
  constructor() { }

  ngOnInit(): void {
  }

}
