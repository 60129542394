import { Component, OnInit } from '@angular/core';
import { PixelService } from 'ngx-pixel';
import { take } from 'rxjs';
import { CartService } from 'src/app/services';
import { OrdersService } from 'src/app/services/orders.service';
import { environment } from 'src/environments/environment';
import { CouponsService } from '../cart/coupons/coupons.service';
import { HttpClient } from '@angular/common/http';
import { DataLayerService } from 'src/app/services/dataLayer.service';
declare let dataLayer: any[];
@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss'],
})
export class ThankYouComponent implements OnInit {
  courierPrice: number = environment.courierPrice;
  totalPrice$: any;
  private theMarketerSaveOrder = environment.theMarketerSaveOrder;

  constructor(
    private pixel: PixelService,
    private _CartService: CartService,
    private _OrderService: OrdersService,
    private _CouponsService: CouponsService,
    private _DataLayeService: DataLayerService,
    private _httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this._DataLayeService.reinitializeScript();
    this._CartService.totalPrice.subscribe((info) => {
      if (parseInt(info) < 249) {
        this.totalPrice$ = parseInt(info + this.courierPrice).toFixed(2);
      } else {
        this.totalPrice$ = parseInt(info).toFixed(2);
      }
    });

    this.pixel.track('Purchase', {
      value: this.totalPrice$, // Value of all items
      currency: 'RON', // Currency of the value
    });
    //this.pixel.track('Purchase');

    this._OrderService.orderSucceded$.pipe(take(1)).subscribe((order) => {
      const productsItems = [];
      const productsItemsFacebook = [];
      order.products.map((product: any) => {
        productsItems.push({
          product_id: product.id,
          price:
            product.selectedSize.old_price > 0
              ? product.selectedSize.old_price
              : product.selectedSize.current_price,
          quantity: 1,
          variation_sku: `${product.id}-${product.selectedColor.color_name}-${product.selectedSize.size_name}`,
        });

        productsItemsFacebook.push({
          item_id: product.id,
          item_name: product.name,
          item_category:
            product.categories[0].subcategories[0].subcategory_name +
            ' ' +
            product.categories[0].category_name,
          item_price:
            product.selectedSize.old_price > 0
              ? product.selectedSize.old_price
              : product.selectedSize.current_price,
          item_quantity: 1,
        });
      });
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        event: 'purchase',
        ecommerce: {
          purchase: {
            actionField: {
              transaction_id: order.uuid, // Transaction ID. Required for purchases and refunds.
              revenue: order.total, // Total transaction value (incl. tax and shipping)
              shipping: order.total < 249 ? environment.courierPrice : 0,
              value: order.total,
              currency: 'RON',
              affiliation: 'Shardoray',
            },
            products: productsItemsFacebook, // Array of productFieldObjects.
          },
        },
      });

      (window as any).dataLayer.push({
        event: '__sm__order',
        number: order.uuid,
        email_address: order.email,
        phone: order.nationalNumber,
        firstname: order.name,
        lastname: order.surname,
        city: order.address.city,
        county: order.address.county,
        address: order.address,
        discount_value: order?.individual_discount
          ? this._CouponsService.individualDiscount$.value.coupon.value +
            (this._CouponsService.individualDiscount$.value.coupon
              .is_fixed_discount === 1
              ? 'Lei'
              : '%')
          : order?.discount_newsletter == 1
          ? '10%'
          : order?.discount_cart_products == 1
          ? '20%'
          : 0, // Final value of the discount, such as 10 EUR, without currency
        discount_code: order?.individual_discount
          ? this._CouponsService.individualDiscount$.value.coupon.code
          : order?.discount_newsletter == 1
          ? 'Abonare newsletter prima comanda'
          : order?.discount_cart_products == 1
          ? 'Trei sau mai multe produse adaugate in cos'
          : '', //If no discount code is used by the customer, set empty string
        shipping: order.total < 249 ? environment.courierPrice : 0,
        tax: 0,
        total_value: order.total,
        products: productsItems,
      });

      (window as any).dataLayer.push({
        event: '__sm__set_phone',
        phone: order.nationalNumber,
      });

      const orderSave = {
        k: environment.theMarketerApiKey,
        u: environment.theMarketerCustomerID,
        number: order.uuid,
        email_address: order.email,
        phone: order.nationalNumber,
        firstname: order.name,
        lastname: order.surname,
        city: order.address.city,
        county: order.address.county,
        address: order.address,
        discount_value: order?.individual_discount
          ? this._CouponsService.individualDiscount$.value.coupon.value +
            (this._CouponsService.individualDiscount$.value.coupon
              .is_fixed_discount === 1
              ? 'Lei'
              : '%')
          : order?.discount_newsletter == 1
          ? '10%'
          : order?.discount_cart_products == 1
          ? '20%'
          : 0, // Final value of the discount, such as 10 EUR, without currency
        discount_code: order?.individual_discount
          ? this._CouponsService.individualDiscount$.value.coupon.code
          : order?.discount_newsletter == 1
          ? 'Abonare newsletter prima comanda'
          : order?.discount_cart_products == 1
          ? 'Trei sau mai multe produse adaugate in cos'
          : '', //If no discount code is used by the customer, set empty string
        shipping: order.total < 249 ? environment.courierPrice : 0,
        tax: 0,
        total_value: order.total,
        products: productsItems,
      };

      this._httpClient.post(this.theMarketerSaveOrder, orderSave);
    });
  }
}
