import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ImagesService, ProductsService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  menDiscount: any;
  womanDiscount: number;
  menSmallerPrice: number;
  womanSmallerPrice: number;
  blackFriday: boolean;
  sliders: any;
  sliderImageUrl: string;

  constructor(
    private _ProductsService: ProductsService,
    private _ImagesService: ImagesService
  ) {
    this.blackFriday = environment.blackFriday;
    this.sliderImageUrl = environment.uploadImagesUrl;
  }

  customOptions: OwlOptions = {
    dots: false,

    nav: true,
    autoplay: true,
    autoplayTimeout: 4000,
    animateOut: 'fadeOut',
    navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
    loop: true,
    margin: 0,
    items: 1,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      800: {
        items: 1,
      },
      1024: {
        items: 1,
      },
    },
  };

  ngOnInit(): void {
    this._ProductsService.getBestCategoryDiscount('barbati').then((data) => {
      this.menDiscount = Math.ceil(
        100 - (parseInt(data.current_price) / parseInt(data.old_price)) * 100
      );
    });
    this._ProductsService.getBestCategoryDiscount('femei').then((data) => {
      this.womanDiscount = Math.ceil(
        100 - (parseInt(data.current_price) / parseInt(data.old_price)) * 100
      );
    });
    this._ProductsService.getSmallerCategoryPrice('barbati').then((data) => {
      this.menSmallerPrice = Math.ceil(data.current_price);
    });
    this._ProductsService.getSmallerCategoryPrice('femei').then((data) => {
      this.womanSmallerPrice = Math.ceil(data.current_price);
    });

    this._ImagesService.getSlidersLive().then((data) => {
      data.map((slider) => {
        if (slider.subtitle_discount)
          slider.subtitle_discount = slider.subtitle_discount.split(' ');

        if (slider.url) slider.url = slider.url.split('https://shardoray.ro');
      });

      this.sliders = data;
    });
  }
}
