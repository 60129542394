import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Product } from '../models';
import { ScriptLoaderService } from '../helpers/script-loader.service';
import { DataLayerService } from './dataLayer.service';
declare let dataLayer: any[];
@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  constructor(
    public router: Router, 
    private toaster: ToastrService, 
    private _DataLayeService: DataLayerService
    ) { }

  itemsFavorites$ = new BehaviorSubject<Product[]>([]);
  favoritesItems: any[] = [];

  
  toggleFavorites(product: any) {

    
    const storageKey = 'shardorayFavorites';
    let favoriteProducts: any[] = JSON.parse(localStorage.getItem(storageKey) || '[]');

    const isProductInFavorites = (existingProduct: any) =>
      existingProduct.id === product.id &&
      existingProduct.selectedColor.ColorID === product.selectedColor.ColorID;

    const existingIndex = favoriteProducts.findIndex(isProductInFavorites);

    product.addedToFavorites = !product.addedToFavorites;

    if (existingIndex !== -1) {
      favoriteProducts.splice(existingIndex, 1);

      product.selectedColor.addedToFavorites = false;
      this._DataLayeService.reinitializeScript();
      (window as any).dataLayer.push({
        event: '__sm__remove_from_wishlist',
        product_id: product.id,
        variation: {
          id: `${product.id}-${product.selectedColor.ColorID}-${product.selectedSize.SizeID}`,
          sku: `${product.id}-${product.selectedColor.color_name}-${product.selectedSize.size_name}`
        }
      });
    } else {
      this._DataLayeService.reinitializeScript();
      dataLayer.push({
        event: '__sm__add_to_wishlist',
        product_id: product.id,
        variation: {
          id: `${product.id}-${product.selectedColor.ColorID}-${product.selectedSize.SizeID}`,
          sku: `${product.id}-${product.selectedColor.color_name}-${product.selectedSize.size_name}`
        }
      });
      product.selectedColor.addedToFavorites = true;
      favoriteProducts.push({ ...product });
    }

    localStorage.setItem(storageKey, JSON.stringify(favoriteProducts));
    this.itemsFavorites$.next(favoriteProducts);
  }

  fetchFavorites() {
    this.favoritesItems = JSON.parse(
      localStorage.getItem('shardorayFavorites') || '[]'
    );
    this.itemsFavorites$.next(this.favoritesItems);
  }

  setFavorites(product: any): void {
    this.itemsFavorites$
      .pipe(
        take(1),
        map((products: any[]) => {
          products.forEach((existingProduct) => {
            if (existingProduct.id === product.id) {
              product.addedToFavorites = true;
            }

            if (existingProduct.id === product.id && existingProduct.selectedColor.ColorID === product.selectedColor.ColorID) {
              product.selectedColor.addedToFavorites = true;
            } 
          });
        })
      )
      .subscribe();
  }
}
