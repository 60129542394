<div class="order-summary">
    <h3>Detalii comanda</h3>

    <h4>
      <a [class.collapsed]="!isCollapsed" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
        aria-controls="collapseExample">{{(itemsInCart$ | async).length > 1 ? (itemsInCart$ | async).length + ' produse in cos' : (itemsInCart$ | async).length + ' produs in cos'}}</a>
    </h4>

    <div class="collapse" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <table class="table table-mini-cart">
        <tbody>
          <tr *ngFor="let product of itemsInCart$ | async">
            <td class="product-col">
              <figure class="product-image-container">
                <a routerLink="/produs/{{product?.alias}}/{{product?.id}}" class="product-image">

                  <img src="{{uploadedImgUrl}}{{product?.selectedImageObj}}" alt="image">
                </a>
                <span class="discount-applied" *ngIf="product?.fullPrice != product?.selectedPrice">-20%</span>
              </figure>
              <div>
                <h2 class="product-title">

                  <a routerLink="/produs/{{product?.alias}}/{{product?.id}}">{{product?.name}}</a>
                  <span class="type-image" *ngIf="product?.selectedColor.color_list.length == 1"
                    [style.background-color]="product?.selectedColor.color_list[0]"></span>
                  <span class="type-image" *ngIf="product?.selectedColor.color_list.length == 0"
                    style="background-color: #fff;">{{product.selectedSize.size_name}}</span>
                  <div class="two-colors" *ngIf="product?.selectedColor.color_list.length > 1">
                    <span [style.background-color]="product?.selectedColor.color_list[0]"></span>
                    <span [style.background-color]="product?.selectedColor.color_list[1]"></span>
                  </div>
                  <span class="size-text">
                    {{product.selectedSize.size_name}}
                  </span>
                </h2>
                <span class="product-qty">Cnt: {{product?.num}} x <del
                    *ngIf="product.hasDiscount">{{product?.fullPrice}} lei</del> {{product?.selectedPrice}}
                  lei</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>