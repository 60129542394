import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SizesService {

  private _apiUrl: string;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */

  constructor(private _httpClient: HttpClient) {
    this._apiUrl = environment.apiUrl;
  }


  /**
   * Get sizes
   *
   * @returns {Promise<any>}
   */

   public getSizes(): Promise<any> {

    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'sizes/sizes/getAllSizes')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get sizes
   *
   * @returns {Promise<any>}
   */

   public getAllSizes(): Promise<any> {

    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'sizes/sizes/getAllSizes')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get color
   *
   * @returns {Promise<any>}
   */

  public getSize(sizeID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'size/' + sizeID)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get sizes
   *
   * @returns {Promise<any>}
   */

  public getSizesByCategory(categoryAlias): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'categories/' + categoryAlias + '/sizes')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get sizes
   *
   * @returns {Promise<any>}
   */

  public getSizesByCategoryAndSubcategory(categoryAlias, subcategoryAlias): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'categories/' + categoryAlias + '/sizes/' + subcategoryAlias)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get product sizes
   *
   * @returns {Promise<any>}
   */

  public getProductSizes(productID, colorID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'sizes/productSizes/' + productID + '/' + colorID)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get sizes by color
   *
   * @returns {Promise<any>}
   */

  public getSizesByColor(colorID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'sizes/getSizesByColor/' + colorID)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get sizes by category, subcategory and color
   *
   * @returns {Promise<any>}
   */

  public getSizesByCategorySubcategoryAndColor(categoryID, subcategoryID, colorID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'sizes/getSizesByCategorySubcategoryAndColor/' + categoryID + '/' + subcategoryID + '/' + colorID )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Add product sizes
   *
   * @returns {Promise<any>}
   */

  public addNewProductSize(productID, colorID, sizeID, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'sizes/addProductSizes', { 'ProductID': productID, 'ColorID': colorID, 'SizeID': sizeID}, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Remove product sizes
   *
   * @returns {Promise<any>}
   */

  public removeProductSize(productID, colorID, sizeID, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'sizes/removeProductSizes', { 'ProductID': productID, 'ColorID': colorID, 'SizeID': sizeID}, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  public getMockSizes() {
    return environment.sizes;
  }

  /**
   * Get all sizes
   *
   * @returns {Promise<any>}
   */

   public getAdminSizes(): Promise<any> {

    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'sizes/admin/sizes')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Add size
   *
   * @returns {Promise<any>}
   */

   public addAdminSize(size_name, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'sizes/admin/addSize', {'size_name': size_name}, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Update size name
   *
   * @returns {Promise<any>}
   */

  public updateSizeName(sizeID, size_name, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'sizes/admin/updateSizeName', {'sizeID': sizeID, 'size_name': size_name}, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

}
