import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  private _apiUrl: string;
  private _uploadImages: string;
  private _deleteImage: any;
  private _uploadSlider: string;
  private _uploadCategoriesImages: string;


  constructor(private _httpClient: HttpClient) {
    this._apiUrl = environment.apiUrl;
    this._uploadImages = environment.uploadImages;
    this._uploadCategoriesImages = environment.uploadCategoriesImages;
    this._uploadSlider = environment.uploadSlider;
    this._deleteImage = environment.deleteImage;
  }

  /**
    * Get images
    *
    * @returns {Promise<any>}
    */
  getProductImages(productID, colorID: number): Promise<any> {
    console.log(colorID, productID)
    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'images/productImages/' + productID + '/' + colorID)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });

  }

  /**
    * Fetch images
    *
    * @returns {Promise<any>}
    */
  fetchProductImages(productID, colorID) {
    return this._httpClient.get(this._apiUrl + 'images/productImages/' + productID + '/' + colorID);
  }

  /**
   * Add product colors
   *
   * @returns {Promise<any>}
   */

  public addNewProductImage(productID, addNewProductColor, url, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'images/addProductImages', { 'productID': productID, 'colorID': addNewProductColor, 'url': url }, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Add product colors
   *
   * @returns {Promise<any>}
   */

  public subcategoriesImages(subcategoryID, url, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put(this._apiUrl + 'images/subcategoriesImages', { 'subcategoryID': subcategoryID, 'subcategory_image': url }, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Add product images
   *
   * @returns {Promise<any>}
   */

  public uploadImages(images): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._uploadImages, images)

        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Add product images
   *
   * @returns {Promise<any>}
   */

  public uploadCategoriesImages(images): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._uploadCategoriesImages, images)

        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Add product images
   *
   * @returns {Promise<any>}
   */

  public deleteProductImageFromServer(image): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._deleteImage, { 'image': image })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Add product images
   *
   * @returns {Promise<any>}
   */

  public deleteProductImage(productID, colorID, image, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'images/deleteProductImage', { 'productID': productID, 'colorID': colorID, 'image': image }, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Upload slider image
   *
   * @returns {Promise<any>}
   */

  public uploadSlider(image): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._uploadSlider, image)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Delete slider image
   *
   * @returns {Promise<any>}
   */

  public deleteSliderImage(image): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._deleteImage, { 'image': image })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Add slider
   *
   * @returns {Promise<any>}
   */

  public addSlider(slider, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'theme_images/sliderAdd',
        {
          'image': slider.image,
          'image_mobile': slider.image_mobile,
          'title': slider.title,
          'discount': slider.discount,
          'subtitle': slider.subtitle,
          'subtitle_discount': slider.subtitle_discount,
          'button_text': slider.button_text,
          'url': slider.url,
          'active': slider.active,
        }, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Edit slider
   *
   * @returns {Promise<any>}
   */

  public editSlider(slider, options): Promise<any> {

    return new Promise((resolve, reject) => {
      this._httpClient.put(this._apiUrl + 'theme_images/sliderEdit',
        {
          'id': slider.id,
          'image': slider.image,
          'image_mobile': slider.image_mobile,
          'title': slider.title,
          'discount': slider.discount,
          'subtitle': slider.subtitle,
          'subtitle_discount': slider.subtitle_discount,
          'button_text': slider.button_text,
          'url': slider.url,
          'active': slider.active,
        }, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Edit status
   *
   * @returns {Promise<any>}
   */

  public changeSliderActive(slider, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put(this._apiUrl + 'theme_images/sliderUpdateStatus',
        {
          'id': slider.id,
          'active': slider.active,
        }, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
    * Get slider
    *
    * @returns {Promise<any>}
    */
  getSlider(id, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'theme_images/getSlider/' + id, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });

  }
  /**
    * Get sliders
    *
    * @returns {Promise<any>}
    */
  getSliders(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'theme_images/sliders')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });

  }
  /**
    * Get sliders live
    *
    * @returns {Promise<any>}
    */
  getSlidersLive(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'theme_images/slidersLive')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });

  }
}
