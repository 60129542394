<ng-template #modalData let-modal>
  <div class="newsletter-popup" id="newsletter-popup-form"
    style="background: #f1f1f1">
    <div class="newsletter-popup-content">
      <button (click)="modal.dismiss('Cross click')" type="button" class="mfp-close">×</button>
      <img height="100" src="/assets/images/logo.png" alt="Logo" class="logo-newsletter">
      <h2>Fii primul care afla despre reducerile si ofertele noastre</h2>
      <p>Aboneaza-te la newsletter-ul nostru si vrei primi o reducere la 10% la prima comanda peste 89 Lei!</p>

      <form action="#">
        <div class="input-group">
          <input type="email" name="newletterEmail" [(ngModel)]="newletterEmail" class="form-control"
            placeholder="Adauga email..." required>

          <button type="submit" (click)="addToNewsletter(); modal.dismiss('Cross click')" class="btn"><i class="fas fa-envelope"></i></button>
        </div><!-- End .from-group -->
      </form>
      <div class="newsletter-subscribe">
        <div class="checkbox">
          <label (click)="hidePopup(); modal.dismiss('Cross click')">
            <input type="checkbox" value="1">
            Nu-mi mai arata acest pop-up
          </label>
        </div>
      </div>
    </div><!-- End .newsletter-popup-content -->
  </div><!-- End .newsletter-popup -->
</ng-template>
