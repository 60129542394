import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  distinctUntilChanged,
  map,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  AuthAPIService,
  AuthResponseData,
  CartService,
  NewsletterService,
  ProductsService,
  UserService,
} from 'src/app/services';
import { environment } from 'src/environments/environment';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Product } from 'src/app/models';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { OrdersService } from 'src/app/services/orders.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { PaymentMethodService } from './payment-method/payment-method.service';
import { DeliveryMethodService } from './delivery-method/delivery-method.service';
import { DeliveryAddressComponent } from './delivery-address/delivery-address.component';
import { DeliveryAddressService } from './delivery-address/delivery-address.service';
import { CheckoutService } from './checkout.service';
import { CouponsService } from '../cart/coupons/coupons.service';
import { Coupon } from '../cart/coupons/coupons.type';
import { DataLayerService } from 'src/app/services/dataLayer.service';
// import { SocialLoginModule, SocialAuthService, FacebookLoginProvider } from '@abacritt/angularx-social-login';
declare let gtag: Function;
declare let dataLayer: any[];

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  providers: [
    // SocialLoginModule,
    AuthAPIService,
    UserService,
  ],
})
export class CheckoutComponent implements OnInit {
  //@ViewChild('f') formSubmit: NgForm;

  @ViewChild('formRef')
  deliveryAddressComponent!: DeliveryAddressComponent;

  private _apiUrl: string;
  courierPrice: number;

  public items$ = this._CartService.items$;

  cartTotal$: any;
  cartProducts: any;
  public isCollapsed = true;

  model: any = {};
  public userPostData = {
    email: '',
    name: '',
    provider: '',
    provider_id: '',
    provider_pic: '',
    token: '',
  };
  public responseData: any;
  euplatescURL: string = '';

  submitted: boolean;

  order: any = {};
  products: Product[];

  isAuthentificated: boolean;
  private userSub: Subscription;
  userID: number;
  uploadedImgUrl: string;
  userAccess: number;
  postalCode: boolean = false;
  validate: any = [];
  checkIfStocValue: boolean = false;
  stocChecked: boolean = false;
  loading: boolean = false;
  uuid$: string;
  discount: string;

  discountCart: string;
  newsletterDiscountValue: boolean;
  totalPriceForDiscount: string;

  private _send_order_email: string;
  hasBeenRemoved: boolean = false;

  disabledSendOrder: boolean = false;
  euplatesctSend = environment.euplatesctSend;
  dataAll: any;
  showEPForm: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  payment$ = new BehaviorSubject<string>('');
  samedayDelivery$ = new BehaviorSubject<string>('');

  individualDiscountHasApplied$ = new BehaviorSubject<boolean>(false);
  firstOrderDiscountHasApplied$ = new BehaviorSubject<boolean>(false);
  thirdProductInCartHasDiscount$ = new BehaviorSubject<boolean>(false);
  rememberIndividualDiscountApplied$ = new BehaviorSubject<boolean>(false);
  remembeAboutTheFirstOrder$ = new BehaviorSubject<boolean>(false);
  discountCart$ = new BehaviorSubject<boolean>(false);
  totalPrice$ = new BehaviorSubject<number>(0);
  totalPriceWithCourier$ = new BehaviorSubject<number>(0);
  courierPrice$ = new BehaviorSubject<number>(0);
  individualDiscountValue$ = new BehaviorSubject<Coupon>({} as Coupon);
  redirectToPayment$ = new BehaviorSubject<boolean>(false);

  constructor(
    public _Router: Router,
    private _CartService: CartService,
    public authAPIService: AuthAPIService,
    // private SocialAuthService: SocialAuthService,
    public userService: UserService,
    public _OrderService: OrdersService,
    private _PaymentMethodService: PaymentMethodService,
    private _ProductsService: ProductsService,
    private _NewsletterService: NewsletterService,
    private _CouponsService: CouponsService,
    private _CheckoutService: CheckoutService,
    private _DeliveryMethodService: DeliveryMethodService,
    private _DataLayeService: DataLayerService,
    private toaster: ToastrService,
    private _httpClient: HttpClient,
    @Inject(DOCUMENT) private document: Document
  ) {
    this._apiUrl = environment.apiUrl;
    this.uploadedImgUrl = environment.uploadImagesUrl;
    this._send_order_email = environment.send_order_email;
    this.courierPrice = environment.courierPrice;
    this.payment$ = this._PaymentMethodService.payment$;

    this.thirdProductInCartHasDiscount$ =
      this._CartService.thirdProductInCartHasDiscount$;
    this.individualDiscountValue$ = this._CouponsService.individualDiscount$;
    this.rememberIndividualDiscountApplied$ =
      this._CouponsService.rememberIndividualDiscountApplied$;
    this.individualDiscountHasApplied$ =
      this._CouponsService.individualDiscountHasApplied$;
    this.firstOrderDiscountHasApplied$ =
      this._NewsletterService.firstOrderDiscountHasApplied$;
    this.remembeAboutTheFirstOrder$ =
      this._NewsletterService.remembeAboutTheFirstOrder$;
    this.discountCart$ = this._CheckoutService.discountCart$;
    this.totalPrice$ = this._CartService.totalPrice;
    this.totalPriceWithCourier$ = this._CartService.totalPriceWithCourier$;
    this.courierPrice$ = this._CartService.courierPrice$;
    this.samedayDelivery$ = this._DeliveryMethodService.samedayDelivery$;
  }

  ngOnInit(): void {
    this.items$.pipe(distinctUntilChanged()).subscribe((data) => {
      if (data.length == 0) {
        this._Router.navigate(['/']);
      } else {
        this.products = data;
      }
    });
    this._DataLayeService.reinitializeScript();
    (window as any).dataLayer.push({
      event: '__sm__initiate_checkout',
    });

    this._CheckoutService.totalPrice$
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        console.log;
      });

    this.userSub = this.authAPIService.user.subscribe((user) => {
      this.isAuthentificated = !!user;
      if (this.isAuthentificated) {
        this.userID = user.id;
        this.userAccess = user.access;
      }
    });

    this.payment$
      .pipe(
        distinctUntilChanged(),
        tap((value) => {
          console.log(value);
          value === 'card online'
            ? ((this.courierPrice = 12),
              this._CartService.courierPrice$.next(12))
            : [
                (this.courierPrice = 19),
                this._CartService.courierPrice$.next(19),
              ];
        })
      )
      .subscribe(() => {
        this._CartService.calcTotal();
      });

    this.initDataLayer();
  }

  // handleFormSubmit(form: UntypedFormGroup) {
  //   console.log(form)
  //   if (form.valid) {

  //   }
  // }

  initDataLayer() {
    const productsItems = [];

    this.products.map((product: any) => {
      productsItems.push({
        item_id: product.id,
        item_name: product.name,
        discount: product.selectedSize.current_price,
        item_category:
          product.categories[0].subcategories[0].subcategory_name +
          ' ' +
          product.categories[0].category_name,
        price:
          product.selectedSize.old_price > 0
            ? product.selectedSize.old_price
            : product.selectedSize.current_price,
        currency: 'RON',
        quantity: 1,
      });
    });

    dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        begin_checkout: {
          currency: 'RON',
          value: this.newsletterDiscountValue
            ? this.totalPriceWithCourier$.value
            : this.totalPriceForDiscount,
          items: productsItems,
        },
      },
    });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next('');
    this._unsubscribeAll.complete();
  }

  sendForm() {
    const formValues = this.deliveryAddressComponent.submitForm();
    const values = Object.values(formValues);

    if (values.some((value) => value === null)) {
      const scrollToBody = this.document.querySelector('.page-wrapper');
      scrollToBody.scrollTop = 0;
      this.toaster.error(
        ``,
        `Te rugam sa completezi toate campurile obligatorii!`,
        {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        }
      );

      return;
    }

    this._CartService
      .getCart()
      .pipe(take(1))
      .subscribe((message) => {
        if (message) {
          return;
        } else {
          this.order = [
            {
              individual_discount: '',
              total: this.totalPriceWithCourier$.value,
              method: this.payment$.value,
              products: this.items$.value,
              name: formValues.name,
              surname: formValues.surname,
              cart_uuid: this._CartService.cartUuid$.value,
              email: formValues.email,
              nationalNumber: formValues.phone,
              e164Number: formValues.phone.includes('+4')
                ? formValues.phone
                : '+4' + formValues.phone,
            },
          ];

          this.order[0].address = {
            name: '',
            address: formValues.address,
            city: formValues.city,
            url: '',
            county: formValues.county,
          };

          if (this.isAuthentificated && this.userAccess < 98) {
            this.order[0].user_id = this.userID;
          } else {
            this.order[0].user_id = 0;
          }

          if (this.firstOrderDiscountHasApplied$.value) {
            this.order[0].discount_newsletter = 1;
          }

          console.log(this.thirdProductInCartHasDiscount$.value);

          if (this.thirdProductInCartHasDiscount$.value) {
            this.order[0].discount_cart_products = 1;
          }

          if (this.individualDiscountHasApplied$.value) {
            this.order[0].individual_discount =
              this._CouponsService.individualDiscount$.value.coupon.code +
              ': -' +
              this._CouponsService.individualDiscount$.value.coupon.value +
              ' ' +
              (this._CouponsService.individualDiscount$.value.coupon
                .is_fixed_discount === 1
                ? 'Lei'
                : '%');
          }

          this._httpClient
            .post(
              this._apiUrl + 'orders/order/test/addTestComplementary',
              this.order
            )
            .subscribe((data: any) => {
              if (data.status == 'success') {
                if (this.firstOrderDiscountHasApplied$.value) {
                  this._NewsletterService.useDiscountFirtOrder(
                    formValues.email
                  );
                  this._NewsletterService.firstOrderDiscountHasApplied$.next(
                    false
                  );
                }

                if (this.individualDiscountHasApplied$.value) {
                  this._CouponsService.useIdividualDiscount(formValues.email);
                  this._CouponsService.individualDiscountHasApplied$.next(
                    false
                  );
                }
                this.order[0].uuid = data.uuid;
                this.order[0].discount = data.discount;

                this.order[0].date = data.date;

                this._httpClient
                  .post(this._send_order_email, this.order)
                  .subscribe();

                this.toaster.success('Iti multumim!', `${data.message}`, {
                  timeOut: 3000,
                  positionClass: 'toast-bottom-right',
                });

                this._ProductsService.getProductsAdminAll().then((data) => {
                  this._ProductsService.generateCatalogFeed(
                    this._ProductsService.cleanObjProductCatalog(data)
                  );
                });

                this._OrderService.orderSucceded$.next(this.order[0]);

                if (this.order[0].method == 'card online') {
                  let dataSend = {
                    amount: this.order[0].total,
                    invoice_id: this.order[0].uuid,
                    fname: this.order[0].surname,
                    lname: this.order[0].name,
                    city: this.order[0].address.city,
                    add: this.order[0].address.address,
                    email: this.order[0].email,
                    phone: this.order[0].nationalNumber,
                  };
                  this._httpClient
                    .post(this.euplatesctSend, dataSend)
                    .pipe(
                      take(1),
                      map((response: any) => {
                        this.euplatescURL = encodeURI(
                          response.url +
                            '&fname=' +
                            this.order[0].surname +
                            '&lname=' +
                            this.order[0].name +
                            '&city=' +
                            this.order[0].address.city +
                            '&add=' +
                            this.order[0].address.address +
                            '&email=' +
                            this.order[0].email +
                            '&phone=' +
                            this.order[0].nationalNumber +
                            '&sfname=' +
                            this.order[0].surname +
                            '&slname=' +
                            this.order[0].name +
                            '&scity=' +
                            this.order[0].address.city +
                            '&sadd=' +
                            this.order[0].address.address +
                            '&semail=' +
                            this.order[0].email +
                            '&sphone=' +
                            this.order[0].nationalNumber
                        );

                        this.dataAll = response;
                        this.showEPForm = true;
                        return response;
                      })
                    )
                    .subscribe((data: any) => {
                      if (data) {
                        this.redirectToPayment$.next(true);
                        setTimeout(() => {
                          this.toaster.success(
                            'Iti multumim!',
                            'Vei fi redirectionat catre EuPlatesc!',
                            {
                              timeOut: 3000,
                              positionClass: 'toast-bottom-right',
                            }
                          );

                          // this.submit();
                          this._CartService.emptyCart();
                          // f.reset();
                          window.location.href = this.euplatescURL;
                        }, 1000);
                      }
                    });
                } else {
                  // f.reset();
                  // this.order = [];
                  // this.selectedAddress = false;
                  // this.addressFormmated = {
                  //   name: '',
                  //   address: '',
                  //   url: '',
                  // };
                  this._CartService.emptyCart();
                  this._Router.navigate(['/comanda-finalizata']);
                }

                //}
                // })
              } else {
                this.toaster.error(
                  'Va rugam sa ne contactati',
                  `A aparut o eroare`,
                  {
                    timeOut: 8000,
                    positionClass: 'toast-bottom-right',
                  }
                );
              }
            });
        }
      });
  }

  // async sendOrder(f: NgForm) {
  //   // this.disabledSendOrder = true;
  //   this.submitted = true;
  //   this.loading = true;

  //   if (this.deliveryAddressComponent.invalid) {
  //     this.toaster.error(
  //       ``,
  //       'Te rugam sa completezi toate campurile obligatorii!',
  //       {
  //         timeOut: 3000,
  //         positionClass: 'toast-bottom-right',
  //       }
  //     );
  //     this.loading = false;
  //     return;
  //   }

  //   if (!this.payment$.getValue()) {
  //     this.toaster.warning(
  //       'Comanda nu poate fi trimisa!',
  //       'Te rugam sa alegi metoda de plata!',
  //       {
  //         timeOut: 3000,
  //         positionClass: 'toast-bottom-right',
  //       }
  //     );
  //     this.loading = false;
  //     return;
  //   }

  //   this._CartService.getCart().subscribe((message) => {
  //     if (message) {
  //       return;
  //     } else {
  //       this.order = [
  //         {
  //           total: this.newsletterDiscountValue
  //             ? this.totalPriceWithCourier
  //             : this.totalPriceForDiscount,
  //           method: this.payment$.getValue(),
  //           products: this.products,
  //           name: this.form.value.name,
  //           surname: this.form.value.surname,
  //           cart_uuid: this.uuid$,
  //           email: this.form.value.email,
  //           nationalNumber: this.form.value.phone,
  //           e164Number: '+4' + this.form.value.phone,
  //         },
  //       ];

  //       this.order[0].address = {
  //         name: '',
  //         address: this.form.value.address,
  //         city: this.form.value.city,
  //         url: '',
  //         county: this.form.value.county,
  //       };

  //       if (this.isAuthentificated && this.userAccess < 98) {
  //         this.order[0].user_id = this.userID;
  //       } else {
  //         this.order[0].user_id = 0;
  //       }

  //       if (this.discount != null) {
  //         this._NewsletterService.useDiscountFirtOrder(this.form.value.email);
  //         this.order[0].discount_newsletter = 1;
  //       } else if (this.discountCart != null) {
  //         this.order[0].discount_cart_products = 1;
  //       }

  //       if (this.individualDiscount > 0) {
  //         this._NewsletterService.useIdividualDiscount(this.form.value.email);
  //       }

  //       this.order[0].individual_discount = this.individualDiscount;

  //       this._httpClient
  //         .post(
  //           this._apiUrl + 'orders/order/test/addTestComplementary',
  //           this.order
  //         )
  //         .subscribe((data: any) => {
  //           if (data.status == 'success') {
  //             this.order[0].uuid = data.uuid;
  //             this.order[0].discount = data.discount;

  //             this.order[0].date = data.date;

  //             this._httpClient
  //               .post(this._send_order_email, this.order)
  //               .subscribe();

  //             this.toaster.success('Iti multumim!', `${data.message}`, {
  //               timeOut: 3000,
  //               positionClass: 'toast-bottom-right',
  //             });

  //             this._ProductsService.getProductsAdminAll().then((data) => {
  //               this._ProductsService.generateCatalogFeed(
  //                 this._ProductsService.cleanObjProductCatalog(data)
  //               );
  //             });

  //             this._OrderService.orderSucceded$.next(this.order[0]);

  //             gtag('event', 'conversion', {
  //               send_to: 'AW-11077587507/2hBhCMSXuYkYELOkmqIp',
  //               value: this.order[0].total,
  //               currency: 'RON',
  //               transaction_id: this.order[0].uuid,
  //             });

  //             if (this.order[0].method == 'card online') {
  //               let dataSend = {
  //                 amount: this.order[0].total,
  //                 invoice_id: this.order[0].uuid,
  //                 fname: this.order[0].surname,
  //                 lname: this.order[0].name,
  //                 city: this.order[0].address.city,
  //                 add: this.order[0].address.address,
  //                 email: this.order[0].email,
  //                 phone: this.order[0].nationalNumber,
  //               };
  //               this._httpClient
  //                 .post(this.euplatesctSend, dataSend)
  //                 .pipe(
  //                   take(1),
  //                   map((response: any) => {
  //                     this.euplatescURL = encodeURI(
  //                       response.url +
  //                         '&fname=' +
  //                         this.order[0].surname +
  //                         '&lname=' +
  //                         this.order[0].name +
  //                         '&city=' +
  //                         this.order[0].address.city +
  //                         '&add=' +
  //                         this.order[0].address.address +
  //                         '&email=' +
  //                         this.order[0].email +
  //                         '&phone=' +
  //                         this.order[0].nationalNumber +
  //                         '&sfname=' +
  //                         this.order[0].surname +
  //                         '&slname=' +
  //                         this.order[0].name +
  //                         '&scity=' +
  //                         this.order[0].address.city +
  //                         '&sadd=' +
  //                         this.order[0].address.address +
  //                         '&semail=' +
  //                         this.order[0].email +
  //                         '&sphone=' +
  //                         this.order[0].nationalNumber
  //                     );

  //                     this.dataAll = response;
  //                     this.showEPForm = true;
  //                     return response;
  //                   })
  //                 )
  //                 .subscribe((data: any) => {
  //                   if (data) {
  //                     setTimeout(() => {
  //                       this.toaster.success(
  //                         'Iti multumim!',
  //                         'Vei fi redirectionat catre EuPlatesc!',
  //                         {
  //                           timeOut: 3000,
  //                           positionClass: 'toast-bottom-right',
  //                         }
  //                       );

  //                       // this.submit();
  //                       this._CartService.emptyCart();
  //                       f.reset();
  //                       window.location.href = this.euplatescURL;
  //                     }, 1000);
  //                   }
  //                 });
  //             } else {
  //               f.reset();
  //               this.order = [];
  //               this.selectedAddress = false;
  //               this.addressFormmated = {
  //                 name: '',
  //                 address: '',
  //                 url: '',
  //               };
  //               this._CartService.emptyCart();
  //               this._Router.navigate(['/comanda-finalizata']);
  //             }

  //             //}
  //             // })
  //           } else {
  //             this.toaster.error(
  //               'Va rugam sa ne contactati',
  //               `A aparut o eroare`,
  //               {
  //                 timeOut: 8000,
  //                 positionClass: 'toast-bottom-right',
  //               }
  //             );
  //           }
  //         });
  //     }
  //   });
  // }

  submit() {
    const epForm = <HTMLFormElement>document.getElementById('epForm');

    epForm.submit();
  }
}
