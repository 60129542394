import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddedToCartComponent } from '../components/added-to-cart/added-to-cart.component';
import { ProductQuickviewComponent } from '../components/product-quickview/product-quickview.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReplacePipe } from '../directives/replace-pipe';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
// import { ScullyLibModule } from '@scullyio/ng-lib';


@NgModule({
  declarations: [
    AddedToCartComponent,
    ProductQuickviewComponent,
    ReplacePipe
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    RouterModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    CarouselModule,
    FormsModule,
    NgxPaginationModule,
    // ScullyLibModule,
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    AddedToCartComponent, 
    ProductQuickviewComponent, 
    ShareButtonsModule,
    CarouselModule,
    NgxPaginationModule,
    ReplacePipe,
    // ScullyLibModule
  ]
})
export class SharedModule { }
