<nav aria-label="breadcrumb" class="breadcrumb-nav">
  <div class="container">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Acasa</a></li>
      <li class="breadcrumb-item active" aria-current="page">
        Finalizarea comenzii
      </li>
    </ol>
  </div>
  <!-- End .container -->
</nav>
<div class="mt-6"></div>
<!-- margin -->
<div class="container checkout-bg">
  <div class="row">
    <div class="col-lg-12">
      <app-checkout-login-form
        *ngIf="!isAuthentificated"
      ></app-checkout-login-form>
    </div>

    <div class="col-lg-12">
      <app-delivery-method></app-delivery-method>
    </div>
    <div class="col-lg-12">
      <app-delivery-address #formRef></app-delivery-address>
    </div>
    <div class="col-lg-12">
      <app-order-summary></app-order-summary>
    </div>
    <div class="col-lg-12">
      <app-payment-method></app-payment-method>
    </div>
  </div>
</div>
<!-- End .container -->

<div class="info-checkout container">
  <div class="row">
    <div class="col-lg-12">
      <div
        class="info-box"
        *ngIf="
          !(thirdProductInCartHasDiscount$ | async) &&
          !(individualDiscountHasApplied$ | async) &&
          !(firstOrderDiscountHasApplied$ | async)
        "
      >
        <img src="/assets/images/icons/info.svg" alt="info" />
        <p>
          <span class="info-danger"
            >Primesti o reducere -20% la al treilea produs adaugat in cos!</span
          >
        </p>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="info-box" *ngIf="(totalPrice$ | async) < 249">
        <img src="/assets/images/icons/info.svg" alt="info" />
        <p class="info info-danger">
          Livrare gratuita daca valoarea cosului este mai mare de 249 Lei!
        </p>
      </div>
    </div>
    <div class="col-lg-12">
      <div
        class="info-box"
        *ngIf="
          (rememberIndividualDiscountApplied$ | async) ||
          ((remembeAboutTheFirstOrder$ | async) && (totalPrice$ | async) < 89)
        "
      >
        <img src="/assets/images/icons/info.svg" alt="info" />
        <p class="info info-danger">
          Valoarea comenzii trebuie sa fie mai mare de 89 Lei pentru a beneficia
          de reducere!
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-lg-6">
      <div class="checkout-info-box">
        <h3>Sumar comanda</h3>

        <p c>Cost produse: {{ totalPrice$ | async }} lei</p>
        <p
          *ngIf="
            ((discountCart$ | async) &&
              (!firstOrderDiscountHasApplied$ | async)) ||
            (!individualDiscountHasApplied$ | async)
          "
        >
          Reducere: -20% la al treilea produs din cos
        </p>
        <p *ngIf="firstOrderDiscountHasApplied$ | async">
          Reducere: -10% la prima comanda
        </p>
        <p *ngIf="individualDiscountHasApplied$ | async">
          Reducere:
          {{
            individualDiscountHasApplied$.value
              ? "-" +
                individualDiscountValue$.value.coupon.value +
                " " +
                (individualDiscountValue$.value.coupon.is_fixed_discount === 1
                  ? "Lei"
                  : "%")
              : ""
          }}
        </p>
        <p>
          Cost livrare:
          {{ (totalPrice$ | async) < 249 ? (courierPrice$ | async) : 0 }} lei
        </p>
      </div>
    </div>
    <div class="col-xs-12 col-lg-6">
      <app-coupons-discount></app-coupons-discount>
      <h3 class="total">Total: {{ totalPriceWithCourier$ | async }} lei</h3>
      <div class="checkout-steps-action">
        <button
          [disabled]="redirectToPayment$ | async"
          (click)="sendForm()"
          class="btn btn-primary float-right btn-green"
        >
          PLASEAZA COMANDA
        </button>
      </div>
      <!-- End .checkout-steps-action -->
    </div>
  </div>

  <!-- End .col-lg-8 -->
</div>

<div class="mb-6"></div>
<!-- margin -->
<!---->
