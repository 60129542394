<ng-template #modalData let-modal>
  <div class="modal fade custom-modal" id="addCartModal" tabindex="-1" role="dialog" aria-labelledby="addCartModal"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body add-cart-box text-center">
          <p>Ai adaugat acest produs in cos:</p>
          <h4 id="productTitle">{{product.name}}</h4>
          <img src="{{uploadedImgUrl}}{{product.selectedImageObj}}" alt="image">
          

          <div class="selected-color-container">
            <p class="color-container">Culoare:</p>
            <span class="type-image" *ngIf="product?.selectedColor.color_list.length == 1"
              [style.background-color]="product?.selectedColor.color_list[0]"></span>
            <span class="type-image" *ngIf="product?.selectedColor.color_list.length == 0"
              style="background-color: #fff;">{{product.selectedSize.size_name}}</span>
            <div class="two-colors" *ngIf="product?.selectedColor.color_list.length > 1">
              <span [style.background-color]="product?.selectedColor.color_list[0]"></span>
              <span [style.background-color]="product?.selectedColor.color_list[1]"></span>
            </div>
          </div>

          <p>Marime: {{product?.selectedSize.size_name}}</p>
          <div class="btn-actions">

            <a (click)="modal.dismiss('Cross click')" routerLink="/cos-cumparaturi"><button class="btn-primary">Mergi la
                cos</button></a>
            <a (click)="modal.dismiss('Cross click')"><button class="btn-primary"
                data-dismiss="modal">Continua</button></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
