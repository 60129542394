import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ColorsService {

  private _apiUrl: string;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */

  constructor(private _httpClient: HttpClient) {
    this._apiUrl = environment.apiUrl;
  }

  /**
   * Get colors
   *
   * @returns {Promise<any>}
   */

  public getColors(): Promise<any> {

    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'colors/colors/getAllColors')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get color
   *
   * @returns {Promise<any>}
   */

  public getColor(colorID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'color/' + colorID)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Add color
   *
   * @returns {Promise<any>}
   */

  public addColor(color_name, color, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'colors/admin/addColor', {'color_name': color_name, 'color': color}, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Update color name
   *
   * @returns {Promise<any>}
   */

  public updateColorName(colorID, color_name, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'colors/admin/updateColorName', {'colorID': colorID, 'color_name': color_name}, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Update color
   *
   * @returns {Promise<any>}
   */

  public updateColor(colorID, color, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'colors/admin/updateColor', {'colorID': colorID, 'color': color}, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * delete color
   *
   * @returns {Promise<any>}
   */

  public deleteColor(colorID, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'colors/admin/deleteColor', {'colorID': colorID}, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get product colors
   *
   * @returns {Promise<any>}
   */

  public getProductColors(productID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'colors/productColors/' + productID)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Fetch product colors
   *
   * @returns {Promise<any>}
   */

  public fetchProductColors(productID) {
    return this._httpClient.get(this._apiUrl + 'colors/productColors/' + productID)
  }

  /**
   * Add product colors
   *
   * @returns {Promise<any>}
   */

  public addNewProductColor(productID, addNewProductColor, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'colors/add', { 'ProductID': productID, 'ColorID': addNewProductColor}, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Remove product colors
   *
   * @returns {Promise<any>}
   */

  public removeProductColor(productID, addNewProductColor, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'colors/delete', { 'ProductID': productID, 'ColorID': addNewProductColor}, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get colors
   *
   * @returns {Promise<any>}
   */

   public getColorsByCategory(categoryID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'categories/' + categoryID + '/colors')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get colors
   *
   * @returns {Promise<any>}
   */

   public getColorsByCategoryAndSubcategory(categoryAlias, subcategoryAlias): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this._apiUrl + 'categories/' + categoryAlias + '/colors/' + subcategoryAlias)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  // public getMockColors() {
  //   return environment.colors;
  // }
}
