import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ToastrModule } from 'ngx-toastr';
// import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxPaginationModule } from 'ngx-pagination';
// import { GooglePlaceModule } from "ngx-google-places-autocomplete";
// import { NgxParallaxScrollModule } from 'ngx-parallax-scroll';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { MustMatchDirective } from './directives/must-match.directive';

import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from 'ngx-cookieconsent';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SelectDropDownModule } from 'ngx-select-dropdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { HeaderComponent } from './components/header/header.component';
import { SliderComponent } from './components/slider/slider.component';
import { FooterComponent } from './components/footer/footer.component';
import { NewsletterPopupComponent } from './components/newsletter-popup/newsletter-popup.component';
import { ProductQuickviewComponent } from './components/product-quickview/product-quickview.component';
import { ScheduledCategoriesComponent } from './components/scheduled-categories/scheduled-categories.component';
import { ProductsComponent } from './pages/products/products.component';
import { DiscountProductsComponent } from './components/discount-products/discount-products.component';
import { ProductComponent } from './pages/product/product.component';
import { AddedToCartComponent } from './components/added-to-cart/added-to-cart.component';
import { UtilityService } from './services/utility.service';
import { FACEBOOK_PIXEL_PROVIDER } from './services/facebook-pixel.provider';

import { CartComponent } from './pages/cart/cart.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';

import { CategoriesComponent } from './components/categories/categories.component';
import { SeasonProductsComponent } from './components/season-products/season-products.component';
import { LoginComponent } from './pages/login/login.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { AddressesComponent } from './pages/my-account/addresses/addresses.component';
import { CouponsComponent } from './pages/my-account/coupons/coupons.component';
import { MyOrdersComponent } from './pages/my-account/my-orders/my-orders.component';
import { OrderDetailsComponent } from './pages/my-account/order-details/order-details.component';

import { MeasurementsComponent } from './pages/measurements/measurements.component';
import { ConfidentialityPolicyComponent } from './pages/confidentiality-policy/confidentiality-policy.component';
import { HowToPayComponent } from './pages/how-to-pay/how-to-pay.component';
import { HowToDeliverComponent } from './pages/how-to-deliver/how-to-deliver.component';
import { TermsComponent } from './pages/terms/terms.component';
import { ReturnComponent } from './pages/return/return.component';
import { GdprComponent } from './pages/gdpr/gdpr.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ReturnFormComponent } from './pages/return-form/return-form.component';
import { PixelModule } from 'ngx-pixel';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { WhatsappComponent } from './components/whatsapp/whatsapp.component';
import { ReplacePipe } from './directives/replace-pipe';
import { DropdownProductsComponent } from './components/dropdown-products/dropdown-products.component';
import { SelectedProductsPipe } from './pages/return-form/selected-products.pipe';
import { InvalidPaymentComponent } from './pages/invalid-payment/invalid-payment.component';
import { TestProductsComponent } from './pages/test-products/test-products.component';
import { SelectedProductsComponent } from './components/selected-products/selected-products.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { ConfirmationAccountComponent } from './pages/confirmation-account/confirmation-account.component';
import { VerificationEmailComponent } from './pages/verification-email/verification-email.component';
import { TitleService } from './services/title.service';

import { ProductsModule } from './pages/products/products.module';
import { SliderModule } from './components/slider/slider.module';
import { HomepageModule } from './pages/homepage/homepage.module';
import { SelectedProductsModule } from './components/selected-products/selected-products.module';
import { RecommendedProductsModule } from './components/recommended-products/recommended-products.module';
import { DiscountProductsModule } from './components/discount-products/discount-products.module';
import { CategoriesModule } from './components/categories/categories.module';
// import { ScullyLibModule } from '@scullyio/ng-lib';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
//import { ServerModule } from '@angular/platform-server';
// import { FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { NgxSsrTimeoutModule } from '@ngx-ssr/timeout';
import { TestProductsModule } from './pages/test-products/test-products.module';
import { CloudinaryModule } from '@cloudinary/ng';
import { OrderSummaryModule } from './pages/checkout/order-summary/order-summary.module';
import { DeliveryMethodModule } from './pages/checkout/delivery-method/delivery-method.module';
import { PaymentMethodModule } from './pages/checkout/payment-method/payment-method.module';
import { CheckoutLoginFormModule } from './pages/checkout/checkout-login-form/checkout-login-form.module';
import { DeliveryAddressModule } from './pages/checkout/delivery-address/delivery-address.module';
import { CouponsModule } from './pages/cart/coupons/coupons.module';
import { DataLayerService } from './services/dataLayer.service';
import { DataLayerInterceptor } from './helpers/datalayer-interceptor';
import { ScriptLoaderService } from './helpers/script-loader.service';
import {
  GoogleTagManagerModule,
  GoogleTagManagerService,
} from 'angular-google-tag-manager';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain,
  },
  palette: {
    popup: {
      background: '#222529',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#e1ddc3',
      text: '#000000',
      border: 'transparent',
    },
  },

  content: {
    message:
      'Acest site web folosește cookie-uri pentru a îmbunătăți experiența utilizatorului. Prin utilizarea site-ului nostru web, sunteți de acord cu Politica noastră privind cookie-urile.',
    dismiss: 'Am inteles!',
    deny: 'Refuse cookies',
    link: 'Afla mai multe',
    href: '/gdpr-cookies',
    policy: 'Cookie Policy',
  },
  theme: 'classic',
  position: 'bottom',
  type: 'info',
};

@NgModule({
  declarations: [
    AppComponent,
    // HomepageComponent,
    HeaderComponent,
    // SliderComponent,
    FooterComponent,
    NewsletterPopupComponent,
    // ProductQuickviewComponent,
    ScheduledCategoriesComponent,
    // ProductsComponent,
    // DiscountProductsComponent,
    // ProductComponent,
    //AddedToCartComponent,
    CartComponent,
    CheckoutComponent,
    // AddressAutocompleteComponent,
    // RecommendedProductsComponent,
    // CategoriesComponent,
    SeasonProductsComponent,
    LoginComponent,
    MustMatchDirective,
    MyAccountComponent,
    AddressesComponent,
    CouponsComponent,
    MyOrdersComponent,
    OrderDetailsComponent,
    MeasurementsComponent,
    ConfidentialityPolicyComponent,
    HowToPayComponent,
    HowToDeliverComponent,
    TermsComponent,
    ReturnComponent,
    GdprComponent,
    ContactComponent,
    ReturnFormComponent,
    ThankYouComponent,
    WhatsappComponent,
    // ReplacePipe,
    DropdownProductsComponent,
    SelectedProductsPipe,
    InvalidPaymentComponent,
    // TestProductsComponent,
    // SelectedProductsComponent,
    // NewsletterComponent,
    ConfirmationAccountComponent,
    VerificationEmailComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

    //ServerModule,
    // CarouselModule,
    NgxImageZoomModule,

    ShareIconsModule,
    ToastrModule.forRoot(),
    // NgxSliderModule,
    // NgxPaginationModule,
    // GooglePlaceModule,
    AppRoutingModule,
    // NgxParallaxScrollModule,
    BsDropdownModule.forRoot(),
    // SocialLoginModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    Ng2SearchPipeModule,
    SelectDropDownModule,
    PixelModule.forRoot({ enabled: true, pixelId: '689268724987684' }),
    SharedModule,

    // TestProductsModule,
    ProductsModule,
    HomepageModule,
    RecommendedProductsModule,
    // DiscountProductsModule,
    CategoriesModule,
    // ScullyLibModule,
    // NgxSsrTimeoutModule.forRoot({ timeout: 500 }),
    CloudinaryModule,
    OrderSummaryModule,
    DeliveryMethodModule,
    PaymentMethodModule,
    CheckoutLoginFormModule,
    DeliveryAddressModule,
    CouponsModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-W357LFB',
    }),
  ],
  exports: [
    // TestProductsModule,
    ProductsModule,
    HomepageModule,
    RecommendedProductsModule,
    DiscountProductsModule,
    CategoriesModule,
  ],
  providers: [
    Title,
    // {
    //   provide: 'SocialAuthServiceConfig',
    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       {
    //         id: FacebookLoginProvider.PROVIDER_ID,
    //         provider: new FacebookLoginProvider('4539211849422663')
    //       }
    //     ]
    //   } as SocialAuthServiceConfig,
    // },
    UtilityService,
    // FACEBOOK_PIXEL_PROVIDER
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DataLayerInterceptor,
      multi: true,
    },

    DataLayerService,
    ScriptLoaderService,
    // { provide: 'googleTagManagerId', useValue: 'GTM-W357LFB' },
    GoogleTagManagerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(public titleService: TitleService) {}
}
