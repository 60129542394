import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  public admin = new BehaviorSubject<string>('/');

  constructor() { 
  
  }

  public statusMobile = new BehaviorSubject<any>(false);
  

  toggleMobileMenu(status) {
    this.statusMobile.next(status);
  }
}
