import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  take,
  tap,
} from 'rxjs';
import { CartService, NewsletterService } from 'src/app/services';
import { PaymentMethodService } from './payment-method/payment-method.service';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment.prod';
import { DeliveryAddressService } from './delivery-address/delivery-address.service';
import { CouponsService } from '../cart/coupons/coupons.service';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  totalPriceWithCourier$ = new BehaviorSubject<number>(0);
  thirdProductInCart$ = new BehaviorSubject<boolean>(false);
  courierPrice$ = new BehaviorSubject<number>(0);
  discountCart$ = new BehaviorSubject<boolean>(false);
  totalPrice$ = new BehaviorSubject<number>(0);

  constructor(
    private _CartService: CartService,
    private _PaymentMethodService: PaymentMethodService,
    private _DeliveryAddressService: DeliveryAddressService,
    private _NewsletterService: NewsletterService,
    private _CouponsService: CouponsService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.courierPrice$.next(environment.courierPrice);
  }

  initProperties() {
    combineLatest([
      this._CartService.thirdProductInCart$,
      this._CartService.items$,
      this._CartService.cartUuid$,
      this._CartService.totalPrice,
      this.courierPrice$,
      this._PaymentMethodService.payment$,
      this._DeliveryAddressService.individualDiscountHasApplied$,
      this._DeliveryAddressService.firstOrderDiscountHasApplied$,
      this._CouponsService.individualDiscount$,
    ])
      .pipe(
        take(1),
        map(
          ([
            thirdProductInCart$,
            items$,
            cartUuid$,
            totalPrice$,
            courierPrice$,
            payment$,
            individualDiscountHasApplied$,
            firstOrderDiscountHasApplied$,
            individualDiscount$,
          ]) => ({
            thirdProductInCart: thirdProductInCart$,
            items: items$,
            cartUuid: cartUuid$,
            totalPrice: totalPrice$,
            courierPrice: courierPrice$,
            payment: payment$,
            individualDiscountHasApplied: individualDiscountHasApplied$,
            firstOrderDiscountHasApplied: firstOrderDiscountHasApplied$,
            individualDiscountValue: individualDiscount$,
          })
        ),
        tap((data: any) => {
          if (data.payment == 'card online') {
            data.courierPrice = 12;
          }

          console.log(data.individualDiscount);
          if (
            data.individualDiscountHasApplied ||
            data.firstOrderDiscountHasApplied
          ) {
            if (data.thirdProductInCart) {

              let removeDiscountThirdProduct = data.items.find(
                (item) => item.hasDiscount
              );

              if (
                removeDiscountThirdProduct &&
                removeDiscountThirdProduct.hasDiscount
              ) {
                removeDiscountThirdProduct.hasDiscount = false;
                removeDiscountThirdProduct.selectedPrice =
                  removeDiscountThirdProduct.fullPrice;
                if (isPlatformBrowser(this.platformId)) {
                  localStorage.setItem(
                    'shardorayProducts',
                    JSON.stringify({
                      uuid: data.cartUuid,
                      products: data.items,
                    })
                  );
                  localStorage.setItem(
                    'extendedShardorayProducts',
                    JSON.stringify({
                      uuid: data.cartUuid,
                      products: data.items,
                    })
                  );
                }
                this._CartService.thirdProductInCartHasDiscount$.next(false);
                data.totalPrice = data.items.reduce(
                  (acc, pr) => (acc += pr.selectedPrice * pr.num),
                  0
                );
              }
            }
            let dataPrice = data.individualDiscountHasApplied
              ? Math.floor(
                  data.individualDiscountValue.coupon.is_fixed_discount
                    ? data.totalPrice -
                        data.individualDiscountValue.coupon.value
                    : data.totalPrice -
                        (data.totalPrice *
                          data.individualDiscountValue.coupon.value) /
                          100
                )
              : data.firstOrderDiscountHasApplied
              ? Math.floor(data.totalPrice - (data.totalPrice * 10) / 100)
              : data.totalPrice;

            this.totalPrice$.next(dataPrice);
            data.totalPrice = dataPrice;

            this.totalPriceWithCourier$.next(
              data.totalPrice < 249
                ? (data.totalPrice + data.courierPrice).toFixed(2)
                : data.totalPrice.toFixed(2)
            );
          } else {
            if (data.thirdProductInCart) {
              this.discountCart$.next(true);
            }
          }
        })
      )
      .subscribe(() => this._CartService.calcTotal());
  }
}
