import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/services';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-scheduled-categories',
  templateUrl: './scheduled-categories.component.html',
  styleUrls: ['./scheduled-categories.component.scss']
})
export class ScheduledCategoriesComponent implements OnInit {
  categories: {}[];
  categoriesRand: any[];
  uploadedImgUrl = environment.uploadImagesUrl;

  constructor(
    private _CategoriesService: CategoriesService,
  ) { }

  ngOnInit(): void {

    this.categories = [
      {
        'name': 'TRICOURI',
        'img': 'tricouri-femei.png',
      },
      {
        'name': 'COMPLEURI',
        'img': 'compleuri-femei.png',
      },
      {
        'name': 'PULOVERE',
        'img': 'pulovere-femei.png',
      },
      {
        'name': 'BLUZE',
        'img': 'bluze-femei.png',
      },
      {
        'name': 'HANORACE',
        'img': 'hanorace-femei.png',
      },
      {
        'name': 'ROCHII',
        'img': 'rochii-femei.png',
      },
      {
        'name': 'TOPURI',
        'img': 'topuri-femei.png',
      },
      {
        'name': 'PIJAMALE',
        'img': 'pijamale-femei.png',
      },
      {
        'name': 'COLANTI',
        'img': 'colanti-femei.png',
      },
      {
        'name': 'PANTALONI SPORT',
        'img': 'pantaloni-sport-femei.png',
      },
      {
        'name': 'JEANS',
        'img': 'blugi-femei.png',
      },
      {
        'name': 'TRENING',
        'img': 'trening-femei.png',
      },
      {
        'name': 'GECI',
        'img': 'geci-femei.png',
      },
      {
        'name': 'BODY',
        'img': 'body-femei.png',
      },
    ];

    this.getRandomCategories();
  }

  getRandomCategories() {
    this._CategoriesService.getRandomCategories().then(data => {
      this.categoriesRand = data;
    })
    // let categoriesArr = []
    // for (var i = 0; i < 3; i++) {
    //   let rand = this.categories[Math.floor(Math.random() * this.categories.length)];
    //   categoriesArr.push(rand);
    // }

   // this.categoriesRand = categoriesArr;
  }
}
