import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NewsletterService } from 'src/app/services';

@Component({
  selector: 'app-newsletter-popup',
  templateUrl: './newsletter-popup.component.html',
  styleUrls: ['./newsletter-popup.component.scss']
})
export class NewsletterPopupComponent implements OnInit {
  @ViewChild('modalData') modalContent;

  closeModal: string;

  /**
   * Constructor
   *
   * @param {NgbModal} _ModalService
   * 
   */

   newletterEmail: string;

  constructor(
    private _ModalService: NgbModal,
    private _NewsletterService: NewsletterService,
    private toaster: ToastrService,
  ) { }

  ngOnInit(): void {}

  triggerModal() {
    
    this._ModalService.open(this.modalContent, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  addToNewsletter() {
    this._NewsletterService.addToNewsletter(this.newletterEmail).then(data => {
      if (data.success) {
        this.toaster.success(``, `${data.message}`, {
          timeOut: 3000,
          positionClass: 'toast-bottom-right'
        });
      } else {
        this.toaster.warning(``, `${data.message}`, {
          timeOut: 3000,
          positionClass: 'toast-bottom-right'
        });
      }
      this.newletterEmail = '';
    })
  }

  

  hidePopup() {
    localStorage.setItem('popupShardoray', JSON.stringify({closed: 'closed'}));
  }
}
