<nav aria-label="breadcrumb" class="breadcrumb-nav">
  <div class="container">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Acasa</a></li>
      <li class="breadcrumb-item active" aria-current="page">Autentificare</li>
    </ol>
  </div><!-- End .container -->
</nav>

<div class="login-container">

  <div class="login">
    <div class="login-isRegister" *ngIf="state === 'register'">
      <div class="login-isRegister__inner">
        <h4 class="title">Ai deja cont Shardoray?</h4>
        <button class="btn btn-outline" (click)="changeState('login')">Intra in cont</button>
      </div>

    </div>
    <div class="login__inner" *ngIf="state === 'login'">
      <div class="heading">
        <h4 class="title">Ai deja cont Shardoray?</h4>

      </div><!-- End .heading -->
      <form id="ngForm" [formGroup]="loginForm" (ngSubmit)="onSubmit(f)" #f="ngForm" novalidate>
        <div class="form-control"
          [ngClass]="{ 'is-invalid': f.form.controls['email'].touched && f.form.controls['email'].invalid }">
          <input type="text" name="email" class="form-input" formControlName="email"
            [ngClass]="{'is-not-empty': f.form.controls['email'].value !== null }" required email>
          <label class="form-label">Email</label>
        </div>
        <div *ngIf="f.form.controls['email'].touched && f.form.controls['email'].invalid" class="alert alert-danger">
          <div *ngIf="f.form.controls['email'].errors.required">Te rugam sa introduci email-ul!</div>
          <div *ngIf="f.form.controls['email'].errors.email">Email-ul nu este valid!</div>
        </div>
        <div class="form-control"
          [ngClass]="{ 'is-invalid': f.form.controls['password'].touched && f.form.controls['password'].invalid }">
          <input type="password" name="password" class="form-input" formControlName="password"
            [ngClass]="{'is-not-empty': f.form.controls['password'].value !== null }" required>
          <label class="form-label">Parola</label>

        </div>
        <div *ngIf="f.form.controls['password'].touched && f.form.controls['password'].invalid"
          class="alert alert-danger">
          <div *ngIf="f.form.controls['password'].errors.required">Te rugam sa introduci parola!</div>
        </div>
        <div class="form-footer">
          <button type="submit" class="btn btn-primary login-btn">Intra in cont</button>
          <a routerLink="/am-uitat-parola" class="forget-pass"> Ai uitat parola?</a>

          <button (click)="toggleSocial(); signInWithFB()" class="btn btn-primary btn-facebook">
            Logare cu Facebook</button>
        </div>
      </form>
    </div>
  </div>

  <div class="register">
    <div class="register-why" *ngIf="state !== 'register'">
      <h4>Este prima ta vizită pe site-ul nostru?</h4>
      <button class="btn btn-outline" (click)="changeState('register')">Creeaza cont</button>
      <div class="benefit">
        <h5>Vei beneficia de:</h5>
        <ul>
          <li class="newsletter">10% discount la prima comanda</li>
          <li class="discount">Reduceri si oferte doar clientilor fideli</li>
          <li class="history">Urmareste comanda si acces la istoricul comenzilor</li>
        </ul>
      </div>

    </div>
    <div class="register__inner" *ngIf="state === 'register'">
      <div class="heading">
        <h4 class="title">Este prima ta vizită pe site-ul nostru?</h4>
      </div><!-- End .heading -->

      <form [formGroup]="registerForm" (ngSubmit)="signup(r)" #r="ngForm" novalidate>
        <div class="form-control"
          [ngClass]="{ 'is-invalid': r.form.controls['name'].touched && r.form.controls['name'].invalid }">
          <input type="text" name="name" class="form-input" formControlName="name"
            [ngClass]="{'is-not-empty': r.form.controls['name'].value !== null }" required>
          <label class="form-label">Nume</label>
        </div>

        <div *ngIf="r.form.controls['name'].touched && r.form.controls['name'].invalid" class="alert alert-danger">
          <div *ngIf="r.form.controls['name'].errors.required">Te rugam sa introduci numele!</div>
        </div>
        <div class="form-control"
          [ngClass]="{ 'is-invalid': r.form.controls['emailField'].touched && r.form.controls['emailField'].invalid }">
          <input type="text" name="emailField" class="form-input" formControlName="emailField"
            [ngClass]="{'is-not-empty': r.form.controls['emailField'].value !== null }" required>
          <label class="form-label">Email</label>
        </div>

        <div *ngIf="r.form.controls['emailField'].touched && r.form.controls['emailField'].invalid"
          class="alert alert-danger">
          <div *ngIf="r.form.controls['emailField'].errors.required">Te rugam sa introduci email-ul!</div>
          <div *ngIf="r.form.controls['emailField'].errors.email">Email-ul nu este valid!</div>
        </div>

        <div class="form-control"
          [ngClass]="{ 'is-invalid': r.form.controls['passwordField'].touched && r.form.controls['passwordField'].invalid }">
          <input type="password" name="passwordField" class="form-input" formControlName="passwordField"
            [ngClass]="{'is-not-empty': r.form.controls['passwordField'].value !== null }" required>
          <label class="form-label">Parola</label>
        </div>

        <div *ngIf="r.form.controls['passwordField'].touched && r.form.controls['passwordField'].invalid"
          class="alert alert-danger">
          <div *ngIf="r.form.controls['passwordField'].errors.required">Te rugam sa introduci parola!</div>
          <div *ngIf="r.form.controls['passwordField'].errors.minlength">Parola trebuie sa fie de minim 6 caractere!
          </div>
        </div>

        <div class="subscribe">
          
            <input id="subscribe" type="checkbox" name="subscribe" [ngModelOptions]="{standalone: true}" [(ngModel)]="isSubscribed">
            <label for="subscribe">Da, doresc să mă înregistrez la newsletter și să primesc 10%
              discount la prima comanda.</label>
       
        </div>

        <div class="form-footer form-group">
          <button type="submit" class="btn btn-primary btn-register">Creeaza cont</button>
          <p>Dând click pe butonul "Creeaza cont", ești de acord cu <a
              href="https://shardoray.ro/confidentialitate">Politica de Confidențialitate</a>.</p>
        </div><!-- End .form-footer -->
      </form>
    </div>
  </div>
</div><!-- End .container -->