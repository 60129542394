// import { LabelType, Options } from '@angular-slider/ngx-slider';
import { DOCUMENT } from '@angular/common';

import { HttpHeaders } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { PixelService } from 'ngx-pixel';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import {
  concatMap,
  delay,
  distinctUntilChanged,
  filter,
  map,
  take,
  tap,
} from 'rxjs/operators';
import { Product } from 'src/app/models';
import {
  ProductService,
  CartService,
  FavoritesService,
  CategoriesService,
  ColorsService,
  SizesService,
  UtilityService,
  ProductsService,
} from 'src/app/services';
import { DataLayerService } from 'src/app/services/dataLayer.service';
import { environment } from 'src/environments/environment';
declare let dataLayer: any[];
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  @HostBinding('className') componentClass: string | undefined;
  // options: Options = {
  //   floor: 0,
  //   ceil: 200,
  //   translate: (value: number, label: LabelType): string => {
  //     switch (label) {
  //       case LabelType.Low:
  //         return value + ' Lei';
  //       case LabelType.High:
  //         return value + ' Lei';
  //       default:
  //         return value + ' Lei';
  //     }
  //   },
  // };

  model: any = {};

  products: any = [];
  products$ = new BehaviorSubject<any[]>([]);
  widgetSizes: boolean = true;
  widgetPrices: boolean = true;
  widgetCategories: boolean = true;
  widgetColors: boolean = true;

  categorySlug: string;
  subcategorySlug: string;
  activeCategory: string;
  maxDiscount: number = 40;
  displayType: string = 'grid';
  p: number = 1;
  ipp: number = 12;
  statusMobile: boolean = false;
  menuSelectedColor: number;

  menuSelectedSize: number;
  uploadedImgUrl: string;
  public itemsFavorites$ = this._FavoritesService.itemsFavorites$;
  loaded: boolean = false;
  subcategorySlugRaw: string;
  subcategoryDiscount: number;
  colorState: any = 0;
  sizeState: number = 0;
  priceRangeState: string = '';
  filterHeaders: any = [];
  filtered: any = [];
  fullSize: boolean = false;
  halloween: boolean;
  favoritesProducts: any[] = [];
  valentine: boolean;
  isBlackFriday: boolean;

  page = 1;
  count = 0;
  pageSize = 20;
  pageSizes = [3, 6, 9];

  currentIndex = -1;
  title = '';

  visited: number;
  @ViewChild('scrollTop') private myScrollContainer!: ElementRef;

  // Filters properties

  selectedFilters: any[] = [];
  sizes: any[] = [];
  productCategories: any[] = [];
  categories$ = new BehaviorSubject<any>([]);
  colors: any[] = [];
  minValue: number = 0;
  maxValue: number;
  selectedMaxValue: number = 0;



  productsImages$ = new BehaviorSubject<any[]>([]);

  filteredProducts$ = new BehaviorSubject<Product[]>([]);
  allProductsRaw$ = new BehaviorSubject<Product[]>([]);
  isFilterActive$ = new BehaviorSubject<any>({});
  allProductsRaw: Product[] = [];

  productSubcategories: any = [];
  selectedCategory: number = 0;
  checked: any;
  productsCategoryAll$ = new BehaviorSubject<Product[]>([]);
  count$ = new BehaviorSubject<number>(0);
  colors$ = new BehaviorSubject<any>('');
  sizes$ = new BehaviorSubject<any>('');
  selectedColorChecked: any = [];
  selectedSizeChecked: any = [];
  selectedSortChecked: any = '';
  selectedPriceChecked: any = [];
  colorDropdown: boolean = false;
  sizeDropdown: boolean = false;
  sortDropdown: boolean = false;
  priceDropdown: boolean = false;

  sortSelection$ = new BehaviorSubject<any>([
    {
      label: 'Cele mai noi',
      value: 'new',
      isChecked: false
    },
    {
      label: 'Dupa pret: mic la mare',
      value: 'price-asc',
      isChecked: false
    },
    {
      label: 'Dupa pret: mare la mic',
      value: 'price-desc',
      isChecked: false
    },
  ]);




  /**
   * Constructor
   *
   * @param {ActivatedRoute} _ActivatedRoute
   * @param {ProductService} _ProductService
   * @param {CartService} _CartService
   * @param {FavoriteService} _FavoriteService
   * @param {CategoriesService} _CategoriesService
   * @param {ColorsService} _ColorsService
   * @param {SizesService} _SizesService
   * @param {Router} _Router
   *
   */
  constructor(
    private _ProductService: ProductService,
    private _ProductsService: ProductsService,
    private _CartService: CartService,
    private _DomSanitizationService: DomSanitizer,
    private _FavoritesService: FavoritesService,
    private _CategoriesService: CategoriesService,
    private _ColorsService: ColorsService,
    private _SizesService: SizesService,
    public _Router: Router,
    public _Util: UtilityService,
    private _ActivatedRoute: ActivatedRoute,
    private pixel: PixelService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private _DataLayeService: DataLayerService
  ) {

    this.uploadedImgUrl = environment.uploadImagesUrl;
    this._ActivatedRoute.paramMap.subscribe((params) => {
      this.categorySlug = params.get('categorySlug');
      this.subcategorySlugRaw = params.get('subcategorySlug');


      if (params.get('subcategorySlug')) {
        let param = params.get('subcategorySlug').split('-');

        let matchCount = 0;

        for (let l = 0; l < param.length; l++) {
          if (param.indexOf(param[l]) > -1) {
            matchCount++;
          }
        }

        if (matchCount == 3) {
          this.activeCategory = param[0] + ' ' + param[1];
        } else {
          this.activeCategory = param[0] || '';
        }

        this.subcategorySlug = params.get('subcategorySlug').replace('-', ' ');

        if (params.get('categorySlug') == 'black-friday' || params.get('categorySlug') == 'reduceri') {
          this.activeCategory = 'Black Friday';
          this.isBlackFriday = true;
          this.componentClass = 'black-friday';
        }


      } else {
        if (params.get('categorySlug') == 'produse-de-sezon') {
          this.activeCategory = 'Produse de sezon';
        } else if (params.get('categorySlug') == 'special-pentru-tine') {
          this.activeCategory = 'Special pentru tine';
        } else if (params.get('categorySlug') == 'cautare') {
          this.activeCategory =
            'Cautare: ' + this._ProductsService.searchProductValue$.getValue();
        } else if (params.get('categorySlug') == 'black-friday' || params.get('categorySlug') == 'reduceri') {
          this.activeCategory = 'Black Friday';
          this.isBlackFriday = true;
          this.componentClass = 'black-friday';
        } else {
          this.activeCategory = 'Toate produsele';
        }


      }
    });

    this._Util.toggleMobileMenu(this.statusMobile);

    if (this._ActivatedRoute.snapshot.queryParamMap.get('page')) {
      this.page = parseInt(
        this._ActivatedRoute.snapshot.queryParamMap.get('page')
      );
    }
    if (this._ActivatedRoute.snapshot.queryParamMap.get('produse')) {
      this.ipp = parseInt(
        this._ActivatedRoute.snapshot.queryParamMap.get('produse')
      );
    }

    this._Router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit(): void {
    console.log(this.categorySlug)
    
    this._DataLayeService.reinitializeScript();
    if(this.activeCategory.includes('Cautare:')) {
      (window as any).dataLayer.push({
        event: '__sm__search',
        search_term: this._ProductsService.searchProductValue$.value,
      });

    } else {
      
      if(this.categorySlug) {
        const category = `${(this.categorySlug.charAt(0).toUpperCase() + this.categorySlug.slice(1)).trim() }${this.subcategorySlugRaw ? '|' + this.subcategorySlugRaw.trim().toLowerCase().split('-')[0] : ''}`;
  
      
        (window as any).dataLayer.push({
          event: '__sm__view_category',
          category,
        });
      }
     
    }
    
    this.halloween = environment.halloween;
    this.valentine = environment.valentine;

    if (!this.categorySlug) {
      this.model.categories = null;

      this._ProductsService.fetchMaxPriceByCategory(this.categorySlug, this.subcategorySlugRaw).pipe(take(1))
        .subscribe(maxPrice => {
          this.maxValue = maxPrice.max_price
          this.getProductsByCategory(this.categorySlug, this.subcategorySlugRaw);
        })
    } else if (this.categorySlug == 'cautare') {
      this.title = this._ProductsService.searchProductValue$.getValue();
      if (this.title === '') {
        this._Router.navigate(['/produse']);
      } else {
        this.getProductsSearch();
      }
    } else {
      this.model.categories = this.categorySlug;
      this._ProductsService.fetchMaxPriceByCategory(this.categorySlug, this.subcategorySlugRaw).pipe(take(1))
        .subscribe(maxPrice => {
          this.maxValue = maxPrice.max_price
          this.getProductsByCategory(this.categorySlug, this.subcategorySlugRaw);
        })

    }



    // this is for the banner
    this._ProductsService
      .getBestSubcategoryDiscount(this.categorySlug, this.subcategorySlugRaw)
      .then((data) => {
        if (data) {
          this.subcategoryDiscount = Math.ceil(
            100 -
            (parseInt(data.current_price) / parseInt(data.old_price)) * 100
          );
        }
      });
  }




  getPrice(data) {
    let sizes = [];
    data.map((product) => {
      product.colors.map((color) => {
        color.sizes.map((size) => {
          sizes.push(size);
        });
      });
    });

    let maxPrice = sizes.reduce((prev, curr) => {
      return prev.current_price > curr.current_price ? prev : curr;
    });

    let minPrice = sizes.reduce((prev, curr) => {
      return prev.current_price < curr.current_price ? prev : curr;
    });

    this.maxValue = maxPrice.current_price;
    this.minValue = minPrice.current_price;
    // this.options = {
    //   floor: this.minValue,
    //   ceil: this.maxValue,
    // };
  }

  getProductsPaginate() {
    let headers = new HttpHeaders();

    let options = { headers: headers };
    const params = this._ProductsService.getRequestParams(
      this.title,
      this.page,
      this.ipp
    );

    this._ProductsService.getProductsAllPaginate(params, options).then((data) => {
      this.count = data.total_no_of_products;
      this.count$.next(data.total_no_of_products);
      this.products$.next(
        this._ProductsService.cleanObjProductCatalog(data.products)
      );
      this.loaded = true;
     
    });
  }

  getProductsSearch() {
    const params = this._ProductsService.getRequestParams(
      this.title,
      this.page,
      this.ipp
    );
    this._ProductsService.searchProductsClient(params).subscribe((data) => {
      this.count = data.total_no_of_products;
      this.count$.next(data.total_no_of_products);
      console.log(data)
      this.products$.next(
        this._ProductsService.cleanObjProductCatalog(data.products)
      );
      this.loaded = true;
    });
  }

  searchProduct() {
    this.activeCategory = 'Cautare: ' + this.title;
    this._ProductsService.searchProductValue$.next(this.title);
    const params = this._ProductsService.getRequestParams(
      this.title,
      this.page,
      this.ipp
    );
    this._ProductsService.searchProductsClient(params).subscribe((data) => {
      this.count = data.total_no_of_products;
      this.count$.next(data.total_no_of_products);

      this.products$.next(
        this._ProductsService.cleanObjProductCatalog(data.products)
      );
      this.loaded = true;
    });
  }

  toggleColorDropdown() {
    this.colorDropdown = !this.colorDropdown;
    this.sortDropdown = false;
    this.sizeDropdown = false;
    this.priceDropdown = false;
  }
  toggleSizeDropdown() {
    this.sizeDropdown = !this.sizeDropdown;
    this.sortDropdown = false;
    this.colorDropdown = false;
    this.priceDropdown = false;
  }
  toggleSortDropdown() {
    this.sortDropdown = !this.sortDropdown;
    this.colorDropdown = false;
    this.sizeDropdown = false;
    this.priceDropdown = false;
  }
  togglePriceDropdown() {
    this.priceDropdown = !this.priceDropdown;
    this.sortDropdown = false;
    this.colorDropdown = false;
    this.sizeDropdown = false;
  }

  closeDropdowns() {
    this.sortDropdown = false;
    this.colorDropdown = false;
    this.sizeDropdown = false;
    this.priceDropdown = false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;

    // Check if the click event occurred outside of the dropdowns
    const isClickedOutsideDropdowns =
      !targetElement.closest('.multiple-selection') &&
      !targetElement.closest('.toolbox-sort');

    if (isClickedOutsideDropdowns) {
      this.closeDropdowns();
    }
  }


  selectedColor(color: any) {
    if (color.isChecked) {
      this.selectedColorChecked.push(color);
    } else {
      const index = this.selectedColorChecked.indexOf(color);
      if (index > -1) {
        this.selectedColorChecked.splice(index, 1);
      }
    }

    this._ProductsService.selectedColorChecked$.next(this.selectedColorChecked);
  }
  selectedSize(size: any) {
    if (size.isChecked) {
      this.selectedSizeChecked.push(size);
    } else {
      const index = this.selectedSizeChecked.indexOf(size);
      if (index > -1) {
        this.selectedSizeChecked.splice(index, 1);
      }
    }

    this._ProductsService.selectedSizeChecked$.next(this.selectedSizeChecked);
  }

  filterSelected() {
    this.updateQueryParams();
    this.getProductsByCategory(this.categorySlug, this.subcategorySlugRaw);
  }


  sortProducts(sort: any) {
    this.selectedSortChecked = sort
    this._ProductsService.selectedSortChecked$.next(this.selectedSortChecked);
  }

  selectedPrice() {
    this.selectedPriceChecked = this.minValue + '-' + this.maxValue;
    this._ProductsService.selectedPriceChecked$.next(this.selectedPriceChecked);
    this.updateQueryParams();
    this.getProductsByCategory(this.categorySlug, this.subcategorySlugRaw);
  }


  getProductsByCategory(categoryAlias, subcagoryAlias) {
    const params = this._ProductsService.getRequestParams(
      this.title,
      this.page,
      this.ipp
    );


    this.checkIfParams();

    this._ProductsService
      .getProductsByCategoryPaginate(categoryAlias, subcagoryAlias, params, this.setHeaders())
      .pipe(
        distinctUntilChanged(),
        tap((data: any) => {
         
          if (data.no_of_pages < this.page && data.total_no_of_products > 0) {
            this.page = 1;
            this.handlePageChange(1);
          }

          this.selectedColorChecked.forEach(obj => {
            const matchingObject = data.colors.find(item => item.id === obj.id);
            if (matchingObject) {
              matchingObject.isChecked = true;
            }
          });
          this.selectedColorChecked = [];
          this.selectedColorChecked = data.colors.filter(color => color.isChecked);
          this._ProductsService.selectedColorChecked$.next(this.selectedColorChecked);


          this.selectedSizeChecked.forEach(obj => {
            const matchingSizeObject = data.sizes.find(item => item.id === obj.id);
            if (matchingSizeObject) {
              matchingSizeObject.isChecked = true;
            }
          });

          this.selectedSizeChecked = [];
          this.selectedSizeChecked = data.sizes.filter(size => size.isChecked);
          this._ProductsService.selectedSizeChecked$.next(this.selectedSizeChecked);

          this.sortSelection$.pipe(take(1)).subscribe(sortOptions => {
            const selectedSort = this._ProductsService.selectedSortChecked$.getValue();

            sortOptions.forEach(option => {
              option.isChecked = option.value === selectedSort;
            });
          });

          this.selectedSortChecked = this._ProductsService.selectedSortChecked$.getValue();

          this.colors$.next(data.colors);
          this.sizes$.next(data.sizes);

          this.count = data.total_no_of_products;
          this.count$.next(data.total_no_of_products);
        })
      )
      .subscribe((data) => {

        this.products$.next(
          this._ProductsService.cleanObjProductCatalog(data.products)
        );
        console.log(this._ProductsService.cleanObjProductCatalog(data.products))
        this.loaded = true;
      });
  }


  selectColor(product, color) {
    this._ProductService.selectColor(product, color);
  }

  selectSize(product, size) {
    this._ProductService.selectSize(product, size);
  }

  addToCart(product) {
    this._CartService.addToCart(product, 1);
  }


  displayProductsAs(display) {
    this.displayType = display;
  }

  changeShowOnPage(show) {
    this.ipp = show;
    this.setIpp(this.ipp);
    this.getProductsByCategory(this.categorySlug, this.subcategorySlugRaw);

    const scrollToBody = this.document.querySelector('.header');
    scrollToBody.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  openMobileMenu() {
    this.statusMobile = !this.statusMobile;
    this._Util.toggleMobileMenu(this.statusMobile);
  }

  menuSelectColor(colorID) {
    this.menuSelectedColor = colorID;
  }
  menuSelectSizes(sizeID) {
    this.menuSelectedSize = sizeID;
  }

  getProductsByPrice() {
    this.filterHeaders.push(['x-pricerange', this.minValue + '-' + this.maxValue])
    this.loaded = false;

    let headers = new HttpHeaders();
    headers = headers.set('x-pricerange', this.minValue + '-' + this.maxValue);
    let options = { headers: headers };
    // let selectedPrice = []
    // // this.products.map((product,i) => {
    // //   this.products[i].selectedPrice = product.selectedSize
    // // })
    // let filteredProducts = this.products.filter((item) => {

    //   return item.hasOwnProperty('selectedSize') && parseInt(item.selectedSize.current_price) >= this.minValue && parseInt(item.selectedSize.current_price) <= this.maxValue;
    // });

    // filteredProducts.map((product, i) => {
    //   filteredProducts[i]['sizes'] = product['colors'][0].sizes;
    // })

    // this.products$.next(filteredProducts);
    // this.loaded = true;

    // if (!this.subcategorySlugRaw) {

    //   this._ProductsService.getProductsFilterByCategory(this.categorySlug, options).then(data => {
    //     data.map((product, i) => {
    //       data[i]['sizes'] = product['colors'][0].sizes;
    //     })
    //     this.products$.next(data);
    //     this.loaded = true;
    //   })
    // } else {
    //   this._ProductsService.getProductsByColorAndCategoryAndSubcategory(this.categorySlug, this.subcategorySlugRaw, options).then(data => {

    //     let filteredProducts = data.filter(product => {
    //       product['colors'].map(size => {
    //         //
    //         size.sizes.filter(price => {
    //           return price.current_price >= this.minValue && price.current_price <= this.maxValue
    //         })
    //       })
    //       // return product['colors'][0].sizes;
    //     });

    //

    //     filteredProducts.map((product, i) => {
    //       filteredProducts[i]['sizes'] = product['colors'][0].sizes;
    //     })
    //     // data.map((product, i) => {
    //     //   data[i]['sizes'] = product['colors'][0].sizes;
    //     // })
    //     this.products$.next(data);
    //     this.loaded = true;
    //   })
    // }

  }


  toggleFavorites(product) {
    this._FavoritesService.toggleFavorites(product);
  }

  handlePageChange(newPage: number): void {
    this.page = newPage;
    let queryParams: Params = { ...this._ActivatedRoute.snapshot.queryParams };

    delete queryParams.visited;
    queryParams.page = newPage;
    this.navigateByParams(queryParams);

    if (!this.categorySlug) {
      this.model.categories = null;
      this.getProductsPaginate();
    } else if (this.categorySlug == 'cautare') {
      this.title = this._ProductsService.searchProductValue$.getValue();
      if (this.title === '') {
        this._Router.navigate(['/produse']);
      } else {
        this.getProductsSearch();
      }
    } else {
      this.model.categories = this.categorySlug;
      this.getProductsByCategory(this.categorySlug, this.subcategorySlugRaw);
    }

    this.loaded = false;

    const scrollToBody = this.document.querySelector('.page-wrapper');
    scrollToBody.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  navigateByParams(queryParams) {
    this._Router.navigate([], {
      //relativeTo: this._ActivatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  setAnchor(id): void {
    const queryParams: Params = { ...this._ActivatedRoute.snapshot.queryParams };
    queryParams.visited = id;

    this._Router.navigate([], {
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  setIpp(ipp) {
    const queryParams: Params = { produse: ipp };

    this._Router.navigate([], {
      relativeTo: this._ActivatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }
  updateQueryParams() {
    combineLatest([
      this._ProductsService.selectedSortChecked$,
      this._ProductsService.selectedColorChecked$,
      this._ProductsService.selectedSizeChecked$,
      this._ProductsService.selectedPriceChecked$
    ]).pipe(take(1)).subscribe(([sort, colors, sizes, price]) => {
      const queryParams: any = {};



      // Update the sort query parameter
      if (sort) {
        queryParams.sortare = sort;
      }
      // Update the price query parameter
      if (price) {
        queryParams.pret = price;
      }

      // Update the culori query parameter
      const uniqueColorIds = new Set<number>();
      colors.forEach(color => {
        uniqueColorIds.add(color.id);
      });
      const uniqueColors = colors.filter((obj, index, self) =>
        index === self.findIndex(o => JSON.stringify(o) === JSON.stringify(obj))
      );
      this.selectedColorChecked = uniqueColors;
      const colorsString = uniqueColorIds.size > 0 ? Array.from(uniqueColorIds).join('-') : null;
      queryParams.culori = colorsString;

      // Update the marimi query parameter
      const uniqueSizeIds = new Set<number>();
      sizes.forEach(size => {
        uniqueSizeIds.add(size.id);
      });
      const uniqueSizes = sizes.filter((obj, index, self) =>
        index === self.findIndex(o => JSON.stringify(o) === JSON.stringify(obj))
      );
      this.selectedSizeChecked = uniqueSizes;
      const sizesString = uniqueSizeIds.size > 0 ? Array.from(uniqueSizeIds).join('-') : null;
      queryParams.marimi = sizesString;


      // Navigate with the updated query parameters
      this._Router.navigate([], {
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
    });
  }


  navigateToProduct(alias, id, colorID, meta) {

    this.setAnchor(id);
    this._ProductService.productID$.next(id);
    this._ProductService.productID$.pipe(
      take(1),
      delay(20)
    ).subscribe(() => {
      this._Router.navigate([`/produs/${alias}/${id}/${colorID}`], {
        queryParams: { title: alias, metadata: meta },
      });
    });
  }

  scrollToElement(id: string) {
    const scrollToElement = this.document.querySelector(`[data-id="${id}"]`);

    if (scrollToElement) {
      try {
        this.products$.pipe(
          distinctUntilChanged(),
          delay(100),
          tap(products => {
            if (products.length === this.pageSize) {
              this.myScrollContainer.nativeElement.scrollTop = scrollToElement.getBoundingClientRect().top;
            }
          })
        ).subscribe();
      } catch (err) { }
    } else {
      this.products$.pipe(
        distinctUntilChanged(),
        tap(products => {
          if (products.length === this.pageSize) {
            this.myScrollContainer.nativeElement.scrollTop = 0;
          }
        })
      ).subscribe();
    }
  }


  setHeaders() {

    let headers = new HttpHeaders();

    const colorsJson = JSON.stringify(this.selectedColorChecked);
    const sizesJson = JSON.stringify(this.selectedSizeChecked);

    headers = headers.set('x-colors', colorsJson);
    headers = headers.set('x-sizes', sizesJson);
    headers = headers.set('x-pricerange', this.minValue + ',' + this.maxValue);
    headers = headers.set('x-sort', this._ProductsService.selectedSortChecked$.getValue());

    let options = { headers: headers };



    return options;
  }

  checkIfParams() {

    const queryParams = new URLSearchParams(window.location.search);
    const colorsParam = queryParams.get('culori');
    const sizesParam = queryParams.get('marimi');
    const sortParam = queryParams.get('sortare');
    const priceParam = queryParams.get('pret');

    if (this.selectedColorChecked.length === 0 && colorsParam) {
      const colorsArray = colorsParam.split('-');
      this.selectedColorChecked = colorsArray.map(color => ({ id: +color }));
    }

    if (this.selectedSizeChecked.length === 0 && sizesParam) {
      const sizeArray = sizesParam.split('-');
      this.selectedSizeChecked = sizeArray.map(size => ({ id: +size }));
    }

    if (!this.selectedSortChecked && sortParam) {
      this.selectedSortChecked = sortParam;
      this._ProductsService.selectedSortChecked$.next(sortParam);
    }

    if (this.selectedPriceChecked.length === 0 && priceParam) {
      this.selectedPriceChecked = priceParam;
      const splitPrice = priceParam.split('-');
      this.maxValue = parseInt(splitPrice[1]);
      this.minValue = parseInt(splitPrice[0]);
      this._ProductsService.selectedPriceChecked$.next(priceParam);
    }

    // Check if both colors and sizes are empty, then remove the corresponding query parameters
    // if (this.selectedColorChecked.length === 0 && this.selectedSizeChecked.length === 0) {
    //   this._Router.navigate([], { queryParams: { culori: null, marimi: null }, queryParamsHandling: 'merge' });
    // } 
  }

  deleteFilters() {
    this.selectedColorChecked = [];
    this.selectedSizeChecked = [];
    this.selectedSortChecked = [];
    this.selectedPriceChecked = [];
    this._ProductsService.selectedColorChecked$.next(this.selectedColorChecked);
    this._ProductsService.selectedSizeChecked$.next(this.selectedSizeChecked);
    this._ProductsService.selectedSortChecked$.next(this.selectedSortChecked);
    this._ProductsService.selectedPriceChecked$.next(this.selectedPriceChecked);
    this._Router.navigate([], { queryParams: { culori: null, marimi: null, sortare: null, pret: null }, queryParamsHandling: 'merge' });
  }

  ngAfterViewInit() {
    this.products$.pipe(
      distinctUntilChanged(),
      delay(500),
      tap(products => {
        this.scrollToElement(this._ActivatedRoute.snapshot.queryParamMap.get('visited'))
      })).subscribe()
  }

}


