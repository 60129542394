import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CartService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-added-to-cart',
  templateUrl: './added-to-cart.component.html',
  styleUrls: ['./added-to-cart.component.scss']
})
export class AddedToCartComponent implements OnInit {
  @ViewChild('modalData') modalContent;

  closeModal: string;
  product: any;
  uploadedImgUrl: string;

  constructor(
    private _CartService: CartService,
    private _ModalService: NgbModal,
    public _Router: Router,
    private _DomSanitizationService: DomSanitizer
  ) {
    this.uploadedImgUrl = environment.uploadImagesUrl;
  }

  ngOnInit(): void {
  }

  triggerModal(product) {
    
    if (product['selectedColor'].color_list > 1) {
      product['selectedColor'].color_list = product['selectedColor'].color_list.split(",");
    }

    this.product = product;
    
    this._ModalService.open(this.modalContent, { ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'custom-modal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



}
