import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  editState$ = new BehaviorSubject<boolean>(false);
  editValues$ = new BehaviorSubject<any>(null);

  private _apiUrl: string;
  generateAWBLink: string;
  geocodingKey: string;
  geocoding: string;
  reverseGeocoding: string;

  courierToken$ = new BehaviorSubject<string>('');
  courierToken: string = '';
  courierTokenStreetFit$ = new BehaviorSubject<string>('');
  courierTokenStreetFit: string = '';
  private _generateTokenLink = environment.getCourierToken;
  private _generateTokenLinkStreetFit = environment.getCourierTokenStreetFit;
  private downloadAWBLink = environment.downloadAWBLink;
  private downloadAWBLinkStreetFit = environment.downloadAWBLinkStreetFit;
  private courierUsername = environment.courierUsername;
  private courierPass = environment.courierPass;
  private courierUsernameStreetFit = environment.courierUsernameStreetFit;
  private courierPassStreetFit = environment.courierPassStreetFit;
  generatedAWB: any;
  generateAWBLinkStreetFit: any;
  optionsPrint!: { headers: HttpHeaders };
  billNumber: string;
  billSeries: string;
  orderSucceded$ = new BehaviorSubject<any>([]);

  constructor(
    private _httpClient: HttpClient,
    private _toaster: ToastrService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this._apiUrl = environment.apiUrl;
    this.generateAWBLink = environment.generateAWBLink;
    this.generateAWBLinkStreetFit = environment.generateAWBLinkStreetFit;
    this.geocodingKey = environment.geocodingKey;
    this.geocoding = environment.geocoding;
    this.reverseGeocoding = environment.reverseGeocoding;
  }

  /**
   * Get orders
   *
   * @returns {Promise<any>}
   */
  getOrdersAll(options, params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(
          this._apiUrl + `orders/paginate/${params.page}/${params.size}`,
          options
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * search orders
   *
   * @returns {Promise<any>}
   */
  searchOrders(options, search): Observable<any> {
    return this._httpClient.get(
      this._apiUrl + `orders/search/${search}`,
      options
    );
  }
  /**
   * Get orders
   *
   * @returns {Promise<any>}
   */
  getOrdersFirstOneHundred(options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + 'orders/first-one-hunderd', options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get orders
   *
   * @returns {Promise<any>}
   */
  getReturnsAll(options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + 'orders/returns', options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get orders
   *
   * @returns {Promise<any>}
   */
  getOrder(orderID, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + 'orders/order/' + orderID, options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get return
   *
   * @returns {Promise<any>}
   */
  // getReturn(returnID, options): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this._httpClient
  //       .get(this._apiUrl + 'returns/admin/retur/' + returnID, options)
  //       .subscribe((response: any) => {
  //         resolve(response);
  //       }, reject);
  //   });
  // }

  getReturn(returnID, options): Observable<any> {
    const url = `${this._apiUrl}returns/admin/retur/${returnID}`;
    return this._httpClient.get(url, { ...options, responseType: 'json' });
  }



  /**
   * Get order
   *
   * @returns {Promise<any>}
   */
  getOrderDetails(orderID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + 'orders/retur/' + orderID)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get order
   *
   * @returns {Promise<any>}
   */
  sendReturn(obj): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this._apiUrl + 'orders/retur/add', obj)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  checkIfStreetInvoice(id, options) {
    return this._httpClient.get(
      this._apiUrl + `orders/searchForInvoice/${id}`,
      options
    );
  }
  /**
   * Change order
   *
   * @returns {Promise<any>}
   */
  changeStatus(order, status, options) {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    let issueDate = yyyy + '-' + mm + '-' + dd;

    let dueDate = new Date();
    dueDate.setDate(dueDate.getDate() + 15);

    let dueDateDd = String(dueDate.getDate()).padStart(2, '0');
    let dueDateMm = String(dueDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    let DueDateYyyy = dueDate.getFullYear();

    let dueDateToString = DueDateYyyy + '-' + dueDateMm + '-' + dueDateDd;

    // let totalWithoutDelivery = order.total - environment.courierPrice;
    // let totalWithoutVat = (totalWithoutDelivery / 1.19).toFixed(2);
    let courierPriceWithoutVat = (environment.courierPrice / 1.19).toFixed(2);

    const emitere = {
      companyVatCode: environment.smartbillCif,
      client: {
        name: order.customer.name + ' ' + order.customer.surname,
        address: order.addressData.address,
        city: order.addressData.details,
        county: order.addressData.county,
        country: 'Romania',
        email: order.customer.email,
        saveToDb: false,
      },
      issueDate: issueDate,
      seriesName: 'SHBRY',
      isDraft: false,
      dueDate: dueDateToString,
      deliveryDate: issueDate,
      products: [],
    };
    const emitereStreet = {
      companyVatCode: environment.smartbillStreetFitCif,
      client: {
        name: order.customer.name + ' ' + order.customer.surname,
        address: order.addressData.address,
        city: order.addressData.details,
        county: order.addressData.county,
        country: 'Romania',
        email: order.customer.email,
        saveToDb: false,
      },
      issueDate: issueDate,
      seriesName: 'STR',
      isDraft: false,
      dueDate: dueDateToString,
      deliveryDate: issueDate,
      products: [],
    };

    JSON.parse(order.products_ordered).forEach((product) => {
      emitere.products.push({
        name: product.name,
        code: product.id,
        isDiscount: false,
        measuringUnitName: 'buc',
        currency: 'RON',
        quantity: product.num,
        price: (product.selectedPrice / 1.19).toFixed(2),
        saveToDb: false,
        isService: false,
        taxName: 'Normala',
        taxPercentage: 19,
      });
      emitereStreet.products.push({
        name: product.name,
        code: product.id,
        isDiscount: false,
        measuringUnitName: 'buc',
        currency: 'RON',
        quantity: product.num,
        price: (product.selectedPrice / 1.19).toFixed(2),
        saveToDb: false,
        isService: false,
        taxName: 'Normala',
        taxPercentage: 19,
      });
    });

    emitere.products.push({
      name: 'Transport',
      code: order.order_uuid,
      isDiscount: false,
      measuringUnitName: 'buc',
      currency: 'RON',
      quantity: 1,
      price: courierPriceWithoutVat,
      saveToDb: false,
      isService: false,
      taxName: 'Normala',
      taxPercentage: 19,
    });

    emitereStreet.products.push({
      name: 'Transport',
      code: order.order_uuid,
      isDiscount: false,
      measuringUnitName: 'buc',
      currency: 'RON',
      quantity: 1,
      price: courierPriceWithoutVat,
      saveToDb: false,
      isService: false,
      taxName: 'Normala',
      taxPercentage: 19,
    });

    if (order.total >= 249) {
      emitere.products.pop();
      emitereStreet.products.pop();
    }
    const incasare = {
      companyVatCode: environment.smartbillCif,
      issueDate: issueDate,
      type: 'Ordin plata',
      isCash: false,
      useInvoiceDetails: true,
      invoicesList: [
        {
          seriesName: order.series,
          number: order.number,
        },
      ],
    };

    const headers = new HttpHeaders({
      Authorization: 'Basic ' + environment.smarbillEncodedToken,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    const headersStreet = new HttpHeaders({
      Authorization: 'Basic ' + environment.smarbillStreetFitEncodedToken,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    if (status === 'confirmata') {
      this.checkIfStreetInvoice(order.id, options).subscribe((order: any) => {
        if (order.series === 'STR') {
          this.updateStatus(order, status, order.number, order.series, options);
        } else {
          this._httpClient
            .post('https://ws.smartbill.ro/SBORO/api/invoice', emitere, {
              headers: headers,
            })
            .subscribe(
              (data: any) => {
                if (data.errorText === '') {
                  this.billNumber = data.number;
                  this.billSeries = data.series;
                  this._toaster.success(
                    ``,
                    `Factura ${
                      this.billNumber + ' ' + this.billSeries
                    }, s-a emis cu success!`,
                    {
                      timeOut: 3000,
                      positionClass: 'toast-bottom-right',
                    }
                  );
                } else {
                  this._toaster.error(``, `${data.errorText}`, {
                    timeOut: 3000,
                    positionClass: 'toast-bottom-right',
                  });
                }

                this.updateStatus(
                  order,
                  status,
                  this.billNumber,
                  this.billSeries,
                  options
                );
              },
              (error) => {
                this._toaster.error(
                  ``,
                  `S-a produs o eroare la emiterea facturii!`,
                  {
                    timeOut: 3000,
                    positionClass: 'toast-bottom-right',
                  }
                );
              }
            );
        }
      });
    } else if (status === 'preluata') {
      this._httpClient
        .post('https://ws.smartbill.ro/SBORO/api/invoice', emitereStreet, {
          headers: headersStreet,
        })
        .subscribe(
          (data: any) => {
            if (data.errorText === '') {
              this.billNumber = data.number;
              this.billSeries = data.series;
              this._toaster.success(
                ``,
                `Factura ${
                  this.billNumber + ' ' + this.billSeries
                }, s-a emis cu success!`,
                {
                  timeOut: 3000,
                  positionClass: 'toast-bottom-right',
                }
              );
            } else {
              this._toaster.error(``, `${data.errorText}`, {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
            }

            this.updateStatus(
              order,
              status,
              this.billNumber,
              this.billSeries,
              options
            );
          },
          (error) => {
            this._toaster.error(
              ``,
              `S-a produs o eroare la emiterea facturii!`,
              {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              }
            );
          }
        );
      // } else if (status === 'preluata') {
      //   this._httpClient.post('https://ws.smartbill.ro/SBORO/api/payment', incasare, { headers: headers }).subscribe((data: any) => {

      //     if (data.errorText === "") {
      //       this._toaster.success(``, `Factura ${order.series + ' ' + order.number}, a fost incasata cu success!`, {
      //         timeOut: 3000,
      //         positionClass: 'toast-bottom-right'
      //       });
      //     } else {
      //       this._toaster.error(``, `${data.errorText}`, {
      //         timeOut: 3000,
      //         positionClass: 'toast-bottom-right'
      //       });
      //     }

      //     this.updateStatus(order, status, order.number, order.series, options)

      //   },
      //     error => {
      //       this._toaster.error(``, `S-a produs o eroare la emiterea facturii!`, {
      //         timeOut: 3000,
      //         positionClass: 'toast-bottom-right'
      //       });
      //     }
      //   );
    } else if (status === 'refuzata' && order.series && order.number) {
      this._httpClient
        .delete(
          'https://ws.smartbill.ro/SBORO/api/invoice?cif=' +
            (order.series === 'STR'
              ? environment.smartbillStreetFitCif
              : environment.smartbillCif) +
            '&seriesname=' +
            order.series +
            '&number=' +
            order.number,
          { headers: order.series === 'STR' ? headersStreet : headers }
        )
        .subscribe(
          (data: any) => {
            if (data.errorText === '') {
              this._toaster.success(
                ``,
                `Factura ${
                  order.series + ' ' + order.number
                }, a fost stearsa cu success!`,
                {
                  timeOut: 3000,
                  positionClass: 'toast-bottom-right',
                }
              );
            } else {
              this._toaster.error(``, `${data.errorText}`, {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
            }

            this.updateStatus(
              order,
              status,
              order.number,
              order.series,
              options
            );
          },
          (error) => {
            this._toaster.error(
              ``,
              `S-a produs o eroare la emiterea facturii!`,
              {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              }
            );
          }
        );
    } else {
      this.updateStatus(order, status, '', '', options);
    }

    // if (status === 'preluata') {
    //   this._httpClient.post(environment.smartbillIncasare).subscribe((data: any) => {
    //     number = data.number
    //     series = data.series
    //   });
    // }

    this._httpClient.get('https://t.themarketer.com/api/v1/update_order_status', {
      params: {
        k: environment.theMarketerApiKey,
        order_number: order.order_uuid,
        u: environment.theMarketerCustomerID,
        order_status: status,
      }
    }).subscribe();
  }

  updateStatus(order, status, billNumber, billSeries, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put(
          this._apiUrl + 'orders/order/edit/status',
          {
            order_uuid: order.order_uuid,
            status: status,
            number: billNumber,
            series: billSeries,
          },
          options
        )
        .subscribe((response: any) => {
          if (response.success) {
            this._toaster.success('', `${response['message']}`, {
              timeOut: 8000,
              positionClass: 'toast-bottom-right',
            });
          }
          resolve(response);
        }, reject);
    });
  }
  /**
   * Update order
   *
   * @returns {Promise<any>}
   */
  updateOrder(orderID, products, total, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put(
          this._apiUrl + 'orders/order/update/update-order',
          { order_uuid: orderID, products: products, total: total },
          options
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Increment products order
   *
   * @returns {Promise<any>}
   */
  incrementOrder(orderID, products, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put(
          this._apiUrl + 'orders/order/update/increment-order',
          { order_uuid: orderID, products: products },
          options
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Increment products order
   *
   * @returns {Promise<any>}
   */
  decrementOrder(orderID, products, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put(
          this._apiUrl + 'orders/order/update/decrement-order',
          { order_uuid: orderID, products: products },
          options
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  addPostalCode(orderID, customerID, postal_code, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put(
          this._apiUrl + 'orders/order/edit/postal_code',
          {
            order_uuid: orderID,
            customerID: customerID,
            postal_code: postal_code,
          },
          options
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  editAddress(
    customerID,
    address,
    addressDetails,
    addressCounty,
    addressCity,
    options
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put(
          this._apiUrl + 'orders/order/edit/address',
          {
            customerID: customerID,
            address: address,
            addressDetails: addressDetails,
            addressCounty: addressCounty,
            addressCity: addressCity,
          },
          options
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  editCustomer(
    customerID,
    clientName,
    clientSurName,
    nationalNumber,
    e164Number,
    email,
    options
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put(
          this._apiUrl + 'orders/order/edit/customer',
          {
            customerID: customerID,
            clientName: clientName,
            clientSurName: clientSurName,
            nationalNumber: nationalNumber,
            e164Number: e164Number,
            email: email,
          },
          options
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Change return
   *
   * @returns {Promise<any>}
   */
  changeReturnStatus(orderID, status, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put(
          this._apiUrl + 'orders/retur/edit/status',
          { ReturnID: orderID, status: status },
          options
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Generate courier token
   *
   * @returns {Promise<any>}
   */
  generateCourierToken(options: any) {
    let headers = new HttpHeaders();

    headers = headers.set('X-Auth-Username', this.courierUsername);
    headers = headers.set('X-Auth-Password', this.courierPass);

    let sameday = { headers: headers };

    return this._httpClient
      .post(this._generateTokenLink, sameday)
      .pipe(
        tap((response: any) => {
          this.courierToken$.next(response.token);
          this.courierToken = response.token;
        })
      )
      .subscribe((response) => {
        if (response.token != null) {
          this.refreshToken(response.token, response.expire_at, options);
        } else {
        }
      });
  }
  generateCourierTokenStreetFit(options: any) {
    let headers = new HttpHeaders();

    headers = headers.set('X-Auth-Username', this.courierUsernameStreetFit);
    headers = headers.set('X-Auth-Password', this.courierPassStreetFit);

    let sameday = { headers: headers };

    return this._httpClient
      .post(this._generateTokenLinkStreetFit, sameday)
      .pipe(
        tap((response: any) => {
          console.log(response);
          this.courierTokenStreetFit$.next(response.token);
          this.courierTokenStreetFit = response.token;
        })
      )
      .subscribe((response) => {
        if (response.token != null) {
          console.log(response);
          this.refreshTokenStreetFit(
            response.token,
            response.expire_at,
            options
          );
        } else {
        }
      });
  }
  /**
   * Refresh token
   *
   * @returns {Promise<any>}
   */
  refreshToken(token: string, expire_at: string, options: any) {
    return this._httpClient
      .put(
        this._apiUrl + 'orders/refreshCourierToken',
        { token: token, expire_at: expire_at },
        options
      )
      .subscribe();
  }
  /**
   * Refresh token
   *
   * @returns {Promise<any>}
   */
  refreshTokenStreetFit(token: string, expire_at: string, options: any) {
    return this._httpClient
      .put(
        this._apiUrl + 'orders/refreshCourierTokenStreetFit',
        { token: token, expire_at: expire_at },
        options
      )
      .subscribe();
  }

  /**
   * Check if token is still available
   *
   * @returns {Promise<any>}
   */
  checkCourierToken(options: any) {
    return this._httpClient
      .get(this._apiUrl + 'orders/checkCourierToken', options)
      .pipe(
        tap((data: any) => {
          if (data.expired) {
            this.generateCourierToken(options);
          } else {
            this.courierToken$.next(data.token);
            this.courierToken = data.token;
          }
        })
      )
      .subscribe();
  }
  checkCourierStreetToken(options: any) {
    return this._httpClient
      .get(this._apiUrl + 'orders/checkCourierSteetToken', options)
      .pipe(
        tap((data: any) => {
          if (data.expired) {
            this.generateCourierTokenStreetFit(options);
          } else {
            console.log(data);
            this.courierTokenStreetFit$.next(data.token);
            this.courierTokenStreetFit = data.token;
          }
        })
      )
      .subscribe();
  }

  /**
   * Generate AWB
   *
   * @returns {Promise<any>}
   */
  // generateAWB(body: any, options: any, uuid: string) {
  //   // this._httpClient.post(this.generateAWBLink, [{ 'body': body, 'key': this.courierKey, 'token': this.courierToken }]).subscribe(
  //   //   (data: any) => {
  //   //     if (data.Error) {
  //   //       if (data.Error == "The recipient's locality could not be matched!") {
  //   //         this._toaster.error(``, 'Orasul sau judetul contine diacritice sau nu este corect!', {
  //   //           timeOut: 3000,
  //   //           positionClass: 'toast-bottom-right'
  //   //         });
  //   //       }
  //   //       return;
  //   //     }

  //   //     if (data.length > 0) {
  //   //       if (data[0] == "BuildingNumber is too long! Maximum character length: 9") {
  //   //         this._toaster.error(``, 'Numarul strazii este incorect!', {
  //   //           timeOut: 3000,
  //   //           positionClass: 'toast-bottom-right'
  //   //         });

  //   //         return;
  //   //       }
  //   //     }
  //   //     this.generatedAWB = data;
  //   //     this._httpClient.put(this._apiUrl + 'orders/addAWB', { 'awb': data, 'uuid': uuid }, options)
  //   //       .subscribe(order => {

  //   //
  //   //         this.orderDetails$.next(order)

  //   //         let params = {
  //   //           // Request parameters
  //   //           "barCodes": this.generatedAWB,
  //   //           "type": "PDF",
  //   //           "format": 0,
  //   //           "printMainOnce": "1",
  //   //           "printOneAwbPerPage": "1",
  //   //           "senderName": environment.company.company_name,
  //   //         };

  //   //         this._httpClient.post(environment.generateAWBPrint, [{ 'params': params, 'token': this.courierToken, 'key': this.courierKey }])
  //   //           .subscribe((response: any) => {
  //   //             const linkSource = 'data:application/pdf;base64,' + response;
  //   //             const fileName = uuid + ".pdf";
  //   //             window.open(linkSource, '_blank');
  //   //             const downloadLink = document.createElement("a");
  //   //             downloadLink.href = linkSource;
  //   //             downloadLink.download = fileName;
  //   //             downloadLink.click();
  //   //           });
  //   //         // this._httpClient.get('https://urgentcargus.azure-api.net/api/AwbDocuments?barCodes=970878665&type=PDF&format=1&printMainOnce=1&printOneAwbPerPage=1', this.optionsPrint)
  //   //         // .subscribe(response => {
  //   //         //
  //   //         //   this.downLoadFile(response, "application/pdf")
  //   //         // });

  //   //         // const url = this.router.serializeUrl(this.router.createUrlTree([environment.generateAWBPrint], { queryParams: { ...params } }));
  //   //         // window.open(url, '_blank');
  //   //       });
  //   //   }
  //   // );
  // }
  /**
   * Generate AWB
   *
   * @returns {Promise<any>}
   */
  generateAWB(order, options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this.generateAWBLink, { order: order })
        .subscribe((response: any) => {
          this._httpClient
            .put(
              this._apiUrl + 'orders/order/edit/awb',
              { awb: response.awbNumber, order_uuid: order.order_uuid },
              options
            )
            .subscribe((data: any) => {
              if (data.success) {
                this._toaster.success(``, `${data.message}`, {
                  timeOut: 3000,
                  positionClass: 'toast-bottom-right',
                });
                this.downLoadFile(response, order);
              } else {
                this.generateCourierToken(options);
                this._toaster.error(``, `${data.message},`, {
                  timeOut: 3000,
                  positionClass: 'toast-bottom-right',
                });
              }
            });
        });
    });
  }
  generateAWBStreetFit(order, options): Promise<any> {
    console.log(order);
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this.generateAWBLinkStreetFit, { order: order })
        .subscribe((response: any) => {
          console.log(response);
          this._httpClient
            .put(
              this._apiUrl + 'orders/order/edit/awb',
              { awb: response.awbNumber, order_uuid: order.order_uuid },
              options
            )
            .subscribe((data: any) => {
              if (data.success) {
                this._toaster.success(``, `${data.message}`, {
                  timeOut: 3000,
                  positionClass: 'toast-bottom-right',
                });
                this.downLoadStreetFitFile(response, order);
              } else {
                this.generateCourierTokenStreetFit(options);
                this._toaster.error(``, `${data.message},`, {
                  timeOut: 3000,
                  positionClass: 'toast-bottom-right',
                });
              }
            });
        });
    });
  }

  downLoadFile(data: any, order: any) {
    // let blob = new Blob([data], { type: type });
    // let url = window.URL.createObjectURL(blob);
    // let pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //   alert('Please disable your Pop-up blocker and try again.');

    // }

    // return new Promise((resolve, reject) => {
    //   this._httpClient.post(this.downloadAWBLink, { 'awb': data, 'token': order.token })
    //     .subscribe(response => {
    //
    //     });
    // })

    const linkSource =
      this.downloadAWBLink +
      '?awb=' +
      data.awbNumber +
      '&token=' +
      order.token +
      '&order_uuid=' +
      order.order_uuid;
    // const fileName = 'order_uuid' + ".pdf";
    window.open(linkSource, '_blank');
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    // downloadLink.download = fileName;
    //downloadLink.click();
  }
  downLoadStreetFitFile(data: any, order: any) {
    const linkSource =
      this.downloadAWBLinkStreetFit +
      '?awb=' +
      data.awbNumber +
      '&tokenStreetFit=' +
      order.tokenStreetFit +
      '&order_uuid=' +
      order.order_uuid;
    window.open(linkSource, '_blank');
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
  }

  getZipCode(address): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this.geocoding + address + '&apiKey=' + this.geocodingKey)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  reverseGeocodingFn(lat, long): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(
          this.reverseGeocoding +
            'lat=' +
            lat +
            '&lon=' +
            long +
            '&apiKey=' +
            this.geocodingKey
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get orders
   *
   * @returns {Promise<any>}
   */
  getTodayOrders(options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + 'orders/today-orders', options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get products
   *
   * @returns {Promise<any>}
   */
  getTodayProducts(options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + 'orders/today-orders-products', options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get products
   *
   * @returns {Promise<any>}
   */
  getYesterdayProducts(options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + 'orders/yesterday-orders-products', options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  getYesterdayOrders(options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + 'orders/yesterday-orders', options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  getThisWeekOrders(options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + 'orders/thisWeek-orders', options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  getThisWeekProducts(options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + 'orders/thisWeek-orders-products', options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  getTwoWeeksAgoOrders(options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + 'orders/twoWeeksAgo-orders', options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  getTwoWeeksAgoProducts(options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + 'orders/twoWeeksAgo-orders-products', options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  lastYear(options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + 'orders/this-year-orders', options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  currentYear(options): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(this._apiUrl + 'orders/this-currentYear-orders', options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
}
