import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Product, ProductInterface } from 'src/app/models';
import { Category } from 'src/app/models/category.model';
import { AuthAPIService, ProductsService } from 'src/app/services';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class TestProductsService {

  private _apiUrl = environment.apiUrl;
  private options: { headers: HttpHeaders; };

  products$ = new BehaviorSubject<Product[]>([]);
  categories$ = new BehaviorSubject<any[]>([]);
  colors$ = new BehaviorSubject<any[]>([]);
  sizes$ = new BehaviorSubject<any[]>([]);
  favoriteProducts: any[];

  constructor(
    private _httpClient: HttpClient,
    private _AuthAPIService: AuthAPIService,
    private _ProductsService: ProductsService,
    private router: Router) {
    this._AuthAPIService.user.pipe(take(1)).subscribe(user => {
      if (user.access != 99) {
        this.router.navigate(['/contul-meu']);
      } else {

        let headers = new HttpHeaders();
        headers = headers.set('X-Auth-Token', user._token);
        this.options = { headers: headers };
      }
    });
  }

  /**
     * Get products
     *
     * @returns {Promise<any>}
     */

  fetchProductsTest() {
    return this._httpClient.get<Product[]>(this._apiUrl + 'products/isTest', this.options)
      .pipe(
        take(1),
        tap((products: any) => {
          this.setProductsTest(this._ProductsService.cleanObjProductCatalog(products))
        }),
      )
  }

  setProductsTest(products: any[]) {
      this.products$.next(products);
      this.getColors(products);
      this.getCategories(products);
      this.getSizes(products);
  }

  getCategories(data) {
    let productCategories = [];
    let productSubcategories = [];

    data.slice().forEach((product) => {
      product.categories.forEach((category) => {
        productCategories.push(category)
        category.subcategories.forEach((subcategory) => {
          productSubcategories.push(subcategory);
        });
      });
    });



    productCategories = productCategories.reduce((unique, o) => {
      if (!unique.some(obj => obj.id === o.id)) {
        unique.push(o);
      }
      return unique;
    }, []);



    productSubcategories = productSubcategories.reduce((unique, o) => {
      if (!unique.some(obj => obj.id === o.id)) {
        unique.push(o);
      }
      return unique;
    }, []);



    this.categories$.next(productCategories);




  }

  getColors(data) {

    let colors = [];
    data.map(product => {
      product.colors.map(color => {
        colors.push(color)
      })
    })

    colors = colors.reduce((unique, o) => {
      if (!unique.some(obj => obj.color_name === o.color_name && obj.ColorID === o.ColorID)) {
        unique.push(o);
      }
      return unique;
    }, []);

    this.colors$.next(colors);

  }

  getSizes(data) {

    let sizes = [];
    data.map(product => {
      product.colors.map(color => {
        color.sizes.map(size => {
          sizes.push(size)
        })
      })
    })



    sizes = sizes.reduce((unique, o) => {
      if (!unique.some(obj => obj.size_name === o.size_name && obj.SizeID === o.SizeID)) {
        unique.push(o);
      }
      return unique;
    }, []);

    this.sizes$.next(sizes);

  }

  public cleanObjProductCatalogTest(products: ProductInterface[]) {
    
    let productsRaw = products.slice();
    let productsClean: any[] = [];


    let multiple = productsRaw.filter(product => product.isMultiple);
    
    //productsRaw = productsRaw.filter(raw => !multiple.find(multiple => raw.id === multiple.id));

  

    // remove products sizes without price
    productsRaw.map(product => product.colors.map(color => color.sizes = color.sizes.filter(size => size.current_price > 0)))

    // remove products sizes without stoc
    productsRaw.map(product => product.colors.map(color => color.sizes = color.sizes.filter(size => size.stoc > 0)))

    // remove products colors without sizes
    productsRaw.map(product => product.colors = product.colors.filter(color => color.sizes.length > 0))

    // remove products colors without images
    productsRaw.map(product => product.isMultiple === 0 ? product.colors = product.colors.filter(color => color.images.length > 0): '')

    // remove products without colors
    productsRaw = productsRaw.filter(product => product.colors.length > 0);

    // remove products categories without subcategories
    productsRaw.map(product => product.categories = product.categories.filter(category => category.subcategories.length > 0))

    // remove products without categories
    productsRaw = productsRaw.filter(product => product.categories.length > 0);


    if (this.favoriteProducts.length > 0) {

      let existingFavoriteProducts = this.favoriteProducts.filter((ar: any) => productsRaw.some((rm: any) => (rm.id == ar.id && rm.selectedColor.ColorID == ar.selectedColor.ColorID)));
      existingFavoriteProducts.map(product => {
        let objIndex = productsRaw.findIndex((existingProduct => existingProduct.id == product.id && existingProduct.selectedColor.ColorID == product.selectedColor.ColorID));
        productsRaw[objIndex].addedToFavorites = true;
      })
    }

    

    let allProductsBasedOnColors: any[] = [];
    let colors: any[] = [];


    productsRaw.map((product: any) => {
      product.colors.map((color: any, j: number) => {
        allProductsBasedOnColors.push(product)
        colors.push(color)
      })
    })


    productsClean = allProductsBasedOnColors.map((item, i) => {
      let selectedColor = colors[i];
      let selectedPrice = selectedColor.sizes[0].current_price;
      let selectedSize = selectedColor.sizes[0];

      let selectedImage;
      let selectedImageObj;
      let images;
      if (selectedColor.images.length > 0) {
        selectedImage = selectedColor.images[0].url;
        selectedImageObj = selectedColor.images[0].url;
        images = selectedColor.images;
      }

      let sizes = selectedColor.sizes;

      //let hasDiscount = false;
      

      let selectedCategory: Category = item.categories[0];
      let selectedSubcategory = selectedCategory.subcategories;
      return item = { ...item, selectedColor, selectedPrice, selectedSize, selectedImage, selectedImageObj, sizes, selectedCategory, selectedSubcategory }
    })
    return productsClean
  }

  getProducts$(): Observable<Product[]> {
    return this.products$.asObservable();
  }
  getCategories$(): Observable<any[]> {
    return this.categories$.asObservable();
  }
  getColors$(): Observable<any[]> {
    return this.colors$.asObservable();
  }
  getSizes$(): Observable<any[]> {
    return this.sizes$.asObservable();
  }

}
