<nav aria-label="breadcrumb" class="breadcrumb-nav">
  <div class="container">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Acasa</a></li>
      <li class="breadcrumb-item active" aria-current="page">Cum se plateste</li>
    </ol>
  </div><!-- End .container -->
</nav>
<div class="mb-6"></div>
<div _ngcontent-serverapp-c133="" class="container">
  <div _ngcontent-serverapp-c133="" class="row">
    <div _ngcontent-serverapp-c133="" class="col-12">
      <div _ngcontent-serverapp-c133="" class="c8">
        <strong class="c0"><span _ngcontent-serverapp-c133="" class="c3">Informatii despre
            plata</span></strong>
        <p _ngcontent-serverapp-c133="" class="c0 c1"><span _ngcontent-serverapp-c133="" class="c2"></span></p>
        <p _ngcontent-serverapp-c133="" class="c0"><span _ngcontent-serverapp-c133="" class="c2">Plata produselor se
            poate efectua atat in numerar cat si prin card debit / credit la livrarea produsului sau prin card debit /
            credit la plasarea comenzii online de pe site. </span></p>
        <p _ngcontent-serverapp-c133="" class="c0 c1"><span _ngcontent-serverapp-c133="" class="c3"></span></p>
        <p _ngcontent-serverapp-c133="" class="c0"><span _ngcontent-serverapp-c133="" class="c2">Toate comenzile
            plasate prin intermediul acestui site se achita in urmatoarele instrumente de plata:</span></p>
        <ul _ngcontent-serverapp-c133="" class="c5 lst-kix_dntem9yy37wd-0 start">
          <li _ngcontent-serverapp-c133="" class="c0 c4 li-bullet-0">
              <strong class="c3">Plata
              online </strong></li>
        </ul>
        <p _ngcontent-serverapp-c133="" class="c0"><span _ngcontent-serverapp-c133="" class="c2">Comanda se achita
            cu cardul bancar prin intermediul EuPlatesc, euplatesc.ro. Pentru a finaliza plata este necesar sa
            completati un formular cu informatii despre cardul dumneavoastra, pe o pagina securizata. In cazul in
            care va fi necesara rambursarea unei plati in contul dumneavoastra, aceasta se va face conform regulilor
            procesatorului de plati, asa cum sunt publicate pe siteul sau (https://www.euplatesc.ro/), &nbsp;iar
            intrarea efectiva a banilor inapoi in contul dumneavoastra poate dura, durata care este independenta de
            vointa noastra si care depinde de complexitatea cazului si de modul de comunicare dintre banca emitenta
            a cardului dumneavoastra si celelalte parti implicate in tranzactie.</span></p>
        <p _ngcontent-serverapp-c133="" class="c0 c1"><span _ngcontent-serverapp-c133="" class="c2"></span></p>
        <ul _ngcontent-serverapp-c133="" class="c5 lst-kix_dntem9yy37wd-0">
          <li _ngcontent-serverapp-c133="" class="c0 c4 li-bullet-0"><strong
              class="c3">Numerar / Card la livrare</strong></li>
        </ul>
        <p _ngcontent-serverapp-c133="" class="c0"><span _ngcontent-serverapp-c133="" class="c2">Comanda se achita
            Livratorului in momentul in care va livreaza comanda prin inmanarea numerarului aferent sau, dupa caz,
            prin plata cu cardul la terminalul mobil de plata (POS Bancar)</span></p>

        <p _ngcontent-serverapp-c133="" class="c0 c1"><span _ngcontent-serverapp-c133="" class="c2"></span></p>
        <strong class="c0"><span _ngcontent-serverapp-c133="" class="c3">Costuri de
            transport</span></strong>
        <p _ngcontent-serverapp-c133="" class="c0"><span _ngcontent-serverapp-c133="" class="c2">Livram gratuit oriunde
            in Romania pentru comenzi de minim 249 Lei</span></p>
        <p _ngcontent-serverapp-c133="" class="c0"><span _ngcontent-serverapp-c133="" class="c2">Pentru comenzi mai mici
            de 249 lei, pretul transportului este de 19 lei</span></p>
        <p _ngcontent-serverapp-c133="" class="c0 c1"><span _ngcontent-serverapp-c133="" class="c7"></span></p>
      </div>
    </div>
  </div>
</div>
<div class="mb-6"></div>
