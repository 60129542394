import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { tap, take } from 'rxjs/operators';
import { AuthAPIService, AuthResponseData, NewsletterService, UserService } from 'src/app/services';
// import { SocialLoginModule, SocialAuthService, GoogleLoginProvider, FacebookLoginProvider } from '@abacritt/angularx-social-login';
@Component({
  selector: 'app-login-page',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [
    // SocialLoginModule, 
    AuthAPIService,
    UserService
  ]
})
export class LoginComponent implements OnInit {

  public responseData: any;
  public userPostData = {
    email: '',
    name: '',
    provider: '',
    provider_id: '',
    provider_pic: '',
    token: ''
  };

  public model: any = {};
  public login: any = {};

  authObs: Observable<any>;
  private userSub: Subscription;
  isAuthentificated: boolean;
  activeTab: any = 'auth';
  social: boolean = false;

  loginForm = new UntypedFormGroup({
    email: new UntypedFormControl(null, [Validators.email]),
    password: new UntypedFormControl(null, [Validators.required]),
  });

  registerForm = new UntypedFormGroup({
    emailField: new UntypedFormControl(null, [Validators.email]),
    name: new UntypedFormControl(null, [Validators.required]),
    passwordField: new UntypedFormControl(null, [Validators.required]),
    confirmPassword: new UntypedFormControl(null, [Validators.required]),
  });

  state: string = 'login';
  isSubscribed: boolean = false;

  constructor(
    // private SocialAuthService: SocialAuthService,
    private _NewsletterService: NewsletterService,
    public authAPIService: AuthAPIService,
    public router: Router,
    public user: UserService,
    private _toaster: ToastrService) {
    //this.user.sessionIn();
  }

  ngOnInit() {
    this.userSub = this.authAPIService.user.subscribe(user => {
      this.isAuthentificated = !!user;
      if (this.isAuthentificated) {
        this.router.navigate(['/contul-meu']);
      }
    })
    this.login.email = this.loginForm.value.email;
    this.login.password = this.loginForm.value.password;
  }

  changeState(state: string) {
    this.state = state;
  }


  signInWithGoogle(): void {
    // this.SocialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(userData => {
    //   this.apiConnection(userData);
    // });;
  }

  signInWithFB(): void {
    // this.SocialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(userData => {
    //   this.apiConnection(userData);
    // });;
  }

  signOut(): void {
    //this.SocialAuthService.signOut();
  }

  apiConnection(data) {

    this.userPostData.email = data.email;
    this.userPostData.name = data.name;
    this.userPostData.provider = data.provider;
    this.userPostData.provider_id = data.id;
    this.userPostData.provider_pic = data.photoUrl;
    this.userPostData.token = data.authToken;

    this.authAPIService.postData(this.userPostData, 'signup').then(
      result => {

        this.responseData = result;
        if (this.responseData.userData) {

          this.user.storeData(this.userPostData)

          this._toaster.success('', `${result['message']}`, {
            timeOut: 8000,
            positionClass: 'toast-bottom-right'
          });


          window.location.reload();
          setTimeout(() => {
            this.router.navigate(['/contul-meu'])

          }, 2000)


        }
      },
      err => {
        // this._toaster.warning('', `${result['message']}`, {
        //   timeOut: 8000,
        //   positionClass: 'toast-bottom-right'
        // });
        console.log(err);
      }
    );

  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }

    const email = form.value.email;
    const password = form.value.password;


    this.authAPIService.login(this.loginForm.value.email, this.loginForm.value.password).pipe(
      take(1),

    ).subscribe(() => console.log);
  

  //   let authObs: Observable<AuthResponseData>;

  //   authObs = this.authAPIService.login(this.loginForm.value.email, this.loginForm.value.password);



  //   authObs.subscribe(data => {
  //     if (data.user.active === 0) {
  //       this._toaster.warning('Te rugam sa accesezi link-ul din email!', `Contul tau nu este activ`, {
  //         timeOut: 3000,
  //         positionClass: 'toast-bottom-right'
  //       });
  //       return;
  //     }
  //     if (data['success']) {
  //       this._toaster.success('', `${data['message']}`, {
  //         timeOut: 8000,
  //         positionClass: 'toast-bottom-right'
  //       });


  //       window.location.reload();
  //       setTimeout(() => {
  //         this.router.navigate(['/contul-meu'])
  //       }, 2000)





  //     } else {
  //       this._toaster.warning('', `${data['message']}`, {
  //         timeOut: 8000,
  //         positionClass: 'toast-bottom-right'
  //       });
  //       //form.reset();
  //     }

  //   }, error => {
  //     console.log(error)
  //   });

   }

  signup() {
    this.model.name = this.registerForm.value.name;
    this.model.email = this.registerForm.value.emailField;
    this.model.password = this.registerForm.value.passwordField;
    this.authAPIService.signup(this.model).pipe(take(1)).subscribe(data => {
      if (data['success']) {
        this._toaster.success('', `${data['message']}`, {
          timeOut: 8000,
          positionClass: 'toast-bottom-right'
        });

        if (this.isSubscribed) {
          this._NewsletterService.addToNewsletter(this.registerForm.value.emailField).then(data => {
            if (data.success) {
              this._toaster.success(``, `${data.message}`, {
                timeOut: 3000,
                positionClass: 'toast-bottom-right'
              });
            } else {
              this._toaster.warning(``, `${data.message}`, {
                timeOut: 3000,
                positionClass: 'toast-bottom-right'
              });
            }
          })
        }

        this.router.navigate(['/confirmare-email/' + data.user.email + '/' + data.user.token]);
      } else {
        this._toaster.warning('', `${data['message']}`, {
          timeOut: 8000,
          positionClass: 'toast-bottom-right'
        });
      }

    })
  }

  authTab(activeTab) {
    this.activeTab = activeTab;
  }

  registerTab(activeTab) {
    this.activeTab = activeTab;
  }

  toggleSocial() {
    this.social = true;
  }

}

