import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormControl,
  ValidationErrors,
  FormGroup,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CartService, NewsletterService } from 'src/app/services';
import { DeliveryAddressService } from './delivery-address.service';
import { BehaviorSubject, take } from 'rxjs';
import { CheckoutService } from '../checkout.service';
import { CouponsService } from '../../cart/coupons/coupons.service';
import { DOCUMENT } from '@angular/common';
import { DeliveryMethodService } from '../delivery-method/delivery-method.service';

@Component({
  selector: 'app-delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.scss'],
})
export class DeliveryAddressComponent implements OnInit {
  form: FormGroup;

  individualDiscount: number = 0;
  @Output() formSubmit = new EventEmitter<FormGroup>();
  selectedAddress: boolean = false;

  addressFormmated = {
    name: '',
    address: '',
    url: '',
  };

  individualDiscountHasApplied$ = new BehaviorSubject<boolean>(false);
  firstOrderDiscountHasApplied$ = new BehaviorSubject<boolean>(false);
  samedayDelivery$ = new BehaviorSubject<string>('');
  formValid: boolean = true;

  constructor(
    private _NewsletterService: NewsletterService,
    private _CouponsService: CouponsService,
    private _DeliveryAddressService: DeliveryAddressService,
    private _DeliveryMethodService: DeliveryMethodService,
    private _CartService: CartService,
    private _CheckoutService: CheckoutService,
    private toaster: ToastrService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.individualDiscountHasApplied$ =
      this._DeliveryAddressService.individualDiscountHasApplied$;
    this.firstOrderDiscountHasApplied$ =
      this._DeliveryAddressService.firstOrderDiscountHasApplied$;
      this.samedayDelivery$ = this._DeliveryMethodService.samedayDelivery$;
    this.form = new FormGroup({
      phone: new FormControl(undefined, [
        Validators.required,
        this.customPhoneValidator,
      ]),
      email: new FormControl(undefined, [
        Validators.required,
        Validators.email,
      ]),
      address: new FormControl(undefined, [Validators.required]),
      city: new FormControl(undefined, [
        Validators.required,
        Validators.minLength(3),
      ]),
      // addressDetails: new FormControl(undefined, [Validators.required]),
      name: new FormControl(undefined, [
        Validators.required,
        Validators.minLength(3),
      ]),
      surname: new FormControl(undefined, [
        Validators.required,
        Validators.minLength(3),
      ]),
      county: new FormControl(undefined, [
        Validators.required,
        Validators.minLength(3),
      ]),
      gdpr: new FormControl(undefined, [Validators.requiredTrue]),
    });
  }

  ngOnInit(): void {
   
  
  }

  checkEmail() {
    this._NewsletterService.checkBadCustomer(this.form.value.email);
    // this._NewsletterService.checkDiscountFirstOrder(this.form.value.email).subscribe(() => );
    //this._DeliveryAddressService.checkForDiscount()
    this._CartService.abandonedCarts(this.form.value.email);
    
    this._DeliveryAddressService.discountAppliedEmail$.next(
      this.form.value.email
    );
   this._CheckoutService.initProperties();
  }

  cleanForm(formGroup: FormGroup) {
    // Object.keys(formGroup.controls).filter(key => key === 'email');
    Object.keys(formGroup.controls).forEach((key) => {
      if (key === 'email' && formGroup.get('email').value) {
        formGroup.get('email').setValue(formGroup.get('email').value.trim());
      }
    });
  }

  submitForm() {
    // Subscribe to lockerAddress$ to get the values
    this._DeliveryMethodService.lockerAddress$.pipe(take(1)).subscribe((lockerAddress) => {
      console.log(lockerAddress)
      if (lockerAddress && this._DeliveryMethodService.samedayDelivery$.value === 'easybox') {
        // Update the form controls with values from lockerAddress
        this.form.patchValue({
          city: lockerAddress.city,
          address: lockerAddress.name + ' ' + lockerAddress.address,
          county: lockerAddress.county,
        });
  
      }
    });
    if (!this.form.valid) {
      this.formValid = false;
    }
  
    // Return the form's raw value
    return this.form.getRawValue();
  }
  

  public handleAddressChange(address: any) {
    this.selectedAddress = true;
    this.addressFormmated = {
      name: address.name,
      address: address.formatted_address,
      url: address.url,
    };
  }

  customPhoneValidator(control: FormControl): ValidationErrors | null {
    const value = control.value;

    if (!value) {
      return null; // Don't perform validation if the field is empty.
    }

    const validPhoneRegex = /^(\+4\d{10}|\d{10})$/;

    if (!validPhoneRegex.test(value)) {
      return { invalidPhone: true }; // Validation failed for invalid input.
    }

    return null; // Validation passed.
  }
}
