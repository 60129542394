<div class="container-fluid mt-8 selected-products__container">
    <h2 class="section-title">Recomandate</h2>
    <owl-carousel-o [options]="customOptions" class="selected-products-slider dots-top dots-small" *ngIf="products.length > 0">
        <ng-container *ngFor="let product of products">
          <ng-template carouselSlide>
            <div class="product-default inner-quickview inner-icon" [attr.data-id]="product?.id">
    
                <figure>
    
                  <img class="bf-badge" *ngIf="product.isBlackFriday" src="/assets/images/banners/bf-badge.png" alt="">
                  <a routerLink="/produs/{{product?.alias}}/{{product?.id}}/{{product?.selectedColor.ColorID}}">
                    <img src="{{uploadedImgUrl}}{{product.selectedImageObj}}" alt="image">
                  </a>
                  <!-- <a *ngIf="product?.selectedImage" (click)="navigateToProduct(product?.alias, product?.id)">
                    <ng-container *ngFor="let image of product?.selectedImage.url">
                      <img src="{{uploadedImgUrl}}{{image.url}}" alt="image">
                    </ng-container>
                  </a> -->
                  <div class="label-group">
                    <div class="product-label label-sale"
                      *ngIf="product?.selectedSize && product?.selectedSize.old_price > 0">
                      {{100-((product?.selectedSize.current_price/product?.selectedSize.old_price)*100) | number : '1.0-0'
                      }}
                      %
                    </div>
    
                    <div *ngIf="product?.isRecommended === 1" class="product-label label-hot">Recomandat</div>
                  </div>
                  <!-- <div>
                    <span class="stoc-alert product-label label-sale" *ngIf="product?.selectedSize.stoc == 3">Ultimele trei produse</span>
                    <span class="stoc-alert product-label label-sale" *ngIf="product?.selectedSize.stoc == 2">Ultimele doua produse</span>
                    <span class="stoc-alert product-label label-sale" *ngIf="product?.selectedSize.stoc == 1">Ultimul produs</span>
                  </div> -->
                  <!-- <div class="btn-icon-group">
                    <button class="btn-icon btn-add-cart"
                      (click)="addToCart(product); addedToCart.triggerModal(product)"><i
                        class="icon-shopping-cart"></i></button>
                  </div> -->
                  <a (click)="quickView.triggerModal(product)" class="btn-quickview" title="Vezi Produs">Vezi
                    Produs</a>
                </figure>
                <div class="product-details">
                  <div class="category-wrap">
                    <!-- <div class="category-list">
                       <a routerLink="/produse/{{product?.categories.category_name.toLowerCase()}}/{{product?.categories.subcategories.subcategory_name.toLowerCase()}}-{{product?.categories.category_name.toLowerCase()}}"
                        class="product-category"> {{product?.categories.subcategories.subcategory_name}}
                        {{product?.categories.category_name}}</a> 
                      <ng-container *ngFor="let category of product?.categories; let isLast = last">
                        <a *ngFor="let subcategory of category.subcategories"
                          routerLink="/produse/{{category.category_name.toLowerCase()}}/{{subcategory.subcategory_name.toLowerCase()}}-{{category.category_name.toLowerCase()}}"
                          class="product-category"> {{subcategory.subcategory_name}} {{category.category_name}}
                          {{isLast ? ' ' : ' , '}} </a>
                      </ng-container>
    
                    </div> -->
                    <a
                      *ngIf="!product?.selectedColor.addedToFavorites"
                      (click)="toggleFavorites(product)"
                      class="btn-icon-wish"
                      ><i class="icon-heart"></i
                    ></a>
                    <a
                      *ngIf="product?.selectedColor.addedToFavorites"
                      (click)="toggleFavorites(product)"
                      class="btn-icon-wish"
                      ><i class="fas fa-heart"></i
                    ></a>
                  </div>
                  <div class="product-select-group" *ngIf="product?.selectedColor">
                    <ng-container *ngFor="let color of product?.colors;">
                      <ul class="color-container">
                        <li>
                          <a (click)="selectColor(product, color); changeImage(image.url)"
                            class="d-flex align-items-center justify-content-center p-0"
                            [ngClass]="{'checked': product.selectedColor.ColorID === color.ColorID}">
                            <img [src]="uploadedImgUrl + color.images[0].url" width="30" height="30" alt="filter-img" />
                          </a>
                        </li>
                      </ul>
    
                    </ng-container>
    
                  </div>
    
                  <h2 class="product-title">
                    <a (click)="navigateToProduct(product?.alias, product?.id)">{{product?.name}}</a>
                  </h2>
                  <div class="ratings-container">
    
                    <div class="product-ratings">
                      <!-- <ng-template #t let-fill="fill">
                        <span class="star" [class.full]="fill === 100">
                          <span class="half" [style.width.%]="fill">&#9733;</span>&#9734;
                        </span>
                      </ng-template>
                      <ngb-rating [(rate)]="product.rating" [starTemplate]="t" [readonly]="true" max="5"></ngb-rating>
                      <span class="tooltiptext tooltip-top">{{product?.rating}}</span> -->
                    </div><!-- End .product-ratings -->
                  </div><!-- End .product-container -->
                  <div class="price-box">
                    <span *ngIf="product?.selectedSize && product?.selectedSize.current_price > 0"
                      class="product-price" [class.reduced]="product?.selectedSize.old_price > 0">{{product?.selectedSize.current_price}} Lei</span>
                    <span *ngIf="product?.selectedSize && product?.selectedSize.old_price > 0"
                      class="old-price">{{product?.selectedSize.old_price}}
                      Lei</span>
                  </div><!-- End .price-box -->
                </div><!-- End .product-details -->
              </div>
          </ng-template>
    
        </ng-container>
      </owl-carousel-o><!-- End .products-slider -->
</div>

<app-product-quickview #quickView></app-product-quickview>