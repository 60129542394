<nav aria-label="breadcrumb" class="breadcrumb-nav">
  <div class="container">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="index.html">Acasa</a></li>
      <li class="breadcrumb-item active" aria-current="page">Comenzile mele</li>
    </ol>
  </div><!-- End .container -->
</nav>

<div class="my-account pt-95 pb-100">

  <div class="container">
    <div class="row">

      <aside class="sidebar col-lg-3">
        <div class="widget widget-dashboard">
          <h3 class="widget-title">Contul meu</h3>

          <ul class="list">
            <li routerLinkActive='active'><a routerLink="/contul-meu">Informatii cont</a></li>
            <li routerLinkActive='active'><a routerLink="/adresele-mele">Adresele mele</a></li>
            <li routerLinkActive='active'><a routerLink="/comenzile-mele">Comenzile mele</a></li>
            <!-- <li routerLinkActive='active'><a routerLink="/cupoane-reducere">Cupoane reducere</a></li> -->
            <li routerLinkActive='active'><a (click)="logout()">Delogare</a></li>
          </ul>

        </div><!-- End .widget -->
      </aside><!-- End .col-lg-3 -->
      <div class="col-lg-9 order-lg-last dashboard-content" *ngIf="orders">

        <ul class="orders">
          <li *ngFor="let order of orders | paginate: { itemsPerPage: 5, currentPage: p }">
            <div class="desc">
              <h4>Comanda nr {{order.order_uuid}}</h4>
              <p>Plasata in data: {{order.order_date | date:'dd-MM-yyyy H:mm'}}, Total: {{order.total}}</p>
            </div>

            <div class="actions">
              <a class="theme-btn-one" routerLink="/comenzile-mele/{{order.order_uuid}}">Vezi detalii
                comanda</a>
            </div>
          </li>
        </ul>
        <pagination-controls (pageChange)="p = $event" class="float-right pagination" directionLinks="true"
          autoHide="true" responsive="true" previousLabel="" nextLabel="" screenReaderPaginationLabel="Pagination"
          screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
      </div><!-- End .col-lg-9 -->
      <div class="col-lg-9 order-lg-last dashboard-content" *ngIf="!orders">
        <p>Momentan nu ai nicio comanda inreagistrata!</p>
      </div>

    </div><!-- End .row -->
  </div><!-- End .container -->

  <div class="mb-5"></div><!-- margin -->

</div>
