import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  take,
  tap,
} from 'rxjs';
import { CartService, NewsletterService } from 'src/app/services';
import { CheckoutService } from '../checkout.service';
import { CouponsService } from '../../cart/coupons/coupons.service';

@Injectable({
  providedIn: 'root',
})
export class DeliveryAddressService {
  individualDiscountHasApplied$ = new BehaviorSubject<boolean>(false);
  firstOrderDiscountHasApplied$ = new BehaviorSubject<boolean>(false);
  discountAppliedEmail$ = new BehaviorSubject<string>(null);

  constructor(
    private _NewsletterService: NewsletterService,
    private _CouponsService: CouponsService,
    private _CartService: CartService,
    private toaster: ToastrService,
    public _Router: Router
  ) {}
  checkForDiscount() {
    combineLatest([
      this._NewsletterService.badCustomer$,
      
      //this._CouponsService.individualDiscount$,
      this._CartService.totalPrice,
    ])
      .pipe(
        debounceTime(300),
        take(1),
        map(([badCustomer$, 
          //individualDiscount$, 
          
          totalPrice]) => ({
          badCustomer: badCustomer$,
          
          //individualDiscount: individualDiscount$,
          totalPrice: totalPrice,
        })),
        tap((data: any) => {
          if (data.badCustomer && data.badCustomer.success) {
            this.toaster.error(``, `${data.badCustomer.message}`, {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            this._Router.navigate(['/']);
            return;
          }

          // if (data.individualDiscount && data.individualDiscount.success) {
          //   this.toaster.success(``, `${data.individualDiscount.message}`, {
          //     timeOut: 3000,
          //     positionClass: 'toast-bottom-right',
          //   });
          //   this.individualDiscountHasApplied$.next(true);
          // } else
          if (data.firstOrder) {
            console.log('asdad')
            if (data.totalPrice > 89) {
              this.toaster.success(``, `${data.firstOrder.message}`, {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              
              this.firstOrderDiscountHasApplied$.next(true);
            } else {
              this.toaster.warning(
                ``,
                `Valoarea comenzii trebuie sa fie mai mare de 89 Lei pentru a beneficia de reducere!`,
                {
                  timeOut: 3000,
                  positionClass: 'toast-bottom-right',
                }
              );
            }
          }
        })
      )
      .subscribe(console.log);
  }
}
