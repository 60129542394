import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { RecommendedProductsComponent } from './recommended-products.component';



@NgModule({
  declarations: [
    RecommendedProductsComponent
  ],
  imports: [
    SharedModule
  ]
})
export class RecommendedProductsModule { }
