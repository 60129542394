import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MyAccountService {
  private _apiUrl: string;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */

  constructor(private _httpClient: HttpClient) {
    this._apiUrl = environment.apiUrl;
  }

  /**
   * Update User
   *
   * @returns {Promise<any>}
   */

  public updateUser(user): Promise<any> {

    return new Promise((resolve, reject) => {
      this._httpClient.post(this._apiUrl + 'user/update',
        {
          'id': user.id,
          'firstName': user.name,
          'lastName': user.last_name,
          'email': user.email,
          'password': user.password
        })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get user orders
   *
   * @returns {Promise<any>}
   */

  public getUserOrders(userEmail, token): Promise<any> {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();

      headers = headers.set('X-Auth-Token', token);
      this._httpClient.post(this._apiUrl + 'customer/orders', { 'email': userEmail }, { headers: headers })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get user order
   *
   * @returns {Promise<any>}
   */

  public getUserOrder(orderUUID, token): Promise<any> {

    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.set('X-Auth-Token', token);

      this._httpClient.post(this._apiUrl + 'customer/order', { 'order_uuid': orderUUID }, { headers: headers })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }


  /**
   * Get remommended
   *
   * @returns {Promise<any>}
   */

  // public getRecomended(categorySlug): Promise<any> {

  //     return new Promise((resolve, reject) => {
  //         this._httpClient.get<Product[]>(this._apiUrl + 'recommended/category/' + categorySlug)
  //             .subscribe((response: any) => {
  //                 resolve(response);
  //             }, reject);
  //     });
  // }
}
