<div class="banners-container text-uppercase clear">
    <div class="container">
        <div class="row row-joined">
            <div class="banner col-md-4" *ngFor="let category of categoriesRand">
                <div class="banner-content text-left">
                    <h3 class="banner-title mb-0">{{category.subcategory_name}}</h3>
                    <!-- <p>{{category.products === 1 ? category.products + ' Produs' : category.products + ' Produse'}}</p> -->
                    <hr class="divider-short-thick">

                    <a routerLink="/produse/{{category.category_name.toLowerCase()}}/{{category.alias}}" class="btn">Cumpara acum <i
                            class="fas fa-long-arrow-alt-right"></i></a>
                </div><!-- End .banner-content -->
                <a routerLink="/produse/{{category.category_name.toLowerCase()}}/{{category.alias}}">
                    <img src="{{uploadedImgUrl}}{{category.subcategory_image}}" alt="banner">
                </a>
            </div><!-- End .banner -->
            
        </div><!-- End .row -->
    </div><!-- End .container -->
</div><!-- End .banners-container -->

<div class="container mb-5">
    <div class="info-boxes-container">
        <div class="row row-joined">
            <div class="info-box info-box-icon-left col-lg-4">
                <i class="icon-shipping"></i>

                <div class="info-box-content">
                    <h4>Livrare gratuita</h4>
                    <p>Livrare gratuita pentru comenzile ce depasesc 249 Lei</p>
                </div><!-- End .info-box-content -->
            </div><!-- End .info-box -->

            <div class="info-box info-box-icon-left col-lg-4">
                <i class="icon-money"></i>

                <div class="info-box-content">
                    <h4>Retur garantat</h4>
                    <!-- <p>100% banii inapoi garantat</p> -->
                </div><!-- End .info-box-content -->
            </div><!-- End .info-box -->

            <div class="info-box info-box-icon-left col-lg-4">
                <i class="icon-support"></i>

                <div class="info-box-content">
                    <h4>SUPORT ONLINE</h4>
                    <p>De luni pana vineri intre orele 9-17</p>
                </div><!-- End .info-box-content -->
            </div><!-- End .info-box -->
        </div><!-- End .row -->
    </div><!-- End .info-boxes-container -->
</div><!-- End .container -->
