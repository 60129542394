import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Product } from '../models'
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { TitleService } from './title.service';
import { isPlatformBrowser } from '@angular/common';
import { FavoritesService } from './favorites.service';
// import { TransferStateService } from '@scullyio/ng-lib';

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    private _apiUrl: string;
    product: Product;
    productID$ = new BehaviorSubject<string>('');
    favoriteProducts: any[];
    public itemsFavorites$ = this._FavoritesService.itemsFavorites$;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */

    constructor(
        private _httpClient: HttpClient,
        private titleService: TitleService,
        private _FavoritesService: FavoritesService,
        @Inject(PLATFORM_ID) private platformId
        //private transferState: TransferStateService
    ) {
        this._apiUrl = environment.apiUrl;
        if (isPlatformBrowser(this.platformId)) {
            this.favoriteProducts = JSON.parse(
                localStorage.getItem('shardorayFavorites') || '[]'
            );
        }
    }

    /**
     * Get products
     *
     * @returns {Promise<any>}
     */

    public fetchProductByID(productID): Observable<Product> {
        // console.log(productID)
        // return this.transferState.useScullyTransferState(
        //     `product-${productID}`,
        //     this._httpClient.get<Product>(`${this._apiUrl}products/fetchIsActive/${productID}`)
        //   );
        return this._httpClient.get<Product>(this._apiUrl + 'products/fetchIsActive/' + productID)
            .pipe(
                tap((product: Product) => {
                    this.titleService.title$.next(product.seo)
                    this.titleService.meta$.next(product.meta)
                })
            )
    }
    /**
     * Get products
     *
     * @returns {Promise<any>}
     */

    public getProductInfo(productID, colorID): Observable<Product[]> {
        return this._httpClient.get<Product[]>(this._apiUrl + `products/getProductIsActive/${productID}/${colorID}`)
    }
    public getProductTest(productID, colorID): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get<Product[]>(this._apiUrl + `products/getProductIsActive/${productID}/${colorID}`)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Get product
     *
     * @returns {Observable<Product>}
     */

    public getProduct(productID) {
        return this._httpClient.get<Product>(this._apiUrl + 'products/isActive/' + productID).pipe(
            tap(product => {
                this.product = product;
            }),
        )

    }
    public getProductForCart(products) {
        return this._httpClient.post<Product>(this._apiUrl + 'products/getProductForCart', { 'products': products }).pipe(
            tap(product => {
                this.product = product;
            }),
        )

    }




    getReturnedProduct() {
        return this.product;
    }

    /**
     * Update price
     *
     * @returns {Promise<any>}
     */

    public updateProductPrice(productID, colorID, sizeID, price, options): Promise<any> {

        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/updatePrice', { 'ProductID': productID, 'ColorID': colorID, 'SizeID': sizeID, 'price': price }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Increment View
     *
     * @returns {Promise<any>}
     */

    public incrementProductView(productID): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/views/add', { 'ProductID': productID })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Update completed
     *
     * @returns {Promise<any>}
     */

    public updateToCompleted(productID, isCompleted, options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/updateToCompleted', { 'ProductID': productID, 'isCompleted': isCompleted }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Update old price
     *
     * @returns {Promise<any>}
     */

    public updateProductOldPrice(productID, colorID, sizeID, oldPrice, options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/updateOldPrice', { 'ProductID': productID, 'ColorID': colorID, 'SizeID': sizeID, 'old_price': oldPrice }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Update stoc
     *
     * @returns {Promise<any>}
     */

    public updateStoc(productID, colorID, sizeID, stoc, options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/updateStoc', { 'ProductID': productID, 'ColorID': colorID, 'SizeID': sizeID, 'stoc': stoc }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Update product name
     *
     * @returns {Promise<any>}
     */

    public updateProductName(productID, product_name, options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/updateProductName', { 'ProductID': productID, 'name': product_name }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Update product composition
     *
     * @returns {Promise<any>}
     */

    public updateComposition(productID, composition, options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/updateComposition', { 'ProductID': productID, 'composition': composition }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Update product description
     *
     * @returns {Promise<any>}
     */

    public updateDescription(productID, description, options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/updateDescription', { 'ProductID': productID, 'description': description }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Update product description
     *
     * @returns {Promise<any>}
     */

    public updateSeo(productID, seo, options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/updateSeo', { 'ProductID': productID, 'seo': seo }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Update product description
     *
     * @returns {Promise<any>}
     */
    /**
     * Update product description
     *
     * @returns {Promise<any>}
     */

    public updateMeta(productID, meta, options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/updateMeta', { 'ProductID': productID, 'meta': meta }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Update product description
     *
     * @returns {Promise<any>}
     */

    public updateIsActive(productID, isActive, options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/updateIsActive', { 'ProductID': productID, 'isActive': isActive }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Update product description
     *
     * @returns {Promise<any>}
     */

    public updateIsRecommended(productID, isRecommended, options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/updateIsRecommended', { 'ProductID': productID, 'isRecommended': isRecommended }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Update product description
     *
     * @returns {Promise<any>}
     */

    public updateIsOnSeason(productID, isOnSeason, options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/updateIsOnSeason', { 'ProductID': productID, 'isOnSeason': isOnSeason }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Update product description
     *
     * @returns {Promise<any>}
     */
    /**
     * Update product description
     *
     * @returns {Promise<any>}
     */

    public updateIsMultiple(productID, isMultiple, options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/updateIsMultiple', { 'ProductID': productID, 'isMultiple': isMultiple }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    public updateHasDiscount(productID, hasDiscount, options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/updateHasDiscount', { 'ProductID': productID, 'hasDiscount': hasDiscount }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Update product description
     *
     * @returns {Promise<any>}
     */
    /**
     * Update product description
     *
     * @returns {Promise<any>}
     */

    public updateIsTest(productID, isTest, options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/updateIsTest', { 'ProductID': productID, 'isTest': isTest }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Update product description
     *
     * @returns {Promise<any>}
     */

    public addNewProduct(
        name,
        composition,
        description,
        seo,
        meta,
        isActive,
        isRecommended,
        isOnSeason,
        isTest,
        isMultiple,
        hasDiscount,
        options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/add', {
                'name': name,
                'composition': composition,
                'description': description,
                'seo': seo,
                'meta': meta,
                'isActive': isActive,
                'isMultiple': isMultiple,
                'hasDiscount': hasDiscount,
                'isRecommended': isRecommended,
                'isOnSeason': isOnSeason,
                'isTest': isTest
            }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Update product description
     *
     * @returns {Promise<any>}
     */

    public addProductCategory(ProductID, CategoryID, options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/add/category', { 'ProductID': ProductID, 'CategoryID': CategoryID }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Update product description
     *
     * @returns {Promise<any>}
     */

    public addProductSubcategory(ProductID, SubcategoryID, options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/add/subcategory', { 'ProductID': ProductID, 'SubcategoryID': SubcategoryID }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Update product description
     *
     * @returns {Promise<any>}
     */

    public removeProductCategory(ProductID, CategoryID, options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/remove/category', { 'ProductID': ProductID, 'CategoryID': CategoryID }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Update product description
     *
     * @returns {Promise<any>}
     */

    public removeProductSubcategory(ProductID, SubcategoryID, options): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/remove/subcategory', { 'ProductID': ProductID, 'SubcategoryID': SubcategoryID }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Get category
     *
     * @returns {Promise<any>}
     */

    public getProductSizes(productID, ColorID): Promise<any> {

        return new Promise((resolve, reject) => {
            this._httpClient.get<Product[]>(this._apiUrl + 'sizes/getPrices/' + productID + '/' + ColorID)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Get category
     *
     * @returns {Promise<any>}
     */

    // public getCategory(categorySlug): Promise<any> {

    //     return new Promise((resolve, reject) => {
    //         this._httpClient.get<Product[]>(this._apiUrl + 'category/' + categorySlug)
    //             .subscribe((response: any) => {
    //                 resolve(response);
    //             }, reject);
    //     });
    // }

    /**
     * Get accesories
     *
     * @returns {Promise<any>}
     */

    // public getAccesories(): Promise<any> {

    //     return new Promise((resolve, reject) => {
    //         this._httpClient.get<Product[]>(this._apiUrl + 'accessories')
    //             .subscribe((response: any) => {
    //                 resolve(response);
    //             }, reject);
    //     });
    // }

    /**
     * Get remommended
     *
     * @returns {Promise<any>}
     */

    // public getRecomended(categorySlug): Promise<any> {

    //     return new Promise((resolve, reject) => {
    //         this._httpClient.get<Product[]>(this._apiUrl + 'recommended/category/' + categorySlug)
    //             .subscribe((response: any) => {
    //                 resolve(response);
    //             }, reject);
    //     });
    // }

    /**
     * Add product review
     *
     * @returns {Promise<any>}
     */

    // addProductReview(review): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this._httpClient.post(this._apiUrl + 'review/add',
    //             {
    //                 id: review.id,
    //                 user_name: review.user_name,
    //                 user_email: review.user_email,
    //                 user_message: review.user_message,
    //                 ProductID: review.ProductID,
    //                 rating: review.rating
    //             })
    //             .subscribe((response: any) => {
    //                 resolve(response);
    //             }, reject);
    //     });

    // }


    /**
     * Get product review
     *
     * @returns {Promise<any>}
     */

    // getProductReview(ProductID): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this._httpClient.post(this._apiUrl + 'reviews', { ProductID: ProductID })
    //             .subscribe((response: any) => {
    //                 resolve(response);
    //             }, reject);
    //     });

    // }

    /**
     * Get product rating
     *
     * @returns {Promise<any>}
     */

    // getProductRating(ProductID): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this._httpClient.post(this._apiUrl + 'review/product', { ProductID: ProductID })
    //             .subscribe((response: any) => {
    //                 resolve(response);
    //             }, reject);
    //     });

    // }

    /**
     * Change product color
     *
     * 
     */

    // public selectColor(product, color) {




    //     if (color.images) {
    //         product['selectedImage'].url = color.images;
    //     }

    //     product['selectedColor'] =

    //     {
    //         ColorID: color.ColorID,
    //         color_active: color.color_active,
    //         color_name: color.color_name,
    //         color_list: color.color_list
    //     }
    //         ;

    //     product['selectedSize'] =
    //     {
    //         SizeID: color.sizes[0].SizeID,
    //         added_at: color.sizes[0].added_at,
    //         current_price: color.sizes[0].current_price,
    //         old_price: color.sizes[0].old_price,
    //         size_name: color.sizes[0].size_name,
    //         stoc: color.sizes[0].stoc,
    //     };
    //     product['sizes'] = color.sizes;
    //     product['prices'] = color.sizes[0];

    // }

    public selectColor(product: any, color: any) {

        this.itemsFavorites$
            .pipe(
                take(1),
                map((products) => {

                    let selectedColor = color;

                    product.selectedColor = selectedColor
                    product.selectedPrice = selectedColor.sizes[0].current_price;
                    product.selectedSize = selectedColor.sizes[0];
                    product.selectedImage = selectedColor.images[0].url;
                    product.selectedImageObj = selectedColor.images[0].url;
                    product.images = selectedColor.images;
                    product.sizes = selectedColor.sizes;

                    console.log(product)

                    products.map((existingProduct) => {
                        if (existingProduct.id === product.id) {
                            product.addedToFavorites = true;
                        }
    
                        if (existingProduct.id === product.id && existingProduct.selectedColor.ColorID === product.selectedColor.ColorID) {
                            console.log('asdasd')
                            product.selectedColor.addedToFavorites = true;
                        } 
    
    
                    });

                    console.log(product)
                })
            )
            .subscribe();
    }


    /**
     * Change product size
     *
     * 
     */


    public selectSize(product, size) {


        product['prices'] = size;
        product['selectedPrice'] = size.current_price;
        product['selectedSize'] =
        {
            SizeID: size.SizeID,
            added_at: size.added_at,
            current_price: size.current_price,
            old_price: size.old_price,
            size_name: size.size_name,
            stoc: size.stoc,
        };

    }
    public selectSizeMultiple(product, complementary_product, size) {

        let complementary = product.complementary_products.find(product => product.id === complementary_product.id);

        complementary['prices'] = size;
        complementary['selectedPrice'] = size.current_price;
        complementary['selectedSize'] =
        {
            SizeID: size.SizeID,
            added_at: size.added_at,
            current_price: size.current_price,
            old_price: size.old_price,
            size_name: size.size_name,
            stoc: size.stoc,
        };

    }

    /**
   * Update product complementary
   *
   * @returns {Promise<any>}
   */

    public addRemoveComplementaryProduct(ProductID: any, products: Product[], options: {}): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/admin/addRemove/complementary', { 'id': ProductID, 'products': products }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
   * Update product complementary
   *
   * @returns {Promise<any>}
   */

    public addRemoveComplementaryProductColor(ProductID: any, colorObj: any, options: {}): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/admin/addRemove/complementaryColor', { 'ProductID': ProductID, 'colors': colorObj }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    /**
   * Update product complementary
   *
   * @returns {Promise<any>}
   */

    public addRemoveComplementaryProductSizes(ProductID: any, sizeObj: any, options: {}): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._apiUrl + 'products/admin/addRemove/complementarySize', { 'ProductID': ProductID, 'sizes': sizeObj }, options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    public getMockProducts() {
        return environment.products;
    }
}
