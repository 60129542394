import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, take, takeUntil } from 'rxjs';
import { ProductInterface } from 'src/app/models';
import { CartService } from 'src/app/services';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent implements OnInit, OnDestroy {
  itemsInCart$ = this._CartService.items$;
  products$ =  new BehaviorSubject<ProductInterface[]>([]);
  uploadedImgUrl: string;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public isCollapsed = false;
  
  constructor(
    private _CartService: CartService
  ) {
    this.uploadedImgUrl = environment.uploadImagesUrl;
  }

  ngOnInit(): void {
    this.itemsInCart$.pipe(takeUntil(this._unsubscribeAll)).subscribe((data: any) => {
      this.products$ = data;
    });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next('');
    this._unsubscribeAll.complete();
  }
}
