<div class="home-slider-container clearfix">

  <owl-carousel-o [options]="customOptions" class="home-slider owl-carousel owl-theme show-nav-hover nav-large">
    <!-- <ng-template carouselSlide>
      <div class="home-slide home-slide1 home-slide-cart d-flex align-items-center banner">
        <div class="slide-bg owl-lazy d-none d-md-block" style="background-image: url('/assets/images/slider/black-friday.png')"></div>
        <div class="slide-bg owl-lazy d-block d-sm-none" style="background-image: url('/assets/images/slider/black-friday-mobile.png')"></div>
        
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="home-slide home-slide1 d-flex align-items-center banner">
        <div class="slide-bg owl-lazy d-none d-md-block" style="background-image: url('/assets/images/slider/slide-5.png')"></div>
        <div class="slide-bg owl-lazy d-block d-sm-none" style="background-image: url('/assets/images/slider/mobile-slider-5.png')"></div>
        
        <div class="container">
          <div class="banner-layer banner-layer-middle float-left">
            <h2 class="m-b-1">Reducere la al treilea produs adugat in cos</h2>
            <h3 class="text-uppercase rotated-upto-text mb-0">-20%</h3>
            <hr class="divider-short-thick">
            <a routerLink="/produse" class="btn btn-light btn-xl btn-icon-right" role="button">Cumpara acum <i
                class="fas fa-long-arrow-alt-right"></i></a>
          </div>
        </div>

      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="home-slide home-slide1 d-flex align-items-center banner">
        <div class="slide-bg owl-lazy d-none d-md-block" style="background-image: url('/assets/images/slider/slide-1.jpg')"></div>
        <div class="slide-bg owl-lazy d-block d-sm-none" style="background-image: url('/assets/images/slider/mobile-slider-1.png')"></div>
        
        <div class="container">
          <div class="banner-layer banner-layer-middle">
            <h2 class="m-b-1">Imbracaminte femei</h2>
            <h3 class="text-uppercase rotated-upto-text mb-0">-66%</h3>

            <hr class="divider-short-thick">

            <h5 class="text-uppercase d-inline-block mb-0 ls-n-20">Preturi incepand de la <span
                class="ml-2"><em>29</em>Lei</span></h5>
            <a routerLink="/produse/femei" class="btn btn-light btn-xl btn-icon-right" role="button">Cumpara acum <i
                class="fas fa-long-arrow-alt-right"></i></a>
          </div>
        </div>

      </div>
    </ng-template>


    <ng-template carouselSlide>
      <div class="home-slide home-slide2 d-flex align-items-center banner">
        <div class="slide-bg owl-lazy d-none d-md-block" style="background-image: url('/assets/images/slider/slide-3.jpg')"></div>
        <div class="slide-bg owl-lazy d-block d-sm-none" style="background-image: url('/assets/images/slider/mobile-slider-3.png')"></div>
        
        <div class="container">
          <div class="banner-layer banner-layer-middle float-right">
            <h2 class="m-b-1">Imbracaminte barbati</h2>
            <h3 class="text-uppercase rotated-upto-text mb-0">-75% </h3>

            <hr class="divider-short-thick">

            <h5 class="text-uppercase d-inline-block mb-0 ls-n-20">Preturi incepand de la <span
                class="ml-2"><em>39</em>Lei</span></h5>
            <a routerLink="/produse/barbati" class="btn btn-light btn-xl btn-icon-right" role="button">Cumpara acum <i
                class="fas fa-long-arrow-alt-right"></i></a>
          </div>
        </div>

      </div>

    </ng-template>
    <ng-template carouselSlide>
      <div class="home-slide home-slide2 d-flex align-items-center banner">
        <div class="slide-bg owl-lazy d-none d-md-block" style="background-image: url('/assets/images/slider/slide-4.png')"></div>
        <div class="slide-bg owl-lazy d-block d-sm-none" style="background-image: url('/assets/images/slider/mobile-slider-4.png')"></div>
        
        <div class="container">
          <div class="banner-layer banner-layer-middle float-right">
            <h2 class="m-b-1">Special pentru tine!</h2>
            <h3 class="text-uppercase rotated-upto-text mb-0">-70% </h3>

            <hr class="divider-short-thick">

            <h5 class="text-uppercase d-inline-block mb-0 ls-n-20">Preturi incepand de la <span
                class="ml-2"><em>39</em>Lei</span></h5>
            <a routerLink="/produse/special-pentru-tine" class="btn btn-light btn-xl btn-icon-right" role="button">Cumpara acum <i
                class="fas fa-long-arrow-alt-right"></i></a>
          </div>
        </div>

      </div>

    </ng-template> -->

    <ng-template carouselSlide *ngFor="let slider of sliders">
      <div class="home-slide home-slide2 d-flex align-items-center banner">
        <div class="slide-bg owl-lazy d-none d-md-block" [style.background-image]="'url(' + sliderImageUrl + slider.image + ')'"></div>
        <div class="slide-bg owl-lazy d-block d-sm-none" [style.background-image]="'url(' + sliderImageUrl + slider.image_mobile + ')'"></div>
        
        <div class="container">
          <div *ngIf="slider.title && slider.discount" class="banner-layer banner-layer-rigth float-right">
         
            <!-- <h2 *ngIf="slider.title" class="m-b-1">{{slider.title}}</h2>
            <h3 *ngIf="slider.discount" class="text-uppercase rotated-upto-text mb-0">{{slider.discount}} </h3>

            <hr class="divider-short-thick" *ngIf="slider.button_text"> -->

            <!-- <h5 class="text-uppercase d-inline-block mb-0 ls-n-20">{{slider.subtitle}}<span
                *ngIf="slider.subtitle_discount" class="ml-2"><em>{{slider.subtitle_discount[0]}}</em>{{slider.subtitle_discount[1]}}</span></h5> -->
            <a *ngIf="slider.button_text" [routerLink]="slider.url[1]" class="btn btn-light btn-xl btn-icon-right" role="button">{{slider.button_text}} <i
                class="fas fa-long-arrow-alt-right"></i></a>
          </div>
        </div>

      </div>

    </ng-template>
  </owl-carousel-o><!-- End .home-slider -->
</div><!-- End .home-slider-container -->
