<div class="page-wrapper" *ngIf="_Router.url.includes('generate-bill')">
  <router-outlet></router-outlet>
</div>
<!-- <div class="page-wrapper" *ngIf="!_Router.url.includes('generate-bill')" [ngClass]="{'admin': _Router.url.includes('admin')}" (scroll)="header.makeSticky($event)"> -->
<div class="page-wrapper" *ngIf="!_Router.url.includes('generate-bill')" [ngClass]="{'admin': _Router.url.includes('admin')}">
 
  <app-header #header></app-header>
  <main class="main" [class.sidebar-opened]="statusMobile$">
    <router-outlet></router-outlet>
  </main>
  <app-footer *ngIf="!_Router.url.includes('admin')"></app-footer>
  <app-whatsapp *ngIf="!_Router.url.includes('admin')"></app-whatsapp>
</div>
<a *ngIf="!_Router.url.includes('generate-bill')" id="scroll-top" href="#top" title="Top" role="button"><i class="icon-angle-up"></i></a>
<app-newsletter-popup #newsletter *ngIf="!_Router.url.includes('admin')"></app-newsletter-popup>
