<footer class="footer bg-dark">
    <div class="halloween" *ngIf="halloween">
        <div class="halloween__inner">
     
          <div class="net-bottom-left">
            <img src="/assets/images/banners/bottom-left.svg" alt="">
          </div>
          <div class="net-bottom-right">
            <img src="/assets/images/banners/bottom-right.svg" alt="">
          </div>
        </div>
        
      </div>
    <div class="container">
        <div class="footer-middle">
            <div class="row">
                <div class="col-lg-4 col-xl-3 col-sm-6 pb-5 pb-sm-0">
                    <div class="widget">
                        
                        <ul class="contact-info">
                            <!-- <li>
                                <i class="icon-direction"></i>
                                <span class="contact-info-label">Adresa</span>Soseaua Colentina, Bucuresti
                            </li> -->
                            <li>
                                <i class="icon-phone-1"></i>
                                <span class="contact-info-label">Telefon</span><a href="tel:+40787344366">(0787) 344 366</a>
                            </li>
                            <li>
                                <i class="icon-envolope"></i>
                                <span class="contact-info-label">Email</span> <a
                                    href="mailto:office@shardoray.ro">office@shardoray.ro</a>
                            </li>
                            <li class="legal">

                                    <a href="https://ec.europa.eu/consumers/odr" target="_blank">
                                        <img src="/assets/images/anpc-sol.png" alt="">
                                    </a>
                                    <a href="https://anpc.ro/ce-este-sal/" target="_blank">
                                        <img src="/assets/images/anpc-1.png.webp" alt="">
                                    </a>
           
                               
                            </li>
                            
                        </ul>
                    </div><!-- End .widget -->
                </div><!-- End .col-lg-3 -->

                <div class="col-xl-2 offset-xl-1 col-lg-2 col-sm-3 pb-5 pb-sm-0">
                    <div class="widget">
                        <h4 class="widget-title">Utile</h4>

                        <ul class="links">
                            <li><a routerLink="/tabel-marimi">Tabel marimi</a></li>
                            <li><a routerLink="/cum-platesc">Cum platesc</a>
                            </li>
                            <li><a routerLink="/cum-se-livreaza">Cum se
                                    livreaza</a></li>
                            
                            <li><a routerLink="/formular-retur">Formular retur</a></li>
                            <li><a routerLink="/politica-retur">Politica
                                    retur</a></li>
                        </ul>
                    </div><!-- End .widget -->
                </div><!-- End .col-md-3 -->

                <div class="col-xl-2 col-lg-3 col-sm-3 pb-5 pb-sm-0">
                    <div class="widget">
                        <h4 class="widget-title">Info</h4>
                        <ul class="links">
                            <li><a routerLink="/contul-meu">Contul meu</a></li>
                            <li><a
                                    routerlink="/termeni-si-conditii"
                                    
                                    href="/termeni-si-conditii">Termeni si conditii</a></li>
                            <li><a routerlink="/confidentialitate"
                                  
                                    href="/confidentialitate">Politica confidentialitate</a></li>
                            <li><a routerlink="/politica-cookies"
                                     href="/politica-cookies">GDPR si
                                    Cookies</a></li>
                            <li><a href="https://anpc.ro/"
                                    target="_blank">ANPC</a></li>
                        </ul>
                    </div><!-- End .widget -->
                </div><!-- End .col-md-5 -->

                <div class="col-lg-3 offset-xl-1 offset-lg-0 offset-sm-6 col-sm-6">
                    <div class="widget widget-newsletter">
                        <h4 class="widget-title">Newsletter</h4>

                        <p class="mb-2 mr-3">Afla primul toate informatiile despre reduceri si oferte. Aboneaza-te acum la newsletter-ul nostru!</p>

                        <form action="#">
                            <input type="email" name="newletterEmail" [(ngModel)]="newletterEmail" class="form-control" placeholder="Adauga email..." required>

                            <button type="submit" (click)="addToNewsletter()" class="btn"><i class="fas fa-envelope"></i></button>
                        </form>
                    </div><!-- End .widget -->
                </div><!-- End .col-lg-9 -->
            </div><!-- End .row -->
        </div><!-- End .footer-middle -->

        <div class="footer-bottom d-flex flex-column flex-lg-row align-items-sm-center">
            <p class="footer-copyright mb-lg-0">&copy; Shardoray 2021</p>

            <div class="social-icons ml-lg-auto">
                <a href="https://www.instagram.com/shardoray.ro/" class="social-icon social-instagram icon-instagram" target="_blank"
                    title="Instagram"></a>
                <a href="https://www.facebook.com/shardoray.ro/" class="social-icon social-facebook icon-facebook" target="_blank"
                    title="Facebook"></a>
               
            </div><!-- End .social-icons -->
        </div><!-- End .footer-bottom -->
    </div><!-- End .container -->
</footer><!-- End .footer -->