<nav aria-label="breadcrumb" class="breadcrumb-nav">
  <div class="container">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="index.html">Acasa</a></li>
      <li class="breadcrumb-item active" aria-current="page">Adresele mele</li>
    </ol>
  </div><!-- End .container -->
</nav>
  
  <div class="my-account pt-95 pb-100">
  
    <div class="container">
      <div class="row">
        <aside class="sidebar col-lg-3">
          <div class="widget widget-dashboard">
            <h3 class="widget-title">Contul meu</h3>
  
            <ul class="list">
              <li routerLinkActive='active'><a routerLink="/contul-meu">Informatii cont</a></li>
              <li routerLinkActive='active'><a routerLink="/adresele-mele">Adresele mele</a></li>
              <li routerLinkActive='active'><a routerLink="/comenzile-mele">Comenzile mele</a></li>
              <!-- <li routerLinkActive='active'><a routerLink="/cupoane-reducere">Cupoane reducere</a></li> -->
              <li routerLinkActive='active'><a (click)="logout()">Delogare</a></li>
            </ul>
  
          </div><!-- End .widget -->
        </aside><!-- End .col-lg-3 -->
        <div class="col-lg-9 order-lg-last dashboard-content" *ngIf="addresses">
          <h2>Adresele mele</h2>
          <ul class="addresses">
            <li *ngFor="let address of addresses; let i = index" class="delivery-card card">
              <p *ngIf="!edit[i]">{{address.address}}, {{address.phone}}, {{address.town}}, {{address.county}}</p>
  
              <div class="actions" *ngIf="!edit[i]">
                <a class="theme-btn-one" (click)="editAddress(i, address)">Editeaza</a>
                <a class="theme-btn-one delete-btn" (click)="toggleDelete(i)" *ngIf="!delete[i]">Sterge</a>
  
                <div *ngIf="delete[i]" class="delete">
                  <span>Stergi adresa?</span>
                  <a class="theme-btn-two" (click)="deleteAddress(address.id)">Da</a>
                  <a class="theme-btn-two" (click)="toggleDelete(i)">Nu</a>
                </div>
              </div>
  
              <form *ngIf="edit[i]" name="form" (ngSubmit)="f.form.valid && changeAddress(i, f)" #f="ngForm" novalidate>
                <div class="row">
  
                  <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                    <label>Telefon*</label>
                    <div class="field-input">
                      <input type="text" name="phone" class="form-control" [(ngModel)]="model.phone" #phone="ngModel"
                        [ngClass]="{ 'is-invalid': f.submitted && phone.invalid }" required>
                      <div *ngIf="f.submitted && phone.invalid" class="invalid-feedback">
                        <div *ngIf="phone.errors.required">Te rugam sa introduci numarul de telefon!</div>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                    <label>Adresa*</label>
                    <div class="field-input">
                      <input type="text" name="address" class="address form-control" [(ngModel)]="model.address"
                        #address="ngModel" [ngClass]="{ 'is-invalid': f.submitted && address.invalid }" required>
                      <div *ngIf="f.submitted && address.invalid" class="invalid-feedback">
                        <div *ngIf="address.errors.required">Te rugam sa introduci adresa!</div>
                      </div>
                      <input type="text" name="address_1" [(ngModel)]="model.address_1">
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                    <label>Oras*</label>
                    <div class="field-input">
                      <input type="text" name="town_city" class="form-control" [(ngModel)]="model.town_city"
                        #town_city="ngModel" [ngClass]="{ 'is-invalid': f.submitted && town_city.invalid }" required>
                      <div *ngIf="f.submitted && town_city.invalid" class="invalid-feedback">
                        <div *ngIf="town_city.errors.required">Te rugam sa introduci orasul!</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                    <label>Judet*</label>
                    <div class="field-input">
                      <input type="text" name="county" class="form-control" [(ngModel)]="model.county" #county="ngModel"
                        [ngClass]="{ 'is-invalid': f.submitted && county.invalid }" required>
                      <div *ngIf="f.submitted && county.invalid" class="invalid-feedback">
                        <div *ngIf="county.errors.required">Te rugam sa introduci judetul!</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="edit-actions">
                  <button type="submit" class="theme-btn-one">Salveaza</button>
                  <a class="theme-btn-two" (click)="editAddress(i, address)">Renunta</a>
                </div>
              </form>
            </li>
  
  
          </ul>
  
  
        </div><!-- End .col-lg-9 -->
        <div class="col-lg-9 order-lg-last dashboard-content" *ngIf="!addresses">
          <p>Momentan nu ai salvat nicio adresa!</p>
          </div>
        
      </div><!-- End .row -->
    </div><!-- End .container -->
  
  
    <div class="mb-5"></div><!-- margin -->
  </div>
  