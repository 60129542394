<nav aria-label="breadcrumb" class="breadcrumb-nav">
  <div class="container">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Acasa</a></li>
      <li class="breadcrumb-item active" aria-current="page">Formular de retur</li>
    </ol>
  </div><!-- End .container -->
</nav>
<div class="mt-6"></div><!-- margin -->

<div class="container">

  <div class="row">
    <div class="col-12">
      <h4>Pasul 1 </h4>
      <h5>Te rugam sa adaugi numarul comenzii de pe factura</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6 uuid">
      <input type="text" [disabled]="(stepOneComplete$ | async)" class="form-control" [(ngModel)]="order_uuid" name="uuid" class="form-control">
      <button class="btn btn-dark add-cart" [disabled]="(stepOneComplete$ | async)" (click)="findOrder()">Cauta</button>
    </div>
  </div>
  <!-- <form #f="ngForm" id="ngForm" (ngSubmit)="sendReturn()" [formGroup]="form" novalidate> -->
    
    <div class="mb-4"></div>
    <div class="row" *ngIf="products">
      <div class="col-12">
        <h4>Pasul 3 </h4>
        <h5>Selecteaza produsul si motivul returului</h5>
      </div>
    </div>
    <div class="row align-items-center" *ngFor="let product of products$ | async; let i = index">
      <div class="col-12 col-md-2 d-flex product-name">
        <input type="checkbox" name="productReturn_{{i}}" id="productReturn_{{i}}"
          (change)="selectForReturn(product, i)">

        <label for="productReturn_{{i}}">
          
          <img *ngIf="product.selectedImage.url" [src]="uploadedImgUrl + (!product.selectedImage.url[0] ? product.selectedImageObj : product.selectedImage.url[0].url)" alt="">
          <img *ngIf="!product.selectedImage.url"  [src]="uploadedImgUrl + product.selectedImageObj" alt="">{{product.name}}
          
        </label>
      </div>
      <div class="col-12 col-md-2"
        *ngIf="options[i].returnMethod">
        <label for="returnMethod">Metoda de retur</label>
        <select class="form-control" #method name="returnMethod" (change)="addReturnMethod(method.value, i)">
          <option disabled selected>Alege</option>
          <option value="schimb">Schimb cu alt produs</option>
          <option value="iban">Banii in cont</option>
        </select>
      </div>

      <div class="col-12 col-md-2"
        *ngIf="options[i].motive">
        <label for="motive">Motiv</label>
        <select class="form-control" #reason name="motive" (change)="addMotive(reason.value, i)">
          <option disabled selected>Alege</option>
          <option value="Marimea prea mica">Marimea prea mica</option>
          <option value="Marimea prea mica">Marimea prea mare</option>
          <option value="Stramti, ingusti">Stramti, ingusti</option>
          <option value="Incomozi">Incomozi</option>
          <option value="Arata altfel decat in poze">Arata altfel decat in poze</option>
          <option value="Trimis gresit">Trimis gresit</option>
          <option value="Deteriorati">Deteriorati</option>
          <option value="Altele">Altele</option>
        </select>
      </div>
      <div class="col-12 col-md-2"
        *ngIf="options[i].replaceProduct">
        <label for="products">Produs inlocuitor</label>
        <app-dropdown-products (selectProduct)="selectProduct($event, i)"></app-dropdown-products>
        <!-- <ngx-select-dropdown (change)="selectProduct($event, i)" [multiple]="false"
        [(ngModel)]="selectProductChange[i]" name="products" [config]="config_products" [options]="productsChange">
      </ngx-select-dropdown> -->
      </div>
      <div class="col-12 col-md-2"
        *ngIf="options[i].color">
        <label for="color">Culoare</label>
        
        <select class="form-control" [(ngModel)]="options[i].color" name="color"
          (ngModelChange)="addColor($event, i)">
          <option value="" disabled selected>Alege</option>
          <option *ngFor="let color of ((retunObj$ | async).products | selectedProducts:i).colors" [ngValue]="color">
            {{color.color_name}}</option>
        </select>
      </div>
      <div class="col-12 col-md-2" *ngIf="options[i].size">
        <label for="color">Marime</label>
        <select class="form-control" name="size" [(ngModel)]="selectProductSizeChange[i]"
          (ngModelChange)="addSize($event, i)">
          <option value="" disabled selected>Alege</option>
          <option *ngFor="let size of ((retunObj$ | async).products | selectedProducts:i).sizes"
            [ngValue]="size">{{size.size_name}}</option>
        </select>
      </div>
    </div>
    <div class="mb-4"></div>

    <div class="row" *ngIf="products && (method$ | async) === 'iban'">
      <div class="col-12">
        <h4>Pasul 4 </h4>
        <h5>Te rugam sa adaugi datele contului tau pentru returnarea banilor</h5>
      </div>

      <div class="col-12 col-md-6 iban">
        <label>IBAN</label>
        <input (input)="removeWhiteSpaces()" class="form-control" type="text" [(ngModel)]="model.iban" id="ban" name="iban" #iban="ngModel"
          [ngClass]="{ 'is-invalid': iban.touched && iban.invalid }" required minlength="24" maxlength="24">
        <div *ngIf="iban.touched && iban.invalid" class="invalid-feedback">
          <div *ngIf="iban.errors.required">Te rugam sa introduci iban-ul tau!</div>
          <div *ngIf="iban.errors.minlength">Iban-ul trebuie sa contina 24 de caractere!</div>
        </div>
      </div>
      <div class="col-12 col-md-6 iban">
        <label>Titular cont</label>
        <input class="form-control" type="text" [(ngModel)]="model.name" id="ban" name="name" #name="ngModel"
          [ngClass]="{ 'is-invalid': name.touched && name.invalid }" required>
        <div *ngIf="name.touched && name.invalid" class="invalid-feedback">
          <div *ngIf="name.errors.required">Te rugam sa introduci numele titularului!</div>
        </div>
      </div>
    </div>
    <div class="mb-4"></div>

    <div class="row" *ngIf="products">
      <div class="col-12">
        <h4 *ngIf="(method$ | async) === 'iban'">Pasul 5 </h4>
        <h4 *ngIf="(method$ | async) != 'iban'">Pasul 4 </h4>
        <h5>Coletul se va expedia prin orice firma de curierat doriti, taxele de expeditie fiind suportate de catre dvs.
        </h5>
        <h5>Adresa de expeditie: Str. Elena, nr. 11, Sector 2, Bucuresti</h5>
        <h5>Produsele trebuie ambalate in carton si asigurate corespunzator inainte de expediere</h5>
      </div>
      <div class="col-12 actions">
        <button class="btn btn-dark add-cart" (click)="checkIfErrors()">Trimite</button>
      </div>
    </div>
  <!-- </form> -->
</div>
<div class="mb-6"></div><!-- margin -->
