/* eslint-disable prefer-const */
import { TitleService } from 'src/app/services/title.service';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
// import { isScullyRunning } from '@scullyio/ng-lib';
import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent,
} from 'ngx-cookieconsent';
import { Subject, Subscription, timer, filter, map } from 'rxjs';
import { UtilityService } from './services/utility.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild('newsletter') newsletter;
  statusMobile$: any;
  admin$: string;
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  constructor(
    public _Router: Router,
    private _Util: UtilityService,
    private ccService: NgcCookieConsentService,
    private _ActivatedRoute: ActivatedRoute,
    private titleService: TitleService,
    private titleService2: Title,

    @Inject(PLATFORM_ID) private platformId
  ) {}

  ngOnInit() {
    if (!this.getData() || this.getData().closed != 'closed') {
      timer(3000).subscribe((x) => {
        this.newsletter.triggerModal();
      });
    }

    this._Router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationStart)) {
        const appRoot = document.querySelector('app-root');
        appRoot.classList.remove('d-block');
      }

      if (!(evt instanceof NavigationEnd)) {
        timer(800).subscribe((x) => {
          const appRoot = document.querySelector('app-root');
          appRoot.classList.add('d-block');
        });
      }
    });

    this._Util.statusMobile.subscribe((info) => {
      this.statusMobile$ = info;
    });

    this._Util.admin.subscribe((admin) => {
      this.admin$ = admin;
    });

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
      console.log('popupOpen');
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
      console.log('popuClose');
    });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log(`initialize: ${JSON.stringify(event)}`);
      }
    );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log(`statusChange: ${JSON.stringify(event)}`);
      }
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log(`revokeChoice`);
      }
    );

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log(`noCookieLaw: ${JSON.stringify(event)}`);
      }
    );
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  getData() {
    if (isPlatformBrowser(this.platformId)) {
      return JSON.parse(localStorage.getItem('popupShardoray'));
    }
  }
}
