<ng-template #modalData let-modal>
  <div class="product-single-container product-single-default product-quick-view">
    <div class="row">
      <div class="col-md-6 product-single-gallery">
        <div class="product-slider-container">
         

          <owl-carousel-o [options]="customOptionsQuick" class="product-single-carousel show-nav-hover"
          (change)="slideChange($event)" #owlCar>
          <ng-container *ngFor="let image of product?.images; let i = index">
            <ng-template carouselSlide [id]="image.url">
              <div class="product-item">
                <img class="product-single-image" [src]="uploadedImgUrl + image.url" width="915" height="915"
                  alt="product" />
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
        </div>
        <!-- <div class="prod-thumbnail" id='carousel-custom-dots'>
      
          <owl-carousel-o [options]="dotsOptions" *ngIf="additionalImages > 0">
           
            <ng-container *ngFor="let image of product?.images; let i = index">
              <ng-template carouselSlide [id]="image.url+i" >
                <a (click)="owlCar.to(uploadedImgUrl + image.url); changeImage(image.url+i)" [width]="80">
                  <img [src]="uploadedImgUrl + image.url"
                    [ngClass]="{'product-gallery__carousel-item--active':selectedImage === image.url+i || (i == 0 && !selectedImage)}">
                </a>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div> -->
      </div><!-- End .product-single-gallery -->

      <div class="col-md-6 product-single-details">
        <h1 class="product-title">{{product.name}}</h1>

        <div class="ratings-container">
          <!-- <div class="product-ratings">
            <span class="ratings" style="width:60%"></span>
          </div> -->

          <!-- <a href="#" class="rating-link">( 6 Recenzii )</a>
          <p>Stoc: {{product?.stoc}}</p> -->
        </div><!-- End .ratings-container -->

        <div class="price-box">
          <span class="product-price" [class.reduced]="product?.selectedSize.old_price > 0">{{product?.selectedSize.current_price}} Lei</span>
          <span *ngIf="product?.selectedSize.old_price > 0" class="old-price">{{product?.selectedSize.old_price}}
            Lei</span>
        </div><!-- End .price-box -->
        <div class="product-desc">
          <p>
            Compozitie:
            {{product.composition}}
          </p>
          <p>
            Descriere:
          </p>
          <p [innerHTML]="product.description"></p>
        </div><!-- End .product-desc -->
        <div class="product-filters-container">
          <div class="product-single-filter mb-2">
            <label>Culori:</label>
            <!-- <ng-container *ngFor="let color of product?.colors; first as isFirstColor; let i = index">
              <a *ngIf="color.color_list.length === 1" (click)="selectColor(product, color)"
                class="product-select type-image"
                [ngClass]="{'checked': product.selectedColor.ColorID === color.ColorID}"
                [style.background-color]="color.color_list[0]"></a>
              <a *ngIf="color.color_list.length === 0" (click)="selectColor(product, color)"
                class="product-select type-image"
                [ngClass]="{'checked': product.selectedColor.ColorID === color.ColorID}"
                style="background-color: #fff;"></a>
              <a (click)="selectColor(product, color)" class="product-select type-image two-colors"
                [ngClass]="{'checked': product.selectedColor.ColorID === color.ColorID}"
                *ngIf="color.color_list.length > 1">
                <span [style.background-color]="color.color_list[0]"></span>
                <span [style.background-color]="color.color_list[1]"></span>
              </a>
            </ng-container> -->
            <ng-container *ngFor="let color of product?.colors;">
              <ul class="color-container">
                <li>
                  <a (click)="selectColor(product, color); changeImage(image.url)"
                    class="d-flex align-items-center justify-content-center p-0"
                    [ngClass]="{'checked': product.selectedColor.ColorID === color.ColorID}">
                    <img [src]="uploadedImgUrl + color.images[0].url" width="30" height="30" alt="filter-img" />
                  </a>
                </li>
              </ul>
              
            </ng-container>
           
          </div><!-- End .product-single-filter -->
        </div><!-- End .product-filters-container -->


        <div class="product-filters-container">
          <div class="product-single-filter mb-2">
            <label>Marimi:</label>
            <ul class="sizes">
              <li *ngFor="let size of product?.sizes">
                <a (click)="selectSize(product, size);" class="product-select type-image"
                  [ngClass]="{'checked': product.selectedSize.SizeID === size.SizeID}">{{size.size_name}}</a>
              </li>
            </ul>
          </div><!-- End .product-single-filter -->
        </div><!-- End .product-filters-container -->

        <div class="product-action">
          <div class="product-single-qty">
            <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected">
              <span class="input-group-btn input-group-prepend">
                <button (click)="removeOneFromCart()"
                  class="btn btn-outline btn-down-icon bootstrap-touchspin-down"></button>
              </span>
              <input class="horizontal-quantity form-control" min="1" [(ngModel)]="cartQuantity" value="1" type="number"
                name="quantity">
              <span class="input-group-btn input-group-append">
                <button (click)="addOneToCart()" class="btn btn-outline btn-up-icon bootstrap-touchspin-up"></button>
              </span>
            </div>
          </div><!-- End .product-single-qty -->

          <a (click)="addToCart(product); addedToCart.triggerModal(product)"
            class="btn btn-dark add-cart icon-shopping-cart" title="Add to Cart">Adauga in cos</a>
        </div><!-- End .product-action -->

        <div class="product-single-share mt-2">
          <div class="row">
            <div class="col-12">
              <label class="sr-only">Distribuie:</label>
              <share-buttons [theme]="'circles-dark'"
                [include]="['facebook', 'messenger','whatsapp', 'twitter','linkedin','pinterest','reddit','email','copy']"
                [show]="3" [size]="1"
                [url]="'/produs/' + product?.alias +'/' + product?.id"
                [image]="'product?.images[0].src'" [autoSetMeta]="false">
              </share-buttons>
            </div>
          </div>
          <!-- <a href="#" class="add-wishlist" title="Add to Wishlist">Adauga la favorite</a> -->
        </div><!-- End .product single-share -->
      </div><!-- End .product-single-details -->
    </div><!-- End .row -->
    <button (click)="modal.dismiss('Cross click')" type="button" class="mfp-close">×</button>
  </div><!-- End .product-single-container -->
</ng-template>
<app-added-to-cart #addedToCart></app-added-to-cart>
