import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  AuthAPIService,
  CartService,
  CategoriesService,
  FavoritesService,
  NewsletterService,
  ProductService,
  ProductsService,
} from 'src/app/services';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable, Subscription, fromEvent } from 'rxjs';
import { PixelService } from 'ngx-pixel';
import { json } from 'express';
import { DOCUMENT, JsonPipe } from '@angular/common';
import { AlertsService } from 'src/app/admin/alerts/alerts.service';
import { CouponsService } from 'src/app/pages/cart/coupons/coupons.service';
import { CheckoutService } from 'src/app/pages/checkout/checkout.service';
import { Coupon } from 'src/app/pages/cart/coupons/coupons.type';
import { DataLayerService } from 'src/app/services/dataLayer.service';

declare let dataLayer: any[];
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class HeaderComponent implements OnInit {
  scroll: string;
  sticky: string;
  lastScroll: number = 0;
  fixed: string;
  totalPrice$ = new BehaviorSubject<number>(0);
  fullPrice$: any;
  cartTotal$ = new BehaviorSubject<number>(0);
  public items$ = this._CartService.items$;
  // public itemsFavorites$ = this._FavoritesService.itemsFavorites$;
  cartProducts: any;
  open: boolean;
  categories: any;
  activeCategory: any;
  uploadedImgUrl: string;
  authObs: Observable<any>;
  private userSub: Subscription;
  isAuthentificated: boolean;
  search: boolean;
  isAdmin: boolean = false;
  results: boolean;
  productSearch: any;
  products: any;
  discount: string;
  halloween: boolean;
  favorites: any[] = [];

  // black friday
  targetDate: any = new Date(2023, 11, 1, 23, 59, 59);
  targetTime: any = this.targetDate.getTime();
  difference: number;
  date: any = new Date();
  now: any;
  startDate: any = new Date(2023, 10, 9, 23, 59, 59);
  startTime: any = this.startDate.getTime();
  isBlackFriday: boolean = true;

  @ViewChild('days', { static: true }) days: ElementRef;
  @ViewChild('hours', { static: true }) hours: ElementRef;
  @ViewChild('minutes', { static: true }) minutes: ElementRef;
  @ViewChild('seconds', { static: true }) seconds: ElementRef;

  alert: any = {};

  individualDiscountHasApplied$ = new BehaviorSubject<boolean>(false);
  firstOrderDiscountHasApplied$ = new BehaviorSubject<boolean>(false);
  thirdProductInCartHasDiscount$ = new BehaviorSubject<boolean>(false);
  discountCart$ = new BehaviorSubject<boolean>(false);
  individualDiscountValue$ = new BehaviorSubject<Coupon>({} as Coupon);
  private resizeSubscription: Subscription;
  isMobile: boolean = false;
  showCart: boolean = false;

  constructor(
    public _Router: Router,
    private _CartService: CartService,
    private _CategoriesService: CategoriesService,
    private _FavoritesService: FavoritesService,
    private _DomSanitizationService: DomSanitizer,
    private _ProductsService: ProductsService,
    private _ProductService: ProductService,
    public authAPIService: AuthAPIService,
    private _NewsletterService: NewsletterService,
    private _CouponsService: CouponsService,
    private _CheckoutService: CheckoutService,
    private _AlertsService: AlertsService,
    private _eref: ElementRef,
    private pixel: PixelService,
    private _DataLayeService: DataLayerService,
    @Inject(DOCUMENT) private _document: any,
    private renderer: Renderer2
  ) {
    //this.categories = environment.categories;
    this.uploadedImgUrl = environment.uploadImagesUrl;
    this.individualDiscountHasApplied$ =
      this._CouponsService.individualDiscountHasApplied$;
    this.firstOrderDiscountHasApplied$ =
      this._NewsletterService.firstOrderDiscountHasApplied$;
    this.discountCart$ = this._CheckoutService.discountCart$;
    this.individualDiscountValue$ = this._CouponsService.individualDiscount$;
    this.totalPrice$ = this._CartService.totalPrice;
    this.cartTotal$ = this._CartService.numTotal;

    this._Router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.showCart = false;
      }
    });
  }

  ngOnInit(): void {
    // Check the initial window size
    //  this.isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
    //  this.onResize();
    // Listen for window resize events using RxJS
    //  this.resizeSubscription = fromEvent(window, 'resize')
    //    .subscribe((event: Event) => {
    //      this.onResize();
    //    });

    this.halloween = environment.halloween;
    this._CategoriesService.getCategoriesWithProducts().then((data) => {
      this._CategoriesService.generateCategoriesFeed(data);

      this.categories = data;
      this._ProductsService.categories$.next(data);
    });

    this._CartService.fullPrice.subscribe((info) => {
      this.fullPrice$ = info.toFixed(2);
    });

    this._ProductsService.getProductsAll().then((products) => {
      
      this._ProductsService.generateCatalogFeed(
        this._ProductsService.cleanObjProductCatalog(products)
      );
      this._ProductsService.generateTheMarketerFeed(
        this._ProductsService.cleanObjProductCatalog(products)
      );
      console.log(products)
      this._ProductsService.productsSearch$.next(
        this._ProductsService.cleanObjProductCatalog(products)
      );
      this._ProductsService.productsAll$.next(products);
    });

    // this._CartService.getCart().pipe(take(1)).subscribe();

    // this._CartService.numTotal.subscribe((info) => {
    //   this.cartTotal$ = info;

    //   if (info >= 3) {
    //     this.discount = '-20%';
    //   } else {
    //     this.discount = null;
    //   }
    // });

    this.getProducts();

    this._Router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationStart)) {
        this.activeCategory = '';
        this.open = false;
        this.search = false;
        this.results = false;
      }
    });

    this.userSub = this.authAPIService.user.pipe(take(1)).subscribe((user) => {
      this.isAuthentificated = !!user;
      if (this.isAuthentificated && user.access == 99) {
        this.isAdmin = true;
      }

      if (
        this.isAuthentificated &&
        !this._NewsletterService.firstOrderDiscountHasApplied$.value
      ) {
        this._NewsletterService
          .checkDiscountFirstOrder(user.email)
          .subscribe(() =>
            this._CartService.items$.value.length > 0
              ? this._CartService.calcTotal()
              : ''
          );
      }
    });

    this.totalPrice$
      .pipe(
        distinctUntilChanged(),
        filter((value) => value > 89),
        withLatestFrom(
          this._NewsletterService.remembeAboutTheFirstOrder$,
          this._NewsletterService.firstOrderDiscountHasApplied$,
          this._CouponsService.individualDiscountHasApplied$
        ),
        filter(
          ([
            totalPrice,
            rememberFirstOrder,
            discountApplied,
            individualDiscountApplied,
          ]) =>
            rememberFirstOrder && !discountApplied && !individualDiscountApplied
        ),
        take(1),
        switchMap(() =>
          this._NewsletterService.checkDiscountFirstOrder(
            this.authAPIService.user.value.email
          )
        ),
        tap(() => this._CartService.calcTotal())
      )
      .subscribe();

    this._FavoritesService.fetchFavorites();
    this._FavoritesService.itemsFavorites$
      .pipe(distinctUntilChanged())
      .subscribe((favorites) => {
        this.favorites = favorites;
      });

    this.startDate.setHours(0, 0, 0, 0);

    setInterval(() => {
      this.tickTock();
      this.difference = this.targetTime - this.now;
      this.difference = this.difference / (1000 * 60 * 60 * 24);

      !isNaN(this.days.nativeElement.innerText)
        ? (this.days.nativeElement.innerText = Math.floor(this.difference))
        : (this.days.nativeElement.innerHTML = `<img src="https://i.gifer.com/VAyR.gif" />`);
    }, 1000);

    setTimeout(() => {
      const slider = this._document.querySelector('app-slider');
      const header = this._document.querySelector('app-header');
      slider.style.height = window.innerHeight - header.offsetHeight + 'px';
    }, 200);

    this._AlertsService
      .getAlerts()
      .pipe(take(1))
      .subscribe((data) => {
        this.alert.backgroundColor = data.alert.color;
        this.alert.row_one = data.alert.row_one;
        this.alert.row_two = data.alert.row_two;
      });
  }

  onResize() {
    // Update the isMobile flag based on the window size
    this.isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
    console.log(this.isMobile);
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      //this.openUser = false;
      this.search = false;
      this.results = false;
      // this.results = false;
      // if (!event.target.closest('.shopping-cart-delete')) {
      //   this.openCart = false;
      // }
    }
  }

  toggleFavorites(product: any) {
    this._FavoritesService.toggleFavorites(product);
  }

  makeSticky(event) {
    const currentScroll = event.target.scrollTop;
    if (currentScroll > 125) {
      this.sticky = 'fixed';
    }

    if (currentScroll <= 125) {
      this.scroll = '';
      this.sticky = '';
      return;
    }

    if (
      currentScroll > this.lastScroll &&
      this.scroll != 'scrollDown' &&
      currentScroll > 550
    ) {
      // down
      // this.scroll = 'scrollDown';
    } else if (
      currentScroll < this.lastScroll &&
      this.scroll === 'scrollDown'
    ) {
      // up
      this.scroll = 'scrollUp';
    }
    this.lastScroll = currentScroll;
    this.results = false;
    this.search = false;
  }

  getProducts() {
    this.items$
      .pipe(
        take(1),
        map((products) => {
          products.map((product) => {
            if (product['selectedColor'].color_list > 1) {
              product['selectedColor'].color_list =
                product['selectedColor'].color_list.split(',');
            }

            //product.selectedImage =
          });

          this.cartProducts = products;
          this.cartTotal$;
        })
      )
      .subscribe();
  }

  removeCart(product) {
    this._CartService.removeFromCart(product);
    this.getProducts();
  }

  toggleMenu() {
    this.open = !this.open;
    this.sticky = '';
    this.results = false;
    this.search = false;
    this.scroll = '';
  }

  toggleCategory(category) {
    if (this.activeCategory == category) {
      this.activeCategory = '';
    } else {
      this.activeCategory = category;
    }
    this.results = false;
    this.search = false;
  }

  toggleSearch() {
    this.search = !this.search;
  }

  removeFromFavorites(product) {
    this._FavoritesService.toggleFavorites(product);
  }

  addToCart(product) {
    this._CartService.addToCart(product, 1);
  }

  logout() {
    this.authAPIService.logout();
  }

  onSearchChange() {
    this._ProductsService.searchProductValue$.next(this.productSearch);
    this._Router.navigateByUrl('/produse/cautare');
  }

  navigateToCategory(alias) {
    this._Router.navigateByUrl('/produse/' + alias);
  }

  tickTock() {
    this.date = new Date();
    this.now = this.date.getTime();
    this.hours.nativeElement.innerText = 23 - this.date.getHours();
    this.minutes.nativeElement.innerText = 60 - this.date.getMinutes();
    this.seconds.nativeElement.innerText = 60 - this.date.getSeconds();

    this.isBlackFriday =
      this.date > this.startDate.setHours(0, 0, 0, 0) &&
      this.date < this.targetDate;
  }

  ngOnDestroy() {
    // Unsubscribe from the resize event when the component is destroyed
    this.resizeSubscription.unsubscribe();
  }
}
