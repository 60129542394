import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NewsletterService } from 'src/app/services';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  newletterEmail: string;
  halloween: boolean;

  constructor(
    private _NewsletterService: NewsletterService,
    private toaster: ToastrService,) { }

  ngOnInit(): void {
    this.halloween = environment.halloween;
  }

  addToNewsletter() {
    this._NewsletterService.addToNewsletter(this.newletterEmail).then(data => {
      if (data.success) {
        this.toaster.success(``, `${data.message}`, {
          timeOut: 3000,
          positionClass: 'toast-bottom-right'
        });
      } else {
        this.toaster.warning(``, `${data.message}`, {
          timeOut: 3000,
          positionClass: 'toast-bottom-right'
        });
      }
      this.newletterEmail = '';
    })
  }

}
