import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { DiscountProductsComponent } from './discount-products.component';



@NgModule({
  declarations: [
    DiscountProductsComponent
  ],
  imports: [
    SharedModule
  ]
})
export class DiscountProductsModule { }
