import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PaymentMethodService } from './payment-method.service';
import { DeliveryMethodService } from '../delivery-method/delivery-method.service';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent {
  payment$ = new BehaviorSubject<string>('');
  samedayDelivery$= new BehaviorSubject<string>('');

  constructor(
    private _PaymentMethodService: PaymentMethodService,
    private _DeliveryMethodService: DeliveryMethodService
  ) {
    this.payment$ = this._PaymentMethodService.payment$;
    this.samedayDelivery$ = this._DeliveryMethodService.samedayDelivery$;
  }


  handlePaymentChange(value: string) {
    console.log(value)
    this._PaymentMethodService.payment$.next(value);
    if (value === 'numerar') {
      this._DeliveryMethodService.samedayDelivery$.next('curier');
    }
  }

}
