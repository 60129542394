import { Color } from "./color.model";
import { Category } from "./category.model";
import { Size } from "./size.model";
import { Subcategory } from "./subcategory.model";
import { Images } from "./image.model";
export class Product {
    alias: string;
    num: number;
    id: number;
    selectedPrice: number;
    fullPrice: number;
    isMultiple: number;
    product_name: string;
    description: string;
    hasDiscount: boolean;
    ingredients: string;
    categories: [];
    images: any[];
    information: [];
    availableQuantities: [];
    selectedImage: [];
    selectedColor: any;
    selectedSize: any;
    complementary_products: string ;
    selectedImageObj: any;
    cart_uuid: string;
    seo: string;
    meta: string



    /**
     * Constructor
     *
     * @param product
     */

    constructor(product?) {
        product = product || {};
        this.num = product.num;
        this.alias = product.alias;
        this.seo = product.seo;
        this.meta = product.meta;
        this.id = parseInt(product.id)
        this.selectedPrice = parseInt(product.selectedPrice)
        this.fullPrice = parseInt(product.fullPrice)
        this.isMultiple = parseInt(product.isMultiple)
        this.product_name = product.product_name || '';
        this.description = product.description || '';
        this.hasDiscount = product.hasDiscount || '';
        this.ingredients = product.ingredients || '';
        this.categories = product.categories || [];
        this.images = product.images || [];
        this.information = product.information || [];
        this.availableQuantities = product.availableQuantities || [];
        this.complementary_products = product.complementary_products || '';
        this.cart_uuid = product.cart_uuid || '';
    }
}

export interface ProductInterface {
    added_at: Date
    alias: string
    categories: Category[]
    colors: Color[];
    sizes: Size[]
    composition: string
    description: string
    id: number
    isActive: number
    isOnSeason: number
    isInStoc: number
    isRecommended: number,
    isMultiple: number
    name: string
    SKUBazar: string
    SKUBerkay: string
    selectedColor: Color;
    selectedImage: any
    selectedSize: Size,
    selectedPrice: number
    selectedImageObj: string
    views: string,
    addedToFavorites?: boolean
    addedToCart?: boolean
    num?: number,
    cart_uuid?: string,
    hasDiscount: boolean,
    products?: Product[]
    images: Images[],
    ratingScore: any,
    ratingsCount: number,
    selectedCategory: Category,
    selectedSubcategory: Subcategory[],
    disabled: boolean
}[]


export interface ReturnProduct {
    order_uuid: string,
    products: any[],
    userAccess: number,
    return_iban_request: string,
    return_iban_name: string

}

export class ProductModel {
    filter(arg0: (existingProduct: any) => boolean): any {
      throw new Error('Method not implemented.');
    }
    alias: string;
    num: number;
    id: number;
    selectedPrice: number;
    fullPrice: number;
    product_name: string;
    description: string;
    hasDiscount: boolean;
    ingredients: string;
    categories: [];
    images: [];
    information: [];
    availableQuantities: [];
    selectedImage: [];
    selectedImageObj: any;
    cart_uuid: string;
}


