<nav aria-label="breadcrumb" class="breadcrumb-nav">
  <div class="container">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Acasa</a></li>
      <li class="breadcrumb-item active" aria-current="page">Termeni si conditii</li>
    </ol>
  </div><!-- End .container -->
</nav>
<div class="mt-6"></div><!-- margin -->
<div class="container">
  <div class="jas-container">
    <div class="vc_row">
      <div class="wpb_column vc_column_container vc_col-sm-12">
        <div class="vc_column-inner ">
          <div class="wpb_wrapper">
            <div class="wpb_text_column wpb_content_element ">
              <div class="wpb_wrapper">
                <h4>Pasi de urmat pentru retur</h4>
                <p>Daca un produs achizitionat nu te multumeste pe deplin, il poti returna in 14 de zile calendaristice de la momentul livrarii, urmand doar 3 pasi simpli:</p>
                <p>1.Completeaza si trimite formularul de retur pe care il gasesti in sectiunea UTILE de pe site.Fa acest lucru pana cel tarziu in a 14-a zi calendaristica de la data livrarii.Returnati produsele in ambalajul original fara semne de uzura, murdarie, mirosuri de parfum sau de alta natura.</p>
                <p>2.Asteapta confirmarea din partea unui operator Shardoray privind inregistrarea cererii si trimite produsele cu orice firma de curierat doresti, taxa de transport fiind suportata de client.</p>
                <p>3.Daca produsul returnat indeplineste conditiile de retur (produsul nu trebuie sa fie deteriorat sau sa prezinte alte defecte mecanice, eticheta sa nu fie rupta, factura produsului sa fie in original), iti vom returna banii in contul specificat de tine in formularul de retur in termen de 14 zile lucratoare din momentul in care produsele au ajuns la sediul nostru (SHARDORAY). Suma ce urmeaza a fi restituita in contul dumneavoastra, reprezinta doar contravaloarea produsului achitizionat, fara costul de transport, mentionat mai jos.</p>
                <p>4.Nu returnati catre noi produse cumparate din alte magazine, chiar daca sunt la fel cu cele comercializate de site-ul www.SHARDORAY.ro Acestea se returneaza daca legea permite catre magazinele de unde le-ati cumparat in baza bonului fiscal primit la cumparare. Nu expediati produsul daca l-ati purtat sau l-ati deteriorat din vina dumneavoastra. Orice retur care nu respecta conditiile de mai sus va fi refuzat.</p>
               
                <p><strong>IMPORTANT!</strong> &nbsp;<strong>Schimbul Produselor Defecte este </strong>
                  <strong>GRATUIT</strong></p>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mb-6"></div>