<nav aria-label="breadcrumb" class="breadcrumb-nav">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/">Acasa</a></li>
        <li class="breadcrumb-item active" aria-current="page">Politica de confidentialitate</li>
      </ol>
    </div><!-- End .container -->
  </nav>
  <div class="mt-6"></div><!-- margin -->
<div _ngcontent-serverapp-c118="" class="pt-100 pb-100">
  <div _ngcontent-serverapp-c118="" class="container">
    <div _ngcontent-serverapp-c118="" class="row">
      <div _ngcontent-serverapp-c118="" class="col-12">
        <div _ngcontent-serverapp-c118="" class="c17">
          <p _ngcontent-serverapp-c118="" class="c6"><span _ngcontent-serverapp-c118="" class="c11 c3">POLITICA DE
              CONFIDENȚIALITATE</span></p>
          <p _ngcontent-serverapp-c118="" class="c6"><span _ngcontent-serverapp-c118="" class="c3">Acest website
              reprezintă proprietatea exclusivă </span><span _ngcontent-serverapp-c118="">CROW FASHION INTERNATIONAL SRL</span><span _ngcontent-serverapp-c118="" class="c11 c3">, persoană juridică de origine română,
              &nbsp;denumit in continuare “SHARDORAY”</span></p>
          <p _ngcontent-serverapp-c118="" class="c5"><span _ngcontent-serverapp-c118="" class="c1">Scopul acestei
              politici de confidențialitate este să explice ce date culegem de la vizitatorii website-ului nostru, de ce
              le culegem și ce facem cu ele.</span></p>
          <p _ngcontent-serverapp-c118="" class="c6"><span _ngcontent-serverapp-c118="" class="c3">Shardoray
            </span><span _ngcontent-serverapp-c118="" class="c1">dorește să protejeze confidențialitatea utilizatorilor
              care vizitează website-ul nostru și facem toate demersurile necesare în acest sens. </span></p>
          <p _ngcontent-serverapp-c118="" class="c5"><span _ngcontent-serverapp-c118="" class="c1">Această politică de
              confidențialitate se aplică exclusiv datelor colectate prin intermediul acestui site.</span></p>
          <hr _ngcontent-serverapp-c118="">
          <p _ngcontent-serverapp-c118="" class="c5 c13"><span _ngcontent-serverapp-c118="" class="c1"></span></p>
          <p _ngcontent-serverapp-c118="" class="c7 c12"><span _ngcontent-serverapp-c118="" class="c11 c3">CINE VA
              PRELUCRA DATELE DVS. CU CARACTER PERSONAL?</span></p>
          <p _ngcontent-serverapp-c118="" class="c7 c12"><span _ngcontent-serverapp-c118="">Prelucrarea datelor dvs. cu
              caracter personal se va face de către societatea &nbsp;</span><span _ngcontent-serverapp-c118=""
              class="c1">CROW FASHION INTERNATIONAL SRL, cu sediul social in Mosoaia, Nr.45 A Bucuresti, persoană juridică 
              romană înregistrată la Oficiul Registrului Comertului sub
              nr.J40/9276/202, având codul de înregistrare fiscală &nbsp;CIF: RO-024145</span></p>
          <p _ngcontent-serverapp-c118="" class="c7 c12"><span _ngcontent-serverapp-c118="">E-mail: </span><span
              _ngcontent-serverapp-c118="" class="c4"><a _ngcontent-serverapp-c118=""
                href="mailto:contact@shardoray.ro" class="c18">contact@shardoray.ro</a></span><span
              _ngcontent-serverapp-c118="" class="c1">&nbsp;Telefon: 0787.344.366</span></p>
          <p _ngcontent-serverapp-c118="" class="c7 c12"><span _ngcontent-serverapp-c118="" class="c1">Depunem toate
              eforturile în vederea protejării dreptului la protecția datelor cu caracter personal al utilizatorilor
              acestui site.</span></p>
          <hr _ngcontent-serverapp-c118="">
          <p _ngcontent-serverapp-c118="" class="c7 c12 c13"><span _ngcontent-serverapp-c118="" class="c1"></span></p>
          <p _ngcontent-serverapp-c118="" class="c7 c12"><span _ngcontent-serverapp-c118="" class="c11 c3">CATEGORIILE
              DE DATE CU CARACTER PERSONAL PRELUCRATE</span></p>
          <p _ngcontent-serverapp-c118="" class="c7"><span _ngcontent-serverapp-c118="" class="c3">Shardoray
            </span><span _ngcontent-serverapp-c118="" class="c1">prelucrează, după caz, următoarele categorii de date cu
              caracter personal:</span></p>
          <ul _ngcontent-serverapp-c118="" class="c10 lst-kix_7wx36x1x2wcx-0 start">
            <li _ngcontent-serverapp-c118="" class="c2 li-bullet-0"><span _ngcontent-serverapp-c118="" class="c1">nume,
                prenume, adresa de livrare, telefonul, adresa de e-mail</span></li>
            <li _ngcontent-serverapp-c118="" class="c2 li-bullet-0"><span _ngcontent-serverapp-c118="" class="c1">opinii
                şi viziuni pe care ni le transmiteţi sau pe care le postaţi despre noi pe reţelele de socializare
                (social media) sau alte canale publice</span></li>
            <li _ngcontent-serverapp-c118="" class="c2 li-bullet-0"><span _ngcontent-serverapp-c118=""
                class="c1">eventuale alte date cu caracter personal pe care decideți să ni le furnizați prin intermediul
                site-ului, prin comunicările și solicitările pe care ni le transmiteți, prin optiunea de recenzii</span>
            </li>
          </ul>
          <hr _ngcontent-serverapp-c118="">
          <p _ngcontent-serverapp-c118="" class="c7 c12 c13"><span _ngcontent-serverapp-c118="" class="c1"></span></p>
          <p _ngcontent-serverapp-c118="" class="c5"><span _ngcontent-serverapp-c118="" class="c3">COLECTAREA DATELOR SE
              FACE PRIN: </span></p>
          <ul _ngcontent-serverapp-c118="" class="c10 lst-kix_pud39f7947d6-0 start">
            <li _ngcontent-serverapp-c118="" class="c0 li-bullet-0"><span _ngcontent-serverapp-c118=""
                class="c1">Formularul de contact </span></li>
            <li _ngcontent-serverapp-c118="" class="c0 li-bullet-0"><span _ngcontent-serverapp-c118=""
                class="c1">Formularul de comanda</span></li>
            <li _ngcontent-serverapp-c118="" class="c0 li-bullet-0"><span _ngcontent-serverapp-c118=""
                class="c1">Formularul de înregistrare cont de utilizator</span></li>
            <li _ngcontent-serverapp-c118="" class="c0 li-bullet-0"><span _ngcontent-serverapp-c118=""
                class="c1">Formularul de recenzii produse</span></li>
            <li _ngcontent-serverapp-c118="" class="c0 li-bullet-0"><span _ngcontent-serverapp-c118=""
                class="c1">Telefonic</span></li>
            <li _ngcontent-serverapp-c118="" class="c5 c8 li-bullet-0"><span _ngcontent-serverapp-c118=""
                class="c1">Modulul de comunicare WhatsApp</span></li>
          </ul>
          <hr _ngcontent-serverapp-c118="">
          <p _ngcontent-serverapp-c118="" class="c5 c15 c13"><span _ngcontent-serverapp-c118="" class="c1"></span></p>
          <p _ngcontent-serverapp-c118="" class="c7 c12"><span _ngcontent-serverapp-c118="" class="c11 c3">Datele cu
              caracter personal care sunt colectate prin intermediul acestui website, sunt folosite cu următoatele
              scopuri:</span></p>
          <ul _ngcontent-serverapp-c118="" class="c10 lst-kix_ldm81iw15njc-0 start">
            <li _ngcontent-serverapp-c118="" class="c14 c0 li-bullet-0"><span _ngcontent-serverapp-c118=""
                class="c3">Comunicarea</span><span _ngcontent-serverapp-c118="" class="c1">&nbsp;directă cu clienții și
                potențialii clienți</span></li>
            <li _ngcontent-serverapp-c118="" class="c0 c14 li-bullet-0"><span _ngcontent-serverapp-c118=""
                class="c3">Facturarea </span><span _ngcontent-serverapp-c118="" class="c1">produselor comandate </span>
            </li>
            <li _ngcontent-serverapp-c118="" class="c14 c0 li-bullet-0"><span _ngcontent-serverapp-c118=""
                class="c3">Livrarea</span><span _ngcontent-serverapp-c118="" class="c1">&nbsp;produselor
                comandate</span></li>
            <li _ngcontent-serverapp-c118="" class="c14 c0 li-bullet-0"><span _ngcontent-serverapp-c118=""
                class="c3">Informarea</span><span _ngcontent-serverapp-c118="" class="c1">&nbsp;utilizatorilor cu
                privire la oferte/ promotii, statusul comenzilor, evaluarea comenzilor efectuate</span></li>
          </ul>
          <hr _ngcontent-serverapp-c118="">
          <p _ngcontent-serverapp-c118="" class="c14 c12 c15 c13 c16"><span _ngcontent-serverapp-c118=""
              class="c1"></span></p>
          <p _ngcontent-serverapp-c118="" class="c5"><span _ngcontent-serverapp-c118="" class="c11 c3">PROTEJAREA
              INFORMAȚIILOR PERSONALE</span></p>
          <p _ngcontent-serverapp-c118="" class="c5"><span _ngcontent-serverapp-c118="" class="c1">Acest site și toată
              informația pe care o dezvăluiti pe acest site este colectată, depozitată și procesată în România. Permitem
              accesul la datele personale exclusiv angajaților și furnizorilor de servicii care au nevoie de ele pentru
              a asigura buna funcționare a acestui site și pentru livrarea produselor noastre. </span></p>
          <p _ngcontent-serverapp-c118="" class="c5"><span _ngcontent-serverapp-c118="" class="c1">Am implementat
              sisteme de securitate fizică, administrativă și tehnică pentru a proteja datele personale de accesul
              neautorizat. Cu toate acestea, pe cât de eficiente sunt sistemele noastre de securitate, nici un sistem nu
              este impenetrabil. Nu putem garanta impenetrabilitatea serviciilor noastre de securitate și nici faptul că
              informația transmisă către noi nu va fi interceptată în momentul în care este divulgată.</span></p>
          <hr _ngcontent-serverapp-c118="">
          <p _ngcontent-serverapp-c118="" class="c5 c13"><span _ngcontent-serverapp-c118="" class="c1"></span></p>
          <p _ngcontent-serverapp-c118="" class="c7 c12"><span _ngcontent-serverapp-c118="" class="c11 c3">DURATA
              PRELUCRĂRII DATELOR CU CARACTER PERSONAL</span></p>
          <p _ngcontent-serverapp-c118="" class="c7 c12"><span _ngcontent-serverapp-c118="" class="c1">Datele vor fi
              prelucrate pe întreaga perioadă de timp necesară realizării scopului pentru care acestea au fost
              colectate, cu asigurarea unor măsuri corespunzătoare de securitate.</span></p>
          <p _ngcontent-serverapp-c118="" class="c7 c12"><span _ngcontent-serverapp-c118="" class="c1">De exemplu,
              datele dvs. care sunt colectate în scopuri legate de încheierea unui contract vor fi păstrate pe durata
              executării contractului cât și ulterior, pe perioada de timp impusă de legislația în vigoare pentru
              păstrarea documentelor. </span></p>
          <p _ngcontent-serverapp-c118="" class="c7 c12"><span _ngcontent-serverapp-c118=""
              class="c3">Shardoray</span><span _ngcontent-serverapp-c118="" class="c1">&nbsp;va revizui periodic
              datele colectate, analizând în ce măsură păstrarea lor este necesară scopurilor menționate iar dacă nu mai
              sunt necesare, datele vor fi șterse.</span></p>
          <hr _ngcontent-serverapp-c118="">
          <p _ngcontent-serverapp-c118="" class="c7 c12 c13"><span _ngcontent-serverapp-c118="" class="c1"></span></p>
          <p _ngcontent-serverapp-c118="" class="c5"><span _ngcontent-serverapp-c118="" class="c3 c19">TEMEIUL LEGAL AL
              PRELUCRĂRII DATELOR CU CARACTER PERSONAL</span></p>
          <p _ngcontent-serverapp-c118="" class="c5"><span _ngcontent-serverapp-c118="" class="c3">Shardoray
            </span><span _ngcontent-serverapp-c118="">își asumă obligația de a administra datele personale furnizate de
              utilizatorii site-ului nostru în condiții de siguranță și exclusiv pentru scopurile specificate, conform
              &nbsp;prevederilor</span><span _ngcontent-serverapp-c118="" class="c3">&nbsp;Legii 2016/679/UE</span><span
              _ngcontent-serverapp-c118="" class="c1">&nbsp;pentru protecția prelucrării datelor cu caracter personal și
              libera circulație a acestor date.</span></p>
          <p _ngcontent-serverapp-c118="" class="c5"><span _ngcontent-serverapp-c118="" class="c3 c11">Conform
              regulamentului 2016/679/UE utilizatorii site-ului www.Shardoray.ro au următoarele drepturi:</span></p>
          <ul _ngcontent-serverapp-c118="" class="c10 lst-kix_w8al097u7yp-0 start">
            <li _ngcontent-serverapp-c118="" class="c5 c8 li-bullet-0"><span _ngcontent-serverapp-c118=""
                class="c3">Dreptul de acces</span><span _ngcontent-serverapp-c118="" class="c1">&nbsp;înseamnă că ai
                dreptul de a obține o confirmare din partea noastră că prelucrăm sau nu datele cu caracter personal care
                te privesc și, în caz afirmativ, acces la datele respective și la informații privind modalitatea în care
                sunt prelucrate datele.</span></li>
            <li _ngcontent-serverapp-c118="" class="c5 c8 li-bullet-0"><span _ngcontent-serverapp-c118=""
                class="c3">Dreptul la portabilitatea datelor</span><span _ngcontent-serverapp-c118=""
                class="c1">&nbsp;se referă la dreptul de a primi datele personale într-un format structurat, utilizat în
                mod curent și care poate fi citit automat și la dreptul ca aceste date să fie transmise direct altui
                operator, dacă acest lucru este fezabil din punct de vedere tehnic.</span></li>
            <li _ngcontent-serverapp-c118="" class="c5 c8 li-bullet-0"><span _ngcontent-serverapp-c118=""
                class="c3">Dreptul la opoziție</span><span _ngcontent-serverapp-c118="" class="c1">&nbsp;vizează dreptul
                de a te opune prelucrării datelor personale atunci când prelucrarea este necesară pentru îndeplinirea
                unei sarcini care servește unui interes public sau când are în vedere un interes legitim al
                operatorului. Atunci când prelucrarea datelor cu caracter personal are drept scop marketingul direct, ai
                dreptul de a te opune prelucrării în orice moment.</span></li>
            <li _ngcontent-serverapp-c118="" class="c5 c8 li-bullet-0"><span _ngcontent-serverapp-c118=""
                class="c3">Dreptul la rectificare</span><span _ngcontent-serverapp-c118="" class="c1">&nbsp;se referă la
                corectarea, fără întârzieri nejustificate, a datelor cu caracter personal inexacte. Rectificarea va fi
                comunicată fiecărui destinatar la care au fost transmise datele, cu excepția cazului în care acest lucru
                se dovedește imposibil sau presupune eforturi disproporționate.</span></li>
            <li _ngcontent-serverapp-c118="" class="c5 c8 li-bullet-0"><span _ngcontent-serverapp-c118=""
                class="c3">Dreptul la ștergerea datelor (“dreptul de a fi uitat”)</span><span
                _ngcontent-serverapp-c118="" class="c1">&nbsp;înseamnă că ai dreptul de a solicita ștergerea datelor cu
                caracter personal, fără întârzieri nejustificate, în cazul în care se aplică unul dintre următoarele
                motive: acestea nu mai sunt necesare pentru îndeplinirea scopurilor pentru care au fost colectate sau
                prelucrate; îți retragi consimțământul și nu există nici un alt temei juridic pentru prelucrare; te opui
                prelucrării și nu există motive legitime care să prevaleze; datele cu caracter personal au fost
                prelucrate ilegal; datele cu caracter personal trebuie șterse pentru respectarea unei obligații legale;
                datele cu caracter personal au fost colectate în legătură cu oferirea de servicii ale societății
                informaționale.</span></li>
            <li _ngcontent-serverapp-c118="" class="c5 c8 li-bullet-0"><span _ngcontent-serverapp-c118=""
                class="c3">Dreptul la restricționarea prelucrării</span><span _ngcontent-serverapp-c118="">&nbsp;poate
                fi exercitat în cazul în care persoana contestă exactitatea datelor, pe o perioadă care ne permite
                verificarea corectitudinii datelor; prelucrarea este ilegală, iar persoana se opune ștergerii datelor cu
                caracter personal, solicitând în schimb restricționarea; în cazul în care </span><span
                _ngcontent-serverapp-c118="" class="c3">Shardoray</span><span _ngcontent-serverapp-c118=""
                class="c1">&nbsp;nu mai are nevoie de datele cu caracter personal în scopul prelucrării, dar persoana i
                le solicită pentru constatarea, exercitarea sau apărarea unui drept în instanță; în cazul în care
                persoana s-a opus prelucrării pentru intervalul de timp în care se verifică dacă drepturile legitime ale
                operatorului prevalează asupra celor ale persoanei respective.</span></li>
            <li _ngcontent-serverapp-c118="" class="c5 c8 li-bullet-0"><span _ngcontent-serverapp-c118=""
                class="c1">Toate aceste drepturi pot sa fie exercitate printr-o cerere scrisă, semnată și datată,
                transmisă la sediul nostru sau la adresa de email de mai jos.</span></li>
          </ul>
          <hr _ngcontent-serverapp-c118="">
          <p _ngcontent-serverapp-c118="" class="c5 c13 c15"><span _ngcontent-serverapp-c118="" class="c1"></span></p>
          <p _ngcontent-serverapp-c118="" class="c5"><span _ngcontent-serverapp-c118="" class="c11 c3">ÎNTREBĂRI SAU
              NELĂMURIRI?</span></p>
          <p _ngcontent-serverapp-c118="" class="c5"><span _ngcontent-serverapp-c118="" class="c1">Dacă ai orice
              întrebări, nelămuriri sau reclamații legate de prezenta politică de confidențialitate sau de condițiile de
              confidențialitate în general, te rugăm să le direcționezi către:</span></p>
          <p _ngcontent-serverapp-c118="" class="c9"><span _ngcontent-serverapp-c118="" class="c3">CROW FASHION INTERNATIONAL SRL</span><span _ngcontent-serverapp-c118="" class="c1">&nbsp;</span></p>
          <p _ngcontent-serverapp-c118="" class="c9"><span _ngcontent-serverapp-c118="" class="c1">Sediul social in Mosoaia 45 A Bucuresti &nbsp;</span></p>
          <p _ngcontent-serverapp-c118="" class="c9"><span _ngcontent-serverapp-c118="" class="c1">J40/92/202</span>
          </p>
          <p _ngcontent-serverapp-c118="" class="c9"><span _ngcontent-serverapp-c118="" class="c1">RO-024145</span></p>
          <p _ngcontent-serverapp-c118="" class="c9"><span _ngcontent-serverapp-c118="">Email: </span><span
              _ngcontent-serverapp-c118="" class="c4"><a _ngcontent-serverapp-c118=""
                href="mailto:contact@shardoray.ro" class="c18">contact@shardoray.ro</a></span><span
              _ngcontent-serverapp-c118="" class="c1">&nbsp;</span></p>
          <p _ngcontent-serverapp-c118="" class="c9"><span _ngcontent-serverapp-c118="" class="c1">Telefon:
            0787.344.366
        </span></p>
         
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mt-6"></div><!-- margin -->