import { Component, OnDestroy } from '@angular/core';
import { CouponsService } from './coupons.service';
import { AuthAPIService, CartService } from 'src/app/services';
import { BehaviorSubject, Subject, switchMap, takeUntil } from 'rxjs';
import { User } from 'src/app/models';
import { CheckoutService } from '../../checkout/checkout.service';
import { DeliveryAddressService } from '../../checkout/delivery-address/delivery-address.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-coupons-discount',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss'],
})
export class CouponsComponent implements OnDestroy {
  couponDiscount: string;
  individualDiscountHasApplied$ = new BehaviorSubject<boolean>(false);
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _CouponsService: CouponsService,
    private _authAPIService: AuthAPIService,
    private _CheckoutService: CheckoutService,
    private _DeliveryAddressService: DeliveryAddressService,
    private _CartService: CartService,
    private toaster: ToastrService
  ) {
    this.individualDiscountHasApplied$ =
      this._CouponsService.individualDiscountHasApplied$;
  }

  applyCoupon() {
    if (this._CartService.totalPrice.value <= 89) {
     
      this.toaster.warning(
        ``,
        `Valoarea comenzii trebuie sa fie mai mare de 89 Lei pentru a beneficia de reducere!`,
        {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        }
      );
      return;
    }
    this._authAPIService.user
      .pipe(
        takeUntil(this._unsubscribeAll),
        switchMap((user: User) => {
          if (!user) {
            this.toaster.warning(
              ``,
              'Trebuie sa fii autentificat pentru a aplica reducerea!',
              {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              }
            );
            return;
          } else {
            return this._CouponsService.checkForIndividualDiscount(
              this.couponDiscount,
              user.email
            );
          }
        })
      )
      .subscribe(() => {
        // this._DeliveryAddressService.checkForDiscount();
        // this._CheckoutService.initProperties();
        this._CartService.calcTotal();
      });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next('');
    this._unsubscribeAll.complete();
  }
}
