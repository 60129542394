import { Component, OnInit } from '@angular/core';
import { CartService, FavoritesService, ProductService, ProductsService } from 'src/app/services';
import { environment } from 'src/environments/environment';

import { DomSanitizer } from '@angular/platform-browser';
import { map, take } from 'rxjs/operators';
import { PixelService } from 'ngx-pixel';

@Component({
  selector: 'app-discount-products',
  templateUrl: './discount-products.component.html',
  styleUrls: ['./discount-products.component.scss']
})
export class DiscountProductsComponent implements OnInit {
  products: {}[];
  show: string;

  closeModal: string;
  product_images: any;
  images: any[];
  uploadedImgUrl: string;
  valid: string[];
  public itemsFavorites$ = this._FavoritesService.itemsFavorites$;

  /**
   * Constructor
   *
   * @param {ProductsService} _ProductsService
   * @param {CartService} _CartService
   * @param {FavoriteService} _FavoriteService
   * 
   */

  constructor(
    private _ProductsService: ProductsService,
    private _ProductService: ProductService,
    private _CartService: CartService,
    private _DomSanitizationService: DomSanitizer,
    private _FavoritesService: FavoritesService,
    private pixel: PixelService
  ) {
    this.uploadedImgUrl = environment.uploadImagesUrl;
  }


  ngOnInit(): void {

    this._ProductsService.getDiscountProducts().then(data => {
      if (data) {
        data.map((product, i) => {
          
          if (product['colors'].length > 0) {
            data[i]['sizes'] = product['colors'][0].sizes;
            data[i]['selectedPrice'] = product['colors'][0].sizes[0].current_price;
          }
          this.itemsFavorites$.pipe(
            take(1),
            map((products) => {
              products.map(existingProduct => {
                if (existingProduct["name"] == data[i].product_name) {
                  data[i].addedToFavorites = true;
                }
              });
            }),
          ).subscribe();
        })

        this.products = data;
      } else {
        this.products = [];
      }

    });
  }

  selectColor(product, color) {
    this._ProductService.selectColor(product, color);
  }


  selectSize(product, size) {
    this._ProductService.selectSize(product, size);
  }

  addToCart(product) {

    this._CartService.addToCart(product, 1);
  }


  toggleFavorites(product) {
    this._FavoritesService.toggleFavorites(product);
  }

}
