<nav aria-label="breadcrumb" class="breadcrumb-nav">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/">Acasa</a></li>
        <li class="breadcrumb-item active" aria-current="page">Cum se livreaza</li>
      </ol>
    </div><!-- End .container -->
  </nav>
  <div class="mb-6"></div>
<div _ngcontent-serverapp-c146="" class="container">
  <div _ngcontent-serverapp-c146="" class="row">
    <div _ngcontent-serverapp-c146="" class="col-12">
      <div _ngcontent-serverapp-c146="" class="c5">
        <p _ngcontent-serverapp-c146="" class="c1"><strong class="c0">Livrarea
            comenzilor</strong></p>
        <p _ngcontent-serverapp-c146="" class="c1"><span _ngcontent-serverapp-c146="" class="c2">Dupa plasarea comenzii
            veti primi un email pentru confirmarea comenzii efectuate si veti fi contactat de un reprezentat Shardoray. </span></p>
        <p _ngcontent-serverapp-c146="" class="c1 c3"><span _ngcontent-serverapp-c146="" class="c2"></span></p>
        <p _ngcontent-serverapp-c146="" class="c1"><span _ngcontent-serverapp-c146="" class="c2">Termenul de livrare
            poate fi modificat de comun acord cu dumneavoastra in functie de complexitatea comenzii si de
            disponibilitatea produselor comandate (ca de exemplu pentru comenzile de sarbatori, de Black Friday, de Craciun, de
            Revelion sau alte sarbatori care indiferent de momentul plasarii, se vor livra conform discutiei in
            prealabil cu dumneavoastra). </span></p>
        <p _ngcontent-serverapp-c146="" class="c1 c3"><span _ngcontent-serverapp-c146="" class="c2"></span></p>
        <p _ngcontent-serverapp-c146="" class="c1"><span _ngcontent-serverapp-c146="" class="c2">Livrarea se face prin
            intermediul Sameday la adresa specificata de client.</span></p>
        <p _ngcontent-serverapp-c146="" class="c1 c3"><span _ngcontent-serverapp-c146="" class="c2"></span></p>
       
      </div>
    </div>
  </div>
</div>
<div class="mb-6"></div>