import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-confirmation-account',
  templateUrl: './confirmation-account.component.html',
  styleUrls: ['./confirmation-account.component.scss']
})
export class ConfirmationAccountComponent implements OnInit {

  _send_order_email = environment.send_confirmation_email;
  _apiUrl = environment.apiUrl;
  model: any = [];

  constructor(
    private _httpClient: HttpClient,
    private _ActivatedRoute: ActivatedRoute,
    private router: Router,
    private toaster: ToastrService,
  ) {
    this._ActivatedRoute.paramMap.subscribe(params => {
      this.model = [{
        email: params.get('email'),
        token: params.get('token')
      }]
    });
  }

  ngOnInit(): void {
    this._httpClient.post(this._send_order_email, this.model).subscribe();
    this.checkConfirmation();
  }

  resendEmail() {
    this._httpClient.post(this._send_order_email, this.model).subscribe();

    this.toaster.success('', `Email-ul a fost trimis!`, {
      timeOut: 3000,
      positionClass: 'toast-bottom-right'
    });
  }

  checkConfirmation() {
    const checkExist = setInterval(() => {
      this._httpClient.post(this._apiUrl + 'users/getUserByEmail', { 'email': this.model[0].email })
        .subscribe((response: any) => {
          if (response.user.active === 1) {
            clearInterval(checkExist);
            this.router.navigate(['/'])
          } 
        });
    }, 1000);
  }

}
