import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Product } from 'src/app/models';
import { FavoritesService, ProductService, ProductsService } from 'src/app/services';
import { environment } from 'src/environments/environment.prod';
import { SelectedProductsService } from './selected-products.service';

@Component({
  selector: 'app-selected-products',
  templateUrl: './selected-products.component.html',
  styleUrls: ['./selected-products.component.scss']
})
export class SelectedProductsComponent implements OnInit {
  products: Product[] = [];
  uploadedImgUrl = environment.uploadImagesUrl;

  customOptions: OwlOptions = {
    dots: false,
    nav: true,
    autoplay: false,
    autoplayTimeout: 4000,
    animateOut: 'fadeOut',
    navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
    loop: false,
    margin: 16,
    items: 5,
    responsive: {
      0: {
        items: 2
      },
      600: {
        items: 2
      },
      800: {
        items: 4
      },
      1024: {
        items: 5
      }
    }
  }

  constructor(
    private _SelectedProductsService: SelectedProductsService,
    private _ProductsService: ProductsService,
    private _FavoritesService: FavoritesService
  ) { }

  ngOnInit(): void {
    this._SelectedProductsService.getSelectedProducts().then(products => {
      this.products = this._ProductsService.cleanObj(products)
    });
  }

  toggleFavorites(product: Product) {
    this._FavoritesService.toggleFavorites(product);
  }

}
