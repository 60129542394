<nav aria-label="breadcrumb" class="breadcrumb-nav">
  <div class="container">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Acasa</a></li>
      <li class="breadcrumb-item active" aria-current="page">Termeni si conditii</li>
    </ol>
  </div><!-- End .container -->
</nav>
<div class="mt-6"></div><!-- margin -->
<div _ngcontent-serverapp-c118="" class="container">
  <div class="vc_row vc_custom_1461496273975">
    <div class="wpb_column vc_column_container vc_col-sm-12">
      <div class="vc_column-inner ">
        <div class="wpb_wrapper">
          <div class="wpb_text_column wpb_content_element ">
            <div class="wpb_wrapper">
              <ol>
                <li><strong>Identitatea firmei si descrierea produselor/serviciilor</strong></li>
                <li><strong>Definitii</strong>:</li>
              </ol>
              <p>Vanzator: CROW FASHION INTERNATIONAL SRL<strong>,&nbsp;</strong>cu sediul în Mosoaia 45 A Bucuresti, denumita in
                continuare SHARDORAY comercializeaza si distribuie pe teritoriul Romaniei haine pentru barbatisi femei.
              </p>
              <p>Utilizator: orice persoana care viziteaza site-ul SHARDORAY.ro</p>
              <p>Client: orice persoana fizica sau juridica care efectueaza o Comanda pe site-ul SHARDORAY.ro sau orice
                alta pagina conexa si care este in proprietatea SHARDORAY.</p>
              <p>Produse: orice produs sau serviciu mentionat in Comanda efectuata de utilizator si care urmeaza sa fie
                livrata de catre Vanzator, Clientului.</p>
              <p>Comanda: un document electronic realizat intre Vanzator si Client prin care Clientul isi exprima
                intentia
                de a achizitiona anumite Produse si Servicii si sa faca plata acestora.</p>
              <p>Contract – o Comanda confirmata de catre Vanzator, prin care Vanzatorul este de acord sa vanda si sa
                livreze Produsele si Serviciile iar Clientul este de acord sa achizitioneze, sa primeasca si sa faca
                plata
                acestor Produse si Servicii.</p>
              <p>Curier – orice persoana de drept public sau privat care presteaza servicii de curierat rapid.</p>
           
              <ol start="2">
                <li><strong>Definitii</strong>:</li>
              </ol>
              <p>Vanzator: SHARDORAY<strong>,&nbsp;</strong>cu sediul în Mosoaia 45 A Bucuresti, denumita in
                continuare SHARDORAY comercializeaza si distribuie pe teritoriul Romaniei haine pentru barbatisi femei.
              </p>
              <p>Utilizator: orice persoana care viziteaza site-ul SHARDORAY.ro</p>
              <p>Client: orice persoana fizica sau juridica care efectueaza o Comanda pe site-ul SHARDORAY.ro sau orice
                alta pagina conexa si care este in proprietatea SHARDORAY.</p>
              <p>Produse: orice produs sau serviciu mentionat in Comanda efectuata de utilizator si care urmeaza sa fie
                livrata de catre Vanzator, Clientului.</p>
              <p>Comanda: un document electronic realizat intre Vanzator si Client prin care Clientul isi exprima
                intentia
                de a achizitiona anumite Produse si Servicii si sa faca plata acestora.</p>
              <p>Contract – o Comanda confirmata de catre Vanzator, prin care Vanzatorul este de acord sa vanda si sa
                livreze Produsele si Serviciile iar Clientul este de acord sa achizitioneze, sa primeasca si sa faca
                plata
                acestor Produse si Servicii.</p>
              <p>Curier – orice persoana de drept public sau privat care presteaza servicii de curierat rapid.</p>
              <ol start="3">
                <li><strong>Continut –drepturi de proprietate intelectuala</strong></li>
              </ol>
              <p>Continutul site-ului SHARDORAY.ro: imagini, texte, produsele, elemente de grafica web, sunt integral
                proprietatea SHARDORAY si a furnizorilor sai si este aparat de Legea privind dreptul de autor si
                drepturile conexe si de legile privind proprietatea intelectuala si industriala. Folosirea fara acordul
                SHARDORAY&nbsp;a oricaror elemente enumerate mai sus se pedepseste conform legislatiei in vigoare.</p>
              <ol start="4">
                <li><strong>Exonerarea de raspundere</strong></li>
              </ol>
              <p>SHARDORAY. nu garanteaza ca site-ul, serverele pe care este gazduit, sau e-mailurile trimise de la
                SHARDORAY.ro sunt fara virusi sau alte componente informatice cu caracter potential daunator, ca nu
                contine erori, omisiuni, defectiuni, intarzieri sau intreruperi in operare sau transmitere, caderi de
                linie sau orice alti factori similari. Utilizatorul foloseste site-ul pe riscul propriu, shardoray.ro
                fiind liber de orice raspundere pentru eventualele daune directe sau indirecte cauzate de utilizarea sau
                accesarea/vizitarea site-ului sau ca urmare a utilizarii informatiilor de pe site. SHARDORAY&nbsp;nu
                este
                raspunzatoare de erorile sau omisiunile care pot interveni in redactarea sau prezentarea materialelor de
                pe site.</p>
              <p>Informatiile incluse pe SHARDORAY.ro au caracter informativ si sunt puse la dispozitie cu buna
                credinta,
                din surse pe care Vanzatorul le considera de incredere. In cazul in care vreunul dintre articolele
                publicate sau orice alta informatie intra sub incidenta Legii privind dreptul de autor si drepturile
                conexe, Utilizatorul este rugat sa aduca acest fapt la cunostinta Vanzatorului la adresa
                legal@shardoray.ro, pentru a putea fi luate masurile legale necesare.</p>
              <p>SHARDORAY.ro isi rezerva dreptul de a anula Comenzile pentru Produse care sunt afisate pe site ca
                urmare
                a unor erori tehnice, sau care, din cauza unor erori tehnice prezinta preturi evident eronate/derizorii
                pentru produse (preturi pe care le poate aprecia ca fiind eronate/derizorii orice cumparator cu un nivel
                mediu de pregatire).</p>
              <p>SHARDORAY.ro, in calitate de organizator al campaniilor promotionale, nu isi asuma raspunderea pentru
                pierderile sau deteriorarile voucherului campaniei, nu este responsabila pentru voucherele falsificate
                sau
                deteriorate si isi rezerva dreptul de a anula voucherul utilizat la achizitie ori de a scadea valoarea
                acestuia din valoarea de rambursat, in cazul returnarii conform legii, a unui produs participant in
                campania respectiva.</p>
              <p>Orice link-uri catre alte site-uri sunt oferite numai in scopul unei accesibilitati sporite a
                informatiilor, iar SHARDORAY.ro nu isi asuma nici o responsabilitate sau raspundere pentru continutul
                acestor site-uri, pentru produsele sau serviciile promovate sau comercializate prin aceste site-uri.</p>
              <p>SHARDORAY are o raspundere limitata privind anumite probleme tehnice cu site-ul, de exemplu
                nefunctionare
                sau informatii eronate, pierderea datelor daca utilizatorul si-a share-uit parola.</p>
              <ol start="5">
                <li><strong>Drepturile si obligatiile vanzatorului</strong>:</li>
              </ol>
              <p>SHARDORAY isi rezerva dreptul de a modifica oricand continutul site-ului si a paginii de „Termeni si
                conditii” fara sa-i anunte pe utilizatori, de a nu posta anumite comentarii pe site care pot fi
                daunatoare
                imaginii companiei, de a suspenda conturi si alte mentiuni potrivite activitatii.</p>
              <ol start="6">
                <li><strong>Drepturile si obligatiile cumparatorului</strong>:</li>
              </ol>
              <p>Cumparatorul are obligatia de a nu furniza informatii false, de a nu înstraina contul (mai ales
                parola),
                dreptul de modificare sau stergere a contului utilizatorului din motive specifice.</p>
              <ol start="7">
                <li><strong>Prelucrarea datelor cu caracter personal</strong></li>
              </ol>
              <p>SHARDORAY. este inregistrata in Registrul Operatorilor de Date cu Caracter Personal.</p>
              <p>Datele colectate cu privire la newslettere sunt confidentiale.</p>
              <p>Conform Legii nr. 677/2001 pentru protectia persoanelor cu privire la prelucrarea datelor cu caracter
                personal si libera circulatie a acestor date, modificata si completata, si Legii nr. 506/2004 privind
                prelucrarea datelor cu caracter personal si protectia vietii private in sectorul comunicatiilor
                electronice, SHARDORAY.ro are obligatia de a prelucra in conditii de siguranta si numai pentru scopurile
                specificate datele personale furnizate de Utilizatori.</p>
              <p>Scopul colectarii datelor cu caracter personal este: gestiune economico-financiara, reclama, marketing,
                publicitate, statistica, servicii de comunicatii electronice, informarea Clientilor privind situatia
                contului lor de pe SHARDORAY.ro, informarea Clientilor privind evolutia si starea Comenzilor.</p>
              <p>Completarea de catre Utilizatori a formularelor pe site echivaleaza cu acceptarea neconditionata ca
                aceste date sa fie incluse in baza de date a SHARDORAY.ro si cu utilizarea si prelucrarea de catre
                SHARDORAY.ro, afiliatii si colaboratorii acestora pentru desfasurarea activitatilor enumerate mai sus,
                inclusiv, dar nelimitandu-se la furnizorii de servicii de marketing, curierat, servicii de plata /
                bancare.</p>
              <p>Clientilor le sunt garantate drepturile prevazute de Legea nr. 677/2001 pentru protectia persoanelor cu
                privire la prelucrarea datelor cu caracter personal si libera circulatie a acestor date, respectiv
                dreptul
                de informare, dreptul de acces la date, dreptul de interventie, dreptul de opozitie, dreptul de a nu fi
                supus unei decizii individuale, dreptul de se adresa justitiei in caz de incalcare a drepturilor sale.
                Clientii au dreptul de a solicita stergerea totala sau partiala a datelor cu caracter personal. Clientul
                poate solicita oricare dintre drepturile enumerate mai sus in baza unei cereri scrise, semnate si
                datate,
                inaintata catre SHARDORAY. la adresa officel@SHARDORAY.ro.</p>
              <p>Refuzul furnizarii acestor date determina imposibilitatea onorarii Comenzilor.</p>
              <p>In cazul in care Clientul isi modifica prin utilizarea formularelor de pe site datele personale livrate
                deja catre SHARDORAY.ro si exista Comenzi in derulare, Comenzile isi pastreaza datele de la momentul
                plasarii Comenzii iar livrarea produselor se va face luandu-se in considerare datele nou modificate.</p>
              <p>SHARDORAY.ro nu solicita Utilizatorilor sai prin niciun mijloc de comunicare (telefon/e-mail etc)
                informatii confidentiale, date despre conturi bancare, parole personale etc. In cazul in care
                Utilizatorul
                le divulga unor terti, acesta poarta intreaga responsabilitate pentru actiunile sale. Astfel, in cazuri
                de
                divulgare de astfel de date, Utilizatorul nu poate tine raspunzator pe shardoray.ro pentru niciun fel de
                prejudiciu.</p>
              <ol start="8">
                <li><strong>Inregistrarea ca Utilizator</strong></li>
              </ol>
              <p>Pentru crearea unui cont pe site-ul SHARDORAY.ro, Utilizatorul este obligat sa foloseasca o adresa de
                e-mail valida. SHARDORAY.ro poate refuza cererea de inregistrare in situatiile in care constata ca a
                utilizat informatii neconforme cu realitatea sau foloseste serviciile intr-un mod neconform cu uzantele
                normale.</p>
              <p>&nbsp;</p>
              <ol start="9">
                <li><strong>Pretul</strong></li>
              </ol>
              <p>Pretul final platit de Client este format din pretul produsului + cheltuielile de expeditie si/sau
                incasare aferente. Preturile de livrare sunt detaliate pe site.</p>
              <p>Toate preturile aferente produselor comercializate prin intermediul site-ului SHARDORAY.ro sunt
                exprimate
                in Lei si contin TVA</p>
              <p>În cazul plaților online vânzătorul nu este/nu poate fi făcut responsabil pentru niciun alt cost
                suplimentar suportat de cumpărător, incluzând dar nelimitându-se la comisioane de conversie valutară
                aplicate de către banca emitentă a cardului acestuia, în cazul în care moneda de emitere a acestuia
                diferă
                de RON. Responsabilitatea pentru această acțiune o poartă numai cumpărătorul.</p>
              <ol start="10">
                <li><strong>Comanda</strong></li>
              </ol>
              <p>Pentru a se putea realiza Comanda, Utilizatorul trebuie sa seteze detaliile preferate de Comanda (unde
                sa
                fie livrata, modalitatea de expediere si modalitatea de plata) in contul de Utilizator, iar aceste
                setari
                vor fi retinute si folosite pentru fiecare Comanda cu un click. Aceste setari pot fi modificate oricand.
              </p>
              <p>Prin finalizarea Comenzii Clientul garanteaza ca toate datele furnizate sunt reale si corecte, in caz
                contrar ar putea suporta consecintele acestor erori (Comanda intarziata, transmisa gresit etc).</p>
              <p>Adăugarea unui bun/serviciu în coșul de cumpărături, în lipsa finalizării comenzii, nu atrage după sine
                înregistrarea unei comenzi, implicit nici rezervarea automată a bunului/serviciului.</p>
              <p>Astfel, potrivit OUG nr 34/2014, perioada de returnare a unui bun sau renunțare la un serviciu expiră
                în
                termen de 14 zile de la:</p>
              <p>– ziua în care cumpărătorul intră în posesia fizică a ultimului bun – în cazul în care cumpărătorul
                comandă printr-o singură comandă produse multiple care vor fi livrate separat<br>
                – ziua în care cumpărătorul intră în posesia fizică a ultimului bun sau a ultimei piese – în cazul
                livrării unui produs care constă din mai multe loturi sau piese</p>
              <p>În cazul în care cumpărătorul decide să se retragă din contract, acesta va putea completa online
                formularul de retur.</p>
              <p>În cazul în care clientul/cumpărătorul solicită retragerea din contract în cadrul termenului legal de
                retragere din contract,&nbsp;trebuie să returneze și eventualele cadouri care au însoțit produsul
                respectiv. În cazul în care comanda este achitată, vânzătorul va rambursa suma în maxim 14 (paisprezece)
                zile de la data informării vânzătorului de către cumpărător asupra deciziei sale de retragere din
                contract. Suma va fi returnată după cum urmează:</p>
              <p>– pentru comenzile achitate cu card online – prin restituire în contul din care a fost efectuată
                plata;<br>
                – pentru comenzile achitate cu Op/Ramburs – prin virament bancar;</p>
              <p>Vânzătorul va putea amâna rambursarea sumei până la primirea bunurilor vândute sau până la primirea
                unei
                dovezi conform căreia acestea au fost expediate (se va lua data cea mai recentă).</p>
              <p>În cazul în care un bun și/sau serviciu comandat de către cumpărător, nu poate fi livrat de către
                vânzător, acesta din urmă va informa clientul/cumpărătorul asupra acestui fapt și va returna în contul
                cumpărătorului contravaloarea bunului și/sau serviciului, în termen de maxim 7 (șapte) zile de la data
                la
                care vânzătorul a luat la cunoștință acest fapt sau de la data la care cumpărătorul și-a exprimat în mod
                expres intenția de reziliere a contractului.</p>
              <p>SHARDORAY nu poate fi tras la raspundere pentru informatii introduse eronat, din care pot decurge
                intarzieri de livrare. In acest context, toate taxele de transport efectuat in vederea reexpedierii
                Comenzii vor cadea in sarcina Clientului.</p>
              <p>Prin finalizarea Comenzii, Clientul se declara de acord ca un reprezentant&nbsp;mextomen.ro&nbsp;sa il
                contacteze prin orice mijloc disponibil convenit de catre Parti (e-mail/telefon) pentru confirmarea
                personala a Comenzii, in cazurile in care aceasta confirmare este necesara.</p>
              <p>Pentru confortul si siguranta Clientului, exista optiunea ca timp de 30 de minute dupa plasarea unei
                Comenzi de a renunta la Comanda. Astfel, daca Utilizatorul care a plasat o Comanda din greseala sau a
                revenit asupra deciziei, Comanda se poate anula in 30 de minute dupa plasarea ei.</p>
              <p>SHARDORAY.ro poate refuza o Comanda in urma unei notificari prealabile adresate Clientului, fara a
                exista
                obligatii intre parti si fara ca o parte sa poata pretinde daune, pentru urmatoarele situatii:</p>
              <p>– esuarea / invalidarea tranzactiei online (daca este cazul);</p>
              <p>– neacceptarea de catre banca emitenta a cardului Clientului / a tranzactiei;</p>
              <p>– date incomplete sau incorecte ale Clientului;</p>
              <p>– activitatea Clientului poate produce daune site-ului SHARDORAY.ro;</p>
              <p>– livrari consecutiv esuate;</p>
              <p>– alte motive obiective: in cazul in care Clientul nu garanteaza ca modul de plata este valid si ca nu
                este obtinut printr-o metoda frauduloasa sau exista suspiciuni cu privire la modalitatea de plata.</p>
              <p>Chiar daca a luat toate masurile pentru ca informatia prezentata pe acest site sa fie exacta si
                corecta,
                SHARDORAY.ro nu poate fi raspunzatoare de inexactitati care pot aparea in completarea de catre Client a
                formularelor din site pentru finalizarea Comenzii. Utilizatorii sunt responsabili pentru evaluarea
                exactitatii, completitudinii si utilitatii informatiilor furnizate in cadrul formularelor disponibile pe
                acest site.</p>
              <p>In anumite situatii si pentru motive justificate, SHARDORAY.ro isi rezerva dreptul de a modifica
                cantitatea Bunurilor si/sau Serviciilor din Comanda. In aceste situatii SHARDORAY.ro va anunta Clientul
                la
                adresa de e-mail sau la numarul de telefon puse la dispozitia Vanzatorului la efectuarea Comenzii si va
                returna suma achitata.</p>
              <p>Contractul se considera incheiat intre Client si Vanzator in momentul primirii de catre Client de la
                shardoray.ro, a notificarii de expediere a Comenzii.</p>
              <p>In cazul in care un Bun si/sau Serviciu comandat de catre Client nu poate fi livrat de catre
                SHARDORAY.ro, ne obligam sa informam Clientul asupra acestui fapt si sa returnam in contul Clientului
                contravaloarea Bunului si/sau Serviciului, in termen de maxim 14 zile de la data la care SHARDORAY.ro
                luat
                la cunostinta acest fapt sau de la data la care Clientul si-a exprimat in mod expres intentia de
                reziliere
                a Contractului.</p>
              <ol start="11">
                <li>Plata</li>
              </ol>
              <p>În anumite cazuri, pentru menținerea securității tranzacțiilor, la înregistrarea comenzii,
                cumpărătorului
                i se va solicita sa autorizeze plata prin reintroducerea parolei aferente contului sau utilizarea
                amprentei digitale în cazul terminalelor mobile care au această facilitate.</p>
              <p>Pentru motive de securitate a tranzacțiilor clientul/ utilizatorul/ cumpărătorul este sfătuit să nu
                rămână logat pe site și să nu seteze opțiunea de logare automată pe dispozitivele mobile. Divulgarea
                parolei de acces în cont nu este permisă și se recomandă folosirea unei parole cu caracter puternic de
                securitate (de ex.: să conțină cel puțin opt caractere, incluzând litere mari, litere mici, cifre și
                caractere speciale).</p>
              <ol start="11">
                <li><strong>Facturare – plati</strong></li>
              </ol>
              <p>Pretul, modalitatea de plata si termenul de plata sunt specificate in Comanda. Vanzatorul va emite
                catre
                Client o factura pentru Produsele livrate, obligatia Clientului fiind sa furnizeze toate informatiile
                necesare emiterii facturii conform cu legislatia in vigoare.</p>
              <p>Plata se poate face prin ramburs la curier sau online (prin ordin de plata sau plata online cu cardul
                prin MobilPay).</p>
              <ol start="12">
                <li><strong>Garantia</strong></li>
              </ol>
              <p>Orice produs achizitionat de pe site-ul SHARDORAY.ro poate fi inlocuit in conditiile in care se face
                dovada ca acestea au defectiuni tehnice din productie. SHARDORAY nu isi asuma responsabilitatea sa
                inlocuiasca&nbsp; produsele deteriorate din vina clientului.</p>
              <p>Toate celelalte prevederi cuprinse in legislatia in vigoare, referitoare la garantarea produselor
                vandute, sunt valabile. Drepturile Clientilor sunt cele prevazute in legislatia in domeniu.</p>
              <ol start="13">
                <li><strong>Livrarea</strong></li>
              </ol>
              <p>Data estimata de livrare poate varia in functie de produs, si este afisata in pagina produsului, in
                pagina de Comanda precum si in e-mailul sau telefonul de confirmare a Comenzii.</p>
              <p>Pentru toate categoriile de produse, termenul maxim de livrare este de 30 de zile de la data
                confirmarii
                Comenzii.</p>
              <p>Pentru Clientii din strainatate, pretul final al Comenzii va fi comunicat prin e-mail sau telefonic,
                iar
                livrarea se va face dupa acceptarea explicita a acestui pret.</p>
              <p>Atentie!&nbsp;Din cauza timpului foarte scurt de procesare a Comenzilor, este imposibila operarea de
                modificari asupra Comenzilor deja plasate.</p>
              <p>In cazul in care produsele sunt indisponibile (inclusiv in cazul in care pretul sau unele
                caracteristici
                ale produselor au suferit modificari ulterioare confirmarii Comenzii), Clientul va fi informat despre
                indisponibilitate iar sumele pe care acesta le-a achitat vor fi rambursate in termen de 14 zile.
                Clientul
                poate confirma modificarile intervenite cu privire la pretul sau caracteristicile produselor, modificand
                astfel Comanda initiala, iar SHARDORAY&nbsp;va livra produsele conform Comenzii astfel modificate.</p>
              <ol start="14">
                <li><strong>Conditii de livrare</strong></li>
              </ol>
              <p>In cazul in care Clientul se afla in imposibilitatea receptionarii Comenzii in mod personal, Comanda va
                fi lasata la adresa precizata doar unei persoane cu varsta mai mare de 18 ani si doar in cazul
                comunicarii
                catre Curier, a numarului Comenzii.</p>
              <p>Nicio cerere de livrare a unei Comenzi nu va putea fi onorata daca Clientul nu indeplineste conditiile
                de
                mai sus.In cazul livrarii la locul de munca al Clientului, caz in care Curierul nu poate avea acces in
                locatia respectiva, Clientul trebuie sa se asigure ca poate receptiona aceasta Comanda.</p>
              <p>Clientul va fi contactat telefonic de catre Curier in prealabil pentru a obtine confirmarea prezentei
                acestuia la adresa si ora selectate in momentul validarii Comenzii pe site.</p>
              <p>Clientul se obliga sa fie prezent la adresa de livrare indicata, in intervalul orar selectat, in caz
                contrar acesta va supora taxa de transport aferenta unei posibile noi livrari. Intervalul orar va putea
                fi
                modificat, ulterior selectarii, doar cu acordul Curierului.</p>
              <p>Livrarea se considera a fi indeplinita de catre SHARDORAY.ro, la momentul predarii produselor Comandate
                catre Client la adresa selectata de acesta la momentul plasarii Comenzii.</p>
              <ol start="15">
                <li><strong>Receptia produselor</strong></li>
              </ol>
              <p>Clientul se obliga sa semneze bonul de livrare (AWB) prezentat de catre Curier sau Posta Romana la
                livrarea produselor comandate, la care este anexat si bonul fiscal ce contine toate informatiile despre
                produsele livrate (denumire produs, cantitate, pret). Deasemenea, Clientul va verifica daca produsele
                sunt
                deteriorate sau daca exista lipsuri, iar eventualele probleme vor fi mentionate in mod olograf pe bonul
                de
                livrare, pentru a putea fi luate in considerare.</p>
              <p>Prin semnarea bonului de livrare Clientul recunoaste primirea in bune conditii si in totalitate a
                produselor Comandate.</p>
              <ol start="16">
                <li><strong>Pretul</strong></li>
              </ol>
              <p>Pretul final platit de Client este format din pretul produsului + cheltuielile de expeditie si/sau
                incasare aferente. Preturile de livrare sunt detaliate pe site.</p>
              <p>Toate preturile aferente produselor si serviciilor comercializate prin intermediul site-ului
                SHARDORAY.ro
                sunt exprimate in Lei.</p>
              <ol start="17">
                <li><strong>Intreruperea campaniei promotionale</strong></li>
              </ol>
              <p>In cazul unei campanii promotionale oraganizate de SHARDORAY.ro, acesta, in calitate de organizator isi
                rezerva dreptul de a intrerupe Campania promotionala oricand pe parcursul desfasurarii acesteia, cu
                informarea prealabila a consumatorilor prin mijloace de comunicare corespunzatoare (afisare pe site,
                semnalizare in magazinul online, etc.).</p>
              <p>Organizatorul isi rezerva dreptul de a modifica oricare din conditiile stabilite in regulamentul
                campaniei respective, in perioada desfasurarii campaniei, din motive intemeiate, dar nu inainte de a
                anunta publicul despre aceste modificari.</p>
              <ol start="18">
                <li><strong>Anulare, refuz, retur comanda</strong></li>
              </ol>
              <p>Modalitati si termene de returnare a produselor</p>
              <p>Clientul se obliga sa notifice SHARDORAY.ro intentia sa de a returna produsele achizitionate, prin
                e-mail
                la office@SHARDORAY.ro sau prin telefon la numarul +40787344366 in termen de maxim 14 zile
                calendaristice de la primirea produselor. In caz contrar, SHARDORAY.ro poate refuza coletul.</p>
              <p>Informatiile necesare care trebuie furnizate SHARDORAY.ro pentru trimiterea curierului sunt
                urmatoarele:
              </p>
              <p>– data expedierii coletului;</p>
              <p>– numarul Comenzii;</p>
              <p>– suma de returnat;</p>
              <p>– adresa de unde curierul urmeaza sa preia coletul de retur;</p>
              <p>– modalitatea de plata dorita (datele contului bancar sau voucher de cumparaturi echivalent cu suma
                produselor returnate).</p>
              <p>La primirea informatiilor referitoare la intentia de retur a clientului, SHARDORAY.ro efectueaza o
                solicitare la curier si trimite curierul la adresa indicata de client pentru a ridica coletul de
                returnat.
              </p>
              <p>Costurile de transport ale coletului/elor returnate intra in responsabilitatea Clientului.</p>
              <p>&nbsp;</p>
              <ol start="19">
                <li><strong>Conditii de returnare a produselor</strong></li>
              </ol>
              <p>In orice situatie de returnare a produselor, acestea trebuie sa fie in aceeasi stare in care au fost
                trimise Clientului, in ambalajul original, cu etichetele intacte si impreuna cu toate documentele care
                l-au insotit (factura). Produsele returnate trebuie sa fie in aceeasi conditie ca la primire: fara
                defecte
                (cu exceptia viciilor ascunse si exceptand defectele deja semnalate de Client in corespondenta cu
                SHARDORAY.ro).</p>
              <p>In conformitate cu legea in vigoare, SHARDORAY.ro isi rezerva dreptul de a nu accepta returnarea
                produselor care au fost deteriorate din pricina Clientului.</p>
              <p>Nu se acceptă produsele asupra cărora au fost efectuate intervenții neautorizate, cele care prezintă
                urme
                de uzură sau folosire excesivă, zgârieturi, produsele cărora le lipsesc accesorii.&nbsp; Dacă produsele
                de
                returnat au fost însoțite de eventuale cadouri, trebuie returnate și acestea.</p>
              <p>Dacă ai cumpărat mai multe produse de același fel și vrei să le returnezi pe toate, asigură-te că doar
                unul a fost desigilat. Returul celorlaltor produse se acceptă doar dacă sunt sigilate.</p>
              <p>Produsele care nu sunt conforme specificațiilor de pe site din momentul trimiterii comenzii se pot
                returna fără implicarea unor costuri suplimentare din partea clientului, în urma unei notificari scrise
                în
                maxim 3 zile de la primirea produsului în care se va specifica natura lipsei de conformitate. Clientul
                poate solicita returnarea contravalorii produsului sau inlocuirea cu unul conform. Daca
                SHARDORAY&nbsp;nu
                poate înlocui produsul cu unul conform, se va restitui contravaloarea produsului.</p>
              <ol start="20">
                <li><strong>Termene de returnare a banilor</strong></li>
              </ol>
              <p>Pentru retururi integrale de comenzi, Clientul primeste banii aferenti produselor returnate in interval de 14 zile lucratoare de la data la care SHARDORAY a primit produsele.</p>
              <p>Dupa validarea returului, clientul poate primi, la alegere, contravaloarea produselor sub forma unui
                cupon valoric pe care il va putea folosi in termen de 30 zile de la data confirmarii primirii returului
                de
                catre SHARDORAY. Dupa aceasta data cuponul isi pierde valabilitatea.</p>
              <ol start="21">
                <li><strong>Forta majora</strong></li>
              </ol>
              <p>Niciuna dintre partile contractuale nu poate fi trasa la raspundere pentru neexecutarea (total/partial)
                sau executarea cu intarziere a obligatiilor sale, daca acestea au fost cauzate de forta majora. Partile
                isi vor aduce la cunostinta de indata ce are loc cazul de forta majora si vor lua toate masurile
                necesare
                in vederea limitarii consecintelor evenimentului.</p>
              <p>Daca in termen de 15 zile evenimentul de forta majora nu inceteaza, partile au dreptul de a denunta
                unilateral contractul fara pretinderea de daune-interese.</p>
              <p>Forta majora va fi probata conform legii.</p>
              <ol start="22">
                <li><strong>Solutionarea conflictelor.&nbsp;Legea aplicabila</strong></li>
              </ol>
              <p>Contractul va fi guvernat si interpretat in conformitate cu legea romana. Orice conflict aparut intre
                SHARDORAY si Clienti va fi rezolvat pe cale amiabila. In cazul in care acest lucru nu este posibil,
                prima
                cale de solutionare este medierea, in conditiile legii, iar daca aceasta esueaza se apeleaza la
                instantele
                romanesti competente de la domiciliul Clientului.</p>
              <ol start="23">
                <li><strong>Frauda</strong></li>
              </ol>
              <p>Crearea de conturi multiple folosind astfel de adrese generate automat si care expira dupa o perioada
                predefinita, pentru a putea beneficia de promotii sau oferte, sau orice comportament fraudulos in masura
                sa afecteze bunul mers al campaniilor, este interzisa si va fi considerata o tentativa de frauda.
                Proprietarul site-ului SHARDORAY.ro isi rezerva dreptul de a suspenda conturile astfel create si de a
                retrage beneficiile aferente promotiilor sau ofertelor in curs, de a anula contul fara preaviz,
                instiintare, alte formalitati sau despagubiri.</p>
              <p>Orice tentativa de frauda sau orice frauda (cum ar fi dar fara a se limita la: accesarea datelor
                Clientilor SHARDORAY, alterarea continutului site-ului, incercarea de afectare a performantelor
                serverelor
                shardoray.ro, deturnarea continutului livrarilor catre terti etc) vor fi pedepsite conform legii penale.
              </p>
              <p>Actualizat la data de 11.03.2020: SHARDORAY – SHARDORAY.ro.</p>
              <p>.ro fiind liber de orice raspundere pentru eventualele daune directe sau indirecte cauzate de
                utilizarea
                sau accesarea/vizitarea site-ului sau ca urmare a utilizarii informatiilor de pe site. SHARDORAY&nbsp;nu
                este raspunzatoare de erorile sau omisiunile care pot interveni in redactarea sau prezentarea
                materialelor
                de pe site.</p>
              <p>Informatiile incluse pe SHARDORAY.ro au caracter informativ si sunt puse la dispozitie cu buna
                credinta,
                din surse pe care Vanzatorul le considera de incredere. In cazul in care vreunul dintre articolele
                publicate sau orice alta informatie intra sub incidenta Legii privind dreptul de autor si drepturile
                conexe, Utilizatorul este rugat sa aduca acest fapt la cunostinta Vanzatorului la adresa
                office@1.&nbsp;<strong>Identitatea firmei si descrierea produselor/serviciilor</strong></p>
              <ol start="2">
                <li><strong>Definitii</strong>:</li>
              </ol>
              <p>Vanzator: SHARDORAY<strong>,&nbsp;</strong>cu sediul în Mosoaia 45 A Bucuresti, denumita in
                continuare SHARDORAY comercializeaza si distribuie pe teritoriul Romaniei haine pentru barbatisi femei.
              </p>
              <p>Utilizator: orice persoana care viziteaza site-ul SHARDORAY.ro</p>
              <p>Client: orice persoana fizica sau juridica care efectueaza o Comanda pe site-ul SHARDORAY.ro sau orice
                alta pagina conexa si care este in proprietatea SHARDORAY.</p>
              <p>Produse: orice produs sau serviciu mentionat in Comanda efectuata de utilizator si care urmeaza sa fie
                livrata de catre Vanzator, Clientului.</p>
              <p>Comanda: un document electronic realizat intre Vanzator si Client prin care Clientul isi exprima
                intentia
                de a achizitiona anumite Produse si Servicii si sa faca plata acestora.</p>
              <p>Contract – o Comanda confirmata de catre Vanzator, prin care Vanzatorul este de acord sa vanda si sa
                livreze Produsele si Serviciile iar Clientul este de acord sa achizitioneze, sa primeasca si sa faca
                plata
                acestor Produse si Servicii.</p>
              <p>Curier – orice persoana de drept public sau privat care presteaza servicii de curierat rapid.</p>
              <ol start="3">
                <li><strong>Continut –drepturi de proprietate intelectuala</strong></li>
              </ol>
              <p>Continutul site-ului SHARDORAY.ro: imagini, texte, produsele, elemente de grafica web, sunt integral
                proprietatea SHARDORAY si a furnizorilor sai si este aparat de Legea privind dreptul de autor si
                drepturile conexe si de legile privind proprietatea intelectuala si industriala. Folosirea fara acordul
                SHARDORAY&nbsp;a oricaror elemente enumerate mai sus se pedepseste conform legislatiei in vigoare.</p>
              <ol start="4">
                <li><strong>Exonerarea de raspundere</strong></li>
              </ol>
              <p>SHARDORAY. nu garanteaza ca site-ul, serverele pe care este gazduit, sau e-mailurile trimise de la
                SHARDORAY.ro sunt fara virusi sau alte componente informatice cu caracter potential daunator, ca nu
                contine erori, omisiuni, defectiuni, intarzieri sau intreruperi in operare sau transmitere, caderi de
                linie sau orice alti factori similari. Utilizatorul foloseste site-ul pe riscul propriu, shardoray.ro
                fiind liber de orice raspundere pentru eventualele daune directe sau indirecte cauzate de utilizarea sau
                accesarea/vizitarea site-ului sau ca urmare a utilizarii informatiilor de pe site. SHARDORAY&nbsp;nu
                este
                raspunzatoare de erorile sau omisiunile care pot interveni in redactarea sau prezentarea materialelor de
                pe site.</p>
              <p>Informatiile incluse pe SHARDORAY.ro au caracter informativ si sunt puse la dispozitie cu buna
                credinta,
                din surse pe care Vanzatorul le considera de incredere. In cazul in care vreunul dintre articolele
                publicate sau orice alta informatie intra sub incidenta Legii privind dreptul de autor si drepturile
                conexe, Utilizatorul este rugat sa aduca acest fapt la cunostinta Vanzatorului la adresa
                legal@shardoray.ro, pentru a putea fi luate masurile legale necesare.</p>
              <p>SHARDORAY.ro isi rezerva dreptul de a anula Comenzile pentru Produse care sunt afisate pe site ca
                urmare
                a unor erori tehnice, sau care, din cauza unor erori tehnice prezinta preturi evident eronate/derizorii
                pentru produse (preturi pe care le poate aprecia ca fiind eronate/derizorii orice cumparator cu un nivel
                mediu de pregatire).</p>
              <p>SHARDORAY.ro, in calitate de organizator al campaniilor promotionale, nu isi asuma raspunderea pentru
                pierderile sau deteriorarile voucherului campaniei, nu este responsabila pentru voucherele falsificate
                sau
                deteriorate si isi rezerva dreptul de a anula voucherul utilizat la achizitie ori de a scadea valoarea
                acestuia din valoarea de rambursat, in cazul returnarii conform legii, a unui produs participant in
                campania respectiva.</p>
              <p>Orice link-uri catre alte site-uri sunt oferite numai in scopul unei accesibilitati sporite a
                informatiilor, iar SHARDORAY.ro nu isi asuma nici o responsabilitate sau raspundere pentru continutul
                acestor site-uri, pentru produsele sau serviciile promovate sau comercializate prin aceste site-uri.</p>
              <p>SHARDORAY are o raspundere limitata privind anumite probleme tehnice cu site-ul, de exemplu
                nefunctionare
                sau informatii eronate, pierderea datelor daca utilizatorul si-a share-uit parola.</p>
              <ol start="5">
                <li><strong>Drepturile si obligatiile vanzatorului</strong>:</li>
              </ol>
              <p>SHARDORAY isi rezerva dreptul de a modifica oricand continutul site-ului si a paginii de „Termeni si
                conditii” fara sa-i anunte pe utilizatori, de a nu posta anumite comentarii pe site care pot fi
                daunatoare
                imaginii companiei, de a suspenda conturi si alte mentiuni potrivite activitatii.</p>
              <ol start="6">
                <li><strong>Drepturile si obligatiile cumparatorului</strong>:</li>
              </ol>
              <p>Cumparatorul are obligatia de a nu furniza informatii false, de a nu înstraina contul (mai ales
                parola),
                dreptul de modificare sau stergere a contului utilizatorului din motive specifice.</p>
              <ol start="7">
                <li><strong>Prelucrarea datelor cu caracter personal</strong></li>
              </ol>
              <p>SHARDORAY. este inregistrata in Registrul Operatorilor de Date cu Caracter Personal.</p>
              <p>Datele colectate cu privire la newslettere sunt confidentiale.</p>
              <p>Conform Legii nr. 677/2001 pentru protectia persoanelor cu privire la prelucrarea datelor cu caracter
                personal si libera circulatie a acestor date, modificata si completata, si Legii nr. 506/2004 privind
                prelucrarea datelor cu caracter personal si protectia vietii private in sectorul comunicatiilor
                electronice, SHARDORAY.ro are obligatia de a prelucra in conditii de siguranta si numai pentru scopurile
                specificate datele personale furnizate de Utilizatori.</p>
              <p>Scopul colectarii datelor cu caracter personal este: gestiune economico-financiara, reclama, marketing,
                publicitate, statistica, servicii de comunicatii electronice, informarea Clientilor privind situatia
                contului lor de pe SHARDORAY.ro, informarea Clientilor privind evolutia si starea Comenzilor.</p>
              <p>Completarea de catre Utilizatori a formularelor pe site echivaleaza cu acceptarea neconditionata ca
                aceste date sa fie incluse in baza de date a SHARDORAY.ro si cu utilizarea si prelucrarea de catre
                SHARDORAY.ro, afiliatii si colaboratorii acestora pentru desfasurarea activitatilor enumerate mai sus,
                inclusiv, dar nelimitandu-se la furnizorii de servicii de marketing, curierat, servicii de plata /
                bancare.</p>
              <p>Clientilor le sunt garantate drepturile prevazute de Legea nr. 677/2001 pentru protectia persoanelor cu
                privire la prelucrarea datelor cu caracter personal si libera circulatie a acestor date, respectiv
                dreptul
                de informare, dreptul de acces la date, dreptul de interventie, dreptul de opozitie, dreptul de a nu fi
                supus unei decizii individuale, dreptul de se adresa justitiei in caz de incalcare a drepturilor sale.
                Clientii au dreptul de a solicita stergerea totala sau partiala a datelor cu caracter personal. Clientul
                poate solicita oricare dintre drepturile enumerate mai sus in baza unei cereri scrise, semnate si
                datate,
                inaintata catre SHARDORAY. la adresa officel@SHARDORAY.ro.</p>
              <p>Refuzul furnizarii acestor date determina imposibilitatea onorarii Comenzilor.</p>
              <p>In cazul in care Clientul isi modifica prin utilizarea formularelor de pe site datele personale livrate
                deja catre SHARDORAY.ro si exista Comenzi in derulare, Comenzile isi pastreaza datele de la momentul
                plasarii Comenzii iar livrarea produselor se va face luandu-se in considerare datele nou modificate.</p>
              <p>SHARDORAY.ro nu solicita Utilizatorilor sai prin niciun mijloc de comunicare (telefon/e-mail etc)
                informatii confidentiale, date despre conturi bancare, parole personale etc. In cazul in care
                Utilizatorul
                le divulga unor terti, acesta poarta intreaga responsabilitate pentru actiunile sale. Astfel, in cazuri
                de
                divulgare de astfel de date, Utilizatorul nu poate tine raspunzator pe shardoray.ro pentru niciun fel de
                prejudiciu.</p>
              <ol start="8">
                <li><strong>Inregistrarea ca Utilizator</strong></li>
              </ol>
              <p>Pentru crearea unui cont pe site-ul SHARDORAY.ro, Utilizatorul este obligat sa foloseasca o adresa de
                e-mail valida. SHARDORAY.ro poate refuza cererea de inregistrare in situatiile in care constata ca a
                utilizat informatii neconforme cu realitatea sau foloseste serviciile intr-un mod neconform cu uzantele
                normale.</p>
              <p>&nbsp;</p>
              <ol start="9">
                <li><strong>Pretul</strong></li>
              </ol>
              <p>Pretul final platit de Client este format din pretul produsului + cheltuielile de expeditie si/sau
                incasare aferente. Preturile de livrare sunt detaliate pe site.</p>
              <p>Toate preturile aferente produselor comercializate prin intermediul site-ului SHARDORAY.ro sunt
                exprimate
                in Lei si contin TVA</p>
              <p>În cazul plaților online vânzătorul nu este/nu poate fi făcut responsabil pentru niciun alt cost
                suplimentar suportat de cumpărător, incluzând dar nelimitându-se la comisioane de conversie valutară
                aplicate de către banca emitentă a cardului acestuia, în cazul în care moneda de emitere a acestuia
                diferă
                de RON. Responsabilitatea pentru această acțiune o poartă numai cumpărătorul.</p>
              <ol start="10">
                <li><strong>Comanda</strong></li>
              </ol>
              <p>Pentru a se putea realiza Comanda, Utilizatorul trebuie sa seteze detaliile preferate de Comanda (unde
                sa
                fie livrata, modalitatea de expediere si modalitatea de plata) in contul de Utilizator, iar aceste
                setari
                vor fi retinute si folosite pentru fiecare Comanda cu un click. Aceste setari pot fi modificate oricand.
              </p>
              <p>Prin finalizarea Comenzii Clientul garanteaza ca toate datele furnizate sunt reale si corecte, in caz
                contrar ar putea suporta consecintele acestor erori (Comanda intarziata, transmisa gresit etc).</p>
              <p>Adăugarea unui bun/serviciu în coșul de cumpărături, în lipsa finalizării comenzii, nu atrage după sine
                înregistrarea unei comenzi, implicit nici rezervarea automată a bunului/serviciului.</p>
              <p>Astfel, potrivit OUG nr 34/2014, perioada de returnare a unui bun sau renunțare la un serviciu expiră
                în
                termen de 14 zile de la:</p>
              <p>– ziua în care cumpărătorul intră în posesia fizică a ultimului bun – în cazul în care cumpărătorul
                comandă printr-o singură comandă produse multiple care vor fi livrate separat<br>
                – ziua în care cumpărătorul intră în posesia fizică a ultimului bun sau a ultimei piese – în cazul
                livrării unui produs care constă din mai multe loturi sau piese</p>
              <p>În cazul în care cumpărătorul decide să se retragă din contract, acesta va putea completa online
                formularul de retur.</p>
              <p>În cazul în care clientul/cumpărătorul solicită retragerea din contract în cadrul termenului legal de
                retragere din contract,&nbsp;trebuie să returneze și eventualele cadouri care au însoțit produsul
                respectiv. În cazul în care comanda este achitată, vânzătorul va rambursa suma în maxim 14 (paisprezece)
                zile de la data informării vânzătorului de către cumpărător asupra deciziei sale de retragere din
                contract. Suma va fi returnată după cum urmează:</p>
              <p>– pentru comenzile achitate cu card online – prin restituire în contul din care a fost efectuată
                plata;<br>
                – pentru comenzile achitate cu Op/Ramburs – prin virament bancar;</p>
              <p>Vânzătorul va putea amâna rambursarea sumei până la primirea bunurilor vândute sau până la primirea
                unei
                dovezi conform căreia acestea au fost expediate (se va lua data cea mai recentă).</p>
              <p>În cazul în care un bun și/sau serviciu comandat de către cumpărător, nu poate fi livrat de către
                vânzător, acesta din urmă va informa clientul/cumpărătorul asupra acestui fapt și va returna în contul
                cumpărătorului contravaloarea bunului și/sau serviciului, în termen de maxim 7 (șapte) zile de la data
                la
                care vânzătorul a luat la cunoștință acest fapt sau de la data la care cumpărătorul și-a exprimat în mod
                expres intenția de reziliere a contractului.</p>
              <p>SHARDORAY nu poate fi tras la raspundere pentru informatii introduse eronat, din care pot decurge
                intarzieri de livrare. In acest context, toate taxele de transport efectuat in vederea reexpedierii
                Comenzii vor cadea in sarcina Clientului.</p>
              <p>Prin finalizarea Comenzii, Clientul se declara de acord ca un reprezentant&nbsp;mextomen.ro&nbsp;sa il
                contacteze prin orice mijloc disponibil convenit de catre Parti (e-mail/telefon) pentru confirmarea
                personala a Comenzii, in cazurile in care aceasta confirmare este necesara.</p>
              <p>Pentru confortul si siguranta Clientului, exista optiunea ca timp de 30 de minute dupa plasarea unei
                Comenzi de a renunta la Comanda. Astfel, daca Utilizatorul care a plasat o Comanda din greseala sau a
                revenit asupra deciziei, Comanda se poate anula in 30 de minute dupa plasarea ei.</p>
              <p>SHARDORAY.ro poate refuza o Comanda in urma unei notificari prealabile adresate Clientului, fara a
                exista
                obligatii intre parti si fara ca o parte sa poata pretinde daune, pentru urmatoarele situatii:</p>
              <p>– esuarea / invalidarea tranzactiei online (daca este cazul);</p>
              <p>– neacceptarea de catre banca emitenta a cardului Clientului / a tranzactiei;</p>
              <p>– date incomplete sau incorecte ale Clientului;</p>
              <p>– activitatea Clientului poate produce daune site-ului SHARDORAY.ro;</p>
              <p>– livrari consecutiv esuate;</p>
              <p>– alte motive obiective: in cazul in care Clientul nu garanteaza ca modul de plata este valid si ca nu
                este obtinut printr-o metoda frauduloasa sau exista suspiciuni cu privire la modalitatea de plata.</p>
              <p>Chiar daca a luat toate masurile pentru ca informatia prezentata pe acest site sa fie exacta si
                corecta,
                SHARDORAY.ro nu poate fi raspunzatoare de inexactitati care pot aparea in completarea de catre Client a
                formularelor din site pentru finalizarea Comenzii. Utilizatorii sunt responsabili pentru evaluarea
                exactitatii, completitudinii si utilitatii informatiilor furnizate in cadrul formularelor disponibile pe
                acest site.</p>
              <p>In anumite situatii si pentru motive justificate, SHARDORAY.ro isi rezerva dreptul de a modifica
                cantitatea Bunurilor si/sau Serviciilor din Comanda. In aceste situatii SHARDORAY.ro va anunta Clientul
                la
                adresa de e-mail sau la numarul de telefon puse la dispozitia Vanzatorului la efectuarea Comenzii si va
                returna suma achitata.</p>
              <p>Contractul se considera incheiat intre Client si Vanzator in momentul primirii de catre Client de la
                shardoray.ro, a notificarii de expediere a Comenzii.</p>
              <p>In cazul in care un Bun si/sau Serviciu comandat de catre Client nu poate fi livrat de catre
                SHARDORAY.ro, ne obligam sa informam Clientul asupra acestui fapt si sa returnam in contul Clientului
                contravaloarea Bunului si/sau Serviciului, in termen de maxim 14 zile de la data la care SHARDORAY.ro
                luat
                la cunostinta acest fapt sau de la data la care Clientul si-a exprimat in mod expres intentia de
                reziliere
                a Contractului.</p>
              <ol start="11">
                <li>Plata</li>
              </ol>
              <p>În anumite cazuri, pentru menținerea securității tranzacțiilor, la înregistrarea comenzii,
                cumpărătorului
                i se va solicita sa autorizeze plata prin reintroducerea parolei aferente contului sau utilizarea
                amprentei digitale în cazul terminalelor mobile care au această facilitate.</p>
              <p>Pentru motive de securitate a tranzacțiilor clientul/ utilizatorul/ cumpărătorul este sfătuit să nu
                rămână logat pe site și să nu seteze opțiunea de logare automată pe dispozitivele mobile. Divulgarea
                parolei de acces în cont nu este permisă și se recomandă folosirea unei parole cu caracter puternic de
                securitate (de ex.: să conțină cel puțin opt caractere, incluzând litere mari, litere mici, cifre și
                caractere speciale).</p>
              <ol start="11">
                <li><strong>Facturare – plati</strong></li>
              </ol>
              <p>Pretul, modalitatea de plata si termenul de plata sunt specificate in Comanda. Vanzatorul va emite
                catre
                Client o factura pentru Produsele livrate, obligatia Clientului fiind sa furnizeze toate informatiile
                necesare emiterii facturii conform cu legislatia in vigoare.</p>
              <p>Plata se poate face prin ramburs la curier sau online (prin ordin de plata sau plata online cu cardul
                prin MobilPay).</p>
              <ol start="12">
                <li><strong>Garantia</strong></li>
              </ol>
              <p>Orice produs achizitionat de pe site-ul SHARDORAY.ro poate fi inlocuit in conditiile in care se face
                dovada ca acestea au defectiuni tehnice din productie. SHARDORAY nu isi asuma responsabilitatea sa
                inlocuiasca&nbsp; produsele deteriorate din vina clientului.</p>
              <p>Toate celelalte prevederi cuprinse in legislatia in vigoare, referitoare la garantarea produselor
                vandute, sunt valabile. Drepturile Clientilor sunt cele prevazute in legislatia in domeniu.</p>
              <ol start="13">
                <li><strong>Livrarea</strong></li>
              </ol>
              <p>Data estimata de livrare poate varia in functie de produs, si este afisata in pagina produsului, in
                pagina de Comanda precum si in e-mailul sau telefonul de confirmare a Comenzii.</p>
              <p>Pentru toate categoriile de produse, termenul maxim de livrare este de 30 de zile de la data
                confirmarii
                Comenzii.</p>
              <p>Pentru Clientii din strainatate, pretul final al Comenzii va fi comunicat prin e-mail sau telefonic,
                iar
                livrarea se va face dupa acceptarea explicita a acestui pret.</p>
              <p>Atentie!&nbsp;Din cauza timpului foarte scurt de procesare a Comenzilor, este imposibila operarea de
                modificari asupra Comenzilor deja plasate.</p>
              <p>In cazul in care produsele sunt indisponibile (inclusiv in cazul in care pretul sau unele
                caracteristici
                ale produselor au suferit modificari ulterioare confirmarii Comenzii), Clientul va fi informat despre
                indisponibilitate iar sumele pe care acesta le-a achitat vor fi rambursate in termen de 14 zile.
                Clientul
                poate confirma modificarile intervenite cu privire la pretul sau caracteristicile produselor, modificand
                astfel Comanda initiala, iar SHARDORAY&nbsp;va livra produsele conform Comenzii astfel modificate.</p>
              <ol start="14">
                <li><strong>Conditii de livrare</strong></li>
              </ol>
              <p>In cazul in care Clientul se afla in imposibilitatea receptionarii Comenzii in mod personal, Comanda va
                fi lasata la adresa precizata doar unei persoane cu varsta mai mare de 18 ani si doar in cazul
                comunicarii
                catre Curier, a numarului Comenzii.</p>
              <p>Nicio cerere de livrare a unei Comenzi nu va putea fi onorata daca Clientul nu indeplineste conditiile
                de
                mai sus.In cazul livrarii la locul de munca al Clientului, caz in care Curierul nu poate avea acces in
                locatia respectiva, Clientul trebuie sa se asigure ca poate receptiona aceasta Comanda.</p>
              <p>Clientul va fi contactat telefonic de catre Curier in prealabil pentru a obtine confirmarea prezentei
                acestuia la adresa si ora selectate in momentul validarii Comenzii pe site.</p>
              <p>Clientul se obliga sa fie prezent la adresa de livrare indicata, in intervalul orar selectat, in caz
                contrar acesta va supora taxa de transport aferenta unei posibile noi livrari. Intervalul orar va putea
                fi
                modificat, ulterior selectarii, doar cu acordul Curierului.</p>
              <p>Livrarea se considera a fi indeplinita de catre SHARDORAY.ro, la momentul predarii produselor Comandate
                catre Client la adresa selectata de acesta la momentul plasarii Comenzii.</p>
              <ol start="15">
                <li><strong>Receptia produselor</strong></li>
              </ol>
              <p>Clientul se obliga sa semneze bonul de livrare (AWB) prezentat de catre Curier sau Posta Romana la
                livrarea produselor comandate, la care este anexat si bonul fiscal ce contine toate informatiile despre
                produsele livrate (denumire produs, cantitate, pret). Deasemenea, Clientul va verifica daca produsele
                sunt
                deteriorate sau daca exista lipsuri, iar eventualele probleme vor fi mentionate in mod olograf pe bonul
                de
                livrare, pentru a putea fi luate in considerare.</p>
              <p>Prin semnarea bonului de livrare Clientul recunoaste primirea in bune conditii si in totalitate a
                produselor Comandate.</p>
              <ol start="16">
                <li><strong>Pretul</strong></li>
              </ol>
              <p>Pretul final platit de Client este format din pretul produsului + cheltuielile de expeditie si/sau
                incasare aferente. Preturile de livrare sunt detaliate pe site.</p>
              <p>Toate preturile aferente produselor si serviciilor comercializate prin intermediul site-ului
                SHARDORAY.ro
                sunt exprimate in Lei.</p>
              <ol start="17">
                <li><strong>Intreruperea campaniei promotionale</strong></li>
              </ol>
              <p>In cazul unei campanii promotionale oraganizate de SHARDORAY.ro, acesta, in calitate de organizator isi
                rezerva dreptul de a intrerupe Campania promotionala oricand pe parcursul desfasurarii acesteia, cu
                informarea prealabila a consumatorilor prin mijloace de comunicare corespunzatoare (afisare pe site,
                semnalizare in magazinul online, etc.).</p>
              <p>Organizatorul isi rezerva dreptul de a modifica oricare din conditiile stabilite in regulamentul
                campaniei respective, in perioada desfasurarii campaniei, din motive intemeiate, dar nu inainte de a
                anunta publicul despre aceste modificari.</p>
              <ol start="18">
                <li><strong>Anulare, refuz, retur comanda</strong></li>
              </ol>
              <p>Modalitati si termene de returnare a produselor</p>
              <p>Clientul se obliga sa notifice SHARDORAY.ro intentia sa de a returna produsele achizitionate, prin
                e-mail
                la office@SHARDORAY.ro sau prin telefon la numarul +40787344366 in termen de maxim 14 zile
                calendaristice de la primirea produselor. In caz contrar, SHARDORAY.ro poate refuza coletul.</p>
              <p>Informatiile necesare care trebuie furnizate SHARDORAY.ro pentru trimiterea curierului sunt
                urmatoarele:
              </p>
              <p>– data expedierii coletului;</p>
              <p>– numarul Comenzii;</p>
              <p>– suma de returnat;</p>
              <p>– adresa de unde curierul urmeaza sa preia coletul de retur;</p>
              <p>– modalitatea de plata dorita (datele contului bancar sau voucher de cumparaturi echivalent cu suma
                produselor returnate).</p>
              <p>La primirea informatiilor referitoare la intentia de retur a clientului, SHARDORAY.ro efectueaza o
                solicitare la curier si trimite curierul la adresa indicata de client pentru a ridica coletul de
                returnat.
              </p>
              <p>Costurile de transport ale coletului/elor returnate intra in responsabilitatea Clientului.</p>
              <p>&nbsp;</p>
              <ol start="19">
                <li><strong>Conditii de returnare a produselor</strong></li>
              </ol>
              <p>In orice situatie de returnare a produselor, acestea trebuie sa fie in aceeasi stare in care au fost
                trimise Clientului, in ambalajul original, cu etichetele intacte si impreuna cu toate documentele care
                l-au insotit (factura). Produsele returnate trebuie sa fie in aceeasi conditie ca la primire: fara
                defecte
                (cu exceptia viciilor ascunse si exceptand defectele deja semnalate de Client in corespondenta cu
                SHARDORAY.ro).</p>
              <p>In conformitate cu legea in vigoare, SHARDORAY.ro isi rezerva dreptul de a nu accepta returnarea
                produselor care au fost deteriorate din pricina Clientului.</p>
              <p>Nu se acceptă produsele asupra cărora au fost efectuate intervenții neautorizate, cele care prezintă
                urme
                de uzură sau folosire excesivă, zgârieturi, produsele cărora le lipsesc accesorii.&nbsp; Dacă produsele
                de
                returnat au fost însoțite de eventuale cadouri, trebuie returnate și acestea.</p>
              <p>Dacă ai cumpărat mai multe produse de același fel și vrei să le returnezi pe toate, asigură-te că doar
                unul a fost desigilat. Returul celorlaltor produse se acceptă doar dacă sunt sigilate.</p>
              <p>Produsele care nu sunt conforme specificațiilor de pe site din momentul trimiterii comenzii se pot
                returna fără implicarea unor costuri suplimentare din partea clientului, în urma unei notificari scrise
                în
                maxim 3 zile de la primirea produsului în care se va specifica natura lipsei de conformitate. Clientul
                poate solicita returnarea contravalorii produsului sau inlocuirea cu unul conform. Daca
                SHARDORAY&nbsp;nu
                poate înlocui produsul cu unul conform, se va restitui contravaloarea produsului.</p>
              <ol start="20">
                <li><strong>Termene de returnare a banilor</strong></li>
              </ol>
              <p>Pentru retururi integrale de comenzi, Clientul primeste banii aferenti produselor returnate in interval de 14 zile lucratoare de la data la care SHARDORAY a primit produsele.</p>
              <p>Dupa validarea returului, clientul poate primi, la alegere, contravaloarea produselor sub forma unui
                cupon valoric pe care il va putea folosi in termen de 30 zile de la data confirmarii primirii returului
                de
                catre SHARDORAY. Dupa aceasta data cuponul isi pierde valabilitatea.</p>
              <ol start="21">
                <li><strong>Forta majora</strong></li>
              </ol>
              <p>Niciuna dintre partile contractuale nu poate fi trasa la raspundere pentru neexecutarea (total/partial)
                sau executarea cu intarziere a obligatiilor sale, daca acestea au fost cauzate de forta majora. Partile
                isi vor aduce la cunostinta de indata ce are loc cazul de forta majora si vor lua toate masurile
                necesare
                in vederea limitarii consecintelor evenimentului.</p>
              <p>Daca in termen de 15 zile evenimentul de forta majora nu inceteaza, partile au dreptul de a denunta
                unilateral contractul fara pretinderea de daune-interese.</p>
              <p>Forta majora va fi probata conform legii.</p>
              <ol start="22">
                <li><strong>Solutionarea conflictelor.&nbsp;Legea aplicabila</strong></li>
              </ol>
              <p>Contractul va fi guvernat si interpretat in conformitate cu legea romana. Orice conflict aparut intre
                SHARDORAY si Clienti va fi rezolvat pe cale amiabila. In cazul in care acest lucru nu este posibil,
                prima
                cale de solutionare este medierea, in conditiile legii, iar daca aceasta esueaza se apeleaza la
                instantele
                romanesti competente de la domiciliul Clientului.</p>
              <ol start="23">
                <li><strong>Frauda</strong></li>
              </ol>
              <p>Crearea de conturi multiple folosind astfel de adrese generate automat si care expira dupa o perioada
                predefinita, pentru a putea beneficia de promotii sau oferte, sau orice comportament fraudulos in masura
                sa afecteze bunul mers al campaniilor, este interzisa si va fi considerata o tentativa de frauda.
                Proprietarul site-ului SHARDORAY.ro isi rezerva dreptul de a suspenda conturile astfel create si de a
                retrage beneficiile aferente promotiilor sau ofertelor in curs, de a anula contul fara preaviz,
                instiintare, alte formalitati sau despagubiri.</p>
              <p>Orice tentativa de frauda sau orice frauda (cum ar fi dar fara a se limita la: accesarea datelor
                Clientilor SHARDORAY, alterarea continutului site-ului, incercarea de afectare a performantelor
                serverelor
                shardoray.ro, deturnarea continutului livrarilor catre terti etc) vor fi pedepsite conform legii penale.
              </p>
              <p>Actualizat la data de 11.03.2020: SHARDORAY – SHARDORAY.ro.</p>
              <p>.ro, pentru a putea fi luate masurile legale necesare.</p>
              <p>SHARDORAY.ro isi rezerva dreptul de a anula Comenzile pentru Produse care sunt afisate pe site ca
                urmare
                a unor erori tehnice, sau care, din cauza unor erori tehnice prezinta preturi evident eronate/derizorii
                pentru produse (preturi pe care le poate aprecia ca fiind eronate/derizorii orice cumparator cu un nivel
                mediu de pregatire).</p>
              <p>SHARDORAY.ro, in calitate de organizator al campaniilor promotionale, nu isi asuma raspunderea pentru
                pierderile sau deteriorarile voucherului campaniei, nu este responsabila pentru voucherele falsificate
                sau
                deteriorate si isi rezerva dreptul de a anula voucherul utilizat la achizitie ori de a scadea valoarea
                acestuia din valoarea de rambursat, in cazul returnarii conform legii, a unui produs participant in
                campania respectiva.</p>
              <p>Orice link-uri catre alte site-uri sunt oferite numai in scopul unei accesibilitati sporite a
                informatiilor, iar SHARDORAY.ro nu isi asuma nici o responsabilitate sau raspundere pentru continutul
                acestor site-uri, pentru produsele sau serviciile promovate sau comercializate prin aceste site-uri.</p>
              <p>SHARDORAY are o raspundere limitata privind anumite probleme tehnice cu site-ul, de exemplu
                nefunctionare
                sau informatii eronate, pierderea datelor daca utilizatorul si-a share-uit parola.</p>
              <ol start="5">
                <li><strong>Drepturile si obligatiile vanzatorului</strong>:</li>
              </ol>
              <p>SHARDORAY isi rezerva dreptul de a modifica oricand continutul site-ului si a paginii de „Termeni si
                conditii” fara sa-i anunte pe utilizatori, de a nu posta anumite comentarii pe site care pot fi
                daunatoare
                imaginii companiei, de a suspenda conturi si alte mentiuni potrivite activitatii.</p>
              <ol start="6">
                <li><strong>Drepturile si obligatiile cumparatorului</strong>:</li>
              </ol>
              <p>Cumparatorul are obligatia de a nu furniza informatii false, de a nu înstraina contul (mai ales
                parola),
                dreptul de modificare sau stergere a contului utilizatorului din motive specifice.</p>
              <ol start="7">
                <li><strong>Prelucrarea datelor cu caracter personal</strong></li>
              </ol>
              <p>SHARDORAY. este inregistrata in Registrul Operatorilor de Date cu Caracter Personal.</p>
              <p>Datele colectate cu privire la newslettere sunt confidentiale.</p>
              <p>Conform Legii nr. 677/2001 pentru protectia persoanelor cu privire la prelucrarea datelor cu caracter
                personal si libera circulatie a acestor date, modificata si completata, si Legii nr. 506/2004 privind
                prelucrarea datelor cu caracter personal si protectia vietii private in sectorul comunicatiilor
                electronice, SHARDORAY.ro are obligatia de a prelucra in conditii de siguranta si numai pentru scopurile
                specificate datele personale furnizate de Utilizatori.</p>
              <p>Scopul colectarii datelor cu caracter personal este: gestiune economico-financiara, reclama, marketing,
                publicitate, statistica, servicii de comunicatii electronice, informarea Clientilor privind situatia
                contului lor de pe SHARDORAY.ro, informarea Clientilor privind evolutia si starea Comenzilor.</p>
              <p>Completarea de catre Utilizatori a formularelor pe site echivaleaza cu acceptarea neconditionata ca
                aceste date sa fie incluse in baza de date a SHARDORAY.ro si cu utilizarea si prelucrarea de catre
                SHARDORAY.ro, afiliatii si colaboratorii acestora pentru desfasurarea activitatilor enumerate mai sus,
                inclusiv, dar nelimitandu-se la furnizorii de servicii de marketing, curierat, servicii de plata /
                bancare.</p>
              <p>Clientilor le sunt garantate drepturile prevazute de Legea nr. 677/2001 pentru protectia persoanelor cu
                privire la prelucrarea datelor cu caracter personal si libera circulatie a acestor date, respectiv
                dreptul
                de informare, dreptul de acces la date, dreptul de interventie, dreptul de opozitie, dreptul de a nu fi
                supus unei decizii individuale, dreptul de se adresa justitiei in caz de incalcare a drepturilor sale.
                Clientii au dreptul de a solicita stergerea totala sau partiala a datelor cu caracter personal. Clientul
                poate solicita oricare dintre drepturile enumerate mai sus in baza unei cereri scrise, semnate si
                datate,
                inaintata catre SHARDORAY. la adresa officel@SHARDORAY.ro.</p>
              <p>Refuzul furnizarii acestor date determina imposibilitatea onorarii Comenzilor.</p>
              <p>In cazul in care Clientul isi modifica prin utilizarea formularelor de pe site datele personale livrate
                deja catre SHARDORAY.ro si exista Comenzi in derulare, Comenzile isi pastreaza datele de la momentul
                plasarii Comenzii iar livrarea produselor se va face luandu-se in considerare datele nou modificate.</p>
              <p>SHARDORAY.ro nu solicita Utilizatorilor sai prin niciun mijloc de comunicare (telefon/e-mail etc)
                informatii confidentiale, date despre conturi bancare, parole personale etc. In cazul in care
                Utilizatorul
                le divulga unor terti, acesta poarta intreaga responsabilitate pentru actiunile sale. Astfel, in cazuri
                de
                divulgare de astfel de date, Utilizatorul nu poate tine raspunzator pe shardoray.ro pentru niciun fel de
                prejudiciu.</p>
              <ol start="8">
                <li><strong>Inregistrarea ca Utilizator</strong></li>
              </ol>
              <p>Pentru crearea unui cont pe site-ul SHARDORAY.ro, Utilizatorul este obligat sa foloseasca o adresa de
                e-mail valida. SHARDORAY.ro poate refuza cererea de inregistrare in situatiile in care constata ca a
                utilizat informatii neconforme cu realitatea sau foloseste serviciile intr-un mod neconform cu uzantele
                normale.</p>
              <p>&nbsp;</p>
              <ol start="9">
                <li><strong>Pretul</strong></li>
              </ol>
              <p>Pretul final platit de Client este format din pretul produsului + cheltuielile de expeditie si/sau
                incasare aferente. Preturile de livrare sunt detaliate pe site.</p>
              <p>Toate preturile aferente produselor comercializate prin intermediul site-ului SHARDORAY.ro sunt
                exprimate
                in Lei si contin TVA</p>
              <p>În cazul plaților online vânzătorul nu este/nu poate fi făcut responsabil pentru niciun alt cost
                suplimentar suportat de cumpărător, incluzând dar nelimitându-se la comisioane de conversie valutară
                aplicate de către banca emitentă a cardului acestuia, în cazul în care moneda de emitere a acestuia
                diferă
                de RON. Responsabilitatea pentru această acțiune o poartă numai cumpărătorul.</p>
              <ol start="10">
                <li><strong>Comanda</strong></li>
              </ol>
              <p>Pentru a se putea realiza Comanda, Utilizatorul trebuie sa seteze detaliile preferate de Comanda (unde
                sa
                fie livrata, modalitatea de expediere si modalitatea de plata) in contul de Utilizator, iar aceste
                setari
                vor fi retinute si folosite pentru fiecare Comanda cu un click. Aceste setari pot fi modificate oricand.
              </p>
              <p>Prin finalizarea Comenzii Clientul garanteaza ca toate datele furnizate sunt reale si corecte, in caz
                contrar ar putea suporta consecintele acestor erori (Comanda intarziata, transmisa gresit etc).</p>
              <p>Adăugarea unui bun/serviciu în coșul de cumpărături, în lipsa finalizării comenzii, nu atrage după sine
                înregistrarea unei comenzi, implicit nici rezervarea automată a bunului/serviciului.</p>
              <p>Astfel, potrivit OUG nr 34/2014, perioada de returnare a unui bun sau renunțare la un serviciu expiră
                în
                termen de 14 zile de la:</p>
              <p>– ziua în care cumpărătorul intră în posesia fizică a ultimului bun – în cazul în care cumpărătorul
                comandă printr-o singură comandă produse multiple care vor fi livrate separat<br>
                – ziua în care cumpărătorul intră în posesia fizică a ultimului bun sau a ultimei piese – în cazul
                livrării unui produs care constă din mai multe loturi sau piese</p>
              <p>În cazul în care cumpărătorul decide să se retragă din contract, acesta va putea completa online
                formularul de retur.</p>
              <p>În cazul în care clientul/cumpărătorul solicită retragerea din contract în cadrul termenului legal de
                retragere din contract,&nbsp;trebuie să returneze și eventualele cadouri care au însoțit produsul
                respectiv. În cazul în care comanda este achitată, vânzătorul va rambursa suma în maxim 14 (paisprezece)
                zile de la data informării vânzătorului de către cumpărător asupra deciziei sale de retragere din
                contract. Suma va fi returnată după cum urmează:</p>
              <p>– pentru comenzile achitate cu card online – prin restituire în contul din care a fost efectuată
                plata;<br>
                – pentru comenzile achitate cu Op/Ramburs – prin virament bancar;</p>
              <p>Vânzătorul va putea amâna rambursarea sumei până la primirea bunurilor vândute sau până la primirea
                unei
                dovezi conform căreia acestea au fost expediate (se va lua data cea mai recentă).</p>
              <p>În cazul în care un bun și/sau serviciu comandat de către cumpărător, nu poate fi livrat de către
                vânzător, acesta din urmă va informa clientul/cumpărătorul asupra acestui fapt și va returna în contul
                cumpărătorului contravaloarea bunului și/sau serviciului, în termen de maxim 7 (șapte) zile de la data
                la
                care vânzătorul a luat la cunoștință acest fapt sau de la data la care cumpărătorul și-a exprimat în mod
                expres intenția de reziliere a contractului.</p>
              <p>SHARDORAY nu poate fi tras la raspundere pentru informatii introduse eronat, din care pot decurge
                intarzieri de livrare. In acest context, toate taxele de transport efectuat in vederea reexpedierii
                Comenzii vor cadea in sarcina Clientului.</p>
              <p>Prin finalizarea Comenzii, Clientul se declara de acord ca un reprezentant&nbsp;mextomen.ro&nbsp;sa il
                contacteze prin orice mijloc disponibil convenit de catre Parti (e-mail/telefon) pentru confirmarea
                personala a Comenzii, in cazurile in care aceasta confirmare este necesara.</p>
              <p>Pentru confortul si siguranta Clientului, exista optiunea ca timp de 30 de minute dupa plasarea unei
                Comenzi de a renunta la Comanda. Astfel, daca Utilizatorul care a plasat o Comanda din greseala sau a
                revenit asupra deciziei, Comanda se poate anula in 30 de minute dupa plasarea ei.</p>
              <p>SHARDORAY.ro poate refuza o Comanda in urma unei notificari prealabile adresate Clientului, fara a
                exista
                obligatii intre parti si fara ca o parte sa poata pretinde daune, pentru urmatoarele situatii:</p>
              <p>– esuarea / invalidarea tranzactiei online (daca este cazul);</p>
              <p>– neacceptarea de catre banca emitenta a cardului Clientului / a tranzactiei;</p>
              <p>– date incomplete sau incorecte ale Clientului;</p>
              <p>– activitatea Clientului poate produce daune site-ului SHARDORAY.ro;</p>
              <p>– livrari consecutiv esuate;</p>
              <p>– alte motive obiective: in cazul in care Clientul nu garanteaza ca modul de plata este valid si ca nu
                este obtinut printr-o metoda frauduloasa sau exista suspiciuni cu privire la modalitatea de plata.</p>
              <p>Chiar daca a luat toate masurile pentru ca informatia prezentata pe acest site sa fie exacta si
                corecta,
                SHARDORAY.ro nu poate fi raspunzatoare de inexactitati care pot aparea in completarea de catre Client a
                formularelor din site pentru finalizarea Comenzii. Utilizatorii sunt responsabili pentru evaluarea
                exactitatii, completitudinii si utilitatii informatiilor furnizate in cadrul formularelor disponibile pe
                acest site.</p>
              <p>In anumite situatii si pentru motive justificate, SHARDORAY.ro isi rezerva dreptul de a modifica
                cantitatea Bunurilor si/sau Serviciilor din Comanda. In aceste situatii SHARDORAY.ro va anunta Clientul
                la
                adresa de e-mail sau la numarul de telefon puse la dispozitia Vanzatorului la efectuarea Comenzii si va
                returna suma achitata.</p>
              <p>Contractul se considera incheiat intre Client si Vanzator in momentul primirii de catre Client de la
                shardoray.ro, a notificarii de expediere a Comenzii.</p>
              <p>In cazul in care un Bun si/sau Serviciu comandat de catre Client nu poate fi livrat de catre
                SHARDORAY.ro, ne obligam sa informam Clientul asupra acestui fapt si sa returnam in contul Clientului
                contravaloarea Bunului si/sau Serviciului, in termen de maxim 14 zile de la data la care SHARDORAY.ro
                luat
                la cunostinta acest fapt sau de la data la care Clientul si-a exprimat in mod expres intentia de
                reziliere
                a Contractului.</p>
              <ol start="11">
                <li>Plata</li>
              </ol>
              <p>În anumite cazuri, pentru menținerea securității tranzacțiilor, la înregistrarea comenzii,
                cumpărătorului
                i se va solicita sa autorizeze plata prin reintroducerea parolei aferente contului sau utilizarea
                amprentei digitale în cazul terminalelor mobile care au această facilitate.</p>
              <p>Pentru motive de securitate a tranzacțiilor clientul/ utilizatorul/ cumpărătorul este sfătuit să nu
                rămână logat pe site și să nu seteze opțiunea de logare automată pe dispozitivele mobile. Divulgarea
                parolei de acces în cont nu este permisă și se recomandă folosirea unei parole cu caracter puternic de
                securitate (de ex.: să conțină cel puțin opt caractere, incluzând litere mari, litere mici, cifre și
                caractere speciale).</p>
              <ol start="11">
                <li><strong>Facturare – plati</strong></li>
              </ol>
              <p>Pretul, modalitatea de plata si termenul de plata sunt specificate in Comanda. Vanzatorul va emite
                catre
                Client o factura pentru Produsele livrate, obligatia Clientului fiind sa furnizeze toate informatiile
                necesare emiterii facturii conform cu legislatia in vigoare.</p>
              <p>Plata se poate face prin ramburs la curier sau online (prin ordin de plata sau plata online cu cardul
                prin MobilPay).</p>
              <ol start="12">
                <li><strong>Garantia</strong></li>
              </ol>
              <p>Orice produs achizitionat de pe site-ul SHARDORAY.ro poate fi inlocuit in conditiile in care se face
                dovada ca acestea au defectiuni tehnice din productie. SHARDORAY nu isi asuma responsabilitatea sa
                inlocuiasca&nbsp; produsele deteriorate din vina clientului.</p>
              <p>Toate celelalte prevederi cuprinse in legislatia in vigoare, referitoare la garantarea produselor
                vandute, sunt valabile. Drepturile Clientilor sunt cele prevazute in legislatia in domeniu.</p>
              <ol start="13">
                <li><strong>Livrarea</strong></li>
              </ol>
              <p>Data estimata de livrare poate varia in functie de produs, si este afisata in pagina produsului, in
                pagina de Comanda precum si in e-mailul sau telefonul de confirmare a Comenzii.</p>
              <p>Pentru toate categoriile de produse, termenul maxim de livrare este de 30 de zile de la data
                confirmarii
                Comenzii.</p>
              <p>Pentru Clientii din strainatate, pretul final al Comenzii va fi comunicat prin e-mail sau telefonic,
                iar
                livrarea se va face dupa acceptarea explicita a acestui pret.</p>
              <p>Atentie!&nbsp;Din cauza timpului foarte scurt de procesare a Comenzilor, este imposibila operarea de
                modificari asupra Comenzilor deja plasate.</p>
              <p>In cazul in care produsele sunt indisponibile (inclusiv in cazul in care pretul sau unele
                caracteristici
                ale produselor au suferit modificari ulterioare confirmarii Comenzii), Clientul va fi informat despre
                indisponibilitate iar sumele pe care acesta le-a achitat vor fi rambursate in termen de 14 zile.
                Clientul
                poate confirma modificarile intervenite cu privire la pretul sau caracteristicile produselor, modificand
                astfel Comanda initiala, iar SHARDORAY&nbsp;va livra produsele conform Comenzii astfel modificate.</p>
              <ol start="14">
                <li><strong>Conditii de livrare</strong></li>
              </ol>
              <p>In cazul in care Clientul se afla in imposibilitatea receptionarii Comenzii in mod personal, Comanda va
                fi lasata la adresa precizata doar unei persoane cu varsta mai mare de 18 ani si doar in cazul
                comunicarii
                catre Curier, a numarului Comenzii.</p>
              <p>Nicio cerere de livrare a unei Comenzi nu va putea fi onorata daca Clientul nu indeplineste conditiile
                de
                mai sus.In cazul livrarii la locul de munca al Clientului, caz in care Curierul nu poate avea acces in
                locatia respectiva, Clientul trebuie sa se asigure ca poate receptiona aceasta Comanda.</p>
              <p>Clientul va fi contactat telefonic de catre Curier in prealabil pentru a obtine confirmarea prezentei
                acestuia la adresa si ora selectate in momentul validarii Comenzii pe site.</p>
              <p>Clientul se obliga sa fie prezent la adresa de livrare indicata, in intervalul orar selectat, in caz
                contrar acesta va supora taxa de transport aferenta unei posibile noi livrari. Intervalul orar va putea
                fi
                modificat, ulterior selectarii, doar cu acordul Curierului.</p>
              <p>Livrarea se considera a fi indeplinita de catre SHARDORAY.ro, la momentul predarii produselor Comandate
                catre Client la adresa selectata de acesta la momentul plasarii Comenzii.</p>
              <ol start="15">
                <li><strong>Receptia produselor</strong></li>
              </ol>
              <p>Clientul se obliga sa semneze bonul de livrare (AWB) prezentat de catre Curier sau Posta Romana la
                livrarea produselor comandate, la care este anexat si bonul fiscal ce contine toate informatiile despre
                produsele livrate (denumire produs, cantitate, pret). Deasemenea, Clientul va verifica daca produsele
                sunt
                deteriorate sau daca exista lipsuri, iar eventualele probleme vor fi mentionate in mod olograf pe bonul
                de
                livrare, pentru a putea fi luate in considerare.</p>
              <p>Prin semnarea bonului de livrare Clientul recunoaste primirea in bune conditii si in totalitate a
                produselor Comandate.</p>
              <ol start="16">
                <li><strong>Pretul</strong></li>
              </ol>
              <p>Pretul final platit de Client este format din pretul produsului + cheltuielile de expeditie si/sau
                incasare aferente. Preturile de livrare sunt detaliate pe site.</p>
              <p>Toate preturile aferente produselor si serviciilor comercializate prin intermediul site-ului
                SHARDORAY.ro
                sunt exprimate in Lei.</p>
              <ol start="17">
                <li><strong>Intreruperea campaniei promotionale</strong></li>
              </ol>
              <p>In cazul unei campanii promotionale oraganizate de SHARDORAY.ro, acesta, in calitate de organizator isi
                rezerva dreptul de a intrerupe Campania promotionala oricand pe parcursul desfasurarii acesteia, cu
                informarea prealabila a consumatorilor prin mijloace de comunicare corespunzatoare (afisare pe site,
                semnalizare in magazinul online, etc.).</p>
              <p>Organizatorul isi rezerva dreptul de a modifica oricare din conditiile stabilite in regulamentul
                campaniei respective, in perioada desfasurarii campaniei, din motive intemeiate, dar nu inainte de a
                anunta publicul despre aceste modificari.</p>
              <ol start="18">
                <li><strong>Anulare, refuz, retur comanda</strong></li>
              </ol>
              <p>Modalitati si termene de returnare a produselor</p>
              <p>Clientul se obliga sa notifice SHARDORAY.ro intentia sa de a returna produsele achizitionate, prin
                e-mail
                la office@SHARDORAY.ro sau prin telefon la numarul +40787344366 in termen de maxim 14 zile
                calendaristice de la primirea produselor. In caz contrar, SHARDORAY.ro poate refuza coletul.</p>
              <p>Informatiile necesare care trebuie furnizate SHARDORAY.ro pentru trimiterea curierului sunt
                urmatoarele:
              </p>
              <p>– data expedierii coletului;</p>
              <p>– numarul Comenzii;</p>
              <p>– suma de returnat;</p>
              <p>– adresa de unde curierul urmeaza sa preia coletul de retur;</p>
              <p>– modalitatea de plata dorita (datele contului bancar sau voucher de cumparaturi echivalent cu suma
                produselor returnate).</p>
              <p>La primirea informatiilor referitoare la intentia de retur a clientului, SHARDORAY.ro efectueaza o
                solicitare la curier si trimite curierul la adresa indicata de client pentru a ridica coletul de
                returnat.
              </p>
              <p>Costurile de transport ale coletului/elor returnate intra in responsabilitatea Clientului.</p>
              <p>&nbsp;</p>
              <ol start="19">
                <li><strong>Conditii de returnare a produselor</strong></li>
              </ol>
              <p>In orice situatie de returnare a produselor, acestea trebuie sa fie in aceeasi stare in care au fost
                trimise Clientului, in ambalajul original, cu etichetele intacte si impreuna cu toate documentele care
                l-au insotit (factura). Produsele returnate trebuie sa fie in aceeasi conditie ca la primire: fara
                defecte
                (cu exceptia viciilor ascunse si exceptand defectele deja semnalate de Client in corespondenta cu
                SHARDORAY.ro).</p>
              <p>In conformitate cu legea in vigoare, SHARDORAY.ro isi rezerva dreptul de a nu accepta returnarea
                produselor care au fost deteriorate din pricina Clientului.</p>
              <p>Nu se acceptă produsele asupra cărora au fost efectuate intervenții neautorizate, cele care prezintă
                urme
                de uzură sau folosire excesivă, zgârieturi, produsele cărora le lipsesc accesorii.&nbsp; Dacă produsele
                de
                returnat au fost însoțite de eventuale cadouri, trebuie returnate și acestea.</p>
              <p>Dacă ai cumpărat mai multe produse de același fel și vrei să le returnezi pe toate, asigură-te că doar
                unul a fost desigilat. Returul celorlaltor produse se acceptă doar dacă sunt sigilate.</p>
              <p>Produsele care nu sunt conforme specificațiilor de pe site din momentul trimiterii comenzii se pot
                returna fără implicarea unor costuri suplimentare din partea clientului, în urma unei notificari scrise
                în
                maxim 3 zile de la primirea produsului în care se va specifica natura lipsei de conformitate. Clientul
                poate solicita returnarea contravalorii produsului sau inlocuirea cu unul conform. Daca
                SHARDORAY&nbsp;nu
                poate înlocui produsul cu unul conform, se va restitui contravaloarea produsului.</p>
              <ol start="20">
                <li><strong>Termene de returnare a banilor</strong></li>
              </ol>
              <p>Pentru retururi integrale de comenzi, Clientul primeste banii aferenti produselor returnate in interval de 14 zile lucratoare de la data la care SHARDORAY a primit produsele.</p>
              <p>Dupa validarea returului, clientul poate primi, la alegere, contravaloarea produselor sub forma unui
                cupon valoric pe care il va putea folosi in termen de 30 zile de la data confirmarii primirii returului
                de
                catre SHARDORAY. Dupa aceasta data cuponul isi pierde valabilitatea.</p>
              <ol start="21">
                <li><strong>Forta majora</strong></li>
              </ol>
              <p>Niciuna dintre partile contractuale nu poate fi trasa la raspundere pentru neexecutarea (total/partial)
                sau executarea cu intarziere a obligatiilor sale, daca acestea au fost cauzate de forta majora. Partile
                isi vor aduce la cunostinta de indata ce are loc cazul de forta majora si vor lua toate masurile
                necesare
                in vederea limitarii consecintelor evenimentului.</p>
              <p>Daca in termen de 15 zile evenimentul de forta majora nu inceteaza, partile au dreptul de a denunta
                unilateral contractul fara pretinderea de daune-interese.</p>
              <p>Forta majora va fi probata conform legii.</p>
              <ol start="22">
                <li><strong>Solutionarea conflictelor.&nbsp;Legea aplicabila</strong></li>
              </ol>
              <p>Contractul va fi guvernat si interpretat in conformitate cu legea romana. Orice conflict aparut intre
                SHARDORAY si Clienti va fi rezolvat pe cale amiabila. In cazul in care acest lucru nu este posibil,
                prima
                cale de solutionare este medierea, in conditiile legii, iar daca aceasta esueaza se apeleaza la
                instantele
                romanesti competente de la domiciliul Clientului.</p>
              <ol start="23">
                <li><strong>Frauda</strong></li>
              </ol>
              <p>Crearea de conturi multiple folosind astfel de adrese generate automat si care expira dupa o perioada
                predefinita, pentru a putea beneficia de promotii sau oferte, sau orice comportament fraudulos in masura
                sa afecteze bunul mers al campaniilor, este interzisa si va fi considerata o tentativa de frauda.
                Proprietarul site-ului SHARDORAY.ro isi rezerva dreptul de a suspenda conturile astfel create si de a
                retrage beneficiile aferente promotiilor sau ofertelor in curs, de a anula contul fara preaviz,
                instiintare, alte formalitati sau despagubiri.</p>
              <p>Orice tentativa de frauda sau orice frauda (cum ar fi dar fara a se limita la: accesarea datelor
                Clientilor SHARDORAY, alterarea continutului site-ului, incercarea de afectare a performantelor
                serverelor
                shardoray.ro, deturnarea continutului livrarilor catre terti etc) vor fi pedepsite conform legii penale.
              </p>
              <p>Actualizat la data de 11.03.2020: SHARDORAY – SHARDORAY.ro.</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mb-6"></div>