<nav aria-label="breadcrumb" class="breadcrumb-nav">
  <div class="container">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="index.html">Acasa</a></li>
      <li class="breadcrumb-item active" aria-current="page">Comanda {{order?.order_uuid}}</li>
    </ol>
  </div><!-- End .container -->
</nav>
<div class="my-account pt-95 pb-100">

  <div class="container">
    <div class="row">
      <aside class="sidebar col-lg-3">
        <div class="widget widget-dashboard">
          <h3 class="widget-title">Contul meu</h3>

          <ul class="list">
            <li routerLinkActive='active'><a routerLink="/contul-meu">Informatii cont</a></li>
            <li routerLinkActive='active'><a routerLink="/adresele-mele">Adresele mele</a></li>
            <li routerLinkActive='active'><a routerLink="/comenzile-mele">Comenzile mele</a></li>
            <!-- <li routerLinkActive='active'><a routerLink="/cupoane-reducere">Cupoane reducere</a></li> -->
            <li routerLinkActive='active'><a (click)="logout()">Delogare</a></li>
          </ul>

        </div><!-- End .widget -->
      </aside><!-- End .col-lg-3 -->
      <div class="col-lg-9 order-lg-last dashboard-content">

        <p>Plasata in: <strong>{{order?.date}}</strong></p>
        <p>Total: <strong>{{order?.total}} lei</strong></p>



        <p *ngIf="order?.discount > 0">Discount: <strong>{{order?.discount}}%</strong></p>

        <div class="delivery-card card">
          <p><strong>Adresa de livrare:</strong></p>
          <p>{{order?.customer.name}}  {{order?.customer.nationalNumber}}</p>
          <p>{{order?.addressData.address}}, <span *ngIf="order?.addressData.details">{{order?.addressData.details}}</span></p>
            <p>{{order?.addressData.county}},
              {{order?.addressData.city}}</p>
        </div>

        <div class="delivery-card card">
          <p><strong>Modalitate de plata:</strong></p>
          <p>{{order?.method == "card" ? 'Plata card' : 'Plata cash'}}</p>

          <p>Valoare toala: {{order?.total}} lei</p>
        </div>

        <p><strong>Produse:</strong></p>
        <ul class="products">
          <li *ngFor="let product of products">

            <a href="/produs/{{product.alias}}" class="product-image">
              <img [src]="uploadedImgUrl + product.selectedImageObj" alt="product">
            </a>
            <a href="/produs/{{product?.alias}}" class="product-name">
              <strong>{{product.num}} x {{product.name}}</strong>

            </a>

            <span class="price">{{product.selectedPrice}} Lei</span>
          </li>
        </ul>
      </div><!-- End .col-lg-9 -->


    </div><!-- End .row -->
  </div><!-- End .container -->

  <div class="mb-5"></div><!-- margin -->

</div>
