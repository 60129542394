import { NgModule } from '@angular/core';
import { DeliveryAddressComponent } from './delivery-address.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    DeliveryAddressComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    DeliveryAddressComponent
  ]
})
export class DeliveryAddressModule { }
