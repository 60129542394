import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { HomepageComponent } from './homepage.component';
import { SliderModule } from 'src/app/components/slider/slider.module';
import { NewsletterModule } from 'src/app/components/newsletter/newsletter.module';
import { NewsletterComponent } from 'src/app/components/newsletter/newsletter.component';
import { SliderComponent } from 'src/app/components/slider/slider.component';
import { SelectedProductsComponent } from 'src/app/components/selected-products/selected-products.component';




@NgModule({
  declarations: [
    HomepageComponent,
    NewsletterComponent,
    SliderComponent,
    SelectedProductsComponent
  ],
  imports: [
    SharedModule,
   
  ]
})
export class HomepageModule { }
