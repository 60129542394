import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CategoriesService } from 'src/app/services';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: false,
    margin: 20,
    autoplay: false,
    nav: true,
    navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
    dots: false,
    items: 2,
    responsive: {
      576: {
        items: 3
      },
      992: {
        items: 5,
      }
    }
  }
  categories: any;
  uploadedImgUrl = environment.uploadImagesUrl;

  constructor(
    private _CategoriesService: CategoriesService
  ) { }

  ngOnInit(): void {
    this._CategoriesService.getClientCategories().then(data => {
      this.categories = data;
    })
  }

}
