import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './helpers/auth.guard';
import { AuthAPIService } from './services';

import { CartComponent } from './pages/cart/cart.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { LoginComponent } from './pages/login/login.component';
import { AddressesComponent } from './pages/my-account/addresses/addresses.component';
import { CouponsComponent } from './pages/my-account/coupons/coupons.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { MyOrdersComponent } from './pages/my-account/my-orders/my-orders.component';
import { OrderDetailsComponent } from './pages/my-account/order-details/order-details.component';
import { ProductComponent } from './pages/product/product.component';
import { ProductsComponent } from './pages/products/products.component';
import { MeasurementsComponent } from './pages/measurements/measurements.component';
import { ConfidentialityPolicyComponent } from './pages/confidentiality-policy/confidentiality-policy.component';
import { HowToPayComponent } from './pages/how-to-pay/how-to-pay.component';
import { HowToDeliverComponent } from './pages/how-to-deliver/how-to-deliver.component';
import { TermsComponent } from './pages/terms/terms.component';
import { ReturnComponent } from './pages/return/return.component';
import { GdprComponent } from './pages/gdpr/gdpr.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ReturnFormComponent } from './pages/return-form/return-form.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { InvalidPaymentComponent } from './pages/invalid-payment/invalid-payment.component';
import { TestProductsComponent } from './pages/test-products/test-products.component';
import { TestProductsResolver } from './pages/test-products/test-products.resolver';
import { ConfirmationAccountComponent } from './pages/confirmation-account/confirmation-account.component';
import { VerificationEmailComponent } from './pages/verification-email/verification-email.component';
import { ProductResolver } from './pages/product/product.resolver';

const appRoutes: Routes = [
  {
    path: '',
    component: HomepageComponent,
    redirectTo: '',
    pathMatch: 'full',
    data: {
      title:
        'Bun venit la magazinul online fashion Shardoray.Poți comanda îmbrăcăminte și accesorii pentru bărbati și femei la cele mai bune prețuri.',
      description: '',
      ogUrl: 'your og url',
    },
  },

  {
    path: 'produs',
    loadChildren: () =>
      import('src/app/pages/product/product.module').then(
        (m) => m.ProductModule
      ),
    data: { preload: true },
  },

  {
    path: 'produs/:productSlug/:productID',
    component: ProductComponent,
    data: {
      title:
        'Bun venit la magazinul online fashion Shardoray.Poți comanda îmbrăcăminte și accesorii pentru bărbati și femei la cele mai bune prețuri.',
      description: '',
      ogUrl: 'your og url',
    },
  },
  {
    path: 'produs/:productSlug/:productID/:colorID',
    component: ProductComponent,
    data: {
      title:
        'Bun venit la magazinul online fashion Shardoray.Poți comanda îmbrăcăminte și accesorii pentru bărbati și femei la cele mai bune prețuri.',
      description: '',
      ogUrl: 'your og url',
    },
  },

  {
    path: 'produse',
    component: ProductsComponent,
    data: {
      title:
        'Bun venit la magazinul online fashion Shardoray.Poți comanda îmbrăcăminte și accesorii pentru bărbati și femei la cele mai bune prețuri.',
      description: '',
      ogUrl: 'your og url',
    },
  },
  /* TEST Below */
  {
    path: 'produse/:categorySlug/:subcategorySlug',
    component: ProductsComponent,
  },
  {
    path: 'produse-test',
    resolve: [TestProductsResolver],
    component: TestProductsComponent,
    // loadChildren: () =>
    //   import('src/app/pages/test-products/test-products.module').then(
    //     (m) => m.TestProductsModule
    //   ),
    data: {
      title:
        'Bun venit la magazinul online fashion Shardoray.Poți comanda îmbrăcăminte și accesorii pentru bărbati și femei la cele mai bune prețuri.',
      description: '',
      ogUrl: 'your og url',
    },
  },
  {
    path: 'produse/:categorySlug',
    component: ProductsComponent,
    data: {
      title:
        'Bun venit la magazinul online fashion Shardoray.Poți comanda îmbrăcăminte și accesorii pentru bărbati și femei la cele mai bune prețuri.',
      description: '',
      ogUrl: 'your og url',
    },
  },
  {
    path: 'cos-cumparaturi',
    component: CartComponent,
    data: {
      title:
        'Bun venit la magazinul online fashion Shardoray.Poți comanda îmbrăcăminte și accesorii pentru bărbati și femei la cele mai bune prețuri.',
      description: '',
      ogUrl: 'your og url',
    },
  },
  {
    path: 'finalizare-comanda',
    component: CheckoutComponent,
    data: {
      title:
        'Bun venit la magazinul online fashion Shardoray.Poți comanda îmbrăcăminte și accesorii pentru bărbati și femei la cele mai bune prețuri.',
      description: '',
      ogUrl: 'your og url',
    },
  },
  {
    path: 'autentificare',
    component: LoginComponent,
    data: {
      title:
        'Bun venit la magazinul online fashion Shardoray.Poți comanda îmbrăcăminte și accesorii pentru bărbati și femei la cele mai bune prețuri.',
      description: '',
      ogUrl: 'your og url',
    },
  },
  {
    path: 'confirmare-email/:email/:token',
    component: ConfirmationAccountComponent,
  },
  { path: 'verificare-email/:token', component: VerificationEmailComponent },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'contul-meu',
    component: MyAccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'comenzile-mele',
    component: MyOrdersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'comenzile-mele/:orderUUID',
    component: OrderDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cupoane-reducere',
    component: CouponsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'adresele-mele',
    component: AddressesComponent,
    canActivate: [AuthGuard],
  },
  { path: 'tabel-marimi', component: MeasurementsComponent },
  { path: 'comanda-finalizata', component: ThankYouComponent },
  { path: 'plata-esuata', component: InvalidPaymentComponent },
  // { path: 'am-uitat-parola', component: RecoverComponent },
  //{ path: 'am-uitat-parola/:token', component: RecoverComponent },

  // { path: '404', component: NotFoundComponent },

  // { path: 'comanda-finalizata', component: SuccessOrderComponent },
  { path: 'mesaj-trimis', component: ThankYouComponent },
  { path: 'confidentialitate', component: ConfidentialityPolicyComponent },
  { 
    path: 'cum-platesc', component: HowToPayComponent,
    data: {
      title:
        'Cum platesc?',
      description: '',
      ogUrl: 'your og url',
    },
  },
  { path: 'cum-se-livreaza', component: HowToDeliverComponent },
  { path: 'termeni-si-conditii', component: TermsComponent },
  { path: 'politica-retur', component: ReturnComponent },
  {
    path: 'politica-cookies', component: GdprComponent,
    data: {
      title:
        'Gdpr si cookies',
      description: '',
      ogUrl: 'your og url',
    },
  },
  { path: 'formular-retur', component: ReturnFormComponent },
  { path: 'contact', component: ContactComponent },

  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      scrollOffset: [0, 0],
      enableTracing: false,
      scrollPositionRestoration: 'top',
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledBlocking',
    }),
  ],
  providers: [AuthAPIService],
  exports: [RouterModule],
})
export class AppRoutingModule { }
