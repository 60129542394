import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectedProducts'
})
export class SelectedProductsPipe implements PipeTransform {

  transform(items: Array<any>, index: number): Array<any> {
   
    let selectedProduct = items.filter(item => item.index === index);


    
    return selectedProduct[0].return_product_swap
  }
   

}
