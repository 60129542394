import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NewsletterService } from 'src/app/services';
import { DataLayerService } from 'src/app/services/dataLayer.service';
declare let dataLayer: any[];
@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
})
export class NewsletterComponent implements OnInit {
  submitted: boolean = true;
  typing: string = '';
  form = new UntypedFormGroup({
    email: new UntypedFormControl(null, [Validators.email]),
  });

  constructor(
    private _NewsletterService: NewsletterService,
    private _DataLayeService: DataLayerService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {}

  addToNewsletter() {
    this.submitted = true;
    this._NewsletterService
      .addToNewsletter(this.form.value.email)
      .then((data) => {
        if (data.success) {
          this.toaster.success(``, `${data.message}`, {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          this._DataLayeService.reinitializeScript();

          (window as any).dataLayer.push({
            event: '__sm__set_email',
            email_address: this.form.value.email,
          });
          this.form.reset();
        } else {
          this.toaster.warning(``, `${data.message}`, {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
        }
      });
  }

  onBlurMethod() {
    this.typing = this.form.value.email;
  }
}
