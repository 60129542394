<div class="cupon-apply" *ngIf="!(individualDiscountHasApplied$ | async)">
    <form action="#">
      <label for="couponDiscount">Ai un cupon de reducere?</label>
      <div class="form-group">
        <input
          type="text"
          name="couponDiscount"
          id="couponDiscount"
          [(ngModel)]="couponDiscount"
          class="form-control"
          placeholder="Adauga cupon..."
          required
        />

        <button type="submit" (click)="applyCoupon()" class="btn">
          Aplica
        </button>
      </div>
    </form>
  </div>