<nav aria-label="breadcrumb" class="breadcrumb-nav">
  <div class="container">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Acasa</a></li>
      <li class="breadcrumb-item active" aria-current="page">Tabel de marimi</li>
    </ol>
  </div><!-- End .container -->
</nav>
<div class="mb-6"></div>
<div class="container">
  <div class="jas-col-md-12 jas-col-xs-12 mt__60 mb__60" role="main" itemscope="itemscope"
  itemtype="http://schema.org/CreativeWork">
  <p>Masoara-ti dimensiunile pentru a alege marimea potrivita.</p>
  <p><b>Imbracaminte Femei</b></p>
  <p></p>
  <strong>Tabel corespondenta Marimi&nbsp;</strong>
  <p></p>
  <table class="datatable_bordered masuri" border="0" width="782" cellspacing="0" cellpadding="0" align="center">
    <tbody>
      <tr>
        <th align="center" valign="top">Marimi Internationale</th>
        <th align="center" valign="top">Marimi Standard Ro</th>
        
      </tr>
      <tr>
        <td align="center" valign="top">XXS</td>
        <td align="center" valign="top">32</td>
        
      </tr>
      <tr>
        <td align="center" valign="top">XS</td>
        <td align="center" valign="top">34</td>
        
      </tr>
      <tr>
        <td align="center" valign="top">S</td>
        <td align="center" valign="top">36</td>
        
      </tr>
      <tr>
        <td align="center" valign="top">M</td>
        <td align="center" valign="top">38</td>
        
      </tr>
      <tr>
        <td align="center" valign="top">L</td>
        <td align="center" valign="top">40</td>
        
      </tr>
      <tr>
        <td align="center" valign="top">XL</td>
        <td align="center" valign="top">42</td>
        
      </tr>
      <tr>
        <td align="center" valign="top">XXL</td>
        <td align="center" valign="top">44</td>
        
      </tr>
      <tr>
        <td align="center" valign="top">XXXL</td>
        <td align="center" valign="top">46</td>
        
      </tr>
      <tr>
        <td align="center" valign="top">S/M</td>
        <td align="center" valign="top">36/38</td>
        
      </tr>
      <tr>
        <td align="center" valign="top">M/L</td>
        <td align="center" valign="top">38/40</td>
        
      </tr>
      <tr>
        <td align="center" valign="top">L/XL</td>
        <td align="center" valign="top">40/42</td>
        
      </tr>
      <tr>
        <td align="center" valign="top">XL/XXL</td>
        <td align="center" valign="top">42/44</td>
        
      </tr>
      <tr>
        <td align="center" valign="top">XXL/XXXL</td>
        <td align="center" valign="top">44/46</td>
        
      </tr>
    </tbody>
  </table>
  <p>&nbsp;</p>
  <p></p>
  <strong>Tabel marimi pentru articole ce fac parte din categoriile bluze, camasi, tricouri, topuri, sacouri,
      rochii</strong><br>
  
  <p></p>
  <table class="datatable_bordered masuri" border="0" width="40%" cellspacing="0" cellpadding="0" align="center">
    <tbody>
      <tr>
        <th align="center" valign="top">Marime</th>
        <th align="center" valign="top">Circumferinta bust</th>
        <th align="center" valign="top">Circumferinta talie</th>
        <th align="center" valign="top">Circumferinta solduri</th>
      </tr>
      <tr>
        <td align="center" valign="top">32/XXS</td>
        <td align="center" valign="top">74-77 cm</td>
        <td align="center" valign="top">60-62 cm</td>
        <td align="center" valign="top">84-87 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">34/XS</td>
        <td align="center" valign="top">78-81 cm</td>
        <td align="center" valign="top">63-65 cm</td>
        <td align="center" valign="top">88-91 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">36/S</td>
        <td align="center" valign="top">82-85 cm</td>
        <td align="center" valign="top">66-69 cm</td>
        <td align="center" valign="top">92-95 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">38/M</td>
        <td align="center" valign="top">86-89 cm</td>
        <td align="center" valign="top">70-73 cm</td>
        <td align="center" valign="top">96-98 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">40/L</td>
        <td align="center" valign="top">90-93 cm</td>
        <td align="center" valign="top">74-77 cm</td>
        <td align="center" valign="top">99-102cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">42/XL</td>
        <td align="center" valign="top">94-97 cm</td>
        <td align="center" valign="top">78-81 cm</td>
        <td align="center" valign="top">103-106 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">44/XXL</td>
        <td align="center" valign="top">98-102 cm</td>
        <td align="center" valign="top">82-86 cm</td>
        <td align="center" valign="top">107-110 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">46/XXL</td>
        <td align="center" valign="top">103-106 cm</td>
        <td align="center" valign="top">87-90 cm</td>
        <td align="center" valign="top">111-114 cm</td>
      </tr>
    </tbody>
  </table>
  <p>Masurile pentru blugi si unii pantaloni fie sunt compuse dintr-o dimensiune clasica (ex. 27 etc.) care semnifica
    circumferinţa taliei (talie=27×2 cm), fie din 2 dimensiuni (ex.27/31 etc.) din care prima se refera la circumferinta
    taliei iar a doua la lungimea pantalonului.<br>
  </p>
  <strong>Tabel marimi pentru hainele ce fac parte din categoriile&nbsp;<b>(jeans, pantaloni,
        fuste)</b></strong>&nbsp;<p></p>
  <table class="datatable_bordered masuri" border="0" width="40%" cellspacing="0" cellpadding="0" align="center">
    <tbody>
      <tr>
        <th align="center" valign="top">Marime</th>
        <th align="center" valign="top">Circumferinta talie</th>
        <th align="center" valign="top">Circumferinta solduri</th>
      </tr>
      <tr>
        <td align="center" valign="top">32/XXS</td>
        <td align="center" valign="top">60-62 cm</td>
        <td align="center" valign="top">84-87 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">34/XS</td>
        <td align="center" valign="top">63-65 cm</td>
        <td align="center" valign="top">88-91 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">36/S</td>
        <td align="center" valign="top">66-69 cm</td>
        <td align="center" valign="top">92-95 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">38/M</td>
        <td align="center" valign="top">70-73 cm</td>
        <td align="center" valign="top">96-98 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">40/L</td>
        <td align="center" valign="top">74-77 cm</td>
        <td align="center" valign="top">99-102 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">42/XL</td>
        <td align="center" valign="top">78-81 cm</td>
        <td align="center" valign="top">103-106 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">44/XXL</td>
        <td align="center" valign="top">82-86 cm</td>
        <td align="center" valign="top">107-110 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">46/XXL</td>
        <td align="center" valign="top">87-90 cm</td>
        <td align="center" valign="top">111-114 cm</td>
      </tr>
    </tbody>
  </table>
  <p>&nbsp;</p>
  <p></p>
  <strong>Tabel marimi pentru jeans cu marimile cuprinse intre 25 – 31 sau XXS – XXL</strong>&nbsp;<p>
  </p>
  <table class="datatable_bordered masuri" border="0" width="40%" cellspacing="0" cellpadding="0" align="center">
    <tbody>
      <tr>
        <th align="center" valign="top">Marime</th>
        <th align="center" valign="top">Circumferinta talie</th>
        <th align="center" valign="top">Circumferinta solduri</th>
      </tr>
      <tr>
        <td align="center" valign="top">25/XXS</td>
        <td align="center" valign="top">60-62 cm</td>
        <td align="center" valign="top">84-87 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">26/XS</td>
        <td align="center" valign="top">63-65 cm</td>
        <td align="center" valign="top">88-91 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">27/S</td>
        <td align="center" valign="top">66-69 cm</td>
        <td align="center" valign="top">92-95 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">28/M</td>
        <td align="center" valign="top">70-73 cm</td>
        <td align="center" valign="top">96-98 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">29/L</td>
        <td align="center" valign="top">74-77 cm</td>
        <td align="center" valign="top">99-102 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">30/XL</td>
        <td align="center" valign="top">78-81 cm</td>
        <td align="center" valign="top">103-106 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">31/XXL</td>
        <td align="center" valign="top">82-86 cm</td>
        <td align="center" valign="top">107-110 cm</td>
      </tr>
    </tbody>
  </table>
  <p></p>
  <strong>Marime Unica</strong>&nbsp;<p></p>
  <table class="datatable_bordered masuri" border="0" width="90%" cellspacing="0" cellpadding="0" align="center">
    <tbody>
      <tr>
        <td align="center" valign="top">Vei observa ca unele articole apar cu “marime unica”. Aceasta inseamna ca acele
          articole nu au fost confectionate pe masuri, ci pe o singura marime standard.<br>
          Marimea acestora se stabileste de catre noi in urma unor masuratori, pentru a te informa corect si a te ajuta
          sa iei alegerea potrivita tie. Vei observa ca la finalul descrierii produsului cu masura unica va aparea o
          recomandare de marime. Ex: “Recomandat pentru marimea 36”</td>
      </tr>
    </tbody>
  </table>
  <p></p>
  <strong>Marimi Decalate</strong>&nbsp;<p></p>
  <table class="datatable_bordered masuri" border="0" width="90%" cellspacing="0" cellpadding="0" align="center">
    <tbody>
      <tr>
        <td align="center" valign="top">Vei observa ca unele articole apar cu “Atentionarea: Masuri decalate”. Aceasta
          inseamna ca acele articole masurate de noi, se potrivesc diferit fata de marimea scrisa&nbsp;pe eticheta.<br>
          Vei vedea in descriere, recomandarea noastra de achizitie. De exemplu, la unele articole celor care poarta
          marimea S, le este recomandat sa achizitioneze marimea M.</td>
      </tr>
    </tbody>
  </table>
  <p></p>
  <p></p>
  <img src="/assets/images/tabel-marimi-tricouri-barbati.jpeg" alt="">
  <img src="/assets/images/tabel-pantaloni-curti.jpeg" alt="">
  <!-- <p><b>Incaltaminte Femei&nbsp;</b></p>
  <p></p>
  <strong>Tabel corespondenta marimi&nbsp;</strong>
  
  <p></p>
  <table class="datatable_bordered masuri" border="0" width="40%" cellspacing="0" cellpadding="0" align="center">
    <tbody>
      <tr>
        <th align="center" valign="top">Marime</th>
        <th align="center" valign="top">Lungime Interioara</th>
      </tr>
      <tr>
        <td align="center" valign="top">35</td>
        <td align="center" valign="top">22.5 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">36</td>
        <td align="center" valign="top">23 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">37</td>
        <td align="center" valign="top">23.5 cm / 24 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">38</td>
        <td align="center" valign="top">24.5 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">39</td>
        <td align="center" valign="top">25 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">40</td>
        <td align="center" valign="top">25.5 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">41</td>
        <td align="center" valign="top">26 cm</td>
      </tr>
    </tbody>
  </table>
  <p>&nbsp;</p>
  <p><strong>Incaltaminte barbati:&nbsp;</strong></p>
  <table class="datatable_bordered masuri" border="0" width="40%" cellspacing="0" cellpadding="0" align="center">
    <tbody>
      <tr>
        <th align="center" valign="top">Marime</th>
        <th align="center" valign="top">Lungime Interioara</th>
      </tr>
      <tr>
        <td align="center" valign="top">40</td>
        <td align="center" valign="top">25.5 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">41</td>
        <td align="center" valign="top">26 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">42</td>
        <td align="center" valign="top">27 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">43</td>
        <td align="center" valign="top">27.5 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">44</td>
        <td align="center" valign="top">28 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">45</td>
        <td align="center" valign="top">28.5 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">46</td>
        <td align="center" valign="top">29 cm</td>
      </tr>
    </tbody>
  </table>
  <p>Important de tinut minte este ca marimea pantofului nu este data de dimensiunea talpii exterioare, ci de
    dimensiunea talpii interioare.</p> -->
  <!-- <p><strong>Pentru hainele marca Shardoray va rugam sa consultati urmatorul tabel de marimi:&nbsp;</strong></p>
  <table class="datatable_bordered masuri" border="0" width="40%" cellspacing="0" cellpadding="0" align="center">
    <tbody>
      <tr>
        <th align="center" valign="top">Marime</th>
        <th align="center" valign="top">Circumferinta bust</th>
        <th align="center" valign="top">Circumferinta talie</th>
        <th align="center" valign="top">Circumferinta solduri</th>
      </tr>
      <tr>
        <td align="center" valign="top">34</td>
        <td align="center" valign="top">78 – 82 cm</td>
        <td align="center" valign="top">59 – 63 cm</td>
        <td align="center" valign="top">84 – 88 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">36</td>
        <td align="center" valign="top">82 – 86 cm</td>
        <td align="center" valign="top">63 – 67 cm</td>
        <td align="center" valign="top">88 – 92 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">38</td>
        <td align="center" valign="top">86 – 90 cm</td>
        <td align="center" valign="top">67 – 71 cm</td>
        <td align="center" valign="top">92 – 96 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">40</td>
        <td align="center" valign="top">90 – 94 cm</td>
        <td align="center" valign="top">71 – 75 cm</td>
        <td align="center" valign="top">96 – 100 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">42</td>
        <td align="center" valign="top">94 – 98 cm</td>
        <td align="center" valign="top">75 – 79 cm</td>
        <td align="center" valign="top">100 – 104 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">44</td>
        <td align="center" valign="top">98 – 102 cm</td>
        <td align="center" valign="top">79 – 83 cm</td>
        <td align="center" valign="top">104 – 108 cm</td>
      </tr>
      <tr>
        <td align="center" valign="top">46</td>
        <td align="center" valign="top">102 – 106 cm</td>
        <td align="center" valign="top">83 – 87 cm</td>
        <td align="center" valign="top">108 – 112 cm</td>
      </tr>
    </tbody>
  </table> -->
  <p>Pentru o mai buna orientare atunci cand doriti sa comandati un produs, fie ca este vorba despre un obiect
    vestimentar sau un articol de incaltaminte, recomandarea noastra este sa cititi cu atentie descrierea produsului,
    acolo fiind specificate toate dimensiunile pentru articolul folosit in fotografie.</p>
</div>
</div>
<div class="mb-6"></div>