import { Component } from '@angular/core';
import { PaymentMethodService } from '../payment-method/payment-method.service';
import { DeliveryMethodService } from './delivery-method.service';
import { BehaviorSubject, Subject, take, takeUntil, tap } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

// Define the custom type for LockerPlugin configuration
interface LockerPluginConfig {
  clientId: string;
  countryCode: string;
  langCode: string;
  city: string;
  county: string;
  favLockerId: number;
  theme: string;
  apiUsername: string;
  filters: { posOnly: boolean }[];
}

declare global {
  interface Window {
    LockerPlugin: {
      init(config: LockerPluginConfig): void;
      getInstance(): any;
    };
  }
}

@Component({
  selector: 'app-delivery-method',
  templateUrl: './delivery-method.component.html',
  styleUrls: ['./delivery-method.component.scss'],
})
export class DeliveryMethodComponent {
  payment$ = new BehaviorSubject<string>('');
  
  samedayDelivery$ = new BehaviorSubject<string>('curier');
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  samedayEasyboxClinetID: string;
  courierUsername: string;
  lockerAddress$ = new BehaviorSubject<any>({});

  constructor(
    private _PaymentMethodService: PaymentMethodService,
    private _DeliveryMethodService: DeliveryMethodService
  ) {
    this.payment$ = this._PaymentMethodService.payment$;
    this.samedayDelivery$ = this._DeliveryMethodService.samedayDelivery$;
    this.samedayEasyboxClinetID = environment.samedayEasyboxClinetID;
    this.courierUsername = environment.courierUsername;
    this.lockerAddress$ = this._DeliveryMethodService.lockerAddress$;
  }

  // Custom callback function to handle the post message from locker plugin
  getSamedayLokers(msg: any) {
    console.log('I received this msg from locker plugin:', msg);
    this.setLocker(msg);
   
    // Close the modal iframe window
     const pluginInstance = window.LockerPlugin.getInstance();
     pluginInstance.close();
    
  }

  setLocker(msg: any) {
    this._DeliveryMethodService.lockerAddress$.next(msg);
  }

  initSamedayLokers() {
    this._DeliveryMethodService.samedayDelivery$
      .pipe(take(1))
      .subscribe((value) => {});
    this.payment$.pipe(take(1)).subscribe((value) => {
      console.log(value)
      if (value === 'numerar') {
        console.log('numerar')
        return;
      } 
     

      const clientId = this.samedayEasyboxClinetID;
      const countryCode = 'RO';
      const langCode = 'ro';
      const city = 'Stefanestii de jos';
      const county = 'Ilfov';
      const favLockerId = null;
      const theme = 'light';
      const apiUsername = this.courierUsername;
      const filters = [{ posOnly: false }];

      const config: LockerPluginConfig = {
        clientId: clientId,
        countryCode: countryCode,
        langCode: langCode,
        city: city,
        county: county,
        favLockerId: favLockerId,
        theme: theme,
        apiUsername: apiUsername,
        filters: filters,
      };

      window.LockerPlugin.init(config);

      const pluginInstance = window.LockerPlugin.getInstance();

      // Subscribe to the callback function
      pluginInstance.subscribe((msg) => this.getSamedayLokers(msg));

      // Display the modal iframe window
      pluginInstance.open();
    });
  }

  toggleDelivery(value: string) {
   
    

    this._DeliveryMethodService.samedayDelivery$.next(value);
    this._PaymentMethodService.payment$.next(
      value === 'easybox' ? 'card online' : 'numerar'
    );
    
    if(value === 'easybox') {
      this.initSamedayLokers();
    }
    
  }

  ngOnDestroy() {
    this._unsubscribeAll.next('');
    this._unsubscribeAll.complete();
  }
}
